import { Close, OpenInNew } from "@mui/icons-material";
import { Dialog, DialogContent, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import BankCard from "../../UiComponent/BankCard";
import CustomButton from "../../UiComponent/CustomButton";
import { RefrestIcon } from "../../assets/images/FrankIcons";
import noTransaction from "../../assets/images/FrankIcons/notransction.png";
import orangeBackground from "../../assets/images/FrankIcons/orange-background.png";
import apiServices from "../../services/RequestHandler";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import {
  color_theme,
  defaultLabelColors,
  spacing,
  wallet_statuses,
} from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrency,
  numberFormatWithoutCurrencyFixed,
} from "../../utils/helper";
import { useStripe } from "@stripe/react-stripe-js";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import Toast from "../../UiComponent/Toast/Toast";

export default function Wallet(props: any) {
  const selector = useSelector((state: RootState) => state);
  const stripe = useStripe();

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      viewPasswordOld: false,
      viewPasswordNew: false,
      viewPasswordConfirm: false,
      loading: true,
      loadingLoader: true,
      numbrOfjobResult: 5,
      numbrOfAnnouceResult: 5,
      accountDetails: null,
      isBankAvailable: false,
      earningLength: 6,
      user: getDataFromLocalStorage("user"),
      initilalTotalFeedBack: 6,
    }
  );

  React.useEffect(() => {
    handleRetreiveAccount();
    handleGetTransactionDetails();
    // eslint-disable-next-line
  }, []);
  const handleAddBank = async () => {
    try {
      const toke_data = await stripe?.createToken("account", {
        business_type: "individual",
        individual: {
          first_name: selector.mainApp?.user?.first_name,
          last_name: selector.mainApp?.user?.surname,
          email: selector.mainApp?.user?.email,
        },
        tos_shown_and_accepted: true,
      });

      let resp = await apiServices.postFromApi(
        "stripe/create-connected-account",
        {
          token: toke_data?.token?.id,
        },
        ""
      );
      handleURlRedirection(resp?.account);
    } catch (error) {}
  };
  const handleLoginStripe = () => {
    try {
      window.open(`${state?.loginLink}`, "_blank", "noopener, noreferrer");
      handleRetreiveAccount();
    } catch (error) {}
  };

  const handleURlRedirection = async (account_data: any) => {
    try {
      setState({ loadingLoader: false });
      let obj = {
        account_id: account_data?.id,
      };
      setState({ loadingLoader: false });

      let resp = await apiServices.postFromApi("stripe/generate-link", obj, "");
      if (resp?.status === 201 || resp?.status === 200) {
        setState({
          loadingLoader: false,
        });

        window.open(
          `${resp?.accountLink?.url}`, // URL to open
          "_blank", // Open in a new tab
          "noopener,noreferrer" // Security attributes
        );
      }
      setState({ loadingLoader: false });
    } catch (error) {
      setState({ loadingLoader: false });
    }
  };
  const handleRetreiveAccount = async () => {
    try {
      let resp = await apiServices.postFromApi("stripe/reterive-banks", {}, "");
      if (resp?.status === 200 && resp?.details_submitted === true) {
        setState({
          isBankAvailable: true,
          bankDetails: resp?.externalAccounts?.data,
          loginLink: resp?.login_link,
        });
      }
    } catch (error) {}
  };
  const handleMakeTransferRequest = async () => {
    try {
      if (
        state.requested_amount < 1 ||
        !state.requested_amount ||
        state.requested_amount === " "
      ) {
        Toast.Error("Le montant ne peut être inférieur à 1€");
        return;
      }
      if (state.requested_amount > state.total_wallet) {
        Toast.Error(
          selector?.mainApp.lang === "en"
            ? `Sorry, you don’t have enough balance. Your wallet has ${numberFormatWithoutCurrency(
                Number(state.total_wallet ?? 0)
              )} € available.`
            : `Désolé, vous n’avez pas assez de solde. Il vous reste ${numberFormatWithoutCurrency(
                Number(state.total_wallet ?? 0)
              )} € dans votre portefeuille.`
        );
        setState({
          disable_btn: false,
        });
        return;
      }
      // eslint-disable-next-line
      await apiServices.postFromApi(
        "c2c-wallets/requested",
        {
          amount: Number(state.requested_amount),
        },
        ""
      );
      Toast.showSuccess(
        selector.mainApp.lang === "fr"
          ? `💰 Transfert en cours !`
          : "Transfer request initiated! 🚀💰"
      );
      handleGetTransactionDetails();
      setState({
        requested_amount: "",
        openRequestForm: false,
        show_Wallet_success: true,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeForNego = (event: any) => {
    const { value } = event.target;
    if (/^\d*\.?\d{0,3}$/.test(value)) {
      setState({ requested_amount: value });
    }
  };

  const handleGetTransactionDetails = async () => {
    try {
      const resp = await apiServices.getFromApi("c2c-wallets/wallet", "");
      let total_wallet = 0;
      for (const item of resp?.data) {
        if (
          item.transaction_type === "credit" &&
          item?.wallet_status_level === 1
        ) {
          total_wallet += item?.amount;
        } else {
          total_wallet -= item?.amount;
        }
      }
      setState({
        earnings: resp?.data ?? [],
        screen_loading: false,
        total_wallet,
      });
    } catch (error) {
      console.log(error);
      setState({
        screen_loading: false,
      });
    }
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        style={{ columnGap: "50px", height: "100vh" }}
      >
        <Grid
          xs={12}
          md={7}
          lg={7}
          item
          style={{
            background: "#fff",
            borderRadius: "16px",
            padding: "20px",
            // marginRight:'10px'
          }}
        >
          <Grid container spacing={2} style={{ maxHeight: "90vh" }}>
            <Grid xs={12} item>
              <h2
                className="profile-headings"
                style={{
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Raleway-Extra-Bold",
                }}
              >
                Vos transactions
              </h2>
            </Grid>
            <div
              style={{
                overflowY: `${
                  state?.earnings?.length > 6 ? "scroll" : "hidden"
                }`,
                maxHeight: "85vh",
                width: "100%",
              }}
            >
              {!state?.earnings?.length ? (
                <div
                  style={{
                    width: "100%",
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={noTransaction}
                    alt="no-transactions"
                    style={{
                      width: "90%",
                      objectFit: "contain",
                      opacity: "0.5",
                    }}
                  />
                </div>
              ) : (
                state?.earnings
                  ?.slice(0, state?.earningLength)
                  ?.map((i: any, index: any) => {
                    return (
                      <>
                        <Grid
                          xs={12}
                          item
                          style={{
                            borderBottom: "1px solid #E0E0E0",
                            margin: "0px 20px 0px 20px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: 1 }}>
                              <p
                                className="profile-headings"
                                style={{ fontSize: "18px" }}
                              >
                                {i.transaction_type === "credit"
                                  ? i?.is_direct_credit
                                    ? selector?.mainApp?.lang === "en"
                                      ? `Wallet Recharge`
                                      : `Recharge du portefeuille`
                                    : selector?.mainApp?.lang === "en"
                                    ? `Delivery Payment`
                                    : `Paiement de Frank`
                                  : selector?.mainApp?.lang === "en"
                                  ? "Wallet Withdrawal"
                                  : "Transfert vers le compte bancaire"}
                              </p>
                              <p className="profile-text">
                                {" "}
                                {/* {moment(i?.created_at).format(
                                          "DD/MM/YYYY "
                                        ) ?? "10/02/22"} */}
                                {i?.created_at
                                  ? moment(
                                      `${i?.created_at}`.endsWith("Z")
                                        ? i?.created_at
                                        : i?.created_at + "Z"
                                    ).format("DD/MM/YYYY HH:mm")
                                  : "- - -"}
                              </p>
                            </div>
                            <div
                              className="display-flex"
                              style={{ flexDirection: "column" }}
                            >
                              <span
                                style={{
                                  padding: "10px 15px 10px 15px",
                                  fontFamily: "Raleway-Bold",
                                  width: "100%",
                                  fontSize: 16,
                                  textAlign: "right",
                                  color:
                                    i.transaction_type === "credit" &&
                                    i?.wallet_status_level === 1
                                      ? color_theme.green
                                      : color_theme.red,
                                  verticalAlign: "middle",
                                }}
                              >
                                {i.transaction_type === "credit" &&
                                i?.wallet_status_level === 1
                                  ? "+"
                                  : "-"}
                                {numberFormatWithoutCurrencyFixed(
                                  Number(i?.amount ?? 0),
                                  selector?.mainApp.lang === "fr"
                                    ? "en-FR"
                                    : "en-US"
                                )}
                                €
                                <br />
                                <br />
                              </span>
                              <span
                                style={{
                                  color: "#000",
                                  fontSize: "18px",
                                  fontFamily: "Raleway-Semi-Bold",
                                }}
                              >
                                {handleLocalize(
                                  wallet_statuses[i?.wallet_status ?? "wallet"]
                                )}
                              </span>
                            </div>
                          </div>
                        </Grid>
                      </>
                    );
                  })
              )}

              {state?.earnings?.length > 6 ? (
                <Grid xs={12} item className="display-flex">
                  <p
                    className="profile-headings display-flex"
                    style={{
                      color: "#3026f1",
                      textAlign: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setState({
                        earningLength: state?.earningLength + 6,
                      });
                    }}
                  >
                    <RefrestIcon /> Charger plus de transactions
                  </p>
                </Grid>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          md={4}
          lg={4}
          item
          style={{
            padding: "20px",
            background: "#fff",
            borderRadius: "16px",
            height: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <h2
                className="profile-headings"
                style={{
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Raleway-Extra-Bold",
                }}
              >
                {"Montant disponible"}
              </h2>
            </Grid>

            <Grid xs={12} item>
              <div
                style={{
                  background: "#F4F3F6",
                  borderRadius: "12px",
                  padding: "30px 20px",
                  maxWidth: "100%",
                  boxSizing: "border-box",
                  textAlign: "center",
                  overflow: "hidden", // Hides overflow
                  wordWrap: "break-word", // Wraps long words to prevent overflow
                }}
              >
                <p
                  style={{
                    fontSize: "2rem", // Responsive font sizing (use rems)
                    fontFamily: "Raleway-Semi-Bold",
                    textAlign: "center",
                    color: "gray",
                    margin: 0, // Remove default paragraph margins
                    lineHeight: "1.2", // Adjust line height for better readability
                  }}
                >
                  <span
                    style={{
                      color: defaultLabelColors.main_blue,
                      fontSize: "inherit", // Inherits the parent font size
                    }}
                  >
                    {state?.total_wallet
                      ? numberFormatWithoutCurrencyFixed(state?.total_wallet)
                      : "0,00"}
                    €
                  </span>
                </p>
              </div>
            </Grid>
            <Grid xs={12} item>
              {state?.bankDetails ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 7,
                      fontFamily: "Raleway-Variable",
                      cursor: "pointer",
                      marginBottom: "5px",
                    }}
                    onClick={handleLoginStripe}
                    // onClick={()=>{setState({openForm:true})}}
                  >
                    Link Bank Accounts{" "}
                    <OpenInNew
                      fontSize="small"
                      style={{
                        color: defaultLabelColors.main_orange,
                      }}
                    />
                  </div>
                  {state?.bankDetails?.map((i: any) => {
                    return (
                      <div style={{ width: "100% " }}>
                        <BankCard
                          BankName={i?.bank_name}
                          cardNumber={i?.last4}
                          AcountName={i?.account_holder_name}
                        />
                      </div>
                    );
                  })}
                  <CustomButton
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "#fff",
                      fontFamily: "Raleway-Variable",
                      background: state?.total_wallet < 1 && state?.bankDetails?.length ? "lightgrey" : "#ee6931",
                      border: "none",
                      borderRadius: "12px",
                      padding: "10px 30px 10px 30px",
                      width: "100%",
                      marginTop: "10px",
                    }}
                    value={"Transférer vers un compte bancaire "}
                    disable={ state?.bankDetails?.length  && state?.total_wallet < 1 ? true : false}
                    // onClick={handleMakeTransferRequest}
                    onClick={() => {
                      if (state?.bankDetails?.length < 1) {
                        handleLoginStripe();
                      } else {
                        setState({ openRequestForm: true });
                      }
                    }}
                  />{" "}
                </>
              ) : (
                <div
                  style={{
                    paddingTop: "40px",
                    position: "relative",
                    zIndex: 111,
                    flexDirection: "column",
                  }}
                  className="display-flex"
                >
                  <img
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "220px",
                    }}
                    src={orangeBackground}
                    alt=""
                  />
                  <p
                    style={{
                      fontSize: "22px",
                      fontFamily: "Raleway-Extra-Bold",
                      color: "#fff",
                      zIndex: 111,
                    }}
                  >
                    Merci Frank
                  </p>

                  <CustomButton
                    style={{
                      background: "#fff",
                      color: "#ee6931",
                      fontSize: "16px",
                      fontFamily: "Raleway-Variable",
                      border: "none",
                      borderRadius: "12px",
                      // padding: "10px 10px 10px 10px",
                      // width: "max-content",
                      // maxWidth: "450px",
                      height: "50px",
                    }}
                    value={"Transférer vers un compte bancaire"}
                    onClick={() => {
                      setState({
                        loadingLoader: true,
                      });
                      handleAddBank();
                    }}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DraggableDialog
        maxWidth={"sm"}
        dialog_title={"Demande de retrait"}
        open={state?.openRequestForm}
        handleClose={() => {
          setState({ openRequestForm: false, requested_amount: "" });
        }}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              background: "#fff",
            }}
            dividers
          >
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <div>
                  <div></div>

                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: "1px",
                      marginTop: "20px",
                      gap: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Bold",
                        color: "#281208",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      Entrez le montant que vous souhaitez transférer vers votre
                      banque
                    </p>

                    <div
                      className="display-flex"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type="number"
                        onChange={handleChangeForNego}
                        style={{
                          fontSize: "54px",
                          fontFamily: "Raleway-Bold",
                          width: "100%",
                          border: "none",
                          textAlign: "center",
                          color: defaultLabelColors.main_orange,
                        }}
                        value={state?.requested_amount}
                        onKeyDown={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                            return;
                          }
                        }}
                        placeholder="ex : 7,50 €"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      value={"Annuler"}
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#000",
                        border: "1px solid #BCBBC4",
                        padding: "12px 24px",
                        borderRadius: "12px",
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        setState({
                          openRequestForm: false,
                          requested_amount: "",
                        });
                      }}
                    />
                    <CustomButton
                      value={"Valider"}
                      style={{
                        width: "100%",
                        background: defaultLabelColors.main_orange,
                        color: color_theme.white,
                        border: `1px solid ${defaultLabelColors.main_orange}`,
                        padding: "12px 24px",
                        borderRadius: "12px",
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                      disable={state?.disable_btn}
                      onClick={handleMakeTransferRequest}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        }
      />

      <Dialog open={state.show_Wallet_success}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Close
            style={{ margin: 10, cursor: "pointer" }}
            onClick={() => {
              setState({ show_Wallet_success: false });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 32,
            rowGap: 14,
          }}
        >
          <p style={{ fontFamily: "Raleway-Extra-Bold", fontSize: "32px" }}>
            💰 Transfert en cours !
          </p>
          <p style={{ fontFamily: "Raleway-Semi-Bold", fontSize: "20px" }}>
            Votre transfert vers votre compte bancaire a été validé.
            <br />
            Délai estimé : jusqu'à 5 jours ouvrés selon votre banque.
          </p>
        </div>
      </Dialog>
    </>
  );
}
