import { Box, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  DeleteGreyIcon,
  EditGreyIcon,
  ImageGreyIcon,
} from "../../assets/images/FrankIcons";
import { RootState } from "../../Store";
import FrankConstants from "../../utils/FrankConstants";
 
function CustomAnnouceCard(props: any) {
  const selector = useSelector((state: RootState) => state.mainApp)
  const {
    productTitle,
    productImage,
    productheight,
    productLength,
    productWidth,
    imageQuantity,
    handleEdit,
    shipmentQuantity,
    productSize,
    handleRemove,
  } = props;

   

   const deliveryType = FrankConstants.packageSize(selector.lang)?.find(
      (a: any) => a.key === productSize
    );

    
    
  return (
    <Box sx={styles.card}>
      <Box sx={styles.imageContainer} >
        <img
          src={productImage ?? "https://via.placeholder.com/100"}
          alt="Table en bois clair"
          style={{width: "100%",
            borderRadius: "8px",
            maxHeight:"100px",
            objectFit:"contain"}}
         />
      </Box>
      <Box sx={styles.detailsContainer}>
        <Typography sx={styles.title}>
          {productTitle ?? "Table en bois clair"}
        </Typography>
        <Typography sx={styles.dimensions}>
          {productLength ?? 210} x {productWidth ?? 120} x {productheight ?? 40}
        </Typography>
        <Box sx={styles.iconTextContainer}>
          <ImageGreyIcon />
          <Typography sx={styles.imageCount}>x {imageQuantity ?? 3}</Typography>
        </Box>
      </Box>
      <Box sx={styles.rightContainer}>
        <Typography sx={styles.quantity}>x{shipmentQuantity ?? 1}</Typography>
        <Typography sx={styles.size}>{   deliveryType?.size_trans ?? "Extra large"}{shipmentQuantity > 1 ? "s" : null}   </Typography>
      </Box>
      <Box sx={styles.actionIcons}>
        <IconButton onClick={handleEdit} sx={styles.iconButton}>
          <EditGreyIcon />
        </IconButton>
        <IconButton onClick={handleRemove} sx={styles.iconButton}>
          <DeleteGreyIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
const styles = {
  card: {
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between',

    backgroundColor: '#f4f3f6',
    borderRadius: "16px",
    height: "100%",
    minHeight: "155px",
    // margin:"0px 5px"
  },
  imageContainer: {
    flex: "0 0 110px",
    marginRight: "16px",
    marginLeft: "16px",
  },
  image: {
    
  },
  detailsContainer: {
    flex: "1 1 auto",
  },
  title: {
     color: "#20202d",
    fontSize: "18px",
    fontFamily: "Raleway-Extra-Bold",
  },
  dimensions: {
    color: "#888",
    fontSize: "14px",
    fontFamily: "Raleway-Variable",
  },
  iconTextContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "8px",
  },
  icon: {
    color: "#888",
    marginRight: "4px",
  },
  imageCount: {
    color: "#888",
    fontFamily: "Raleway-Variable",
    fontSize: "16px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    marginLeft: "auto",
    marginRight: "16px",
  },
  quantity: {
     color: "#20202d",
    fontSize: "16px",
    fontFamily: "Raleway-Extra-Bold",
  },
  size: {
    color: "#20202d",
    fontSize: "14px",
    textTransform: "Capitalize",
    fontFamily: "Raleway-Extra-Bold",
   },
  actionIcons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  iconButton: {
    padding: "8px",
  },
  actionIcon: {
    color: "#888",
  },
};
CustomAnnouceCard.defaultProps = {
  productTitle: "",
  productImage: "",
  productheight: "",
  productLength: "",
  productWidth: "",
  imageQuantity: "",
  shipmentQuantity: "1",
  productSize: "",
  handleEdit: {},
};

CustomAnnouceCard.propTypes = {
  productTitle: PropTypes.any,
  productImage: PropTypes.any,
  productheight: PropTypes.any,
  productLength: PropTypes.any,
  productWidth: PropTypes.any,
  imageQuantity: PropTypes.any,
  shipmentQuantity: PropTypes.any,
  productSize: PropTypes.any,
  handleEdit: PropTypes.func,
  handleRemove: PropTypes.func,
};
export default CustomAnnouceCard;
