import { Send } from "@mui/icons-material";
import { DialogContent, Grid, IconButton } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { debounce } from "lodash";
import moment from "moment";
import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import DraggableDialog from "../../UiComponent/DraggableDialog";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import { RefrestIcon } from "../../assets/images/FrankIcons";
import { BASE_URL } from "../../services/HttpProvider";
import apiServices from "../../services/RequestHandler";
import { decrypt } from "../../utils/Encrypt";
import { getDataFromLocalStorage } from "../../utils/LocalStore.util";
import {
  color_theme,
  defaultLabelColors,
  no_image_url,
  spacing,
  stripePromise,
} from "../../utils/constants";
import {
  currencyFormat,
  handleLocalize,
  numberFormatWithoutCurrency,
} from "../../utils/helper";
import { firestoreDB } from "../../utils/notificationListner";
import { FrankDivider } from "../MarketPlace/Checkout";
import StripeCheckout from "../MyAnnounces/StripeCheckout";

export function MessageComponent({ item, user, chatMessages, index }: any) {
  const status = item?.sender_id !== `${user?.id ?? ""}`;
  let prev_msg_date =
    index === 0
      ? chatMessages?.[index]?.created_at
      : chatMessages?.[index - 1]?.created_at;
  const prev_msg_date_moment = moment(prev_msg_date);
  const renderDateHeader = React.useMemo(() => {
    return (
      <div
        style={{ placeContent: "center", margin: "10px 0px", display: "flex" }}
      >
        <span style={{ color: "gray", fontSize: 12 }}>
          {prev_msg_date_moment.format("MMM D, YYYY")}
        </span>
      </div>
    );
  }, [prev_msg_date_moment]);

  // eslint-disable-next-line
  if (item?.file_type === "location" && typeof item?.location === "string") {
    // loc = JSON.parse(item?.location);
  } else {
    // loc = item?.location;
  }

  return (
    <div key={`ITEM_MESSAGE_01_${index + 1}`}>
      {item?.is_negotiate || item?.order_details
        ? null
        : index === 0
        ? renderDateHeader
        : // eslint-disable-next-line
        prev_msg_date_moment.format("YYYY-MM-DD") ===
          prev_msg_date_moment.format("YYYY-MM-DD")
        ? null
        : renderDateHeader}
      <div
        style={{
          borderRadius: 10,
          alignItems:
            item?.is_negotiate || item?.order_details
              ? "center"
              : status
              ? "end"
              : "flex-end",
          justifyContent:
            item?.is_negotiate || item?.order_details
              ? "center"
              : status
              ? "flex-start"
              : "flex-end",
          marginBottom: 10,
          display: item?.is_negotiate || item?.order_details ? "none" : "flex",
        }}
      >
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 10,
            display: "flex",
            justifyContent:
              item?.is_negotiate || item?.order_details
                ? "center"
                : status
                ? "flex-start"
                : "flex-end",
            maxWidth: "75%",
          }}
        >
          <div
            style={{
              overflowWrap: "anywhere",
              fontFamily: "Raleway-Variable",
              padding: "7px 10px",
              paddingBottom: 13,

              position: "relative",
              backgroundColor: item?.order_details
                ? color_theme.card_background
                : item?.is_negotiate
                ? color_theme.card_background
                : status
                ? color_theme.main_orange
                : "lightgrey",
              borderRadius: 10,
            }}
          >
            {item?.order_details ||
            item?.is_negotiate ? null : item?.audio_url ? null : item?.file_url ? (
              <div>
                {item?.file_type === "doc" ? (
                  <div
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h1>DOCICON</h1>
                    <span
                      style={{
                        color: status ? color_theme.white : color_theme.black,
                        fontSize: 12,
                        fontFamily: "Epilogue",
                        lineHeight: 16,
                        paddingLeft: 10,
                      }}
                    >
                      {item?.file_name ?? "Document"}{" "}
                    </span>
                  </div>
                ) : (
                  <img
                    style={{
                      height: 200,
                      width: 200,
                      objectFit: "contain",
                    }}
                    src={item?.file_url}
                    alt="imge"
                  />
                )}
              </div>
            ) : item?.message ? (
              <span
                style={{
                  color: status ? color_theme.white : color_theme.black,
                  fontSize: 16,
                  fontFamily: "Raleway-Variable",
                }}
              >
                {item?.message ?? ""}
              </span>
            ) : item?.file_type === "location" ? (
              <div
                style={{
                  height: 150,
                  width: "100%",
                  background: "red",
                }}
                // onPress={async () => {
                //   const lat = loc.coordinates[0];
                //   const lng = loc.coordinates[1];
                //   const scheme = Platform.select({
                //     ios: `maps://${loc.coordinates[0]},${loc.coordinates[1]}?q=`,
                //     android: `geo:${loc.coordinates[0]},${loc.coordinates[1]}?q=`,
                //   });
                //   const latLng = `${lat},${lng}`;
                //   const label = `${item?.sender_name}'s location`;
                //   const url = Platform.select({
                //     ios: `${scheme}${label}@${latLng}`,
                //     android: `${scheme}${latLng}(${label})`,
                //   });
                //   Linking.openURL(url as string);
                // }}
              >
                {/* <MapView
                  scrollEnabled={true}
                  rotateEnabled={true}
                  zoomEnabled={true}
                  provider={PROVIDER_GOOGLE}
                  style={StyleSheet.absoluteFillObject}
                  region={{
                    latitude: loc.coordinates[0] ?? 48.85806249896775,
                    longitude: loc.coordinates[1] ?? 2.3535707423777468,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA,
                  }}
                >
                  <Marker
                    coordinate={{
                      latitude: loc?.coordinates[0] ?? 48.85806249896775,
                      longitude: loc?.coordinates[1] ?? 2.3535707423777468,
                    }}
                  >
                    <Image
                      source={MapMarker}
                      style={{
                        height: 20,
                        width: 20,
                        resizeMode: "contain",
                      }}
                    />
                  </Marker>
                </MapView> */}
              </div>
            ) : null}
          </div>
        </div>
        {!item?.is_negotiate && !item?.order_details && (
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <span
              style={{
                marginLeft: 5,
                paddingRight: 5,
                color: color_theme.black,
                fontSize: 12,
                fontFamily: "Raleway-Variable",
                marginTop: 2,
              }}
            >
              {moment(
                item?.created_at
                  ? `${item?.created_at}`.endsWith("Z")
                    ? item?.created_at
                    : item?.created_at + "Z"
                  : new Date()
              ).format("HH:mm")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

interface AnnouncementChatProps {
  // Define the props interface based on the expected props
  // Add specific prop types if needed, for now, 'any' is used as a placeholder.
  announcement_details: any;
  chat_details: any;
  // [key: string]: any;
}

const AnnouncementChat: React.FC<AnnouncementChatProps> = (props) => {
  const { announcement_details } = props;
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [currentLocation, setCurrentLocation] = React.useState<any>();
  const [isTyping, setTypingUsers] = React.useState<boolean>(false);

  const scrollRef = React.useRef<any>();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      recording: "stop",
      latitude: 48.85806249896775,
      longitude: 2.3535707423777468,
      loading_screen: true,
      chat_details: props?.chat_details?.[0],
      doNotShowPromo: true,
    }
  );

  const handleChangeForNego = (event: any) => {
    const { value } = event.target;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setState({ new_nego_price: value });
    }
  };
  const [chatMessages, setChatMessages] = React.useState<any>([]);
  const selector = useSelector((state: RootState) => state.mainApp);
  const [message, setMessage] = React.useState("");
  // eslint-disable-next-line
  const [user, setUser] = React.useState(getDataFromLocalStorage("user"));

  const typingStatusRef = doc(
    firestoreDB,
    "typing_status",
    `${announcement_details?.receiver_id ?? ""}_${user?.id ?? ""}`
  );

  const updateTypingStatus = async (isTyping: any) => {
    try {
      if (isTyping) {
        await setDoc(
          typingStatusRef,
          {
            chatId: `${announcement_details?.receiver_id ?? ""}_${
              user?.id ?? ""
            }`,
            user_id: `${user?.id}`,
            isTyping: true,
            resource_id: `${announcement_details?.id ?? ""}`,
          },
          { merge: true }
        );
      } else {
        await updateDoc(typingStatusRef, { isTyping: false });
      }
    } catch (error) {
      console.error("Error updating typing status:", error);
    }
  };

  const debouncedStopTyping = debounce(() => {
    updateTypingStatus(false);
  }, 3000); // Stop typing after 3 seconds of inactivity

  React.useEffect(() => {
    // Cleanup on component unmount
    return () => {
      debouncedStopTyping.cancel(); // Cancel any pending debounce calls
      updateTypingStatus(false); // Ensure typing status is cleared
    };
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getMessages();
    handleGetUserCurrentLocation();
    // eslint-disable-next-line
  }, [JSON.stringify(announcement_details)]);

  React.useEffect(() => {
    const messagesRef = collection(firestoreDB, "chat_negotiations");

    const q1 = query(
      messagesRef,
      where("sender_id", "in", [
        `${user?.id}`,
        `${announcement_details?.receiver_id}`,
      ]),
      where("receiver_id", "in", [
        `${user?.id}`,
        `${announcement_details?.receiver_id}`,
      ]),
      where("order_id", "==", `${announcement_details?.id}`)
    );

    const unsubscribe1 = onSnapshot(q1, (snapshot) => {
      let newMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      newMessages = newMessages.sort((a: any, b: any) =>
        new Date(a.created_at).getTime() < new Date(b.created_at).getTime()
          ? -1
          : 1
      );
      console.log("newMessages", newMessages);

      setChatMessages(newMessages);
      getMessages();
    });

    const typingQuery = query(
      collection(firestoreDB, "typing_status"),
      where("chatId", "in", [
        `${announcement_details?.receiver_id ?? ""}_${user?.id ?? ""}`,
        `${user?.id ?? ""}_${announcement_details?.receiver_id ?? ""}`,
      ]),
      where("resource_id", "==", `${announcement_details?.id}`),
      where("user_id", "!=", `${user?.id}`),
      where("isTyping", "==", true)
    );

    const unsubscribe = onSnapshot(typingQuery, (snapshot) => {
      const users = snapshot.docs.map((doc) => doc.data());

      if (users.length > 0) {
        setTypingUsers(true);
      } else {
        setTypingUsers(false);
      }
    });

    return () => {
      unsubscribe();
      unsubscribe1();
    };
    // eslint-disable-next-line
  }, [announcement_details]);

  React.useEffect(() => {
    if (chatMessages) {
      const scrollIntoView = () => {
        if (scrollRef?.current) {
          scrollRef.current.scrollTop = scrollRef?.current?.scrollHeight + 100;
        }
      };
      return scrollIntoView;
    }
    // eslint-disable-next-line
  }, [chatMessages]);

  React.useEffect(() => {
    if (
      props?.chat_details?.ship_status_level === 2 &&
      props?.chat_details?.payment_status !== "Paid"
    ) {
      initPayment();
    }
    // eslint-disable-next-line
  }, [props?.chat_details, state.product_details]);

  React.useEffect(() => {
    initPayment();
    // eslint-disable-next-line
  }, [props?.chat_details?.ship_status]);

  const initPayment = async () => {
    try {
      let total_amt = 0;

      if (props?.chat_details?.handling) {
        total_amt =
          props?.chat_details?.order_amount_detail?.sub_total > 0
            ? Number(props?.chat_details?.order_amount_detail?.sub_total ?? 0)
            : Number(
                props?.chat_details?.agree_ship_price ??
                  props?.chat_details?.ship_price ??
                  0
              ) +
              Number(
                (Number(
                  props?.chat_details?.agree_ship_price ??
                    props?.chat_details?.ship_price ??
                    0
                ) /
                  100) *
                  Number(
                    props?.chat_details?.handling?.handling_charges_percent
                  )
              ) +
              Number(props?.chat_details?.service_charges ?? 0);
      } else {
        total_amt =
          props?.chat_details?.order_amount_detail?.sub_total > 0
            ? Number(props?.chat_details?.order_amount_detail?.sub_total ?? 0) +
              Number(props?.chat_details?.service_charges ?? 0)
            : Number(
                props?.chat_details?.agree_ship_price ??
                  props?.chat_details?.ship_price ??
                  0
              ) + Number(props?.chat_details?.service_charges ?? 0);
      }

      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card",
        {
          ship_id: Number(props?.chat_details?.id),
        },
        ""
      );
      let decryptData = JSON.parse(decrypt(response.pi_key));
      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }
      const { paymentIntent } = decryptData ?? {};

      const options = {
        theme: "stripe",

        clientSecret: paymentIntent,
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: '"Raleway-Variable", sans-serif',
          },
          rules: {
            ".Label": {
              fontFamily: '"Raleway-Variable", sans-serif',
              fontSize: "14px", // Adjust the size as needed
              color: "#000000", // Change the color if needed
            },
          },
        },
      };
      setState({ options, enable_stripe: true, total_amt });
    } catch (error) {
      console.log(error);
    }
  };

  const getMessages = async () => {
    const item = announcement_details;
    try {
      // let obj: any = {
      //   receiver_id: `${item?.receiver_id}`,
      //   ship_id: Number(item?.id),
      // };

      // const messagesRef = collection(firestoreDB, "chat_negotiations");
      // const q = query(
      //   messagesRef,
      //   where("sender", "in", [`${user?.id}`, `${item?.receiver_id}`]), // Sender is either user
      //   where("receiver", "in", [`${user?.id}`, `${item?.receiver_id}`]), // Receiver is either user
      //   orderBy("timestamp", "asc") // Sort by time
      // );

      // const resp = await apiServices.postFromApi(
      //   "/chat/get-messages-by-user-by-announce",
      //   obj,
      //   ""
      // );

      if (item?.id) {
        const resp2 = await apiServices.postFromApi(
          `c2c-shipments/details`,
          {
            id: Number(item?.id),
            customer_id: Number(item?.customer_id),
          },
          ""
        );
        setState({
          product_details: resp2?.data,
          loading_screen: false,
        });
      }

      // if (resp?.data?.length > 0) {
      //   setChatMessages(resp?.data ?? []);
      // } else {
      //   setChatMessages([]);
      // }

      setState({
        loading_screen: false,
        refreshing: false,
      });
    } catch (error) {
      setState({
        loading_screen: false,
        refreshing: false,
      });
      console.log(error);
    }
  };

  const handleGetUserCurrentLocation = async () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const latitude = position.coords.latitude;
          // const longitude = position.coords.longitude;
          const pickupLocation = await apiServices.getFromApi(
            `helper/get-address?lat=${latitude}&lng=${latitude}`,
            ""
          );
          setCurrentLocation(pickupLocation?.result);
        });
      } else {
        console.log("Geolocation not supported");
      }
    } catch (error) {
      console.error("Error in location retrieval:", error);
    }
  };

  const handleCounterOffer = async () => {
    try {
      setState({
        disable_link_btn: true,
      });

      if (!state?.new_nego_price) {
        Toast.Error("Entrez le montant s'il vous plaît");
        setState({
          disable_link_btn: false,
          disable_btn: false,
        });
        return;
      }

      let text = state?.new_nego_price
        .replaceAll("،", ".")
        .replaceAll(".", ".")
        .replaceAll(",", ".")
        .replaceAll("，", ".");
      if (isNaN(Number(text))) {
        alert(
          selector.lang === "fr"
            ? `Veuillez entrer un montant valide.`
            : "Please enter a valid amount"
        );
        setState({
          disable_btn: false,
          disable_link_btn: false,
        });
        return;
      }

      const resp = await apiServices.postFromApi(
        "c2c-shipment-negotiations/reject",
        {
          negotiation_id: state?.lastOffer?.id,
        },
        ""
      );
      if (resp.status === 200) {
        handleNewMessageWithNegotiate(state?.new_nego_price);
        setState({
          disable_link_btn: false,
          new_nego_price: "",
        });
      } else {
        Toast.Error(
          `${
            typeof resp?.error === "string"
              ? resp?.error
              : resp?.error?.message ??
                resp?.error?.isNotEmpty ??
                resp?.error?.error ??
                ""
          }`
        );
        setState({
          disable_link_btn: false,
          disable_btn: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewMessage = async (path?: any, _document?: any) => {
    try {
      const item = announcement_details;
      setTimeout(() => {
        setState({ disable_btn: false });
      }, 3000);

      let new_msg: any = {
        message: message ?? null,
        message_status: "sent",
        order_id: `${item?.id}`,
        is_negotiate: false,
        order_details: null,
        sender_id: `${user?.id}`,
        ship_id: item?.id,
        created_at: new Date().toISOString(),
      };
      const findCustomer = state.product_details?.negotiations?.find(
        (i: any) => i.accepter_id
      );

      if (`${user?.id}` === `${state.product_details?.customer_id}`) {
        new_msg.receiver_id = `${findCustomer?.accepter_id}`;
      } else {
        new_msg.receiver_id = `${state.product_details?.customer_id}`;
      }

      if (state.show_preview && state.upload_type === "location") {
        new_msg.location = {
          type: "Point",
          coordinates: [state.latitude ?? 0, state.longitude ?? 0],
        };
        new_msg.file_type = "location";
      }
      setState({
        show_preview: false,
      });
      console.log("new_msg", new_msg);
      if (path) {
      } else if (_document) {
        let formData = new FormData();
        formData.append("file", _document);
        new_msg.file_type = state.upload_type;
        new_msg.file_name = _document?.name ?? _document?.fileName;
        new_msg.file_size = `${_document?.size}`;
        new_msg.file_url = _document?.uri;
        chatMessages.push(new_msg);
        const resp = await axios({
          baseURL: `${BASE_URL}upload/upload-file`,
          headers: {
            "Content-Type": "multipart/form-data", // this is important
          },
          method: "post",
          data: formData,
        });
        if (resp.data?.url) {
          new_msg.file_url = resp.data?.url;
          await addDoc(collection(firestoreDB, `chat_negotiations`), new_msg);
          await apiServices.postFromApi("chat/send-new-message", new_msg, "");
          setMessage("");
        }
      } else {
      await addDoc(
          collection(firestoreDB, "chat_negotiations"),
          new_msg
        );
        await apiServices.postFromApi("chat/send-new-message", new_msg, "");
        setMessage("");
      }
      setState({
        temp_document: null,
        upload_type: null,
        temp_gallery: null,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleNewMessageWithNegotiate = async (amount?: number) => {
    try {
      const item = announcement_details;
      setTimeout(() => {
        setState({
          disable_btn: false,
        });
      }, 3000);
      let text = amount
        ? amount
        : message
            .replaceAll("،", ".")
            .replaceAll(".", ".")
            .replaceAll(",", ".")
            .replaceAll("，", ".");
      if (isNaN(Number(text))) {
        alert(
          selector.lang === "fr"
            ? `Veuillez entrer un montant valide.`
            : "Please enter a valid amount"
        );
        setState({
          disable_btn: false,
        });
        return;
      }
      let new_msg: any = {
        message: `has sent you a message. neg_amt=${text}`,
        message_status: "sent",
        order_id: item?.id,
        is_negotiate: true,
        order_details: null,
        sender_id: `${user?.id}`,
        created_at: new Date().toISOString(),
      };
      const findCustomer = state.product_details?.negotiations?.find(
        (i: any) => i.accepter_id
      );

      if (`${user?.id}` === `${state.product_details?.customer_id}`) {
        new_msg.receiver_id = `${findCustomer?.accepter_id}`;
      } else {
        new_msg.receiver_id = `${state.product_details?.customer_id}`;
      }

      let obj = {
        parent_id:
          state?.product_details?.negotiations?.[
            state?.product_details?.negotiations?.length - 1
          ]?.id,
        ship_id: Number(item?.id),
        ship_price: Number(text ?? 0),
      };
      const resp = await apiServices.postFromApi(
        "/c2c-shipment-negotiations",
        obj,
        ""
      );
      if (resp.status === 201) {
        Toast.showSuccess(strings.offer_request_sent);
        // await apiServices.postFromApi("chat/send-new-message", new_msg, "");
        await addDoc(collection(firestoreDB, `chat_negotiations`), new_msg); //chat/send-new-message

        getMessages();
        setMessage("");
        setState({
          temp_document: null,
          upload_type: null,
          temp_gallery: null,
          openOffer: false,
          disable_btn: false,
          disable_link_btn: false,
        });
      } else {
        Toast.Error(
          `${resp.error?.message ?? resp?.message ?? resp?.error?.error ?? ""}`
        );
        setState({
          disable_link_btn: false,
          disable_btn: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStripeCallBack = async () => {
    try {
      getMessages();

      setState({
        options: null,
        showStripeForm: false,
        show_stripe: false,
        showValidPayment: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const _last_message = chatMessages?.[chatMessages?.length - 1];
  const last_nego =
    state.product_details?.negotiations?.[
      state.product_details?.negotiations?.length - 1
    ];

  const findAccepter = state.product_details?.negotiations?.find(
    (i: any) => i.accepter_id // && i?.is_accepted
  );

  const handleValidatePromo = async () => {
    try {
      const data = state.product_details;
      let total_amt = 0,
        platform_fee = Number(data?.service_charges ?? 0);
      if (data?.handling) {
        total_amt =
          data?.order_amount_detail?.sub_total > 0
            ? Number(data?.order_amount_detail?.sub_total ?? 0)
            : Number(data?.agree_ship_price ?? data?.ship_price ?? 0) +
              Number(
                (Number(data?.agree_ship_price ?? data?.ship_price ?? 0) /
                  100) *
                  Number(data?.handling?.handling_charges_percent)
              ) +
              Number(data?.service_charges ?? 0);
      } else {
        total_amt =
          data?.order_amount_detail?.sub_total > 0
            ? Number(data?.order_amount_detail?.sub_total ?? 0) +
              Number(data?.service_charges ?? 0)
            : Number(data?.agree_ship_price ?? data?.ship_price ?? 0) +
              Number(data?.service_charges ?? 0);
      }
      const promo_resp = await apiServices.postFromApi(
        "c2c-promo-codes/validate-announce",
        {
          order_id: Number(state.product_details?.id),
          channel: "web",
          actual_order_amount: Number(
            data?.agree_ship_price ?? data?.ship_price ?? 0
          ),
          total_shipment_amt: 0,
          platform_fee: platform_fee,
          total_amount: total_amt,
          promo_code: `${state.promo_code_text ?? ""}`?.toLowerCase(),
          promo_code_type: "announcement",
        },
        ""
      );
      if (promo_resp.status === 200) {
        const resp = await apiServices.postFromApi(
          "stripe/regenerate-payment-intent-announce",
          {
            ship_id: Number(state.product_details?.id),
          },
          ""
        );
        if (resp?.status === 200 || resp?.status === 201) {
          // handleGetProdInfo(param?.id);
          getMessages();
          Toast.showSuccess("Code promotionnel appliqué 🥳");
          setState({ doNotShowPromo: false, show_stripe: false });
          setState({ show_stripe: true });
        }
      } else {
        Toast.Error(
          selector.lang === "en"
            ? "Invalid promo code"
            : "Code promotionnel invalide"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "calc(90vh - 200px)",
            overflowY: "auto",
          }}
          ref={scrollRef}
        >
          <div
            style={{
              border: `1px solid #E0E0E0`,
              padding: 20,
              marginTop: 20,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              rowGap: 30,
              borderRadius: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: 16,
              }}
            >
              <div>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F4F3F6" />
                  <path
                    d="M14.7717 19.6682C15.1417 21.1059 16.4468 22.1682 18 22.1682C19.5532 22.1682 20.8583 21.1059 21.2283 19.6682M17.1814 10.3049L11.5295 14.7009C11.1517 14.9947 10.9628 15.1416 10.8267 15.3256C10.7061 15.4886 10.6163 15.6722 10.5617 15.8675C10.5 16.0879 10.5 16.3272 10.5 16.8058V22.8349C10.5 23.7683 10.5 24.235 10.6817 24.5916C10.8414 24.9052 11.0964 25.1601 11.41 25.3199C11.7665 25.5016 12.2332 25.5016 13.1667 25.5016H22.8333C23.7668 25.5016 24.2335 25.5016 24.59 25.3199C24.9036 25.1601 25.1586 24.9052 25.3183 24.5916C25.5 24.235 25.5 23.7683 25.5 22.8349V16.8058C25.5 16.3272 25.5 16.0879 25.4383 15.8675C25.3837 15.6722 25.2939 15.4886 25.1733 15.3256C25.0372 15.1416 24.8483 14.9947 24.4705 14.7009L18.8186 10.3049C18.5258 10.0772 18.3794 9.96335 18.2178 9.91959C18.0752 9.88097 17.9248 9.88097 17.7822 9.91959C17.6206 9.96335 17.4742 10.0772 17.1814 10.3049Z"
                    stroke="#2D26E6"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <div
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: 14,
                    lineHeight: "20px",
                  }}
                >
                  Adresse de ramassage
                </div>
                <div
                  style={{
                    fontFamily: "Raleway-Bold",
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  {state.product_details?.pickup?.address ?? "N/A"}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                left: 37,
                top: 60,
              }}
            >
              <svg
                width="1"
                height="30"
                viewBox="0 0 1 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  x1="0.5"
                  y1="2.18557e-08"
                  x2="0.499999"
                  y2="30"
                  stroke="#BCBBC4"
                  stroke-dasharray="4 4"
                />
              </svg>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: 16,
              }}
            >
              <div>
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#F4F3F6" />
                  <path
                    d="M18 18.416C19.3807 18.416 20.5 17.2967 20.5 15.916C20.5 14.5353 19.3807 13.416 18 13.416C16.6193 13.416 15.5 14.5353 15.5 15.916C15.5 17.2967 16.6193 18.416 18 18.416Z"
                    stroke="#EF6A31"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18 26.3327C19.6667 22.9993 24.6667 20.8479 24.6667 16.3327C24.6667 12.6508 21.6819 9.66602 18 9.66602C14.3181 9.66602 11.3333 12.6508 11.3333 16.3327C11.3333 20.8479 16.3333 22.9993 18 26.3327Z"
                    stroke="#EF6A31"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div>
                <div
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: 14,
                    lineHeight: "20px",
                  }}
                >
                  Adresse de livraison
                </div>
                <div
                  style={{
                    fontFamily: "Raleway-Bold",
                    fontSize: 16,
                    lineHeight: "24px",
                  }}
                >
                  {state.product_details?.dropoff?.address ?? "N/A"}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              fontFamily: "Raleway-Italic",
              cursor: "pointer",
              display: "none", //comment by asad iqbal from ppt (17/1/25)
              alignItems: "center",
              gap: 5,
              margin: 5,
            }}
            onClick={() => {
              getMessages();
            }}
          >
            <RefrestIcon fill={defaultLabelColors.main_blue} /> Refresh
          </div>
          <div style={{ padding: "10px 10px" }}>
            <div
              style={{
                gap: 17,
                marginTop: 10,
                marginBottom: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  borderRadius: 16,
                  borderTopLeftRadius: 4,
                  alignSelf: "flex-start",
                  display: "flex",
                  flexDirection: "row",
                  columnGap: 14,
                }}
              >
                <img
                  alt="profile-pic"
                  src={
                    `${state?.product_details?.customer_id}` === `${user?.id}`
                      ? state?.product_details?.negotiations[0]?.profile_pic ??
                        no_image_url
                      : state?.product_details?.user_details?.profile_pic ??
                        no_image_url
                  }
                  style={{
                    height: 50,
                    width: 50,
                    objectFit: "contain",
                    borderRadius: "100%",
                    border: `1px solid lightgray`,
                  }}
                />
                <div
                  style={{
                    borderRadius: 16,
                    borderTopLeftRadius: 4,
                    background: "#F4F3F6",
                    padding: 16,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Raleway-Medium",
                      fontSize: 13,
                    }}
                  >
                    {`${state?.product_details?.customer_id}` === `${user?.id}`
                      ? state?.product_details?.negotiations[0]?.added_by
                      : state?.product_details?.user_details?.cus_name}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 14,
                    }}
                  >
                    <Rating
                      disableFillHover
                      initialValue={
                        `${state?.product_details?.customer_id}` ===
                        `${user?.id}`
                          ? state?.product_details?.negotiations[0]?.cus_ratings
                          : state?.product_details?.user_details?.cus_ratings
                      }
                      allowHover={false}
                      size={16}
                      readonly
                    />
                    <p
                      style={{
                        margin: 0,
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: 12,
                      }}
                    >
                      {Number(
                        `${state?.product_details?.customer_id}` ===
                          `${user?.id}`
                          ? state?.product_details?.negotiations[0]?.cus_ratings
                          : state?.product_details?.user_details?.cus_ratings ??
                              0
                      ).toFixed(1)}{" "}
                      avis
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{
                  borderRadius: 16,
                  borderTopRightRadius: 4,
                  alignSelf: "flex-end",
                  display: "flex",
                  flexDirection: "row-reverse",
                  columnGap: 14,
                }}
              >
                <img
                  alt="profile-pic"
                  src={user?.profile_pic ?? no_image_url}
                  style={{
                    height: 50,
                    width: 50,
                    objectFit: "contain",
                    borderRadius: "100%",
                    border: `1px solid lightgray`,
                  }}
                />
                <div
                  style={{
                    borderRadius: 16,
                    borderTopRightRadius: 4,
                    background: "#FCE6DD",
                    padding: 16,
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Raleway-Medium",
                      fontSize: 13,
                    }}
                  >
                    {`${user?.first_name ?? ""}${
                      user?.surname ? ` ${user?.surname ?? ""}` : ""
                    }`}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: 14,
                    }}
                  >
                    <Rating
                      disableFillHover
                      initialValue={user?.rating ?? 0}
                      allowHover={false}
                      size={16}
                      readonly
                    />
                    <p
                      style={{
                        margin: 0,
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: 12,
                      }}
                    >
                      {user?.rating.toFixed(1) ?? 0} avis
                    </p>
                  </div>
                </div>
              </div>

              {state?.product_details?.negotiations?.map(
                (nego_item: any, index: number) => {
                  return nego_item.created_by === user?.id ? (
                    <div
                      style={{
                        background: "#DCDBFB",
                        borderRadius: 20,
                        padding: 16,
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 17,
                        width: 587,
                        maxWidth: "80%",
                        alignSelf:
                          nego_item.sender_id === user?.id
                            ? "flex-start"
                            : "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: 14,
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 16,
                            fontFamily: "Raleway-Semi-Bold",
                            lineHeight: "24px",
                            color: color_theme.black,
                          }}
                        >
                          {nego_item?.is_accepted
                            ? `Vous avez accepté l’offre  ${
                                nego_item.sender_id === user?.id
                                  ? "du transporteur."
                                  : "de l’expéditeur."
                              }`
                            : `Vous avez fait une contre-offre pour ${currencyFormat(
                                nego_item?.ship_price ?? 0
                              )} €`}

                          {/* Vous avez fait une contre-offre pour{" "}
                          {currencyFormat(nego_item?.ship_price ?? 0)}  */}
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            fontFamily: "Raleway-Variable",
                            lineHeight: "20px",
                            color: "#4B4A61",
                          }}
                        >
                          {nego_item?.created_at
                            ? moment().format("DD/MM/YYYY") !==
                              moment(nego_item?.created_at).format("DD/MM/YYYY")
                              ? moment(
                                  `${nego_item?.created_at}`.endsWith("Z")
                                    ? nego_item?.created_at
                                    : nego_item?.created_at + "Z"
                                ).format("DD/MM/YYYY HH:mm")
                              : moment(
                                  `${nego_item?.created_at}`.endsWith("Z")
                                    ? nego_item?.created_at
                                    : `${nego_item?.created_at}Z`
                                ).format("HH:mm")
                            : ""}
                        </div>
                      </div>
                      {!nego_item?.is_accepted && (
                        <div>
                          <div
                            style={{
                              fontSize: 16,
                              color: "#616161",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            {nego_item.sender_id === user?.id
                              ? "Votre contre-offre a bien été envoyée au transporteur."
                              : "Votre contre-offre a bien été envoyée à l’expéditeur."}{" "}
                            {/* {state?.product_details?.negotiations[index - 1]
                            ?.added_by ?? state?.product_details?.added_by}
                          . */}
                          </div>
                          <div
                            style={{
                              fontSize: 16,
                              color: "#616161",
                              fontFamily: "Raleway-Variable",
                            }}
                          >
                            Vous devez attendre qu’il accepte pour discuter.
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      key={`REQUESTER_ITEM_${index + 1}`}
                      style={{
                        display:
                          nego_item?.is_accepted === true ? "none" : "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          borderRadius: 16,
                          backgroundColor: "#F4F3F6",
                          padding: 15,
                          borderWidth: 1,
                          borderColor: "#F4F3F6",
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            flex: 1,
                            justifyContent: "space-between",
                            alignContent: "center",
                            flexWrap: "wrap",
                            width: "100%",
                            display: "flex",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            <p
                              style={{
                                fontSize: 20,
                                color: color_theme.black,
                                fontFamily: "Raleway-Semi-Bold",
                                margin: 0,
                              }}
                            >
                              Nouvelle offre
                              <br />
                              <span
                                style={{
                                  fontSize: 16,
                                  color: color_theme.black,
                                  fontFamily: "Raleway-Variable",
                                  margin: 0,
                                }}
                              >
                                Offre initiale :{" "}
                                {currencyFormat(
                                  state?.product_details?.negotiations?.[
                                    index - 1
                                  ]?.ship_price ??
                                    state?.product_details?.ship_price ??
                                    0
                                )}{" "}
                                €
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "26px",
                                color:
                                  state.product_details?.ship_status_level === 2
                                    ? color_theme.black_40
                                    : state?.product_details?.negotiations[
                                        index + 1
                                      ]?.is_accepted === true
                                    ? color_theme.black_40
                                    : nego_item?.is_accepted === false
                                    ? color_theme.black_40
                                    : color_theme.main_orange,
                                fontFamily: "Raleway-Bold",
                                margin: 0,
                                paddingRight: 15,
                                flex: 1,
                                textAlign:
                                  state.product_details?.ship_status_level === 2
                                    ? "right"
                                    : state?.product_details?.negotiations[
                                        index + 1
                                      ]?.is_accepted === true
                                    ? "right"
                                    : nego_item?.is_accepted === false
                                    ? "right"
                                    : "center",
                              }}
                            >
                              {currencyFormat(nego_item?.ship_price, "fr-FR")}€
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            display:
                              state.product_details?.ship_status_level === 2
                                ? "none"
                                : last_nego?.is_accepted === true
                                ? "none"
                                : nego_item?.is_accepted === null
                                ? `${nego_item?.created_by}` === `${user?.id}`
                                  ? "none"
                                  : "flex"
                                : "none",
                          }}
                        >
                          <div
                            style={{
                              padding: 5,
                            }}
                            onClick={() => {
                              setState({
                                openOffer: true,
                                lastOffer: nego_item,
                              });
                            }}
                          >
                            <p
                              style={{
                                color: "#080626",
                                border: "1px solid #BCBBC4",
                                padding: "12px 24px",
                                borderRadius: "12px",
                                fontFamily: "Raleway-Bold",
                                cursor: "pointer",
                                fontSize: "16px",
                                background: "#fff",
                              }}
                            >
                              Faire une contre-offre
                            </p>
                          </div>
                          <div
                            style={{
                              padding: 5,
                            }}
                            onClick={async () => {
                              if (
                                state.product_details?.ship_status_level === 2
                              ) {
                                alert(
                                  "Unfortunetly the sender acceptes the another driver's request 🥺🥺"
                                );
                              }
                              if (state.disable_btn) return;
                              try {
                                setState({
                                  disable_btn: true,
                                });
                                let nego_obj = {
                                  parent_id: nego_item?.id,
                                  ship_id: nego_item?.ship_id,
                                  ship_price: Number(nego_item?.ship_price),
                                  is_accepted: true,
                                };
                                const resp = await apiServices.postFromApi(
                                  "c2c-shipment-negotiations",
                                  nego_obj,
                                  ""
                                );
                                if (resp.status === 201) {
                                  let new_msg: any = {
                                    message: `offer accepted at ${state.new_nego_price}`,
                                    // message: message ?? null,
                                    message_status: "sent",
                                    order_id: `${nego_item?.ship_id}`,
                                    is_negotiate: true,
                                    order_details: null,
                                    sender_id: `${user?.id}`,
                                    created_at: new Date().toISOString(),
                                  };
                                  const findCustomer =
                                    state.product_details?.negotiations?.find(
                                      (i: any) => i.accepter_id
                                    );

                                  if (
                                    `${user?.id}` ===
                                    `${state.product_details?.customer_id}`
                                  ) {
                                    new_msg.receiver_id = `${findCustomer?.accepter_id}`;
                                  } else {
                                    new_msg.receiver_id = `${state.product_details?.customer_id}`;
                                  }
                                  await addDoc(
                                    collection(
                                      firestoreDB,
                                      `chat_negotiations`
                                    ),
                                    new_msg
                                  );

                                  // getMessages();

                                  setState({
                                    disable_btn: false,
                                  });
                                } else {
                                  Toast.Error(
                                    `${
                                      typeof resp?.error === "string"
                                        ? resp?.error
                                        : resp?.error?.message ??
                                          resp?.error?.isNotEmpty ??
                                          resp?.error?.error ??
                                          ""
                                    }`
                                  );

                                  setState({
                                    disable_btn: false,
                                  });
                                }
                              } catch (error) {}
                            }}
                          >
                            <p
                              style={{
                                color: `#fff`,
                                border: `1px solid ${color_theme.main_orange}`,
                                borderRadius: `12px`,
                                padding: `12px 24px`,
                                fontFamily: `Raleway-Bold`,
                                background: `${color_theme.main_orange}`,
                                cursor: state.disable_btn ? "auto" : `pointer`,
                                fontSize: `16px`,
                              }}
                            >
                              {strings.accept}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}

              {/* {`${state.product_details?.customer_id}` !== `${user?.id}` &&
                state?.product_details?.ship_status_level === 2 && (
                  // added on 4/2/25
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={{
                        width: "587px",
                        position: "relative",
                        height: "100px",
                        borderRadius: "16px",
                        justifyContent: "center",
                        // alignItems:'center',
                        background: defaultLabelColors.main_orange,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: 10,
                        }}
                      >
                        <p
                          style={{
                            position: "relative",
                            color: "#fff",
                            fontSize: "18px",
                            fontFamily: "Raleway-Extra-Bold",
                            margin: 0,
                            marginTop: 0,
                          }}
                        >
                          L’expéditeur vient d’accepter votre offre. Vous pouvez
                          commencer à discuter”{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                )} */}

              {state?.product_details?.ship_status_level === 2 &&
                state?.product_details?.payment_status !== "Paid" && (
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      height: "120px",
                      borderRadius: "16px",
                      justifyContent: "center",
                      // alignItems:'center',
                      background: defaultLabelColors.main_blue,
                      display:
                        Number(state?.product_details?.customer_id) ===
                        Number(user?.id)
                          ? Number(state.product_details?.acc_id) ===
                            Number(findAccepter?.accepter_id)
                            ? "flex"
                            : "none"
                          : "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          position: "relative",
                          color: "#fff",
                          fontSize: "18px",
                          fontFamily: "Raleway-Extra-Bold",
                          margin: 0,
                          marginTop: "-60px",
                        }}
                      >
                        L’offre a été acceptée
                      </p>
                      <CustomButton
                        style={{
                          position: "absolute",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: state.enable_stripe
                            ? color_theme.white
                            : color_theme.black_40,
                          fontFamily: "Raleway-Variable",
                          background: state.enable_stripe
                            ? color_theme.main_orange
                            : color_theme.disabled,
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 30px 10px 30px",
                          minWidth: "200px",
                          maxWidth: "300px",
                          width: "260px",
                          marginTop: "10px",
                        }}
                        disable={state.enable_stripe ? false : true}
                        value={
                          selector.lang === "en"
                            ? "Continue to Payment"
                            : `Continuer vers le paiement`
                        }
                        onClick={() => {
                          setState({ show_stripe: true });
                        }}
                      />
                    </div>
                  </div>
                )}

              {state?.product_details?.ship_status_level === 2 &&
                state?.product_details?.payment_status === "Paid" &&
                state?.product_details?.user_details?.id === user?.id && (
                  <div
                    style={{
                      width: "100%",
                      position: "relative",
                      height: "120px",
                      borderRadius: "16px",
                      justifyContent: "center",
                      background: defaultLabelColors.main_orange,
                      display:
                        Number(state?.product_details?.customer_id) ===
                        Number(user?.id)
                          ? Number(state.product_details?.acc_id) ===
                            Number(findAccepter?.accepter_id)
                            ? "flex"
                            : "none"
                          : "none",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          position: "relative",
                          color: "#fff",
                          fontSize: "18px",
                          fontFamily: "Raleway-Extra-Bold",
                          margin: 0,
                          marginTop: "-60px",
                        }}
                      >
                        Ton paiement est validé !
                      </p>
                      <CustomButton
                        style={{
                          position: "absolute",
                          fontSize: "14px",
                          fontWeight: "500",
                          color: defaultLabelColors.main_orange,
                          fontFamily: "Raleway-Semi-Bold",
                          background: "#fff",
                          border: "none",
                          borderRadius: "12px",
                          padding: "10px 30px 10px 30px",
                          minWidth: "260px",
                          maxWidth: "100%",
                          marginTop: "10px",
                        }}
                        // disable={state.enable_stripe ? false : true}
                        value={"Garde le rythme et publie une autre annonce."}
                        onClick={() => {
                          navigate("/send-package");
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "80px",
                        }}
                      >
                        <p style={{ color: "#fff" }}>
                          {state?.product_details?.payment_log &&
                            moment(
                              `${state?.product_details?.payment_log?.[0]?.created_at}`.endsWith(
                                "Z"
                              )
                                ? state?.product_details?.payment_log?.[0]
                                    ?.created_at
                                : state?.product_details?.payment_log?.[0]
                                    ?.created_at + "Z"
                            ).format("DD/MM/YYYY HH:mm")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              {`${state.product_details?.customer_id}` !== `${user?.id}` && (
                <div
                  key={`REQUESTER_ITEM_${JSON.stringify(last_nego) + 1}`}
                  style={{
                    borderRadius: 16,
                    borderWidth: 1,
                    borderColor: color_theme.card_border,
                    marginTop: "10px",
                    display: last_nego?.is_accepted ? "block" : "none",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    {state?.product_details?.ship_status_level === 2 && (
                      <div className="container-for-offer-card">
                        <div className="content-for-offer-card">
                          <div
                            className="text-for-offer-card"
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <div
                                style={{
                                  fontSize: 20,
                                  fontFamily: "Raleway-Bold",
                                  lineHeight: "28px",
                                  color: color_theme.white,
                                }}
                              >
                                {/* Votre offre a été acceptée ! */}
                              </div>
                              <div
                                style={{
                                  fontSize: 20,
                                  fontFamily: "Raleway-Semi-Bold",
                                  lineHeight: "20px",
                                  color: color_theme.white,
                                  maxWidth: "600px",
                                  rowGap: 5,
                                }}
                              >
                                L’expéditeur vient d’accepter votre offre.
                                <br />
                                Vous pouvez commencer à discuter
                              </div>
                            </div>
                            {/* <div
                              style={{
                                fontSize: 36,
                                color: color_theme.white,
                                fontFamily: "Raleway-Bold",
                                lineHeight: "48px",
                              }}
                              className="amount-for-offer-card"
                            >
                              {" "}
                              + {currencyFormat(last_nego?.ship_price ?? 0)} €
                            </div> */}
                          </div>
                        </div>
                        {/* <div className="background-shape-for-offer-card"></div> */}
                      </div>
                    )}
                  </div>
                </div>
              )}

              {`${state.product_details?.customer_id}` !== `${user?.id}` &&
                state?.product_details?.payment_status === "Paid" && (
                  <div
                    style={{
                      borderRadius: 16,
                      borderWidth: 1,
                      borderColor: color_theme.card_border,
                      marginTop: "10px",
                      display: last_nego?.is_accepted ? "block" : "none",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      {state?.product_details?.payment_status === "Paid" && (
                        <div className="container-for-offer-card">
                          <div className="content-for-offer-card">
                            <div
                              className="text-for-offer-card"
                              style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ gap: "10px" }}>
                                <div
                                  style={{
                                    fontSize: 16,
                                    fontFamily: "Raleway-Variable",
                                    lineHeight: "20px",
                                    color: color_theme.white,
                                    maxWidth: "400px",
                                  }}
                                >
                                  Paiement effectué par l’expéditeur. A toi de
                                  livrer :)
                                </div>
                                <div
                                  onClick={() => {
                                    navigate(
                                      `/job/${state?.product_details?.id}`
                                    );
                                  }}
                                  style={{
                                    maxWidth: "100%",
                                    minWidth: "200px",
                                    background: "#fff",
                                    display: "flex",
                                    justifyContent: "center",
                                    borderRadius: "12px",
                                    padding: "10px",
                                    alignItems: "center",
                                    color: defaultLabelColors.main_orange,
                                    fontFamily: "Raleway-Semi-Bold",
                                    fontSize: "18px",
                                    cursor: "pointer",
                                    marginTop: "10px",
                                  }}
                                >
                                  J’ai récupéré le colis
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="background-shape-for-offer-card"></div> */}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>

          {chatMessages?.map((item: any, index: any) => (
            <MessageComponent
              item={item}
              key={`CHAT_COM_${index + 1}`}
              user={user}
              index={index}
              chatMessages={chatMessages}
            />
          ))}
        </div>

        {state?.product_details?.negotiations?.[
          state?.product_details?.negotiations?.length - 1
        ]?.is_accepted === null ? (
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: 13,
              textAlign: "center",
              color: color_theme.black_40,
            }}
          >
            {state?.product_details?.is_sold_out ? (
              <div>Item sold out</div>
            ) : selector.lang === "fr" ? (
              user?.id === props?.chat_details?.user_details?.id ? (
                `Vous ne pouvez pas échanger avec le transporteur jusqu’à ce qu’il accepte votre offre`
              ) : (
                "Vous ne pouvez pas échanger avec l’expéditeur jusqu’à ce qu’il accepte votre offre."
              )
            ) : (
              `You can't reply to this conversation anymore until someone accepts or declined the request.`
            )}
          </p>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            {isTyping && (
              <div
                style={{
                  position: "absolute",
                  top: -20,
                  zIndex: 999,
                  left: 10,
                }}
              >
                <div className="typing">
                  <div className="typing__dot"></div>
                  <div className="typing__dot"></div>
                  <div className="typing__dot"></div>
                </div>
              </div>
            )}{" "}
            <InputField
              input_style={{
                background: "#f4f3f6",
                width: "97%",
                borderRadius: "32px",
              }}
              input_value={message}
              onchange={(event: any) => {
                let { value } = event.target;
                if (value[0] === " ") {
                  return;
                }
                setMessage(event.target.value);
                updateTypingStatus(true); // Mark as typing
                debouncedStopTyping(); // Schedule stop typing
              }}
              onKeyDown={(event: any) => {
                if (event.code === "Enter") {
                  let { value } = event.target;
                  if (value[0] === " ") {
                    return;
                  }
                  setMessage(event.target.value);
                  updateTypingStatus(true); // Mark as typing
                  debouncedStopTyping(); // Schedule stop typing
                }
              }}
              placeHolder={"Envoyer un message"}
              startIcon={!last_nego?.is_accepted ? "€" : <></>}
              input_type={last_nego?.is_accepted ? "text" : "numeric"}
            />
            <IconButton
              disabled={state?.disable_btn}
              onClick={() => {
                try {
                  if (message === "") {
                    return;
                  }
                  if (state.disable_btn) return;
                  setState({
                    disable_btn: true,
                  });
                  if (!_last_message) {
                    // handleNewMessage(null, null);
                    return;
                  }
                  if (last_nego?.is_accepted === false) {
                    handleNewMessageWithNegotiate();
                  } else {
                    handleNewMessage(null, null);
                  }
                } catch (error) {
                  console.log(error);
                }
              }}
              style={{
                background: defaultLabelColors.main_orange,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Send
                style={{ color: defaultLabelColors.white, fontSize: "32px" }}
              />
            </IconButton>
          </div>
        )}
      </div>

      <DraggableDialog
        maxWidth={"sm"}
        dialog_title={"Faire une offre"}
        open={state?.openOffer}
        handleClose={() => {
          setState({ openOffer: false });
        }}
        dialogBody={
          <DialogContent
            style={{
              display: "flex",
              background: "#fff",
            }}
            dividers
          >
            <Grid container spacing={spacing.spacing2}>
              <Grid xs={12} item>
                <div>
                  <div>
                    <div className="card-container">
                      <img
                        src={
                          state?.product_details?.images?.[0] ??
                          state?.product_details?.shipment_items[0]
                            ?.prod_images?.[0] ??
                          no_image_url
                        }
                        className="card-image"
                        alt="prod-img"
                        style={{ height: "100%" }}
                      />
                      <div className="card-details" style={{ display: "flex" }}>
                        <div
                          style={{
                            fontSize: 20,
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            fontFamily: "Raleway-Bold",
                          }}
                        >
                          <p style={{ margin: 0, color: "#080626" }}>
                            {handleLocalize(
                              state?.product_details?.product_title
                            ) ??
                              state?.product_details?.shipment_items[0]
                                ?.prod_name ??
                              ""}
                          </p>

                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: 16,
                              margin: 0,
                              color: "#080626",
                            }}
                          >
                            Offre initial :{" "}
                            {currencyFormat(
                              Number(
                                state?.product_details?.negotiations?.[
                                  state?.product_details?.negotiations - 2
                                ]?.ship_price ??
                                  state?.product_details?.ship_price ??
                                  0
                              )
                            )}{" "}
                            €
                          </p>

                          <p
                            style={{
                              fontFamily: "Raleway-Variable",
                              fontSize: 16,
                              margin: 0,
                              color: "#080626",
                              marginTop: "5px",
                            }}
                          >
                            Nouvelle offre :{" "}
                            <span
                              style={{
                                fontFamily: "Raleway-Bold",
                                color: defaultLabelColors.main_orange,
                                fontSize: "24px",
                              }}
                            >
                              {currencyFormat(
                                state?.lastOffer?.ship_price ??
                                  state?.product_details?.ship_price ??
                                  0
                              )}{" "}
                              €
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=" "
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      lineHeight: "1px",
                      marginTop: "20px",
                      gap: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Bold",
                        color: "#281208",
                        fontSize: "28px",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Insérez le montant de votre contre-offre
                    </p>

                    <div
                      className="display-flex"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <input
                        type="number"
                        onChange={handleChangeForNego}
                        style={{
                          fontSize: "54px",
                          fontFamily: "Raleway-Bold",
                          width: "100%",
                          border: "none",
                          textAlign: "center",
                          color: defaultLabelColors.main_orange,
                        }}
                        value={state?.new_nego_price}
                        onKeyDown={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                            return;
                          }
                        }}
                        placeholder="ex : 7,50 €"
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      gap: "10px",
                      paddingTop: "20px",
                    }}
                  >
                    <CustomButton
                      value={"Annuler"}
                      style={{
                        width: "100%",
                        background: "#fff",
                        color: "#000",
                        border: "1px solid #BCBBC4",
                        padding: "12px 24px",
                        borderRadius: "12px",
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                      onClick={() => {
                        setState({
                          openOffer: false,
                          new_nego_price: "",
                        });
                      }}
                    />
                    <CustomButton
                      value={"Faire une contre-offre"}
                      style={{
                        width: "100%",
                        background: defaultLabelColors.main_orange,
                        color: color_theme.white,
                        border: `1px solid ${defaultLabelColors.main_orange}`,
                        padding: "12px 24px",
                        borderRadius: "12px",
                        fontFamily: "Raleway-Semi-Bold",
                        fontSize: "16px",
                      }}
                      disable={state?.disable_btn}
                      onClick={() => {
                        if (state.product_details?.ship_status_level === 2) {
                          alert("JOB ALEADY ACCEPTED BY ANOTHER PERSON");
                          return;
                        }

                        setState({
                          disable_btn: true,
                        });
                        handleCounterOffer();
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        }
      />

      <DraggableDialog
        maxWidth={state?.show_stripe ? "sm" : "md"}
        open={state?.show_stripe}
        handleClose={() => {
          setState({ show_stripe: false });
        }}
        dialog_title={`${
          state?.showStripeForm ? strings.payments : "Transporter un colis"
        }`}
        dialogBody={
          <DialogContent dividers>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: 14,
              }}
            >
              <div
                style={{
                  background:
                    !state?.product_details?.promo && state?.doNotShowPromo
                      ? "#fce6dd"
                      : defaultLabelColors.main_orange,
                  borderRadius: "16px",
                }}
              >
                {!state?.product_details?.promo && state?.doNotShowPromo ? (
                  <div style={{ padding: "10px" }}>
                    <p
                      style={{
                        margin: "7px",
                        fontFamily: "Raleway-Bold",
                        color: "#4B4A61",
                        fontSize: "20px",
                      }}
                    >
                      Code promo{" "}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "65%" }}>
                        <div
                          style={{
                            height: 40,
                            width: "100%",
                            border: "1px solid #E0E0E0",
                            background: color_theme.white,
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 6,
                            paddingLeft: 10,
                          }}
                        >
                          <input
                            style={{
                              border: "none",
                              outline: "none",
                              width: "100%",
                              textTransform: "uppercase",
                            }}
                            value={state.promo_code_text}
                            onChange={(e) =>
                              setState({
                                promo_code_text: e.target.value,
                              })
                            }
                            placeholder={"Saisissez le code "}
                          />
                        </div>
                      </div>
                      <button
                        style={{
                          width: "30%",
                          marginLeft: "5%",
                          padding: 10,
                          backgroundColor: state.promo_code_text
                            ? color_theme.main_orange
                            : color_theme.disabled,
                          borderRadius: 6,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: state.promo_code_text
                            ? "pointer"
                            : "not-allowed",
                          border: "none",
                          outline: "none",
                        }}
                        onClick={handleValidatePromo}
                        disabled={!state.promo_code_text} // Disable button when promo code is empty
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontFamily: "Raleway-Semi-Bold",
                            lineHeight: "18px",
                            color: state.promo_code_text
                              ? color_theme.white
                              : "#a4a4a4",
                          }}
                        >
                          Valider
                        </span>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Raleway-Bold",
                        color: "#fff",
                        fontSize: "18px",
                      }}
                    >
                      Code Promo appliqué 🥳
                    </p>
                  </div>
                )}
              </div>

              <div
                style={{
                  border: `1px solid #E0E0E0`,
                  padding: 20,
                  // marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  rowGap: 14,
                  borderRadius: 5,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {strings?.delivery_cost}
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {" "}
                    {state.product_details?.promo ? (
                      <span style={{ marginRight: "10px", color: "red" }}>
                        {" "}
                        <s>
                          {state.product_details?.agree_ship_price
                            ? state.product_details?.agree_ship_price
                            : state.product_details?.ship_price}
                        </s>
                      </span>
                    ) : null}
                    {state.product_details?.promo
                      ? numberFormatWithoutCurrency(
                          Number(
                            (state.product_details?.agree_ship_price ||
                              state.product_details?.ship_price) -
                              (state.product_details?.promo?.discount_amount ||
                                0)
                          )
                        )
                      : numberFormatWithoutCurrency(
                          Number(
                            state.product_details?.agree_ship_price ??
                              state.product_details?.ship_price ??
                              0
                          )
                        )}
                    €
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {strings?.service_charges}
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: 16,
                      margin: 0,
                    }}
                  >
                    {state.product_details?.promo?.is_platform_charges ? (
                      <span style={{ marginRight: "10px", color: "red" }}>
                        {" "}
                        <s>{state.product_details?.service_charges}</s>
                      </span>
                    ) : null}
                    {state.product_details?.promo?.is_platform_charges
                      ? "0.00"
                      : currencyFormat(
                          Number(state.product_details?.service_charges ?? 1.99)
                        )}{" "}
                    €
                  </p>
                </div>

                {FrankDivider()}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: 20,
                      margin: 0,
                      color: color_theme.main_orange,
                    }}
                  >
                    {strings?.total}
                  </p>
                  <p
                    style={{
                      fontFamily: "Raleway-Bold",
                      fontSize: 20,
                      margin: 0,
                      color: color_theme.main_orange,
                    }}
                  >
                    {state.product_details?.promo
                      ? numberFormatWithoutCurrency(
                          Number(
                            Number(
                              state?.product_details?.promo
                                ?.actual_order_amount -
                                state?.product_details?.promo?.discount_amount
                            )
                          ) +
                            Number(
                              state.product_details?.promo?.is_platform_charges
                                ? 0
                                : state.product_details?.service_charges
                            )
                        )
                      : numberFormatWithoutCurrency(
                          Number(
                            Number(
                              state.product_details?.agree_ship_price ??
                                state.product_details?.ship_price ??
                                0
                            ) +
                              Number(
                                state.product_details?.promo
                                  ?.is_platform_charges
                                  ? 0
                                  : state.product_details?.service_charges
                              )
                          )
                        )}{" "}
                    €
                  </p>
                </div>
              </div>

              {state.options?.clientSecret ? (
                <Elements stripe={stripePromise} options={state.options}>
                  <StripeCheckout
                    handleOtpSend={handleStripeCallBack}
                    {...state}
                    padding="0px"
                  />
                </Elements>
              ) : (
                "Unable to load payment"
              )}
            </div>
          </DialogContent>
        }
      />
    </>
  );
};

export default AnnouncementChat;
