import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet";
import "react-quill/dist/quill.snow.css"; // Import styles for Quill editor
import arrowRight from "../../assets/images/FrankIcons/arrows.png";
import apiServices from "../../services/RequestHandler";
import ScrollToTop from "../../UiComponent/ScrollToTop";

import { Instagram, KeyboardArrowLeft } from "@mui/icons-material";
import moment from "moment";
import ReactQuill from "react-quill";
import {   useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import Loader from "../../UiComponent/Loader";
import NewHeader from "../../UiComponent/NewHeader";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import { defaultLabelColors } from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";
import BlogDetailMobileView from "./BlogDetailMobileView";

function BlogDetails(props: any) {
  const params = useParams();
  const navigate = useNavigate();

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      param: params?.id,
      activity: true,
    }
  );

  const getInstaPost = async () => {
    try {
      let resp = await apiServices.getFromApi("c2c-blogs/insta-posts", "");
      setState({ instaPosts: resp?.data, activity: false });
    } catch (error) {}
  };

  React.useEffect(() => {
    handleGetAllCategory();
    handleGetBlogDetails();
    getInstaPost();
    // eslint-disable-next-line
  }, []);

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handleGetAllCategory = async () => {
    try {
      setState({ activity: true });

      let resp = await apiServices.getFromApi("c2c-blog-categories/web", "");
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ allCategories: resp?.data, activity: false });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetBlogDetails = async () => {
    try {
      setState({ activity: true });
      let resp = await apiServices.getFromApi(
        `c2c-blogs/web/${params?.id}/${params?.idx}`,
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({ blogDetails: resp?.data?.[0] });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitComment = () => {
    try {
      if (!state?.nom) {
        Toast.Error("Le nom ne peut pas être vide");
        return;
      }
      if (!state?.prenom) {
        Toast.Error("Prenom ne peut pas être vide");
        return;
      }
      if (!state?.email) {
        Toast.Error("L'e-mail ne peut pas être vide");
        return;
      }
      if (!state?.comment) {
        Toast.Error("Le commentaire ne peut pas être vide");
        return;
      }

      // let obj = {
      //   name: state?.nom,
      //   lastName: state?.prenom,
      //   email: state?.email,
      //   comment: state?.comment,
      // };
      Toast.showSuccess("Commentaire posté avec succès");
      setState({
        nom: "",
        prenom: "",
        email: "",
        comment: "",
      });
    } catch (error) {}
  };
  const { blogDetails } = state;
  return (
    <>
      <Loader loading={state?.activity} />
      <Helmet>
        <title>
          Blogs | Articles et Conseils sur la Livraison Collaborative
        </title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/blogs" />
        <meta
          name="description"
          content="Découvrez nos articles et conseils sur la livraison collaborative, des témoignages de la communauté Frank, et des astuces pour optimiser vos envois."
        />
      </Helmet>
      <div className="web-screen">
        <ScrollToTop />

        <div style={{ background: "#f9d9b6" }}>
          <ScrollToTop />

          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#f9d9b6",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <div
            style={{
              fontFamily: "Raleway-Bold",
              color: "#000",
              fontSize: "48px",
              padding: "50px 160px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div style={{ fontSize: "14px", fontFamily: "Raleway-Variable" }}>
              Posté le{" "}
              {moment(
                `${blogDetails?.created_at}`.endsWith("Z")
                  ? blogDetails?.created_at
                  : blogDetails?.created_at + "Z"
              ).format("DD MMM YYYY")}
            </div>
            {handleLocalize(blogDetails?.title)}

            <div style={{ fontSize: "18px", fontFamily: "Raleway-Variable" }}>
              {handleLocalize(blogDetails?.sub_title)}
            </div>
          </div>
        </div>
        <div style={{ background: "#f9d9b6", padding: "20px 160px" }}>
          <div style={{ fontSize: "18px", padding: "0px 0px 20px 0px" }}>
            <CustomButton
              value={"Retours aux articles"}
              style={{
                background: "#fff",
                borderRadius: 100,
                color: "#000",
                fontFamily: "Raleway-Extra-Bold",
                padding: 15,
                border: "none",
                fontSize: "18px",
              }}
              startIcon={<KeyboardArrowLeft />}
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <Grid container spacing={2}>
            <Grid xs={12} md={8} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    maxHeight: "100%",
                    position: "relative",
                    padding: "0px",
                    borderRadius: "12px",
                    background: "#fff",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReactQuill
                    className="custom-quill-instancee"
                    value={handleLocalize(blogDetails?.description)}
                    readOnly
                    theme="snow"
                    modules={{ toolbar: false }}
                    style={{
                      border: "none",
                      padding: 0,
                      // display: "flex",
                      justifyContent: "center",
                      // paddingBottom:'40px',
                      borderRadius: 12,
                      display: state.activity ? "none" : "flex",
                    }}
                    onChange={() =>
                      setTimeout(() => {
                        setState({
                          activity: false,
                        });
                      }, 1000)
                    }
                  />
                </Box>

                <div>
                  <p
                    style={{
                      fontFamily: "Raleway-Bold-Italic",
                      color: "#000",
                      fontSize: 24,
                    }}
                  >
                    Laisser un commentaire
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ flex: 1 }}>
                      <InputField
                        input_value={state?.nom}
                        input_name={"nom"}
                        placeHolder={"Nom"}
                        onchange={onInputChange}
                        input_style={{
                          background: "#f2cca7",
                          borderRadius: 100,
                        }}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <InputField
                        input_value={state?.prenom}
                        placeHolder={"Prénom"}
                        input_name={"prenom"}
                        onchange={onInputChange}
                        input_style={{
                          background: "#f2cca7",
                          borderRadius: 100,
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <InputField
                      input_value={state?.email}
                      placeHolder={"Email"}
                      input_type={"email"}
                      input_name={"email"}
                      onchange={onInputChange}
                      input_style={{
                        background: "#f2cca7",
                        borderRadius: 100,
                      }}
                    />
                  </div>

                  <div>
                    <InputField
                      input_value={state?.comment}
                      placeHolder={"Votre commentaire..."}
                      input_name={"comment"}
                      isMultiLine
                      input_rows={10}
                      onchange={onInputChange}
                      input_style={{
                        background: "#f2cca7",
                        borderRadius: 20,
                      }}
                    />
                  </div>

                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <CustomButton
                      onClick={handleSubmitComment}
                      value={"Envoyer votre message"}
                      style={{
                        background: defaultLabelColors.main_orange,
                        color: "#fff",
                        fontFamily: "Raleway-Variable",
                        fontSize: "18px",
                        border: "none",
                        padding: "10px 60px 10px 40px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={4} item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {/* <div>
                  <InputField
                    placeHolder={`Chercher un produit chez   `}
                    input_value={props?.global_search}
                    input_name={"global_search"}
                    onchange={props.onInputChangeForHeader}
                    isDisable
                    input_style={{
                      background: "#E0E0E0",
                      // minWidth: "100%",
                      // width: xl ? "726px" : lg ? "600px" : md ? "400px" : "300px",
                      // maxWidth: "726px",
                      borderRadius: "100px",
                      fontFamily: "Raleway-Variable",
                      color: "#000",
                    }}
                    endIicon={<Search style={{ color: "#6D6D6D" }} />}
                  />
                </div> */}

                <div
                  style={{
                    background: "#f2cca7",
                    borderRadius: "13px",
                    padding: "24px 35px",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Raleway-Extra-Bold",
                      fontSize: "18px",
                      color: defaultLabelColors.main_blue,
                      // margin: 20,
                      paddingBottom: "15px",
                      paddingLeft: 10,
                    }}
                  >
                    Catégorie
                  </p>
                  {state?.allCategories?.slice?.(0,6)?.map((item: any, idx: any) => {
                    return (
                      <>
                        <div
                          onClick={() => {
                            navigate(-1);
                          }}
                          style={{
                            display: "flex",
                            fontFamily: "Raleway-Italic",
                            margin: 15,
                            cursor: "pointer",
                          }}
                        >
                          <div style={{ flex: 1, fontSize: "18px" }}>
                            {handleLocalize({
                              en: item?.category_name?.en,
                              fr: item?.category_name?.fr,
                            })}
                          </div>
                          <div>
                            <img
                              src={arrowRight}
                              alt=""
                              style={{ height: "15px", width: "20px" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{ height: "1px", background: "#fff" }}
                        ></div>
                      </>
                    );
                  })}
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "22px",
                    fontFamily: "Raleway-Variable",
                    color: defaultLabelColors.main_blue,
                    gap: 6,
                  }}
                >
                  <Instagram style={{ fontSize: "32px", fontWeight: "400" }} />{" "}
                  Instagram
                </div>
                <div>
                  <Grid container spacing={1} className="gallery">
                    {state?.instaPosts?.map((i: any) => {
                      return (
                        <Grid
                          xs={6}
                          md={4}
                          item
                          className="gallery-item"
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/frankme.fr/",
                              "_blank",
                              "noopener,noreferrer"
                            )
                          }
                        >
                          <img
                            src={
                              i?.media_type === "VIDEO"
                                ? i?.thumbnail_url
                                : i?.media_url
                            }
                            alt=""
                            style={{
                              borderRadius: "12px",
                              height: "120px",
                              objectFit: "cover",
                              width: "100%",
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="main-container" style={{ background: "#f9d9b6" }}>
          <CustomFooter showBorder={true} />
        </div>
      </div>
      <div className="mobile-screen">
        <BlogDetailMobileView />
      </div>
    </>
  );
}

export default BlogDetails;
