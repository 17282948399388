import { Dialog } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiServices from "../../../services/RequestHandler";
import { RootState } from "../../../Store";
import Loader from "../../../UiComponent/Loader";
import { color_theme } from "../../../utils/constants";
import { decrypt } from "../../../utils/Encrypt";
import { handleGetMachineDetails } from "../../../utils/helper";
import StripeCheckout from "../../MyAnnounces/StripeCheckout";

function ParticularPayment(props: any) {
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {}
  );
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();

  const stripePromise = loadStripe(
    "pk_live_51PHLwERrtK56w4VJcMQLW2zbn7LTZPNZnBLUmFdMIakX5v5HKsPRllmYUMmWJXvmPVJaptcEN8uxmQdzDNVJYFUC0050uiD5gE"
  );

  useEffect(() => {
    if (props?.transaction_id) {
      initPayment();
    }
    // eslint-disable-next-line
  }, []);

  const handleSubmitOrder = async () => {
    try {
      setState({
        laoding: true,
      });

      let resp = await apiServices.postFromApi(
        `c2c-orders/particular/submit-order`,
        {
          order_id: Number(props?.transaction_id),
        },
        ""
      );
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          laoding: false,
          show_success: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initPayment = async () => {
    try {
      setState({
        laoding: true,
      });
      const response = await apiServices.postFromApi(
        "stripe/payment-sheet-with-card-frankShop",
        {
          transaction_id: props?.transaction_id,
          amount: Number(props?.total_amount),
          machine_detail: await handleGetMachineDetails(),
        },
        ""
      );
      let decryptData = JSON.parse(decrypt(response.pi_key));
      if (typeof decryptData === "string") {
        decryptData = JSON.parse(decryptData);
      }
      const { 
        // setupIntent,
         paymentIntent, 
        //  ephemeralKey,
          // customer
         } =
        decryptData ?? {};
      console.log(decryptData);

      const options = {
        theme: "stripe",

        clientSecret: paymentIntent,
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily: '"Raleway-Variable", sans-serif',
          },
          rules: {
            ".Label": {
              fontFamily: '"Raleway-Variable", sans-serif',
              fontSize: "14px", // Adjust the size as needed
              color: "#000000", // Change the color if needed
            },
          },
        },
      };

      setState({ options, laoding: false, show_stripe: true });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Loader loading={state?.laoding} />
      {state.show_stripe && (
        <Elements stripe={stripePromise} options={state.options}>
          <StripeCheckout handleSubmitOrder={handleSubmitOrder} />
        </Elements>
      )}

      <Dialog open={state.show_success}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: 16,
            rowGap: 14,
          }}
        >
          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "Raleway",
              margin: 0,
            }}
          >
            N° de commande: {props.transaction_id ?? ""}
          </p>
          <p
            style={{
              fontSize: 32,
              textAlign: "center",
              fontFamily: "Raleway-Bold",
              color: color_theme.black,
              margin: 0,
            }}
          >
            Merci{" "}
            <span style={{ color: color_theme.main_orange }}>
              {selector.user?.first_name ?? ""}
            </span>{" "}
            votre commande à été enregistré
          </p>
          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "Raleway",
              margin: 0,
            }}
          >
            Le récapitulatif de votre commande vous sera envoyé par mail à
            l’adresse {selector?.user?.email ?? ""}
          </p>
          <svg
            width="95"
            height="95"
            viewBox="0 0 95 95"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M86.6654 43.9192V47.5226C86.6606 55.9686 83.9257 64.1867 78.8686 70.9514C73.8115 77.7161 66.7032 82.6648 58.6038 85.0595C50.5045 87.4543 41.848 87.1667 33.9254 84.2397C26.0028 81.3127 19.2386 75.9031 14.6416 68.8178C10.0447 61.7324 7.86121 53.3508 8.41693 44.9231C8.97265 36.4955 12.2378 28.4732 17.7253 22.0528C23.2128 15.6324 30.6288 11.1578 38.8671 9.29649C47.1054 7.43514 55.7247 8.28673 63.4396 11.7242M86.6654 16.1668L47.4987 55.3727L35.7487 43.6227"
              stroke={color_theme.main_orange}
              stroke-width="7.83333"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <p
            style={{
              fontSize: 16,
              textAlign: "center",
              fontFamily: "Raleway",
              margin: 0,
            }}
          >
            Lorsque votre commande sera disponible vous recevrez un nouveau mail
            ou SMS avec toutes les informations pratiques pour récuperer votre
            ou vos colis.
          </p>
          <div
            style={{
              width: "30%",
              marginLeft: "5%",
              padding: 10,
              backgroundColor: color_theme.main_orange,
              background: color_theme.main_orange,
              borderRadius: 6,
              placeContent: "center",
              display: "flex",
              placeItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              setState({
                show_success: false,
              });
              navigate("/marketplace");
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: "Raleway-Semi-Bold",
                lineHeight: "18px",
                color: color_theme.white,
              }}
            >
              Valider
            </span>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ParticularPayment;
