import { Close } from "@mui/icons-material";
import { Grid } from "@mui/material";
import ActionSheet from "actionsheet-react";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import { strings } from "../../Store/Localization";
import CustomButton from "../../UiComponent/CustomButton";
import FlagInputField from "../../UiComponent/FlagInput";
import FlagInputForMobile from "../../UiComponent/FlagInput/FlagInputForMobile";
import GoogleAutocompleteField from "../../UiComponent/GoogleAutocompleteField";
import MapForLocations from "../../UiComponent/MapForLocations";
import ScrollToTop from "../../UiComponent/ScrollToTop";
import SelectField from "../../UiComponent/SelectField";
import InputField from "../../UiComponent/TextFeild";
import Toast from "../../UiComponent/Toast/Toast";
import {
  CartIcon,
  HomeIconBlue,
  LocationPinIcon,
} from "../../assets/images/FrankIcons";
import apiServices from "../../services/RequestHandler";
import FrankConstants from "../../utils/FrankConstants";
import { defaultLabelColors } from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";

const apiKey = "AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ"; // Replace with your actual API key
function DropOffInfo(props: any) {
  const selector = useSelector((state: RootState) => state.mainApp);
  const ref = useRef<any>(null);
  const { classes, handleUpadateState, handleUpadte } = props;
  const [inputValue, setInputValue] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<{
    lat: any;
    lng: any;
    address: any;
  } | null>(null);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      phone_code: "+33",
      anounces_datails: props?.items,
      pickupDetails: props?.pickup,
    }
  );

  useEffect(() => {
    handlePlaceSelected(props?.dropOff?.state?.destinationAddress);
    setState({
      ...props.dropOff?.state,
      reciverName: props?.dropOff?.receiptent_name ?? "",
      phone: props?.dropOff?.phone ?? "",
      phone_code: props?.dropOff?.phone_code ?? "+33",
    });
    setSelectedLocation({
      lat: Number(props?.dropOff?.location?.coordinates[0]),
      lng: Number(props?.dropOff?.location?.coordinates[1]),
      address: props?.dropOff?.address,
    });
    setInputValue(props?.dropOff?.address);
    setState({
      anounces_datails: props?.items,
    });
  }, [props]);

  const handleSelectForProduct = (name: any, event: any, value: any): void => {
    try {
      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handlePlaceSelected = async (
    place: google.maps.places.PlaceResult | null
  ) => {
    if (place && place.geometry) {
      const lat = place.geometry.location?.lat() || 0;
      const lng = place.geometry.location?.lng() || 0;
      const address = place.formatted_address || "";
      setSelectedLocation({ lat, lng, address });
      setInputValue(address);

      const dropOffLocation = await apiServices.getFromApi(
        `helper/get-address?lat=${lat}&lng=${lng}`,
        ""
      );
      setState({ dropOffLocation, destinationAddress: place });
    } else {
      setSelectedLocation(null);
    }
  };
  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  const handleChangeMobileCode = (dialcode: any, codeName: any) => {
    try {
      setState({
        [codeName]: dialcode,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChangeMobile = (event: any) => {
    try {
      setState({
        [event.target.name]: event.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const enableFlagInput = (state: any, value: any) => {
    try {
      setState({
        [state]: value,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveDropOffDetails = () => {
    try {
      if (!state?.dropOffLocation) {
        Toast.Error("Veuillez sélectionner l'emplacement");
        return;
      }

      if (!state?.drop_location) {
        Toast.Error("Mentionnez le Lieu de remise");
        return;
      }

      if (!state?.reciverName) {
        Toast.Error("Mentionnez le lieu de prise en charge ");
        return;
      }
      if (!state?.phone) {
        Toast.Error("Mentionnez le numéro de téléphone du destinataire");
        return;
      }
      let dropoff = {
        location: {
          type: "Point",
          coordinates: [
            Number(state?.dropOffLocation?.result[0]?.latitude),
            Number(state?.dropOffLocation?.result[0]?.longitude),
          ],
        },
        address: state?.dropOffLocation?.result[0]?.formattedAddress ?? "",
        digital_code: state?.dropOffLocation?.result[0]?.zipcode,
        additional_info: state?.drop_location?.value,
        city: state?.dropOffLocation?.result[0]?.city,
        country: state?.dropOffLocation?.result[0]?.country,
        phone_code: state?.phone_code,
        phone: state?.phone,
        receiptent_name: state?.reciverName,
        state,
      };

      // return;
      handleUpadateState("dropOff", dropoff);
    } catch (error) {}
  };
  const handleOpen = () => {
    if (ref.current) {
      ref.current.open();
    }
  };
  const handleClose = () => {
    if (ref.current) {
      ref.current.close();
      props?.handleClearAllFeils();
    }
  };
 const transSizes = (item:any) => {
 const deliveryType = FrankConstants.packageSize(selector.lang)?.find(
      (a: any) => a.key === item
    );

    return deliveryType?.size_trans
  }
  return (
    <>
      <div className="web-screen">
        <ScrollToTop />

        <Grid container spacing={2} style={{ paddingBottom: "30px" }}>
          <Grid
            xs={12}
            md={6}
            item
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div
              style={{
                width: "650px",
                maxWidth: "700px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  height: "100px",
                  display: "flex",
                  gap: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: defaultLabelColors.main_orange,
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    background: defaultLabelColors.main_orange,
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    background: "#E3CFC7",
                    borderRadius: "32px",
                    width: "30%",
                    height: "10px",
                  }}
                ></div>
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    fontFamily: "Raleway-Variable",
                  }}
                >
                  <p style={{ display: "flex" }}>
                    {" "}
                    étape{" "}
                    <span
                      style={{
                        fontFamily: "Raleway-Bold",
                        paddingLeft: 5,
                      }}
                    >
                      {" "}
                      2/3{" "}
                    </span>
                  </p>
                </div>
              </div>
              <h1
                style={{
                  fontFamily: "Raleway-Extra-Bold",
                  fontSize: "32px",
                }}
              >
                Adresse de livraison
              </h1>

              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "18",
                  marginTop: "0",
                }}
              >
                Insérez l’adresse de livraison souhaitée et fixez un délai
              </p>
              <div style={{ height: "250px", marginLeft: 10 }}>
                <MapForLocations
                  handleGetLocation={(addressLoc) => {
                    setState({ dropOffLocation: addressLoc });
                    setSelectedLocation({
                      lat: addressLoc?.result[0]?.latitude,
                      lng: addressLoc?.result[0]?.longitude,
                      address:
                        addressLoc?.result[0]?.latitude?.formattedAddress,
                    });
                    setInputValue(addressLoc?.result[0]?.formattedAddress);
                    setState({ destinationAddress: addressLoc?.result[0] });
                  }}
                  location={
                    selectedLocation
                      ? { lat: selectedLocation.lat, lng: selectedLocation.lng }
                      : undefined
                  }
                />
              </div>
              <p
                style={{
                  fontFamily: "Raleway-Bold",
                  fontSize: 22,
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginTop: "20px",
                }}
              >
                <LocationPinIcon
                  fill={defaultLabelColors.main_orange}
                  height={26}
                  width={26}
                />{" "}
                {strings?.dropoff_address}
              </p>

              <div style={{ width: "96.5" }}>
                <GoogleAutocompleteField
                  apiKey={apiKey}
                  placeholder="Lieu de livraison"
                  inputValue={inputValue}
                  onInputChange={setInputValue}
                  onPlaceSelected={handlePlaceSelected}
                  key={"selectedLocation"}
                  styles={{
                    background: "#F4F3F6",
                    width: "96.5%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: "10px",
                    fontFamily: "Raleway-Italic",
                    padding: "10px",
                    marginLeft: 9,
                    zIndex: 1111,
                    color: "#000",
                    height: "38px",
                  }}
                />
              </div>
              <div>
                <SelectField
                  placeholder={"Lieu de remise"}
                  textFeildBackground={"#F4F3F6"}
                  handleSelect={(name, event, value) =>
                    handleSelectForProduct(name, event, value)
                  }
                  options={[
                    {
                      label: "À déposer devant la porte d’entrée",
                      value: "À déposer devant la porte d’entrée",
                    },
                    {
                      label: "À déposer au pied de l’immeuble",
                      value: "À déposer au pied de l’immeuble",
                    },
                  ]}
                  value={state?.drop_location}
                  name={"drop_location"}
                />
              </div>
              <div>
                <InputField
                  placeHolder={"Nom du destinataire"}
                  input_value={state.reciverName ?? ""}
                  onchange={onInputChange}
                  input_name={"reciverName"}
                  input_style={{
                    background: "#F4F3F6",
                    width: "100%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "14px",
                    float: "left",
                    height: "58px",
                  }}
                />
              </div>
              <div
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  marginTop: "4px",
                  width: "98%",
                }}
              >
                <FlagInputField
                  classes={classes}
                  required={false}
                  name={"phone"}
                  codeName={"phone_code"}
                  value={state?.phone || ""}
                  countryCode={state?.phone_code || "+92"}
                  handleChangeMobileCode={(dialcode, codeName) =>
                    handleChangeMobileCode(dialcode, codeName)
                  }
                  handleChangeMobile={(event) => handleChangeMobile(event)}
                  enableFlagInput={enableFlagInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                  gap: 10,
                }}
              >
                <CustomButton
                  classesNames={
                    state?.screenStep === 1
                      ? "outlined_button_disable"
                      : "outlined_button"
                  }
                  value={"Retour"}
                  style={{ width: "150px" }}
                  disable={state?.screenStep === 1 ? true : false}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 1);
                    }
                  }}
                />

                <CustomButton
                  classesNames={"contained_button"}
                  value={"Suivant"}
                  style={{ width: "150px" }}
                  onClick={handleSaveDropOffDetails}
                />
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              height: "100%",
            }}
          >
            <div
              style={{
                background: defaultLabelColors.white,
                padding: "20px 20px 20px 20px",
                borderRadius: "12px",
                marginTop: "40px",
                width: "400px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: defaultLabelColors.white,
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontFamily: "Raleway-Bold",
                    fontSize: "22px",
                  }}
                >
                  Récapitulatif
                </div>
                <div>
                  <CartIcon />
                </div>
              </div>
              <div>
                {state?.anounces_datails?.map((i: any) => {
                  return (
                    <div
                      className="card-container"
                      style={{ marginTop: "5px" }}
                    >
                      <img
                        src={i?.images[0].uri}
                        className="card-image"
                        alt="prod-img"
                      />
                      <div className="card-details">
                        <div className="card-title" style={{ fontSize: 17 }}>
                          {handleLocalize({
                            fr: i?.packageType?.item_name_fr,
                            en: i?.packageType?.item_name_en,
                          }) ??
                            "" ??
                            ""}
                        </div>
                        <div className="card-dimensions">
                          {transSizes(i?.productSize?.size) ?? ""}{i?.prod_quantity > 1 ? "s" : null}
                        </div>
                      </div>
                      <div className="card-quantity">x{i?.prod_quantity}</div>
                    </div>
                  );
                })}

                <div
                  style={{
                    background: "#F5F5F5",
                    borderRadius: 16,
                    padding: "16px",
                    marginTop: "20px",
                  }}
                >
                  <div style={{ fontFamily: "Raleway-Variable" }}>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Raleway-Variable",
                        gap: 10,
                        fontSize: 14,
                        color: "grey",
                        marginBottom: 0,
                      }}
                    >
                      <HomeIconBlue fill={"grey"} height={20} width={20} />
                      Adresse de ramassage
                    </p>

                    <p style={{ fontSize: "16px" }}>
                      {state?.pickupDetails?.address ?? "N/A"}
                    </p>
                  </div>
                  <div style={{ fontFamily: "Raleway-Variable" }}>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Raleway-Variable",
                        gap: 10,
                        fontSize: 14,
                        color: "grey",
                        marginBottom: 0,
                      }}
                    >
                      <LocationPinIcon fill={"grey"} height={20} width={20} />
                      {strings?.dropoff_address}
                    </p>

                    <p style={{ fontSize: "16px" }}>
                      {state?.destinationAddress?.formattedAddress ??
                        state?.destinationAddress?.formatted_address ??
                        "- - -"}
                    </p>
                    <p style={{ fontSize: "16px" }}>
                      {props.pickup?.state?.pick_location?.label ?? "N/A"}
                    </p>
                  </div>

                  {/* <div style={{ fontFamily: "Raleway-Variable" }}>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontFamily: "Raleway-Variable",
                            gap: 10,
                            fontSize: 14,
                            color: "grey",
                            marginBottom: 0,
                          }}
                        >
                          Type de livraison
                        </p>

                        <p style={{ fontSize: "16px" }}>
                          {selectedValue ?? "Express - entre 1 à 2 jours"}
                        </p>
                      </div> */}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className="mobile-screen"
        style={{ width: "100%", paddingRight: 10 }}
      >
        <Grid container spacing={2} style={{ padding: "0px 10px" }}>
          <Grid xs={12} item style={{ display: "flex" }}>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "32px",
                      fontWeight: "700",
                    }}
                  >
                    Adresse de ramassage
                  </p>

                  <p style={{ fontFamily: "Raleway-Variable", fontSize: "18" }}>
                    Insérez l’adresse de ramassage des objets à déménager
                  </p>
                </div>
                <div style={{ width: "10%", paddingTop: "50px" }}>
                  <div
                    onClick={handleOpen}
                    style={{
                      background: "#fff",
                      borderRadius: 100,
                      width: "50px",
                      height: "50px",
                      padding: 5,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      float: "right",
                    }}
                  >
                    <CartIcon />
                  </div>
                </div>
              </div>

              <div style={{ height: "250px", marginLeft: 10 }}>
                <MapForLocations
                  // apiKey={apiKey}
                  location={
                    selectedLocation
                      ? { lat: selectedLocation.lat, lng: selectedLocation.lng }
                      : undefined
                  }
                />{" "}
              </div>
              <div
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: 22,
                  fontWeight: 600,
                  display: "flex",
                }}
              >
                <div style={{ flex: 1, width: "80%", padding: "10px" }}>
                  Adresse de ramassage
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <GoogleAutocompleteField
                  apiKey={apiKey}
                  placeholder="Enter a location"
                  inputValue={inputValue}
                  onInputChange={setInputValue}
                  onPlaceSelected={handlePlaceSelected}
                  key={"selectedLocation"}
                  styles={{
                    background: "#F4F3F6",
                    width: "97.5%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    margin: "10px",
                    fontFamily: "Raleway-Italic",
                    padding: "10px",
                    marginLeft: 9,
                    zIndex: 1111,
                    color: "#000",

                    height: "60px",
                  }}
                />
              </div>

              <div style={{ width: "98%" }}>
                <SelectField
                  placeholder={"Lieu de remise"}
                  textFeildBackground={"#F4F3F6"}
                  handleSelect={(name, event, value) =>
                    handleSelectForProduct(name, event, value)
                  }
                  options={[
                    { label: "Devant chez moi", value: "Devant chez moi" },
                    {
                      label: "Dans la pièce de mon choix",
                      value: "Dans la pièce de mon choix",
                    },
                  ]}
                  value={state?.pick_location}
                  name={"pick_location"}
                />
              </div>

              <div style={{ width: "100%" }}>
                <InputField
                  placeHolder={"Nom du destinataire"}
                  input_value={state.reciverName ?? ""}
                  onchange={onInputChange}
                  input_name={"reciverName"}
                  input_style={{
                    background: "#F4F3F6",
                    width: "98%",
                    borderRadius: "16px",
                    border: "none",
                    fontSize: "16px",
                    float: "left",
                  }}
                  startIcon={<span style={{ width: "0px" }}> </span>}
                />
              </div>

              <div
                style={{
                  paddingLeft: "10px",
                  width: "100%",
                }}
              >
                <FlagInputForMobile
                  divStyle={{
                    background: "#F4F3F6",
                    height: "55px",
                    borderRadius: "12px",
                  }}
                  inputStyle={{ background: "#F4F3F6" }}
                  classes={classes}
                  required={false}
                  name={"phone"}
                  codeName={"phone_code"}
                  value={state?.phone || ""}
                  countryCode={state?.phone_code || "+92"}
                  handleChangeMobileCode={(dialcode, codeName) =>
                    handleChangeMobileCode(dialcode, codeName)
                  }
                  handleChangeMobile={(event) => handleChangeMobile(event)}
                  enableFlagInput={enableFlagInput}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                  gap: 10,
                }}
              >
                <CustomButton
                  classesNames={
                    state?.screenStep === 1
                      ? "outlined_button_disable"
                      : "outlined_button"
                  }
                  value={"Retour"}
                  style={{ width: "150px" }}
                  disable={state?.screenStep === 1 ? true : false}
                  onClick={() => {
                    if (props.handleUpadte) {
                      handleUpadte("step", props.step - 1);
                    }
                  }}
                />

                <CustomButton
                  classesNames={"contained_button"}
                  value={"Suivant"}
                  style={{ width: "150px" }}
                  onClick={handleSaveDropOffDetails}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <React.Fragment>
          <ActionSheet
            ref={ref}
            sheetStyle={{
              backgroundColor: "#fff",
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
              padding: 10,
              height: "60vh",
              zIndex: 11111111,
            }}
            bgStyle={{
              backgroundColor: "rgba(1, 1, 1, 0.8)",
            }}
          >
            <div className="actionSheetContent">
              <Grid xs={12} item>
                <div
                  style={{
                    background: defaultLabelColors.white,
                    padding: "20px 20px 20px 20px",
                    borderRadius: "12px",
                    marginTop: "40px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      background: defaultLabelColors.white,
                      marginBottom: "20px",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontSize: "22px",
                        fontWeight: "700",
                      }}
                    >
                      Récapitulatif
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Close onClick={handleClose} />
                    </div>
                  </div>
                  <div>
                    {state?.anounces_datails?.map((i: any) => {
                      return (
                        <div
                          className="card-container"
                          style={{ marginTop: "5px", width: 380 }}
                        >
                          <img
                            src={i?.images[0].uri}
                            className="card-image"
                            alt="prod-img"
                          />
                          <div className="card-details">
                            <div
                              className="card-title"
                              style={{ fontSize: 17, width: "100%", flex: 1 }}
                            >
                              {handleLocalize({
                                fr: i?.packageType?.item_name_fr,
                                en: i?.packageType?.item_name_en,
                              }) ?? ""}
                            </div>
                            <div className="card-dimensions">
                              {i?.productSize[0]?.size ?? ""}{i?.prod_quantity > 1 ? "s" : null}
                            </div>
                            x
                          </div>
                          <div className="card-quantity">
                            x{i?.prod_quantity}
                          </div>
                          {i?.prod_is_fragile ? (
                            <div
                              className="card-quantity"
                              style={{
                                fontSize: "14px",
                                fontFamily: "Raleway-Variable",
                                fontWeight: "500",
                                marginTop: 5,
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Fragile</span> :
                              manipuler avec soin
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
            </div>
          </ActionSheet>
        </React.Fragment>
      </div>
    </>
  );
}

export default DropOffInfo;
