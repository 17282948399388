import {
  AddBoxOutlined,
  Close,
  KeyboardArrowDown,
  Logout,
  Search,
  Settings,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../Store";
import { getCart, setUpdateCart } from "../../Store/MainAppReducer";
import {
  CartIconMarketPlace,
  FrankIcon,
  MailIconFigma,
  UserblackIcon,
  UserIconFigma
} from "../../assets/images/FrankIcons";
import drawerBoxIcon from "../../assets/mobileImages/drawer-box-icon.png";
import apiServices from "../../services/RequestHandler";
import {
  getDataFromLocalStorage,
} from "../../utils/LocalStore.util";
import { color_theme, defaultLabelColors } from "../../utils/constants";
import {
  handleLocalize,
  numberFormatWithoutCurrencyFixed
} from "../../utils/helper";
import { FrankDivider } from "../../views/MarketPlace/Checkout";
import InputField from "../TextFeild";
import Toast from "../Toast/Toast";

 
function HeaderForMarketPlace(props: any) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const dispatch = useDispatch<any>();
  let userDetails = getDataFromLocalStorage("user");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEM, setAnchorEM] = useState<HTMLButtonElement | null>(null);
  const selector = useSelector((state: RootState) => state.mainApp);
  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isFavorited: false,
    }
  );
   
  
  const handleClickforCart = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEM(event.currentTarget);
  };
  const handleCloseforCart = () => {
    setAnchorEM(null);
  };
// eslint-disable-next-line
  const handleSearchProducts = useCallback(
    debounce(async (searchValue: any) => {
      try {
        if (!searchValue) {
          setState({ showSearchedData: false });
        }
        if (searchValue?.trim()) {
          let resp = await apiServices.postFromApi(
            "c2c-product-masters/products-search",
            { search: searchValue },
            ""
          );

          setState({
            allSearchProduct: resp?.data,
            showSearchedData: true,
          });
        }
      } catch (error) {
        console.error("Error while searching products:", error);
      }
    }, 1500),
    []
  );

  useEffect(() => {
    handleSearchProducts(state.searched_prod);
  }, [state.searched_prod, handleSearchProducts]);

  const openForCart = Boolean(anchorEM);
  const id = openForCart ? "cart-popover" : undefined;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  const handleUpdateCart = async (item: any) => {
    try {
      const user = getDataFromLocalStorage("user");
      if (user) {
        await apiServices.deleteFromApi(`/c2c-carts/${item?.id}`, "");
        dispatch(getCart());
        Toast.showSuccess("Item removed from cart");
      } else {
        dispatch(
          setUpdateCart({
            ...item,
            quantity: 0,
          })
        );
        Toast.showSuccess("Item removed from cart");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const total_amount = selector?.cart?.reduce((a: any, b: any) => {
    return (
      a +
      Number(Number(b?.price ?? 0) - Number(b?.discount ?? 0)) *
        Number(b?.quantity ?? 1)
    );
  }, 0);

  return (
    <Grid
      container
      spacing={2}
      className="display-flex"
      style={{
        background: "#FCE6DD",
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "30px",
      }}
    >
      <Grid xs={12} item style={{ zIndex: 99 }}>
        <div
          style={{
            padding: 15,
            background: "#FEF5F1",
            borderRadius: 24,
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 14,
                width: "100%",
              }}
            >
              <a href="/" style={{ marginBottom: "-10px", marginLeft: 25 }}>
                <FrankIcon fill={"#ee6931"} height={55} />
              </a>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 14,
                  flex: 1,
                }}
              >
                <div className="font_resp">
                  <div
                    className="menu-component menu-container"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="particulier-marketplace font_resp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Semi-Bold",
                      }}
                    >
                      Particulier{" "}
                      <KeyboardArrowDown
                        style={{
                          fontSize: "24px",
                          fontWeight: "lighter",
                        }}
                      />
                    </span>

                    <div className="dropdown">
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          navigate("/send-package");
                        }}
                      >
                        Envoyer un colis
                      </div>
                      <div className="dropdown-item">Suivre mon colis</div>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          navigate("/transporter-un-colis");
                        }}
                      >
                        Devenir transporteur
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          navigate("/send-package");
                        }}
                      >
                        Démenagement{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="font_resp">
                  <div
                    className="menu-component menu-container"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="particulier-marketplace font_resp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Semi-Bold",
                      }}
                    >
                      Transporteur{" "}
                      <KeyboardArrowDown
                        style={{
                          fontSize: "24px",
                          fontWeight: "lighter",
                        }}
                      />{" "}
                    </span>

                    <div className="dropdown">
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          navigate("/transporter-un-colis");
                        }}
                      >
                        Devenir Transporteur
                      </div>
                      <div
                        className="dropdown-item"
                        onClick={() => {
                          navigate("/transporter-un-colis");
                        }}
                      >
                        Voir les annonces
                      </div>
                    </div>
                  </div>
                </div>

                <div className="font_resp">
                  <div
                    className="menu-component menu-container"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="particulier-marketplace font_resp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Semi-Bold",
                      }}
                      onClick={() => {
                        navigate("/marketplace");
                      }}
                    >
                      Marché de Frank
                    </span>
                  </div>
                </div>

                <div className="font_resp" style={{ paddingLeft: 5 }}>
                  <div
                    className="menu-component menu-container"
                    style={{ cursor: "pointer" }}
                  >
                    <span
                      className="particulier-marketplace font_resp"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontFamily: "Raleway-Semi-Bold",
                      }}
                      onClick={() => {
                        navigate("/blogs");
                      }}
                    >
                      Blog
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="hide_login_drawer"
                style={{ cursor: "pointer", marginTop: "10px" }}
                onClick={toggleDrawer(true)}
              >
                <svg
                  width="23px"
                  height="22px"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginTop: "-10px" }}
                >
                  <path
                    d="M4 16H28M4 8H28M4 24H20"
                    stroke="#281208"
                    stroke-width="2.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              {/* This is Drawer */}
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                style={{
                  borderRadius: "0px",
                  background: "none",
                  height: "100vh",
                }}
                PaperProps={{
                  sx: {
                    width: "300px",
                  },
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 1, padding: "20px 0px 0px 20px" }}>
                    <FrankIcon fill={"#ee6931"} height={60} />
                  </div>
                  <div style={{ padding: "20px 20px 0px 0px" }}>
                    <IconButton onClick={toggleDrawer(false)}>
                      <Close style={{ fontSize: "28px", color: "#000" }} />
                    </IconButton>
                  </div>
                </div>
                <Typography
                  variant="body1"
                  style={{ paddingLeft: "20px", flex: 1 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    {userDetails ? (
                      <div className="user" style={{ cursor: "pointer" }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "15px",
                            fontFamily: "Raleway-Variable",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            navigate("/chat");
                          }}
                        >
                          <MailIconFigma />{" "}
                          <span style={{ color: "#000" }}>Mail</span>
                        </div>
                      </div>
                    ) : null}

                    {userDetails ? (
                      <div
                        className="compte"
                        style={{
                          cursor: "pointer",
                          position: "relative",
                        }}
                      >
                        <div className="user" style={{}}>
                          <div
                            onClick={handleClick}
                            style={{
                              display: "flex",
                              gap: "20px",
                              fontFamily: "Raleway-Variable",
                              fontWeight: "600",
                            }}
                          >
                            <UserIconFigma /> <span>Account</span>
                          </div>
                        </div>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleClose}
                          onClick={handleClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              width: "200px",
                              borderRadius: "12px",
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&::before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            className="dropdown-item"
                            style={{ fontFamily: "Raleway-Variable" }}
                            onClick={() => {
                              navigate("/profile");
                            }}
                          >
                            <Avatar /> Profil
                          </MenuItem>

                          <Divider />

                          <MenuItem
                            className="dropdown-item"
                            style={{ fontFamily: "Raleway-Variable" }}
                            // onClick={handleClose}
                          >
                            <ListItemIcon>
                              <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                          </MenuItem>
                          <MenuItem
                            className="dropdown-item"
                            style={{ fontFamily: "Raleway-Variable" }}
                            // onClick={handleLogout}
                          >
                            <ListItemIcon>
                              <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                          </MenuItem>
                        </Menu>
                      </div>
                    ) : (
                      <div
                        className="menu-component menu-container"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        <span
                          className="particulier-marketplace font_resp"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "Raleway-Semi-Bold",
                            gap: 10,
                          }}
                        >
                          Se Connecter{" "}
                          <span style={{ paddingBottom: "5px" }}>
                            <UserIconFigma />
                          </span>
                        </span>
                      </div>
                    )}

                    {/* <div
                      className="compte"
                      style={{ cursor: "pointer", position: "relative" }}
                    >
                      <div className="user">
                        <div
                          className="particulier"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          onClick={handleClickForLang}
                        >
                          {selector.lang?.toUpperCase()} <ArrowDropDown />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </Typography>
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    <img src={drawerBoxIcon} height={"60%"} alt="box-icon" />
                  </div>
                </Typography>
              </Drawer>
              {/*Drawer ends here*/}

              <div className="login_area">
                <div className="right">
                  <div className="font_resp">
                    <div
                      className="menu-component menu-container"
                      style={{ cursor: "pointer" }}
                    >
                      <span
                        className="particulier-marketplace font_resp"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Semi-Bold",
                        }}
                      >
                        Suivre mon colis
                      </span>
                    </div>
                  </div>

                  {userDetails ? (
                    <div
                      style={{ display: "flex", alignItems: "center", gap: 24 }}
                    >
                      <div
                        className=""
                        style={{ cursor: "pointer", margin: 2 }}
                      >
                        <div
                          className=""
                          onClick={() => {
                            navigate("/chat");
                          }}
                        >
                          <MailIconFigma />
                        </div>
                      </div>

                      {/* <div
                        className=""
                        style={{ cursor: "pointer", margin: 2 }}
                      >
                        <div className="">
                          <BoxIconFigma />
                        </div>
                      </div> */}
                      <div
                        className="compte"
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        <div className="user" style={{}}>
                          <div
                            onClick={() => {
                              navigate("/profile");
                            }}
                          >
                            <UserIconFigma />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="menu-component menu-container"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      <span
                        className="particulier-marketplace font_resp"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontFamily: "Raleway-Semi-Bold",
                          gap: 10,
                        }}
                      >
                        Se Connecter{" "}
                        <span style={{ paddingBottom: "5px" }}>
                          <UserblackIcon />
                        </span>
                      </span>
                    </div>
                  )}

                  {/* <div
                    className="compte"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <div className="user">
                      <div
                        className="particulier-marketplace font_resp"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={handleClickForLang}
                      >
                        {selector.lang?.toUpperCase()} <ArrowDropDown />
                      </div>

                      <Menu
                        id="account-menu"
                        MenuListProps={{
                          "aria-labelledby": "fade-button",
                        }}
                        anchorEl={anchorEN}
                        open={openForLang}
                        onClose={handleCloseForLang}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            // width: "200px",
                            borderRadius: "12px",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("fr"));
                            handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={frIcon}
                              height={30}
                              width={30}
                              alt="fr-icon"
                            />
                            FR
                          </div>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            dispatch(setUpdateLanguage("en"));
                            handleCloseForLang();
                          }}
                        >
                          <div className="display-flex" style={{ gap: 10 }}>
                            <img
                              src={enIcon}
                              height={20}
                              width={30}
                              alt="en-icon"
                            />
                            EN
                          </div>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderTop: "1px solid lightgray",
              padding: 10,
              justifyContent: "space-between",
              alignItems: "center",
              height: "60px",
            }}
          >
            <div>
            <InputField
              placeHolder={`Chercher un produit`}
              input_value={props?.global_search}
              input_name={"global_search"}
              onchange={props.onInputChangeForHeader}
              input_style={{
                background: "#fff",
                
              // 300px t0 280px applying bcz responvsive on sm screen

                width: xl ? "726px" : lg ? "600px" : md ? "400px" : "280px",
                // maxWidth: "726px",
                borderRadius: "12px",
                fontFamily: "Raleway-Variable",
              }}
              startIcon={<Search style={{ opacity: "50%" }} />}
            />
            </div>
            
            <div style={{ display: "flex", gap: 10 ,
              //applying bcz responvsive on sm scree
              width:"50%",justifyContent:"flex-end" }}>
              <Button
                disableElevation
                variant="contained"
                startIcon={<AddBoxOutlined style={{ fontSize: "26px" }} />}
                onClick={() => {
                  navigate("/add-product-individual");
                }}
                sx={{
                  backgroundColor: defaultLabelColors.main_orange, // Same as the blue color in the image
                  color: "#fff",
                  borderRadius: "12px",
                  padding: "10px 20px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontFamily: "Raleway-Variable",
                  fontWeight: "bold",
                  width: "300px",
                  "&:hover": {
                    backgroundColor: defaultLabelColors.main_orange,
                  },
                }}
              >
                Vendre un article
              </Button>

              <Button
                disableElevation
                variant="contained"
                onClick={handleClickforCart}
                sx={{
                  backgroundColor: defaultLabelColors.main_blue,
                  color: "#fff",
                  borderRadius: "12px",
                  textTransform: "none",
                  fontSize: "16px",
                  textAlign: "center",
                  fontFamily: "Raleway-Variable",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: defaultLabelColors.main_blue,
                  },
                }}
              >
                <IconButton aria-describedby={id}>
                  <Badge
                    badgeContent={selector?.cart?.reduce((a: any, b: any) => {
                      return a + Number(b?.quantity ?? 0);
                    }, 0)}
                    color="primary"
                  >
                    <CartIconMarketPlace fill={defaultLabelColors.white} />
                  </Badge>
                </IconButton>
              </Button>

              <Popover
                id={id}
                open={openForCart}
                anchorEl={anchorEM}
                onClose={handleCloseforCart}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "16px", // Apply border radius here
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Optional: Add shadow for effect
                    padding: "10px",
                  },
                }}
              >
                <List>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        fontFamily: "Raleway-Variable",
                        fontWeight: 700,
                        color: defaultLabelColors.main_blue,
                        fontSize: "22px",
                        width: "100%",
                      }}
                    >
                      Panier vide
                    </div>

                    {/* <CartIconMarketPlace
                                      fill={defaultLabelColors.main_blue}
                                    /> */}
                    <IconButton aria-describedby={id}>
                      <Badge
                        badgeContent={selector?.cart?.reduce(
                          (a: any, b: any) => a + Number(b?.quantity ?? 0),
                          0
                        )}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: defaultLabelColors.main_orange,
                            color: "#fff",
                          },
                        }}
                      >
                        <CartIconMarketPlace
                          fill={defaultLabelColors.main_blue}
                        />
                      </Badge>
                    </IconButton>
                  </div>
                  {FrankDivider()}
                  {selector?.cart?.length > 0 ? (
                    <>
                      <div
                        className="hide_scroll"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: 12,
                          maxHeight: 400,
                          overflow: "hidden scroll",
                          paddingRight: 5,
                        }}
                      >
                        {selector?.cart?.map((item: any, index: number) => (
                          <div
                            key={`CART_ITEM_${index + 1}`}
                            style={{
                              width: 400,
                              display: "flex",
                              flexDirection: "row",
                              borderRadius: 12,
                              columnGap: 10,
                              backgroundColor: "#F4F3F6",
                              marginTop: index === 0 ? 10 : 0,
                            }}
                          >
                            <img
                              alt="prod-img"
                              src={item?.images?.[0]}
                              style={{
                                height: 93,
                                width: 102,
                                objectFit: "cover",
                                borderTopLeftRadius: 12,
                                borderBottomLeftRadius: 12,
                                backgroundColor: "#F4F3F6",
                                objectPosition: "top",
                              }}
                            />
                            <div>
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: 16,
                                    whiteSpace: "nowrap",
                                    width: 250,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontFamily: "Raleway-Bold",
                                    margin: "10px 0px 0px 0px",
                                  }}
                                >
                                  {handleLocalize(item?.product_title) ?? ""}
                                </p>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Close
                                    style={{
                                      marginTop: "0.3em",
                                      cursor: "pointer",
                                    }}
                                    titleAccess="Supprimer l'article ?"
                                    onClick={() => handleUpdateCart(item)}
                                  />
                                </div>
                              </div>
                              <p
                                style={{
                                  fontSize: 16,
                                  whiteSpace: "nowrap",
                                  width: 250,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontFamily: "Raleway-Variable",
                                  color: "#78778A",
                                  margin: 0,
                                }}
                              >
                                {handleLocalize(item?.brand_name) ?? ""}
                              </p>

                              <p
                                style={{
                                  fontSize: 18,
                                  fontFamily: "Raleway-Bold",
                                  margin: 0,
                                  textAlign: "right",
                                  color: color_theme.main_blue,
                                }}
                              >
                                {numberFormatWithoutCurrencyFixed(
                                  Number(
                                    Number(item?.price ?? 0) -
                                      Number(item?.discount ?? 0)
                                  ) * Number(item?.quantity ?? 0),
                                  "en-FR"
                                )}{" "}
                                €
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          borderTop: "1px solid #d4d4d4",
                          marginTop: 10,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ flex: 1 }}>
                            <p
                              style={{
                                fontSize: 27,
                                color: "#281208",
                                fontFamily: "Raleway-Bold",
                              }}
                            >
                              Total
                              <span
                                style={{
                                  fontSize: 16,
                                  color: "#78778A",
                                  fontFamily: "Raleway-Bold",
                                }}
                              >
                                {" "}
                                (Hors livraison){" "}
                              </span>
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: 28,
                              color: "#080626",
                              fontFamily: "Raleway-Bold",
                            }}
                          >
                            {total_amount?.toFixed(2)} €
                          </p>
                        </div>
                        <div
                          style={{
                            cursor: "pointer",
                            maxHeight: "40px",
                            padding: "12px",
                            backgroundColor: color_theme.main_blue,
                            background: color_theme.main_blue,
                            borderRadius: 12,
                            placeContent: "center",
                            display: "flex",
                            placeItems: "center",
                          }}
                          onClick={() => {
                            navigate("/cart-details");
                          }}
                        >
                          <span
                            style={{
                              fontSize: 20,
                              fontFamily: "Raleway-Semi-Bold",
                              lineHeight: "18px",
                              color: color_theme.white,
                            }}
                          >
                            Voir mon panier
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <ListItem style={{ width: "400px" }}>
                      <Typography>No items in the cart.</Typography>
                    </ListItem>
                  )}
                </List>
              </Popover>
            </div>
          </div>
        
        </div>
      </Grid>
    </Grid>
  );
}

export default HeaderForMarketPlace;
