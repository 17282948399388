import { Grid } from "@mui/material";

import { Helmet } from "react-helmet";
import CustomFooter from "../../UiComponent/CustomFooter";
import NewHeader from "../../UiComponent/NewHeader";
import AuthAction from "./AuthAction";
import AuthMobileScreen from "./AuthMobileScreen";
 

function LoginPage(props: any) {
  return (
    <>
      <Helmet>
        <title>Connexion à Frank | Accédez à Votre Compte</title>
        <link rel="canonical" href="https://www.v2internal.frankme.com/login" />
        <link
          rel="icon"
          type="image/ico"
          href="../../assets/images/FrankIcons/favicon.ico"
        />
        <meta
          name="description"
          content="Connectez-vous à votre compte Frank pour gérer vos envois, consulter vos annonces et interagir avec la communauté. Rejoignez-nous et profitez de nos services de livraison collaborative."
        />
 
      </Helmet>
      <div style={{ background: "#F5C9B3" }} className="web-screen">
        <div style={{ zIndex: 1111 }} className="auth-background">
          <Grid
            container
            spacing={2}
            className="display-flex"
            style={{
              background: "#F4C9B3",
              paddingLeft: "40px",
              paddingRight: "40px",
              paddingTop: "30px",
            }}
          >
            <Grid xs={12} item style={{ zIndex: 99 }}>
              <NewHeader />
            </Grid>
          </Grid>

          <Grid xs={12} item>
            <AuthAction />
          </Grid>

          <div
            className="main-container"
            style={{ background: "#f9c7b8", marginTop: "50px" }}
          >
            <CustomFooter />
          </div>
        </div>
      </div>

      <div className="mobile-screen">
        <AuthMobileScreen />
      </div>
    </>
  );
}

export default LoginPage;
