import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./App.css";
import { store } from "./Store";
import MapLoaderProvider from "./UiComponent/MapForLocations/MapLoaderProvider";
import { GOOGLE_CLIENT_ID } from "./utils/constants";
function AppEntryPoint() {

  
  return (
    <Provider store={store}>
      <MapLoaderProvider apiKey="AIzaSyCHQclQrRUyxhjnamOPm4n1z09r-KWmcdQ">
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </MapLoaderProvider>
    </Provider>
  );
}

export default AppEntryPoint;
