import {
  PaymentElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import React from "react";
import Loader from "../../UiComponent/Loader";
import Toast from "../../UiComponent/Toast/Toast";
import { color_theme } from "../../utils/constants";

const StripeCheckout = (props: any) => {
  const {
    handleOtpSend,
    handleCloseDialog,
    handleSubmitOrder,
    padding = "20px",
  } = props;
  const [activity, setActivity] = React.useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    if (!handleOtpSend && !handleSubmitOrder) {
      const result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://www.v2internal.frankme.com/",
        },
        redirect: "if_required",
      });
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
      } else {
        Toast.showSuccess("Banque ajoutée avec succès");
        handleCloseDialog();
      }
    }
    if (handleOtpSend) {
      setActivity(true);
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://www.v2internal.frankme.com/",
        },
        redirect: "if_required",
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
      } else {
        // Toast.showSuccess("Payment Succesfull");
        handleOtpSend();
      }

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      setActivity(false);
    }
    if (handleSubmitOrder) {
      setActivity(true);
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://www.v2internal.frankme.com/",
        },
        redirect: "if_required",
      });
      setActivity(false);

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        setActivity(false);
      } else {
        setActivity(false);

        handleSubmitOrder();
      }
    }
    setActivity(false);
  };

  return (
    <>
      <Loader loading={activity} />
      <div style={{ display: "flex", flexDirection: "column", padding }}>
        <PaymentElement />

        <button
          onClick={handleSubmit}
          disabled={!stripe}
          style={{
            padding: 10,
            backgroundColor: color_theme.main_orange,
            background: color_theme.main_orange,
            borderRadius: 6,
            placeContent: "center",
            display: "flex",
            placeItems: "center",
            cursor: "pointer",

            fontSize: 14,
            fontFamily: "Raleway-Semi-Bold",
            lineHeight: "18px",
            color: color_theme.white,
            border: "none",
            marginTop: 16,
          }}
        >
          Enregistrer
        </button>
      </div>
    </>
  );
};

export default StripeCheckout;
