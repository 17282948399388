import PropTypes from "prop-types";
import AboutPage from "../views/AboutPage";
import AllAnounces from "../views/AllAnounces";
import LoginPage from "../views/Auth";
import Blogs from "../views/Blogs";
import BlogDetails from "../views/Blogs/BlogDetails";
import LandingPage from "../views/LandingPage";
import MyAnnounces from "../views/MyAnnounces";
import AnounceDetails from "../views/MyAnnounces/AnounceDetails";
import Notifications from "../views/Noifications";
import Profile from "../views/Profile";
import SenderInbox from "../views/SenderInbox.tsx";
import SendPackage from "../views/SendPackage";
import SignUp from "../views/SignUp";
import TranspoprterUnColis from "../views/TransporterUnColis";
import JobDetails from "../views/TransporterUnColis/JobDetails";
import Unscubscribe from "../views/Unscubscribe";
import { PrivateRoute } from "./AuthenticationRoutes";
import TermsAndCondition from "../views/TermsAndCondition";
import PrivacyPolicy from "../views/PrivacyPolicy";
import AccountDelete from "../views/AccountDetete";

// ==============================|| MAIN ROUTING ||============================== //

export function MainAppRoutes({ classes }: any) {
  const MainRoutesBefore = {
    path: "/",
    children: [
      {
        path: "",
        element: <LandingPage classes={classes} />,
      },
      {
        path: "login",
        element: <LoginPage classes={classes} />,
      },
      {
        path: "sign-up",
        element: <SignUp classes={classes} />,
      },
      {
        path: "profile",
        element: <PrivateRoute element={<Profile classes={classes} />} />,
      },
      {
        path: "blogs",
        element: <Blogs classes={classes} />,
      },
      {
        path: "delete-account",
        element: <AccountDelete classes={classes} />,
      },
      // {
      //   path: "send-parcel",
      //   element: <SendParcel classes={classes} />,
      // },
      {
        path: "send-package",
        element: <SendPackage classes={classes} />,
      },
      {
        path: "relocation",
        element: <SendPackage classes={classes} />,
      },
      {
        path: "transporter-un-colis",
        element: <TranspoprterUnColis classes={classes} />,
      },
      // {
      //   path: "devenir-transporter",
      //   element: <DevenirTransporteur classes={classes} />,
      // },

      // {
      //   path: "my-jobs",
      //   element: (
      //     <PrivateRoute element={<TransporterJobs classes={classes} />} />
      //   ),
      // },
      {
        path: "chat/:ship_id?/:receiver?",
        element: <PrivateRoute element={<SenderInbox classes={classes} />} />,
      },
      {
        path: "announces",
        element: <AllAnounces classes={classes} />,
      },
      {
        path: "my-anounces",
        element: <PrivateRoute element={<MyAnnounces classes={classes} />} />,
      },
      {
        path: "about-us",
        element: <AboutPage classes={classes} />,
      },
      {
        path: "job/:id?",
        element: <PrivateRoute element={<JobDetails classes={classes} />} />,
      },
      {
        path: "blog-details/:id/:idx",
        element: <BlogDetails classes={classes} />,
      },
      {
        path: "anounce/:id?",
        element: (
          <PrivateRoute element={<AnounceDetails classes={classes} />} />
        ),
      },
      {
        path: "unsubscribe",
        element: <Unscubscribe classes={classes} />,
      },
      {
        path: "terms-and-condition",
        element: <TermsAndCondition classes={classes} />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy classes={classes} />,
      },

      {
        path: "notifications",
        element: (
          <PrivateRoute element={<Notifications classes={classes} />} />
        ),
      },

      // comment by asad iqbal 14/1/25  (launching c2c web ppt)
      // {
      //   path: "marketplace",
      //   element: <MarketPlace classes={classes} />,
      // },
      // {
      //   path: "track-order/:id?",
      //   element: <PrivateRoute element={<PurchasedProdDetails classes={classes} />} />,
      // },
      // {
      //   path: "track-p-order/:id?",
      //   element: <PrivateRoute element={<PurchasedParticularProdDetails classes={classes} />} />,
      // },
      // {
      //   path: "p-order/:id?",
      //   element: (
      //     <PrivateRoute
      //       element={<ParticularOrderDetails classes={classes} />}
      //     />
      //   ),
      // },
      // {
      //   path: "my-p-product/:id?",
      //   element: (
      //     <PrivateRoute
      //       element={<ParticularMyListedProduct classes={classes} />}
      //     />
      //   ),
      // },

      // {
      //   path: "p-checkout?",
      //   element: (
      //     <PrivateRoute element={<CheckoutParticular classes={classes} />} />
      //   ),
      // },
      // {
      //   path: "cart-details",
      //   element:<Checkout classes={classes} />
      //   // element: <PrivateRoute element={} />,
      // },
      // {
      //   path: "order-checkout?",
      //   element: <PrivateRoute element={<OrderCheckout classes={classes} />} />,
      // },
      // {
      //   path: "product/:id?",
      //   element: <ProductDetails classes={classes} />,
      // },
      // {
      //   path: "p-shop-details/:id?",
      //   element: <ParticularShopDetails classes={classes} />,
      // },
      // {
      //   path: "search",
      //   element: <SearchProduct classes={classes} />,
      // },
      // {
      //   path: "shop-details/:id?",
      //   element: <FrankShopDetails classes={classes} />,
      // },
      // {
      //   path: "p-product/:id?",
      //   element: <ParticularProductDetails classes={classes} />,
      // },
      // {
      //   path: "all-products/:id?",
      //   element: <AllProductByCateogry classes={classes} />,
      // },
      // {
      //   path: "add-product-individual",
      //   element: <AddProductIndividual classes={classes} />,
      // },
      // {
      //   path: "trending-products",
      //   element: <AlltTrendingProducts classes={classes} />,
      // },
      // {
      //   path: "latest-products",
      //   element: <AllLatestItem classes={classes} />,
      // },
      // {
      //   path: "most-viewed",
      //   element: <MostViewsProducts classes={classes} />,
      // },
    ],
  };

  return MainRoutesBefore;
}

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
