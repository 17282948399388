import { AppStoreFR, FrankIcon } from "../../assets/images/FrankIcons";
import facebookIcon from "../../assets/images/FrankIcons/Artboard_1.png";
import instaIcon from "../../assets/images/FrankIcons/Artboard_2.png";
import linkedInIcon from "../../assets/images/FrankIcons/Artboard_3.png";
import playSore from "../../assets/images/FrankIcons/PlayStore-btn.png";
import mobileFooterNewImage from "../../assets/images/FrankIcons/mobileFooterNew.png";
import { defaultLabelColors } from "../../utils/constants";
export default function CustomMobileFooter() {


  const redirectToStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.frankc2c';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // Redirect to Apple App Store
      window.location.href = 'https://apps.apple.com/us/app/frank/id6502341979';
    } else {
      // Handle cases where the OS is not Android or iOS
      console.log('This feature is only available on mobile devices.');
    }
  };
  return (
    <div style={{ background: defaultLabelColors.main_blue,paddingBottom:'40px',paddingLeft:"10px",paddingRight:'10px' }}>
      <div style={{ padding: "50px 30px 10px 20px" }}>
       
        <div style={{ position: "relative", height: "550px" }}>
          <div
            style={{
              rowGap: 20,
              display: "flex",
              flexDirection: "column",
              zIndex: 111,
              position: "absolute",
              padding: 30,
            }}
          >
            <p
              className="heading-two"
              style={{
                fontSize: "22px",
                textAlign: "left",
                color: "#fff",
                padding: "0px 10px 0px 10px",
                fontFamily: "Raleway-Italic",
                margin: 0,
              }}
            >
              Téléchargez l'application mobile Frank
            </p>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                margin: 0,
                color: "#fff",
                paddingLeft: 5,
              }}
            >
              Rejoignez gratuitement la communauté de livraison verte
            </p>

            <div
              className="display-flex"
              style={{
                flexDirection: "row",
                gap: 10,
              }}
            >
              <a
                href="https://apps.apple.com/app/frank/id6502341979"
                target="_blank"
                rel="noreferrer"
              >
                <AppStoreFR height={40} />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                rel="noreferrer"
                style={{ textAlign: "center" }}
              >
                <img
                  src={playSore}
                  alt="google-play"
                  style={{ width: "130px", height: "40px", marginTop: -5 }}
                />
              </a>
            </div>
          </div>
          <img
            src={mobileFooterNewImage}
            alt=""
            style={{ height: "100%", width: "100%", position: "absolute" }}
          />
        </div>
      </div>
      <div
        className="footer-new"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="contain" style={{ padding: "20px 0px 0px 0px" }}>
          <div
            className="col"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{margin:0}}>
              <FrankIcon fill={"#fff"} height={80} />
            </p>
          </div>
          <div className="col" style={{ width: "310px" }}>
            <div
              style={{
                border: "1px solid #fff",
                borderRadius: "12px",
                marginTop: "20px",
                padding: "20px",
                width: "100%",
              }}
            >
              <span
                style={{
                  padding: 0,
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#fff",
                  fontFamily: "Raleway-Italic",
                }}
              >
                Nous Contacter:
              </span>
              <ul>
                <li>hello@frankme.com</li>
                <li> +33 6 07 94 80 55 </li>
                <li>7 rue du 4 Septembre, 75002 Paris</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                // marginBottom: "70px",
                gap: "10px",
                marginTop: "10px",
                // justifyContent: "center",
                // alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div 
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=61561661727623",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              >
                <img
                  src={facebookIcon}
                  style={{ height: "50px" }}
                  alt="facebook-icon"
                />
              </div>
              <div
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/fr-frank/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              >
                <img
                  src={instaIcon}
                  style={{ height: "50px" }}
                  alt="instgram-icon"
                />
              </div>
              <div
              onClick={() =>
                window.open(
                  "https://www.instagram.com/frankme.fr/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              >
                <img
                  src={linkedInIcon}
                  style={{ height: "50px" }}
                  alt="linkedin-icon"
                />
              </div>
            </div>
          </div>

          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Particulier
            </span>
            <ul>
              <li onClick={redirectToStore}>Envoyer un colis</li>
              <li onClick={redirectToStore}>Transporteur</li>
              <li onClick={redirectToStore}>Devenir transporteur</li>
            </ul>
          </div>

          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Transporteur
            </span>
            <ul>
              <li onClick={redirectToStore}>Devenir transporteur</li>
              <li onClick={redirectToStore}>Voir les annonces pour</li>
              <li onClick={redirectToStore}> une livraison</li>
            </ul>
          </div>

          <div className="col" style={{ display: "flex", fontWeight: "700",width:"350px",gap:20,color:'#fff',fontFamily:"Raleway-Italic" }}>
            <span onClick={redirectToStore}>Mon compte</span>
            <span onClick={redirectToStore}>Messagerie</span>
            <span onClick={redirectToStore}>Blog</span>
            <span >FAQ</span>
          </div>

          <div
            className="col"
            style={{
              width: "100%",
              // marginLeft: "170px",
              fontFamily: "Raleway-Italic",
              fontSize: "14px",
              color: "#fff",
              // marginTop: "-30px",
              // textAlign: "center",
            }}
          >
            <a
              href="https://frankme.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Politique de confidentialité
            </a>
            {" "}
            |
            {" "}

            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://frankme.com/terms-and-condition"
              target="_blank"
              rel="noreferrer"
            >
              Termes et conditions
            </a>
          </div>

          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  );
}
