import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useEffect, useRef } from "react";
import { color_theme } from "../../utils/constants";
import { currencyFormat } from "../../utils/helper";
const MapboxGL = (props: any) => {
  const { handleGetAroundJobs } = props;
  const mapContainerRef = useRef<any>(null);
  const mapRef = useRef<any>();
  const markersRef = useRef<mapboxgl.Marker[]>([]); // Store markers in a reference

  // create a function to make a directions request
  const getRoute = async () => {
    try {
      const route = props.mapbox_direction.coordinates;
      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: route,
        },
      };
      // if the route already exists on the map, we'll reset it using setData
      if (mapRef?.current.getSource("route")) {
        mapRef?.current.getSource("route").setData(geojson);
      } else {
        mapRef?.current.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": color_theme.main_blue,
            "line-width": 3,
            "line-opacity": 0.75,
            "line-dasharray": [2, 2],
          },
        });
      }

      const routeCoordinates = [route[0], route[route.length - 1]];

      const destinationMarker = document.createElement("div");
      destinationMarker.style.width = "40px"; // Set the size of the marker
      destinationMarker.style.height = "40px";
      destinationMarker.style.backgroundImage =
        "url('https://frankme-23.s3.eu-central-1.amazonaws.com/destinationPinIcon.png')"; // Your custom icon URL
      destinationMarker.style.backgroundSize = "contain"; // Make sure the image is scaled correctly
      destinationMarker.style.backgroundRepeat = "no-repeat"; // Prevent repeating the image
      destinationMarker.style.cursor = "pointer";

      const originMarker = document.createElement("div");
      originMarker.style.width = "40px"; // Set the size of the marker
      originMarker.style.height = "40px";
      originMarker.style.backgroundImage =
        "url('https://frankme-23.s3.eu-central-1.amazonaws.com/originPinIcon.png')"; // Your custom icon URL
      originMarker.style.backgroundSize = "contain"; // Make sure the image is scaled correctly
      originMarker.style.backgroundRepeat = "no-repeat"; // Prevent repeating the image
      originMarker.style.cursor = "pointer";

      // Get the bounds of the route using the coordinates
      const bounds = new mapboxgl.LngLatBounds();
      routeCoordinates.forEach((coord: any) => bounds.extend(coord));

      // Add a custom marker
      const dest_marker = new mapboxgl.Marker(destinationMarker)
        .setLngLat(route[0]) // Set position (longitude, latitude)
        .addTo(mapRef.current);

      // Add a custom marker
      const origin_marker = new mapboxgl.Marker(originMarker)
        .setLngLat(route[route.length - 1]) // Set position (longitude, latitude)
        .addTo(mapRef.current);

      setTimeout(() => {
        markersRef.current.push(origin_marker);
        markersRef.current.push(dest_marker);
      }, 1500);

      // Automatically adjust zoom level and fit the bounds using fitBounds
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 }, // Adjust padding as needed
          duration: 500, // Duration of the animation in milliseconds
          maxZoom: 14, // Optional: Max zoom limit
          minZoom: 5, // Optional: Min zoom limit
          linear: true, // Optional: Use linear interpolation for animation
        });
      }

      // add clustring for map pinsss
    } catch (error) {}
  };

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZnJhbmtmciIsImEiOiJjbTVhcWl5OW4wZzZvMmpzNm5iYzUwNWF5In0.X7R4cjq4TJb1rF0XXqbTSQ";
    try {
      if (mapContainerRef.current) {
        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          minZoom: 3.5,
          style: "mapbox://styles/frankfr/cm5avnauy00km01sf90x9gv5f",
          center: {
            lat: 48.861535955394,
            lng: 2.3381058881405896,
          },
        });
        mapRef.current.on("load", () => {
          if (!mapRef.current) return;

          // Add geolocation control
          const geolocateControl = new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
            showUserLocation: true,
          });

          // Add geolocation control
          mapRef.current.addControl(geolocateControl);

          // Listen to geolocation events to update center state
          mapRef.current.on("geolocate", (e: any) => {
            const { longitude, latitude } = e.coords;

            if (props.handleGetAroundJobs) {
              handleGetAroundJobs({
                latitude,
                longitude,
              });
            }
          });

          // Add fullscreen control
          const fullscreenControl = new mapboxgl.FullscreenControl();
          mapRef.current.addControl(fullscreenControl);
        });
      }
    } catch (error) {}

    return () => {
      mapRef.current?.remove();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.re_render_time) {
      clearRoute();
    }
  }, [props.re_render_time]);

  const clearRoute = () => {
    try {

      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: [],
        },
      };
      // if the route already exists on the map, we'll reset it using setData
      if (mapRef?.current.getSource("route")) {
        mapRef?.current.getSource("route").setData(geojson);
      } else {
        mapRef?.current.addLayer({
          id: "route",
          type: "line",
          source: {
            type: "geojson",
            data: geojson,
          },
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": color_theme.main_blue,
            "line-width": 3,
            "line-opacity": 0.75,
            "line-dasharray": [2, 2],
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.mapbox_direction) {
      getRoute();
    }
    // eslint-disable-next-line
  }, [props.mapbox_direction]); // Dependency array includes mapbox_direction prop

  const addMarkers = (
    markerData: Array<{ lat: number; lng: number; id?: number; price?: number }>
  ) => {
    try {
      markerData.forEach((data) => {
        const customMarkerElement = document.createElement("div");
        customMarkerElement.innerHTML = `
            <div
            style="background-color: white; border-radius: 8px; display: flex; flex-direction: column; justify-content: center; align-items: center; cursor: pointer; padding: 10px 20px; border: 1px solid ${
              color_theme.main_blue
            };"
            onclick="() => {}"
          >
            <div
              style="font-size: 18px; font-weight: 800; font-family: Raleway-Italic; color: defaultLabelColors.main_blue;"
            >
              ${currencyFormat(Number(data.price ?? 0))} €
            </div>
          </div>
        `;
        customMarkerElement.addEventListener("click", (e) => {
          // alert(`CLIcked ${data.price}`)
          if (props.onMapIconClick) {
            props.onMapIconClick({
              id: Number(data.id),
            });
          }
        });
        //
        const marker = new mapboxgl.Marker(customMarkerElement)
          .setLngLat([data.lng, data.lat])
          .addTo(mapRef.current);

        // Add the marker to the markersRef so we can remove it later
        markersRef.current.push(marker);
      });
    } catch (error) {}

    //     // Convert mapPins to GeoJSON
    //     if (mapRef.current && props.mapPins.length > 0) {
    //       const geojsonData = {
    //         type: "FeatureCollection",
    //         features: props.mapPins.map((pin: any) => ({
    //           type: "Feature",
    //           geometry: {
    //             type: "Point",
    //             coordinates: [pin.lng, pin.lat],
    //           },
    //           properties: {
    //             id: pin.id,
    //             price: pin.price,
    //           },
    //         })),
    //       };

    //       // Check if source already exists
    //       if (mapRef.current.getSource("pins")) {
    //         const source = mapRef.current.getSource("pins") as any;
    //         source.setData(geojsonData); // Update the data
    //       } else {
    //         // Add GeoJSON source with clustering enabled
    //         mapRef.current.addSource("pins", {
    //           type: "geojson",
    //           data: geojsonData,
    //           cluster: true,
    //           clusterMaxZoom: 14,
    //           clusterRadius: 50,
    //         });
    //         // Add cluster layer
    //         mapRef.current.addLayer({
    //           id: "clusters",
    //           type: "circle",
    //           source: "pins",
    //           filter: ["has", "point_count"],
    //           paint: {
    //             "circle-color": [
    //               "step",
    //               ["get", "point_count"],
    //               color_theme.main_blue,
    //               10,
    //               color_theme.main_orange,
    //               30,
    //               color_theme.green,
    //             ],
    //             "circle-radius": [
    //               "step",
    //               ["get", "point_count"],
    //               15,
    //               10,
    //               20,
    //               30,
    //               25,
    //             ],
    //           },
    //         });
    //         // Add cluster count labels
    //         mapRef.current.addLayer({
    //           id: "cluster-count",
    //           type: "symbol",
    //           source: "pins",
    //           filter: ["has", "point_count"],
    //           layout: {
    //             "text-field": "{point_count_abbreviated}",
    //             "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    //             "text-size": 12,
    //           },
    //         });
    //         // // Add individual point layer
    //         mapRef.current.addLayer({
    //           id: "unclustered-point",
    //           type: "circle",
    //           source: "pins",
    //           filter: ["!", ["has", "point_count"]],
    //           paint: {
    //             "circle-color": "#11b4da",
    //             "circle-radius": 8,
    //             "circle-stroke-width": 1,
    //             "circle-stroke-color": "#fff",
    //           },
    //         });
    //       }
    //     }
    //     // Render custom HTML for unclustered points
    //     const features = props.mapPins.map((pin: any) => ({
    //       type: "Feature",
    //       properties: { id: pin.id, price: pin.price },
    //       geometry: {
    //         type: "Point",
    //         coordinates: [pin.lng, pin.lat],
    //       },
    //     }));

    //     features.forEach((feature: any) => {
    //       const { coordinates } = feature.geometry;
    //       console.log(
    //         "point_count :::",
    //         feature.properties.hasOwnProperty("point_count")
    //       );
    //       // If the feature doesn't have point_count, it means it's an unclustered point
    //       if (!feature.properties.hasOwnProperty("point_count")) {
    //         const markerElement = document.createElement("div");
    //         markerElement.innerHTML = `
    //   <div style="
    //     background-color: white;
    //     border: 2px solid #007aff;
    //     border-radius: 8px;
    //     padding: 5px 10px;
    //     font-size: 12px;
    //     font-weight: bold;
    //     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    //     cursor: pointer;
    //   ">
    //     ${feature.properties.price} €
    //   </div>
    // `;

    //         new mapboxgl.Marker(markerElement)
    //           .setLngLat(coordinates)
    //           .addTo(mapRef.current!);
    //       }

    //       // Create HTML element for the marker
    //       //   const markerElement = document.createElement("div");
    //       //   markerElement.innerHTML = `
    //       //   <div style="
    //       //     background-color: white;
    //       //     border: 2px solid #007aff;
    //       //     border-radius: 8px;
    //       //     padding: 5px 10px;
    //       //     font-size: 12px;
    //       //     font-weight: bold;
    //       //     box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    //       //     cursor: pointer;
    //       //   ">
    //       //     ${price ?? 0} €
    //       //   </div>
    //       // `;
    //     });
  };

  // Function to clear all markers
  const clearMarkers = () => {
    markersRef.current.forEach((marker) => marker.remove()); // Remove all markers
    markersRef.current = []; // Reset the marker array
  };

  useEffect(() => {
    if (props.mapPins) {
      clearMarkers();
      addMarkers(props.mapPins);
    }
    // eslint-disable-next-line
  }, [props.mapPins]);

  return (
    <div
      id="map"
      ref={mapContainerRef}
      style={{ height: "100vh", borderRadius: 16 }}
    ></div>
  );
};

export default MapboxGL;
