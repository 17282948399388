import { decrypt, encrypt } from "./Encrypt";

interface Data {
  [key: string]: any;
}
 
export const saveDataToLocalStorage = (key: string, data: Data): void => {
  const jsonData = JSON.stringify(data);
  const encryptedData = encrypt(jsonData);
  localStorage.setItem(key, encryptedData);
};

 export const getDataFromLocalStorage = (key: string): Data | null => {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData) {
    try {
      const decryptedData = decrypt(encryptedData);
       
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error("Failed to decrypt or parse data from localStorage:", error);
      return null;  
    }
  }
  return null;
};

export const clearDataFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const clearAllDataFromLocalStorage = (): void => {
  localStorage.clear();
};

export const getCart = () => {
  const cart = localStorage.getItem('cart');
  return cart ? JSON.parse(cart) : [];
};

export const addProductToCart = (product:any) => {
  const cart = getCart();
  const isProductInCart = cart.some((item:any) => item.id === product.id);

  if (!isProductInCart) {
    cart.push(product);
    localStorage.setItem('cart', JSON.stringify(cart));
  }
};

export const removeProductFromCart = (productId:any) => {
  let cart = getCart();
  cart = cart?.filter((item:any) => item.id !== productId);
  localStorage.setItem('cart', JSON.stringify(cart));
};