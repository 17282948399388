import { ArrowForward, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import aboutBanner from "../../assets/images/FrankIcons/new-about-banner.png";
import StepScreen1 from "../../assets/images/FrankIcons/new-about-steps-1.png";
import StepScreen2 from "../../assets/images/FrankIcons/about-steps-2.png";
import StepScreen3 from "../../assets/images/FrankIcons/new-about-steps-3.png";
import notre_ambition from "../../assets/images/FrankIcons/notre-ambition.png";
import CustomButton from "../../UiComponent/CustomButton";
import CustomFooter from "../../UiComponent/CustomFooter";
import NewHeader from "../../UiComponent/NewHeader";
import { spacing } from "../../utils/constants";
import "./index.css";

function AboutPage(props: any) {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Qui sommes-nous ? | Frank</title>
        <link rel="canonical" href="https://frankme.com/" />
         
        <link
          rel="icon"
          type="image/ico"
          href="../../assets/images/FrankIcons/favicon.ico"
        />
        <meta
          name="description"
          content="Quel est le concept porté par Frank ? Découvrez-en plus sur notre équipe, nos ambitions, notre communauté et nos valeurs."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Frank",
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "Customer Service",
              areaServed: "FR",
              availableLanguage: "French",
            },
          })}
        </script>
      </Helmet>
      <div className="b-web-screen">
        <Grid
          container
          spacing={2}
          className="display-flex"
          style={{
            background: "#3026f1",
            paddingLeft: "40px",
            paddingRight: "40px",
            paddingTop: "30px",
          }}
        >
          <Grid xs={12} item style={{ zIndex: 99 }}>
            <NewHeader />
            {/* <button onClick={()=>requestPermission()}>Enable Notifications</button> */}
          </Grid>
        </Grid>
        <Grid container spacing={spacing?.spacing2}>
          <Grid xs={10} item style={{ paddingTop: 0, paddingLeft: "50px" }}>
            <div className="b-main-div">
              <div className="b-home-banner-inner-div">
                <p className="b-home-banner-inner-text">QUI SOMMES-NOUS ?</p>
                <p
                  className="b-home-banner-inner-text-two"
                  style={{ marginBottom: -5 }}
                >
                  <span
                    style={{
                      background: "#ffab91",
                      paddingRight: "25px",
                      paddingLeft: "10px",
                    }}
                  >
                    Frank, c'est un service de livraison alternatif
                  </span>
                </p>
                <p className="b-home-banner-inner-text-two">
                  <span
                    style={{
                      background: "#ffab91",
                      paddingRight: "25px",
                      paddingLeft: "10px",
                    }}
                  >
                    à destination des particuliers comme des professionnels.
                  </span>
                </p>

                <div className="b-home-banner-inner-text-child">
                  <div style={{ fontFamily: "Raleway-Italic" }}>
                    <span
                      style={{
                        fontWeight: "700",
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      {" "}
                      L'idée est née d'un constat simple :{" "}
                    </span>
                    chaque jour, un grand nombre de personnes traversent la
                    France dans des véhicules presque vides. D'un point de vue
                    écologique et logistique, c'est loin d'être optimal !
                  </div>
                  <div
                    style={{ fontFamily: "Raleway-Italic", marginLeft: "30px" }}
                  >
                    <span
                      style={{ fontFamily: "Raleway-Italic", fontWeight: 700 }}
                    >
                      Pourquoi ne pas mettre en relation les personnes pour
                      rentabiliser cet espace et proposer une livraison de colis
                      économique et écologique{" "}
                    </span>
                    ? C'est là que Frank arrive!
                  </div>
                </div>
              </div>
              <img
                src={aboutBanner}
                alt="Web Banner"
                style={{
                  width: "100%",
                  objectFit: "fill",
                  position: "absolute",
                  top: 60,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: -1,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <div style={{paddingTop:'100px'}}>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "52px",
                color: "#3026f1",
                textAlign: "center",
                fontWeight: "800",
              }}
            >
              NOTRE AMBITION
            </p>

            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "22px",
                color: "#000",
                marginTop: "20px",
              }}
            >
              <span style={{ fontWeight: "600", fontFamily: "Raleway-Italic" }}>
                {" "}
                Notre objectif:
              </span>{" "}
              mettre en relation les personnes qui ont besoin d'envoyer un
              <br /> colis avec celles qui ont de la place dans leur véhicule.
              Le résultat ?
            </p>
          </div>
        </Grid>
        <Grid container spacing={spacing?.spacing2}>
          <div style={{ objectFit: "contain" }}>
            <img
              src={notre_ambition}
              style={{ height: "100%", width: "100%" }}
              alt=""
            />
          </div>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "22px",
                color: "#000",
                textAlign: "center",
              }}
            >
              {/* Pour charger les voitures qui roulent à vide avec tous ces colis,
              il faut un lieu de rassemblement et
              <br /> une communauté.
              <span style={{ fontWeight: "600", fontFamily: "Raleway-Italic" }}>
                {" "}
                Ce lieu : c'est Frank, avec son site internet et son
                application.
              </span> */}
              Ensemble, créons une communauté pour révolutionner le transport de
              colis
            </p>
          </div>
        </Grid>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{ paddingTop: "40px" }}
        >
          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomButton
              value={"CETTE COMMUNAUTÉ : C'EST VOUS !"}
              // value={"Cette communauté : C’est vous"}
              classesNames={"custom-button"}
              style={{
                background: "#fff",
                color: "#3026f1",
                border: "1px solid #3026f1",
                fontFamily: "Raleway-Extra-Bold-Italic",
                fontWeight: "900px",
                borderRadius: "8px",
                fontSize: "16px",
                height: "54px",
                boxShadow: "1px 64px 137px -51px rgba(0,0,0,1)",
              }}
              onClick={() =>
                window.open(
                  "https://www.instagram.com/frankme.fr/",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            />
          </Grid>
        </Grid>
        <Grid
          className="b-about-us-bottom"
          container
          spacing={spacing?.spacing2}
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 128px 0px 128px",
            minWidth: "1000px",
            height: "500px",
            // padding:'30px',
            width: "100%",
          }}
        >
          <Grid xs={12} item>
            <p
              style={{
                fontFamily: "Raleway-Italic",
                fontSize: "32px",
                color: "#fff",
                fontWeight: "800",
                margin: 0,
              }}
            >
              FRANK, COMMENT ÇA MARCHE ?
            </p>
            <Grid container style={{ marginTop: "40px", padding: "30px" }}>
              <Grid
                xs={12}
                md={6}
                lg={6}
                item
                style={{
                  fontFamily: "Raleway-Italic",
                  color: "#fff",
                  fontSize: "18px",
                  paddingRight: "20px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Italic",margin:0 }}>
                  De nos jours, envoyer un colis, c'est toute une aventure ! Et
                  plus il est volumineux, plus cela revient cher.
                </p>
                <br/>
                <p style={{ fontFamily: "Raleway-Italic" }}>
                  C'est pour cette raison que Frank existe :
                  <span
                    style={{ fontWeight: "600", fontFamily: "Raleway-Italic" }}
                  >
                    {" "}
                    simplifier les envois des particuliers et des professionnels
                  </span>{" "}
                  qui veulent s'y retrouver financièrement.
                </p>
              </Grid>
              <Grid
                xs={12}
                md={6}
                lg={6}
                item
                style={{
                  fontFamily: "Raleway-Italic",
                  color: "#fff",
                  fontSize: "18px",
                  paddingLeft: "20px",
                }}
              >
                <p style={{ fontFamily: "Raleway-Italic",margin:0  }}>
                  Pour cela, nous avons misé sur une interface simple et
                  transparente. Sur notre site ou sur l'appli, vous planifiez
                  votre envoi, vous définissez le tarif que vous souhaitez et
                  hop, votre annonce est en ligne. 
                </p>
                <p style={{ fontFamily: "Raleway-Italic" }}>
                Ensuite, la livraison est
                assurée par un transporteur de la communauté. Facile, non ?
                </p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div className="b-section-background">
          <section className="b-mstpone">
            <div className="b-mst-srcone">
              <div
                // className="b-display-flex"
                style={{
                  // textAlign: "left",
                  display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  flexDirection: "column",
                  padding: "0px 128px",
                }}
              >
                <div
                  style={{
                    fontSize: "32px",
                    fontFamily: "Raleway-Italic",
                    fontWeight: "800",
                    color: "#3026f1",
                    padding: "30px 0px",
                    // width: "1100px",
                    // marginTop: "20px",
                    textAlign: "left",
                  }}
                >
                  NOS DIFFÉRENTS SERVICES
                </div>
                <div
                  style={{
                    background: "#fff",
                    width: "80%",
                    height: "2px",
                    marginBottom: "40px",
                  }}
                ></div>
              </div>
              {/* css change due to new changes in ppt (1/17/25) */}
              <div className="b-imgtxt">
                <div style={{ position: "relative" }} id="image_parent">
                  <img
                    src={StepScreen1}
                    alt=""
                    width={"43%"}
                    style={{ width: "43%" }}
                  />
                </div>
                <div
                  style={{ position: "absolute", bottom: "10%", left: "46%" }}
                  // className="b-vous-voyez-div"
                >
                  <div
                    id="child1"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "flex-end",
                    }}
                  >
                    <p
                      // className="b-vous-voulez"
                      style={{
                        background: "#ee6931",
                        textAlign: "left",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        padding: 10,
                        width: "max-content",
                        margin: 0,
                      }}
                    >
                      LIVRAISON COLLABORATIVE
                    </p>
                    <p
                      // className="b-vous-voulez "
                      style={{ width: "400px" }}
                    >
                      <br />
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#000",
                          margin: 0,
                        }}
                      >
                        Envoyez vos colis entre particuliers, en publiant votre
                        annonce et indiquez les <br /> détails concernant le
                        colis et sa destination.
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            style={{
              // coment by asad iqbal from 16/1/25(Review website + APP ppt)
              display: "none",
            }}
            className="b-mstptwo"
          >
            <div className="b-mst-srctwo">
              <div className="b-imgtxt">
                <img
                  src={StepScreen2}
                  alt="step-screen"
                  width={"43%"}
                  style={{ width: "43%" }}
                />
                <div style={{}} className="b-vous-voyez-two-div">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p
                      className="b-vous-voulez-two"
                      style={{
                        background: "#3026f1",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        padding: 10,
                      }}
                    >
                      LE MARCHÉ DE FRANK
                    </p>
                    <p
                      className="b-vous-voulez-two"
                      style={{ width: "400px", right: "-250px", top: "20px" }}
                    >
                      <br />
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#000",
                        }}
                      >
                        Que vous soyez particulier ou professionnel, vous
                        <br /> vendez vos produits de seconde main, mais aussi
                        <br /> vos créations. Nos transporteurs s'occupent
                        <br />
                        ensuite de la livraison.
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section style={{}} className="b-mstpthree">
            <div className="b-mst-srcthree">
              <div className="b-imgtxt">
                <div style={{ position: "relative" }} id="image_parent1">
                  <img
                    src={StepScreen3}
                    alt="step-screen-3"
                    style={{ width: "43%" }}
                  />
                </div>
                <div
                  // className="b-vous-voyez-three-div"
                  style={{ position: "absolute", bottom: "10%", left: "46%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p
                      // className="b-vous-voulez-three"
                      style={{
                        background: "#ee6931",
                        textAlign: "left",
                        fontFamily: "Raleway-Italic",
                        fontWeight: "800",
                        color: "#fff",
                        padding: 10,
                        margin: 0,
                      }}
                    >
                      LIVRAISON POUR LES PROFESSIONNELS
                    </p>
                    <p
                      // className="b-vous-voulez-three"
                      style={{ width: "400px" }}
                    >
                      <br />
                      <span
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#000",
                          margin: 0,
                        }}
                      >
                        Les entreprises du e-commerce bénéficient de <br />{" "}
                        notre réseau de transporteurs pour optimiser
                        <br /> leur logistique.
                      </span>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Grid
          container
          spacing={spacing?.spacing2}
          style={{
            paddingLeft: "200px",
            paddingRight: "200px",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              borderTop: "5px dotted #3026f1",
              // height: "100px",
              width: "100%",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // paddingLeft: "100px",
              marginBottom: "150px",
            }}
          >
            <div>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "42px",
                  fontWeight: "800",
                  color: "#3026f1",
                }}
              >
                POUR QUI ?
              </p>
            </div>

            <div>
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "19px",
                  color: "#000",
                  fontWeight: "500",
                  margin: 0,
                }}
              >
                Notre but, c'est de vous simplifier la vie, ou au moins l'envoi
                de vos colis !
              </p>
            </div>
          </div>
        </Grid>

        <div
          style={{
            background: "#3026f1",
            position: "relative",
          }}
        >
          <Grid
            container
            spacing={spacing?.spacing2}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "250px",
            }}
          >
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "40px",
                  position: "absolute",
                  top: "-4%",
                }}
              >
                <div
                  style={{ maxWidth: "300px", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/send-package");
                  }}
                >
                  <div
                    style={{
                      background: "#f9d9b6",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "220px",
                      padding: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowForward
                          style={{
                            fontSize: "20px",
                            color: "#3026f1",
                          }}
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          fontSize: "26px",
                          fontWeight: "800",
                          color: "#3026f1",
                          textAlign: "left",
                        }}
                      >
                        Expéditeurs
                      </p>

                      <p
                        style={{
                          marginTop: "20px",
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: "16px",
                        }}
                      >
                        Vous envoyez vos colis partout en France et profitez de
                        tarifs compétitifs
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ maxWidth: "300px", cursor: "pointer" }}
                  onClick={() => {
                    navigate("/transporter-un-colis");
                  }}
                >
                  <div
                    style={{
                      background: "#f9d9b6",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "220px",
                      padding: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowForward
                          style={{
                            fontSize: "20px",
                            color: "#3026f1",
                          }}
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          fontSize: "26px",
                          fontWeight: "800",
                          color: "#3026f1",
                          textAlign: "left",
                        }}
                      >
                        Transporteurs
                      </p>

                      <p
                        style={{
                          marginTop: "20px",
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: "16px",
                        }}
                      >
                        Vous rentalilisez l'espace inoccupé de votre véhicule et
                        votre trajet en prenant en charge des colis.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ maxWidth: "300px", cursor: "pointer" }}
                  onClick={() => {
                    //           // coment by asad iqbal from 16/1/25(Review website + APP ppt)

                    window.open("https://www.peoplespost.fr/", "_blank");
                  }}
                >
                  <div
                    style={{
                      background: "#f9d9b6",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "220px",
                      padding: "35px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          background: "#fff",
                          padding: "10px",
                          borderRadius: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ArrowForward
                          style={{
                            fontSize: "20px",
                            color: "#3026f1",
                          }}
                        />
                      </span>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Raleway-Extra-Bold-Italic",
                          fontSize: "26px",
                          fontWeight: "800",
                          color: "#3026f1",
                          textAlign: "left",
                        }}
                      >
                        E-commerçants
                      </p>

                      <p
                        style={{
                          marginTop: "20px",
                          fontFamily: "Raleway-Semi-Bold",
                          fontSize: "16px",
                        }}
                      >
                        Vous expédiez votre marchandise de façon éco-responsable
                        et économique.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={spacing?.spacing2}>
            <Grid
              xs={12}
              sm={12}
              item
              style={{
                width: "100%",
                fontFamily: "Raleway-Extra-Bold-Italic",
                fontSize: "42px",
                padding: "0px 220px",
                color: "#fff",
                paddingTop: "80px",
              }}
            >
              NOS VALEURS
              <Divider style={{ backgroundColor: "#fff", marginTop: "20px" }} />
            </Grid>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                marginTop: "50px",
                padding: "0px 160px",
                columnGap: "40px",
              }}
            >
              <Grid
                xs={12}
                lg={5}
                md={5}
                sm={12}
                item
                style={{
                  // display: "flex",
                  // justifyContent: "flex-end",
                  // alignItems: "flex-end",
                  flexDirection: "column",
                  // paddingLeft: "400px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "32px",
                    fontWeight: "800",
                    color: "#f9d9b6",
                    margin: 0,
                  }}
                >
                  Connecter les personnes
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    color: "#fff",
                    paddingTop: "50px",
                    margin: 0,
                  }}
                >
                  Pour nous,
                  <span style={{ fontFamily: "Raleway-Italic" }}>
                    "mettre en relation"
                  </span>{" "}
                  des personnes,
                  <br /> ce n'est pas uniquement créer une plateforme
                  <br /> collaborative où chacun trouve son compte.
                  <br />{" "}
                  <span style={{ fontWeight: "800" }}>
                    {" "}
                    C'est aussi rapprocher les gens et créer une
                    <br /> communauté qui partage nos valeurs
                    <br /> d'entraide, d'efficacité et de convivialité
                  </span>{" "}
                  : <br />
                  les Frankies.
                </p>

                <div
                  style={{
                    width: "400px",
                    borderTop: "1px solid #fff",
                    marginTop: "50px",
                  }}
                ></div>
                <div>
                  <p
                    style={{
                      margin: 0,

                      fontFamily: "Raleway-Italic",
                      fontSize: "32px",
                      fontWeight: "800",
                      color: "#f9d9b6",
                      paddingTop: "50px",
                      textAlign: "left",
                    }}
                  >
                    Efficacité et simplicité
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: "#fff",
                      margin: 0,
                      paddingTop: "50px",
                    }}
                  >
                    Nous avons pensé notre
                    <br /> système de la façon la plus simple possible:
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: "#fff",
                      paddingTop: "50px",
                      fontWeight: "800",
                      margin: 0,
                      textAlign: "left",
                    }}
                  >
                    • Mise en relation
                    <br />
                    • Prise en charge du colis
                    <br />• Réception de la commande
                  </p>

                  <p
                    style={{
                      fontFamily: "Raleway-Variable",
                      fontSize: "18px",
                      color: "#fff",
                      paddingTop: "50px",
                      margin: 0,
                    }}
                  >
                    Vous faites des économies, mais vous y<br /> gagnez aussi en
                    sérénité et en sécurité.
                  </p>
                </div>
              </Grid>

              <Grid
                xs={12}
                lg={5}
                md={5}
                item
                style={{
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "32px",
                    fontWeight: "800",
                    color: "#f9d9b6",
                    margin: 0,
                  }}
                >
                  Un impact positif sur
                  <br /> l'environnement... et sur votre
                  <br />
                  portefeuille
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Italic",
                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    paddingTop: "50px",
                    margin: 0,
                  }}
                >
                  {/* Saviez-vous qu'un grand nombre de <br /> voitures roulent avec
                  une seule personne
                  <br /> à l'intérieur ? */}
                  Saviez-vous que 41 % des véhicules circulent avec un seul
                  passager ?
                </p>

                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    color: "#fff",
                    paddingTop: "50px",
                    margin: 0,
                  }}
                >
                  {/* Du côté des camions de transport,
                  <br /> beaucoup de trajets se font avec un coffre
                  <br /> rempli partiellement, ou carrément vide ! En
                  <br /> remplissant ces véhicules vides avec des
                  <br /> colis à distribuer, Frank contribue à réduire
                  <br /> l'empreinte carbone des envois, mais aussi
                  <br /> des trajets. Et avec nos tarifs compétitifs,
                  <br /> tout le monde est content! */}
                  Ce phénomène concerne également les camions, qui effectuent de
                  nombreux trajets avec un coffre partiellement chargé, voire
                  complètement vide. En optimisant l'utilisation de ces camions
                  pour le transport de colis, Frank contribue à réduire
                  l'empreinte carbone du secteur.
                </p>
              </Grid>
            </div>
          </Grid>

          <Grid
            xs={12}
            item
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "1000px",
                marginTop: "60px",
                paddingTop: "60px",
              }}
            ></div>
          </Grid>
        </div>
        <div className="display-flex">
          <Grid
            container
            spacing={2}
            marginTop={5}
            style={{
              padding: 10,
              width: "1000px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              xs={12}
              item
              className="display-flex"
              style={{
                flexDirection: "column",
                background: "#F5C9B3",
                padding: "10px",
                borderRadius: "18px",
              }}
            >
              <h3
                style={{
                  fontSize: "26px",
                  fontFamily: "Raleway-Extra-Bold-Italic",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  textAlign: "left",
                  width: "100%",
                  padding: "15px",
                  paddingLeft: "32px",
                  margin: 0,
                }}
              >
                Des questions ?
              </h3>
              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                }}
              >
                <AccordionSummary
                  style={{ border: "none" }}
                  expandIcon={<ExpandMore />}
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" ,fontSize:19}}>
                    Comment fonctionne la livraison collaborative ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    width: "90%",
                    fontFamily: "Raleway-Variable",
                  }}
                >
                  Grâce à notre plateforme, un particulier peut envoyer un colis
                  qui sera livré par un autre particulier lors d’un de ses
                  trajets personnels. Au cœur de notre activité, la livraison
                  collaborative nous permet de créer une communauté d’entraide
                  en garantissant un service d’envoi peu coûteux et écologique.
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" ,fontSize:19}}>
                    Comment fonctionne la livraison professionnelle avec Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Nous couvrons l’envoi du premier au dernier kilomètre et
                    stockons vos colis dans notre entrepôt en Île-de-France. En
                    fonction de vos besoins, nous proposons plusieurs modes de
                    livraison professionnelle :
                  </p>

                  <p>
                    {" "}
                    • Pick & Pack : récupération des colis à votre adresse,
                    préparation des colis, packaging d’envoi ;{" "}
                  </p>
                  <p>
                    • Livraison totale : nous nous occupons de tout, de la
                    récupération des colis jusqu’à leur dépôt chez le client
                    final ;
                  </p>
                  <p>
                    • Stockage : nous stockons vos colis dans notre entrepôt en
                    Île-de-France, avant leur retrait.
                  </p>

                  <p>
                    {" "}
                    Pour plus d’informations, vous pouvez nous contacter en
                    envoyant un mail à l’adresse :
                    <a href="mailto:hello@frankme.com">hello@frankme.com</a>.
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" ,fontSize:19}}>
                    Puis-je gagner de l’argent avec Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Vous êtes transporteur ? En voiture, en train, en
                    trottinette, en péniche ou à pied, quel que soit le mode de
                    transport, vous gagnez de l’argent !{" "}
                  </p>

                  <p>
                    Vous envoyez un colis avec Frank ? Vous ne gagnez pas
                    d’argent, mais vous en économisez à coup sûr !{" "}
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" ,fontSize:19}}>
                    Comment fonctionne l’assurance des colis ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  Tout envoi de colis via Frank est assuré à une hauteur de 100
                  €. Pour vos colis les plus précieux, vous pouvez souscrire à
                  une assurance supplémentaire (HISCOX).
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:19 }}>
                    Comment devenir transporteur ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Pour devenir transporteur à nos côtés, rien de plus simple :{" "}
                  </p>

                  <p>
                    {" "}
                    • Vous créez un compte sur le site ou l’application de Frank
                    ;{" "}
                  </p>
                  <p>
                    {" "}
                    • Vous checkez les annonces à côté de vous, ou sur vos
                    trajets ;{" "}
                  </p>
                  <p> • Vous acceptez une offre ;</p>
                  <p>
                    {" "}
                    • Vous remettez le colis au destinataire, qui vous remet un
                    code pour valider la livraison ;{" "}
                  </p>
                  <p>
                    {" "}
                    • Vous recevez le montant de la livraison sur votre
                    porte-monnaie une fois que vous avez renseigné le code.{" "}
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500", padding: "5px 0px" ,fontSize:19}}>
                    En tant que transporteur, comment transférer l’argent de mon
                    porte-monnaie sur mon compte bancaire ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    Sur l’application Frank, il y a un espace porte-monnaie, où
                    vous trouverez le montant total de vos gains. Il suffit de
                    cliquer sur la carte bancaire, puis sur “transférer sur un
                    compte bancaire” et de renseigner les informations
                    demandées. L’argent arrive sur votre compte au bout de
                    quelques jours !
                  </p>
                </AccordionDetails>
              </Accordion>

              {/*
                        // coment by asad iqbal from 16/1/25(Review website + APP ppt)

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500" }}>
                    C’est quoi, le Marché de Frank ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "90%" }}>
                  <p>
                    {" "}
                    Le Marché de Frank, c’est une marketplace pour particuliers
                    et pour professionnels :{" "}
                  </p>
                  <p>
                    {" "}
                    • Les professionnels peuvent y vendre autant de produits
                    qu’ils le veulent. Chaque professionnel a son propre shop au
                    nom de sa marque ;{" "}
                  </p>
                  <p>
                    • Les particuliers peuvent vendre leurs articles de seconde
                    main ou leurs créations personnelles jusqu’à une hauteur de
                    2 500 euros par mois. Au-delà de ce montant, vous êtes
                    clairement un professionnel !
                  </p>
                  Pour les vendeurs particuliers, la livraison des produits est
                  également assurée par des particuliers. Une fois qu’une
                  personne a acheté un objet, une annonce est automatiquement
                  créée dans l’espace dédié aux transporteurs. Il ne reste plus
                  qu’à attendre que l’un d’entre eux accepte la livraison ! Ce
                  système de marketplace collaborative est écologique de A à Z.
                </AccordionDetails>
              </Accordion> */}

              <Accordion
                elevation={0}
                style={{
                  border: "0px",
                  background: "#F5C9B3",
                  width: "100%",
                }}
              >
                <AccordionSummary
                  style={{ border: "none", width: "100%" }}
                  expandIcon={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        fontSize: "28px",
                        fontFamily: "Raleway-Variable",
                      }}
                    >
                      {" "}
                      <ExpandMore style={{ textAlign: "right" }} />{" "}
                    </div>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                  className="span-what-is-frank"
                >
                  <h3 style={{ fontWeight: "500",fontSize:19 }}>
                    Y a-t-il une limite de poids pour la livraison des colis ?
                  </h3>
                </AccordionSummary>
                <AccordionDetails style={{ width: "100%" }}>
                  <p>
                    Il n’y a pas de limite de poids chez Frank. Pour un
                    déménagement, vous pouvez prévoir le nombre de personnes
                    dont vous avez besoin en renfort en fonction de la taille
                    des meubles.
                  </p>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className="main-container"
        style={{ background: "#fff", marginTop: "100px" }}
      >
        <CustomFooter />
      </div>
    </>
  );
}
export default AboutPage;
