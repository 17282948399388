import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { defaultLabelColors } from "../../utils/constants";

// Define TypeScript interface for form data
interface BankDetails {
  country: string;
  currency: string;
  account_number: string;
  routing_number?: string;
  account_holder_name?: string;
}

// Country and Currency options
// const countryOptions = [
//   { code: "US", label: "United States" },
//   { code: "CA", label: "Canada" },
//   { code: "GB", label: "United Kingdom" },
//   { code: "AU", label: "Australia" },
// ];

const currencyOptions = [
  { code: "usd", label: "USD - US Dollar" },
  { code: "cad", label: "CAD - Canadian Dollar" },
  { code: "gbp", label: "GBP - British Pound" },
  { code: "aud", label: "AUD - Australian Dollar" },
];

const BankUpdateForm: React.FC<{ onSubmit: (data: BankDetails) => void }> = ({
  onSubmit,
}) => {
  const [formData, setFormData] = useState<BankDetails>({
    country: "US",
    currency: "usd",
    account_number: "",
    routing_number: "",
    account_holder_name: "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof BankDetails, string>>
  >({});



  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const countries = await response?.json();
        const countryList = countries?.map((country: any) => ({
          code: country?.cca2, // ISO country code
          label: country?.name?.common, // Country name
        }));
        setCountryOptions(countryList);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };
  
    fetchCountries();
  }, []);

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // validateForm();  
  };
  
  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // validateForm();  
  };

  const validateForm = () => {
    let newErrors: Partial<Record<keyof BankDetails, string>> = {};
  
    // Validate country
    if (!formData?.country) newErrors.country = "Country is required";
  
    // Validate currency
    if (!formData?.currency) newErrors.currency = "Currency is required";
  
    // Validate account number (IBAN)
    if (!formData?.account_number) {
      newErrors.account_number = "Account number is required";
    } else if (
      formData.account_number &&
      !/^[FR]{2}\d{2}[0-9A-Z]{4}\d{4}[0-9A-Z]{10}$/.test(formData?.account_number)
    ) {
      newErrors.account_number =
        "Account number must follow the IBAN format for France (FRkk BBBB BGGG GGCC CCCC CCCC CCCC)";
    }
  
    // Validate routing number
    if (
      formData.routing_number &&
      !/^\d{5,11}$/.test(formData.routing_number)
    ) {
      newErrors.routing_number =
        "Routing number must be numeric and between 5 to 11 digits";
    }
  
    setErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(formData);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} item>
          <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.country}>
            <Select
              name="country"
              value={formData.country}
              onChange={handleSelectChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Adjust max height
                    fontSize: '14px', // Adjust font size
                  },
                },
              }}
            >
              {countryOptions.map((option:any) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors.country && (
              <Typography color="error">{errors.country}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid xs={12} md={6} item>
          <FormControl fullWidth sx={{ mb: 2 }} error={!!errors.currency}>
            <Select
              name="currency"
              value={formData.currency}
              onChange={handleSelectChange}
            >
              {currencyOptions.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {errors.currency && (
              <Typography color="error">{errors.currency}</Typography>
            )}
          </FormControl>
        </Grid>

        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            label="Account Number"
            name="account_number"
            value={formData.account_number}
            onChange={handleInputChange}
            error={!!errors.account_number}
            helperText={errors.account_number}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            label="Routing Number (Optional)"
            name="routing_number"
            value={formData.routing_number}
            onChange={handleInputChange}
            error={!!errors.routing_number}
            helperText={errors.routing_number}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid xs={12} md={6} item>
          <TextField
            fullWidth
            label="Account Holder Name (Optional)"
            name="account_holder_name"
            value={formData.account_holder_name}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{
          fontFamily: "Raleway-Semi-Bold",
          background: defaultLabelColors.main_orange,
          color: "#fff",
          fontSize: "18px",
        }}
        fullWidth
      >
        Submit
      </Button>
    </Box>
  );
};

export default BankUpdateForm;
