import React, { useEffect } from "react";
import { AppStoreFR } from "../../assets/images/FrankIcons";
// import "./index.css";
import "../MobileView/index.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import StepScreen1 from "../../assets/images/FrankIcons/mobileStepTwo.png";
import StepScreen2 from "../../assets/images/FrankIcons/mobileStepOne.png";
import StepScreen3 from "../../assets/images/FrankIcons/mobileStepThree.png";
import iconThree from "../../assets/images/FrankIcons/assuree@4x.png";
import iconOne from "../../assets/images/FrankIcons/ecologique@4x.png";
import iconTwo from "../../assets/images/FrankIcons/economique@4x.png";
import googlePlayBtn from "../../assets/mobileImages/french-google-play.png";


import {
  East,
  ExpandMore,
} from "@mui/icons-material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../UiComponent/CustomButton";
import CustomHeaderMobile from "../../UiComponent/CustomeHeaderMobile";
import {
  defaultLabelColors,
  spacing,
} from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";
import CustomMobileFooter from "../../UiComponent/CustomMobileFooter";

function MobileView(props: any) {
  const { handleGetRecentJobs, handleGetAllBlog } = props;
   
  const navigate = useNavigate();
 

  useEffect(() => {
    handleGetRecentJobs();
    handleGetAllBlog();
    // eslint-disable-next-line
  }, []);
 

  const redirectToStore = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
  
    if (/android/i.test(userAgent)) {
      // Redirect to Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.frankc2c';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // Redirect to Apple App Store
      window.location.href = 'https://apps.apple.com/us/app/frank/id6502341979';
    } else {
      // Handle cases where the OS is not Android or iOS
      console.log('This feature is only available on mobile devices.');
    }
  };

  return (
    <div className="main-container-mob" style={{ background: "#fad9b6" }}>
      <div className="header-body-top">
        <CustomHeaderMobile />

        <div className="body ">
          <div className="top-section-mob " style={{background:"#fff",marginBottom:'50px'}}>
            <div className="vector"></div>
            <span className="envoyez-vos-colis-mob">
              Envoyez vos colis
              <br />
              entre particuliers!
            </span>
            <div className="frame_mob">
              <Box
              //  onClick={() => {
              //   navigate("/send-package");
              // }}
              onClick={redirectToStore}
                sx={{
                  backgroundColor: defaultLabelColors.main_blue,
                  borderRadius: "32px",
                  padding: "40px",
                  color: "white",
                  maxWidth: "400px",
                  fontFamily: "'Raleway-Variable', sans-serif",
                  position: "relative",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Typography
                   fontFamily={"Raleway-Extra-Bold-Italic"}
                    sx={{ fontSize: "28px", marginBottom: "10px", flex: 1 }}
                  >
                    Envoyer <br />{" "}
                    <span style={{ }}>un colis</span>
                  </Typography>

                  <div
                    className="line-arrow-right_mob"
                    style={{ background: "#fff", borderRadius: "100%",textAlign:"center" }}
                  >
                    <East style={{ color: defaultLabelColors.main_blue }} />
                  </div>
                </div>

                <Typography
                  variant="body1"
                  fontFamily={"Raleway-Variable"}
                  sx={{ fontSize: "16px", lineHeight: "1.6" }}
                >
                  Nos transporteurs prennent en charge vos colis pour un envoi
                  économique.
                </Typography>
              </Box>


              <Box
              // onClick={() => {
              //   navigate("/transporter-un-colis");
              // }}
              onClick={redirectToStore}

                sx={{
                  backgroundColor: defaultLabelColors.main_orange,
                  borderRadius: "32px",
                  padding: "40px",
                  color: "white",
                  maxWidth: "400px",
                  fontFamily: "'Raleway-Variable', sans-serif",
                  position: "relative",
                }}
              >
                <div style={{ display: "flex" }}>
                  <Typography
                   fontFamily={"Raleway-Extra-Bold-Italic"}
                    sx={{ fontSize: "28px", marginBottom: "10px", flex: 1 }}
                  >
                    Transporter <br /> 
                    <span style={{ }}>un colis</span>
                  </Typography>

                  <div
                    className="line-arrow-right_mob"
                    style={{ background: "#fff", borderRadius: "100%",textAlign:"center" }}
                  >
                    <East style={{ color: defaultLabelColors.main_orange }} />
                  </div>
                </div>

                <Typography
                  variant="body1"
                  fontFamily={"Raleway-Variable"}
                  sx={{ fontSize: "16px", lineHeight: "1.6" }}
                >
                    Devenez transporteur et gagnez de l'argent en assurant des
                    livarisons sur vos trajets.
                </Typography>
              </Box>

             

              
               <div style={{ display: "flex",flexDirection:'column',justifyContent:'center',alignItems:'center',gap:10 }}>
              <a
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ width: 160 }}
              >
                <img
                  src={googlePlayBtn}
                  style={{ height: "50px", width: "160px" }}
                  alt="google-play"
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://apps.apple.com/us/app/frank/id6502341979"
                target="_blank"
                style={{ width: 160 }}
              >
                <AppStoreFR />
              </a>
            </div>
            </div>
          </div>
        </div>
      </div>
      <Grid
        container
        spacing={spacing?.spacing2}
        style={{
          display: "flex",
          justifyContent: "center",
          rowGap: "20px",
          background:'#fff'
        }}
      >
        <Grid
          xs={12}
          sm={6}
          md={3}
          item
          style={{
            maxWidth: "300px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontWeight: "900",
              fontSize: "32px",
              color: "#3026f1",
              marginTop: "-10px",
              textAlign:'center',
              margin:0
            }}
          >
            POURQUOI
            <br />
            CHOISIR
            {" "}
            <span style={{ color: "#ee6931", fontFamily: "Raleway-Italic" }}>
              FRANK ?
            </span>
         
          </p>
       
        </Grid>

        <Grid
          xs={12}
          sm={6}
          md={3}
          item
          style={{
            maxWidth: "300px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginBottom:10
          }}
        >
          <CustomButton
              style={{ fontFamily: "Raleway-Variable" }}
              classesNames={"contained_button_two"}
              value={
                <React.Fragment>
                  <div
                    className="display-flex"
                    style={{ columnGap: "40px", padding: "8px 0px 8px 0px" }}
                  >
                    NOUS DECOUVRIR
                    <East
                      style={{
                        color: defaultLabelColors.main_blue,
                        background: "#f9d9b6",
                        borderRadius: 100,
                        margin: "4px",
                        padding: 4,
                      }}
                    />
                  </div>
                </React.Fragment>
              }
              // onClick={()=>navigate("/about-us")}
              onClick={redirectToStore}

            />
            </Grid>
        <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "250px" }}>
          <div
            style={{
              background: "#f9d9b6",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "300px",
              position: "relative",
            }}
          >
            <img
              src={iconOne}
              style={{
                position: "absolute",
                bottom: "32%",
                width: "75%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                left: "1%",
                height: "80%",
              }}
              alt="icon-one"
            />
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "16px",
                fontWeight: "900",
                color: "#ee6931",
                textAlign: "center",
                paddingTop: "100px",
              }}
              // className="display-flex"
            >
              <span style={{ color: "#3026f1", textAlign: "center" }}>
                LIVRAISON
              </span>
              <br />
              ÉCOLOGIQUE
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "600",
                  padding: "0px 30px 0px 30px",
                }}
              >
                Chaque coils livre par Frank. c'est 25kg de CO2 en moins.
              </p>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "250px",marginTop:-20 }}>
          <div
            style={{
              background: "#f9d9b6",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "300px",
              position: "relative",
            }}
          >
            <img
              src={iconTwo}
              style={{
                position: "absolute",
                bottom: "29%",
                width: "75%",
                height: "80%",
                right: "1%",
              }}
              alt="icon-two"
            />
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "16px",
                fontWeight: "900",
                color: "#ee6931",
                textAlign: "center",
                paddingTop: "100px",
              }}
              // className="display-flex"
            >
              <span style={{ color: "#3026f1", textAlign: "center" }}>
                LIVRAISON
              </span>
              <br />
              ÉCONOMIQUE
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "600",
                  padding: "0px 30px 0px 30px",
                }}
              >
                Avec Frank. envoyez vos colis sans vous ruiner !
              </p>
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} item style={{ maxWidth: "250px",marginBottom:'40px' ,marginTop:-20 }}>
          <div
            style={{
              background: "#f9d9b6",
              borderRadius: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "300px",
              position: "relative",
            }}
          >
            <img
              src={iconThree}
              style={{
                position: "absolute",
                bottom: "18%",
                right: "0%",
                height: "90%",
                width: "70%",
              }}
              alt="icon-three"
            />
            <div
              style={{
                fontFamily: "Raleway-Variable",
                fontSize: "16px",
                fontWeight: "900",
                color: "#ee6931",
                textAlign: "center",
                paddingTop: "100px",
              }}
            >
              <span style={{ color: "#3026f1", textAlign: "center" }}>
                LIVRAISON
              </span>
              <br />
              ASSURÉE
              <p
                style={{
                  fontFamily: "Raleway-Variable",
                  fontSize: "12px",
                  color: "#000",
                  fontWeight: "600",
                  padding: "0px 30px 0px 30px",
                }}
              >
                Tous vos colis sont assurés lors de vos envois !
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

     

      {/*
        comment by asad iqbal 1/14/2025 on Ben Demand ( Launching Frank C2C ppt)

      <Grid container spacing={2} style={{ padding: "0px 20px 0px 20px" }}>
        <Grid
          xs={12}
          item
          className="display-flex"
          style={{ flexDirection: "column" }}
        >
          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontWeight: "900",
              fontSize: "32px",
              color: "#ee6931",
              textAlign: "center",
            }}
          >
            <span style={{ color: "#3026f1" }}> FRANK,</span> LA MARKETPLACE
            <br /> COLLABORATIVE
          </p>
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "18px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Frank, c'est aussi une Marketplace où vous pouvez acheter des objets
            de seconde main ou mettre en ligne <br /> vos propres annonces. Nous
            nous chargeons ensuite de la livraison !
          </p>
        </Grid>

        <Grid xs={12} item className="display-flex">
          <div
            style={{ gap: 10, width: "90%", padding: "20px" }}
            className="display-flex"
          >
            <Slider
              {...settings}
              nextArrow={<ArrowForwardIos style={{ fontSize: "22px" }} />}
              arrows={true}
              prevArrow={
                <ArrowBackIos
                  style={{ fontSize: "22px", marginLeft: "10px" }}
                />
              }
            >
              {product_info?.map((i: any) => {
                return (
                  <div style={{ margin: "auto" }}>
                    <ProductCard
                      prductAmount={i?.productAmount}
                      productDesc={i?.productDesc}
                      productImage={i?.productImg}
                      productTitle={i?.productName}
                      productType={i?.productType}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </Grid>

        <Grid xs={12} item style={{ marginTop: "30px" }}>
          <div
            style={{ display: "flex", gap: "20px", flexDirection: "column" }}
            className="display-flex"
          >
            <CustomButton
              style={{
                width: "300px",
              }}
              classesNames={"outlined_button_two"}
              value={
                <React.Fragment>
                  <div
                    className="display-flex"
                    style={{
                      columnGap: "40px",
                      padding: "8px 0px 8px 0px",
                      fontSize: 11,
                    }}
                  >
                    DÉCOUVREZ PLUS D'ANNONCES
                    <East
                      style={{
                        color: defaultLabelColors.main_blue,
                        background: "#f9d9b6",
                        borderRadius: 100,
                        margin: "4px",
                        padding: 4,
                      }}
                    />
                  </div>
                </React.Fragment>
              }
            />
            <CustomButton
              style={{
                width: "300px",
              }}
              classesNames={"contained_button_two"}
              value={
                <React.Fragment>
                  <div
                    className="display-flex"
                    style={{
                      columnGap: "40px",
                      padding: "8px 0px 8px 0px",
                      fontSize: 12,
                    }}
                  >
                    VENDEZ MAINTENANT
                    <East
                      style={{
                        color: defaultLabelColors.main_blue,
                        background: "#f9d9b6",
                        borderRadius: 100,
                        margin: "4px",
                        padding: 4,
                      }}
                    />
                  </div>
                </React.Fragment>
              }
            />
          </div>
        </Grid>
      </Grid> */}

      <Grid container >
        <Grid xs={12} item>
          <section className="mstpone">
            <div className="mst-srcone">
              <div className="display-flex" style={{ textAlign: "center" }}>
                <div
                  style={{
                    fontSize: "26px",
                    fontFamily: "Raleway-Variable",
                    fontWeight: "900",
                    color: defaultLabelColors.main_blue,
                    width:"70%",
                    lineHeight:1.3,
                    paddingTop:20
                  }}
                >
                  <p >
                    LA LIVRAISON DE PARTICULIER À PARTICULIER,
                     
                    COMMENT ÇA MARCHE
                    <span style={{ color: defaultLabelColors.main_orange }}>
                      ?
                    </span>
                  </p>
                </div>
              </div>

             <div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingBottom:'20px'}}>
        <div
          style={{
            
            width: "80%",
            height: "10px",
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Ccircle cx='5' cy='5' r='2' fill='%233026f1' /%3E%3C/svg%3E")`,
            backgroundRepeat: "repeat-x",
          }}
        ></div>
        </div>
        <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    padding:'5px 40px'
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <p
                      style={{
                        // textAlign: "left",
                        fontWeight: "900",
                        fontSize: "20px",
                        color: defaultLabelColors.main_blue,
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      VOUS VOULEZ<br/> ENVOYER UN COLIS ?
                      <br />
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                          marginBottom:0,
                        }}
                      >
                        Publiez votre announce en indiquant les détails{" "}
                        concernant le colis et sa destination.
                      </p>
                      <br />
                      <span style={{ fontWeight: "700"  }}>
                        <CustomButton
                        onClick={redirectToStore}


                          style={{
                            background: "transparent",
                            border: "2px solid #3026f1",
                            borderRadius: "32px",
                            fontSize: "14px",
                            width: "300px",
                            color: "#3026f1",
                            fontWeight: "800",
                          }}
                          value={ <span style={{fontFamily:'Raleway-Extra-Bold',padding:6,fontWeight:'900'}}>ENVOYER VOTRE COLIS</span>}
                        />
                      </span>
                    </p>
                  </div>
                </div>
              <div className="imgtxt">
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img src={StepScreen1} alt="step-one" />
                </div>
              </div>
            </div>
          </section>

          <section className="mstptwo" style={{paddingTop:20}}>
            <div className="mst-srctwo">
              <div className="imgtxt">
              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding:'5px 40px'

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                  <p
                      style={{
                        // textAlign: "left",
                        fontWeight: "900",
                        fontSize: "20px",
                        color: defaultLabelColors.white,
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      VOUS VOUS VOYEZ <br />
                      PLUTÔT TRANSPORTEUR?
                      <br />
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#fff",
                         }}
                      >
                        Parcourez les offres de livarison disponsibles <br />
                        et choisissez celle qui vous convient
                      </p>
                      <br />
                      
                      <span style={{ fontWeight: "700"  }}>
                        <CustomButton
                        onClick={redirectToStore}

                          style={{
                            background: "transparent",
                            border: "2px solid #fff",
                            borderRadius: "32px",
                            fontSize: "14px",
                            width: "300px",
                            color: "#fff",
                            fontWeight: "800",
                          }}
                          value={ <span style={{fontFamily:'Raleway-Extra-Bold',padding:6,fontWeight:'900'}}> VOIR LES ANNONCES</span>}
                        />
                      </span>
                    </p>
                  </div>
                </div>
<div style={{display:'flex',justifyContent:'center'}}>
                <img
                  src={StepScreen2}
                  style={{ marginTop: "20px" }}
                  alt="step-two"
               />
     </div>           
              </div>
            </div>
          </section>

          <section style={{  }} className="mstpthree-mob">
            <div className="mst-srcthree" style={{paddingTop:"20px"}}>
              <div className="imgtxt">


              <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    padding:'5px 40px'

                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <p
                      style={{
                         fontWeight: "900",
                        fontSize: "20px",
                        color: defaultLabelColors.main_blue,
                        fontFamily: "Raleway-Italic",
                      }}
                    >
                      LE COLIS A ÉTÉ LIVRÉ !
                      <br />
                      <p
                        style={{
                          fontFamily: "Raleway-Variable",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#000",
                        }}
                      >
                        <span style={{ fontWeight: "700" }}>Félicitations </span>
                        ! votre porte-monnaie se remplit
                        
                        automatiquement une fois que la livraison a été assurée.
                      </p>
                      <br />
                       


                      <span style={{ fontWeight: "700"  }}>
                        <CustomButton
                        onClick={redirectToStore}

                          style={{
                            background: "transparent",
                            border: "2px solid #3026f1",
                            borderRadius: "32px",
                            fontSize: "14px",
                            width: "300px",
                            color: "#3026f1",
                            fontWeight: "800",
                          }}
                          value={ <span style={{fontFamily:'Raleway-Extra-Bold',padding:6,fontWeight:'900'}}>
                          GAGNER DE L'ARGENT

                          </span>}
                        />
                      </span>
                    </p>
                  </div>
                </div>
<div style={{display:'flex',justifyContent:'center'}}>

                <img
                  src={StepScreen3}
                  style={{ marginTop: "20px" }}
                  alt="step-two"
                />
</div>              
              </div>
            </div>
          </section>
        </Grid>
      </Grid>
      {/* <Grid
        container
        spacing={2}
        className="display-flex"
        style={{ top: "100%" }}
      >
        <div>
          <div
            style={{
              fontFamily: "Raleway-Italic",
              fontWeight: "900",
              fontSize: "22px",
              color: "#ee6931",
              flex: 1,
              width: "100%",
              textAlign: "center",
            }}
          >
            <p style={{ color: defaultLabelColors.main_blue }}>
              LES DERNIÈRES ANNONCES
              <br />
              <span style={{ color: defaultLabelColors.main_orange }}>
                MISES EN LIGNE
              </span>
            </p>
          </div>
          <div
            className="display-flex"
            style={{ flexDirection: "column", rowGap: "20px" }}
          ></div>
          <Grid
            container
            spacing={4}
            style={{
              display: "flex",
              flexDirection: "column",
              // marginTop: "10px",
              paddingLeft: "15px",
            }}
          >
            {props?.annoucesData?.map((i: any) => {
              return (
                <Grid
                  xs={12}
                  item
                  className="display-flex"
                  onClick={() => {
                    navigate("/transporter-un-colis");
                  }}
                >
                  <div className="product" style={{ width: "380px" }}>
                    <div className="frame-30" style={{ width: "380px" }}>
                      <div className="image">
                        <img
                          src={
                            i?.shipment_items?.[0]?.prod_images?.[0] ??
                            "prod name"
                          }
                          alt="prod-img"
                        />
                      </div>
                    </div>
                    <div
                      className="frame-31"
                      style={{ width: "380px", paddingBottom: "30px" }}
                    >
                      <div className="title">
                        <span className="table-wood">
                          {i?.shipment_items?.[0]?.prod_name ?? "prod name"}
                        </span>
                        <span className="time">
                          {moment(i?.created_at).fromNow()}
                        </span>
                        <span
                          className="time-38"
                          style={{
                            fontFamily: "Raleway-Variable",
                            fontWeight: "800",
                            fontSize: "26px",
                            color: defaultLabelColors.main_blue,
                          }}
                        >
                          {i?.ship_price ?? "0.00"}€
                        </span>
                      </div>
                      <div className="town-distance-39">
                        <span className="aix-provence-3a"></span>
                        <div className="frame-3b">
                          <div className="solid-car-3c">
                            <div className="solid-3d">
                              <LocationOn
                                style={{
                                  color: defaultLabelColors.main_orange,
                                  fontSize: "22px",
                                }}
                              />
                            </div>
                          </div>
                          <span className="distance-3e">
                            {i?.dropoff?.city ?? "city"} -{" "}
                            {calculateDistance(
                              i?.pickup?.location?.coordinates?.[0],
                              i?.pickup?.location?.coordinates?.[1],
                              i?.dropoff?.location?.coordinates?.[0],
                              i?.dropoff?.location?.coordinates?.[1]
                            ) ?? "0.0"}
                            <span
                              style={{
                                fontFamily: "Raleway-Variable",
                                fontWeight: "500",
                              }}
                            >
                              {" "}
                              km{" "}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}

            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                style={{
                  background: defaultLabelColors.main_blue,
                  color: defaultLabelColors.white,
                  borderRadius: 100,
                  fontSize: "18px",
                  fontWeight: "700",
                  width: "370px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                classesNames={"contained_button_two"}
                value={
                  <React.Fragment>
                    <div
                      className="display-flex"
                      style={{
                        columnGap: "40px",
                        padding: "8px 0px 8px 0px",
                      }}
                    >
                      VOIR PLUS D'AANONCES
                      <East
                        style={{
                          background: defaultLabelColors.sand,
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 4,
                        }}
                      />
                    </div>
                  </React.Fragment>
                }
              />
            </Grid>
          </Grid>
        </div>
      </Grid> */}

      {/* <div style={{ padding: "0px 20px 0px 20px" }}>
        <div
          style={{
            fontFamily: "Raleway-Italic",
            padding: "50px",
            borderRadius: "20px",
          }}
          className="triangle-two"
        >
          <Grid container>
            <Grid xs={12} item>
              <div style={{ flex: 1 }}>
                <p
                  style={{
                    fontSize: "22px",
                    fontWeight: "800",
                    color: defaultLabelColors.main_blue,
                  }}
                >
                  VOUS ÊTES UN PROFESSIONNEL ?
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "18px",
                    color: "#000",
                    fontWeight: "600",
                  }}
                >
                  Découvrez notre solution de livraison collaborative!
                </p>
                <p
                  style={{
                    fontFamily: "Raleway-Variable",
                    fontSize: "16px",
                    color: "#000",
                    fontWeight: "400",
                    textAlign: "left",
                  }}
                >
                  Éco-responsable, économique et flexible, la solution Frank
                  change la vie des professionnels. En fonction de vos besoins,
                  nous prenons en charge la collecte, la préparation de commande
                  et même la livraison au client final. Pour plus de fluidité,
                  Frank peut s'intégrer à vos systèmes de gestion via une API !
                </p>
              </div>
              <div>
                <CustomButton
                  style={{
                    fontFamily: "Raleway-Variable",
                    width: "230px",
                    fontSize: "20px",
                  }}
                  classesNames={"contained_button_two"}
                  value={
                    <React.Fragment>
                      <div
                        className="display-flex"
                        style={{
                          columnGap: "20px",
                        }}
                      >
                        DÉCOUVRIR
                        <East
                          style={{
                            color: defaultLabelColors.main_blue,
                            background: "#f9d9b6",
                            borderRadius: 100,
                            padding: 4,
                          }}
                        />
                      </div>
                    </React.Fragment>
                  }
                />
              </div>
            </Grid>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ height: "100%" }}>
                <img
                  src={craneImage}
                  alt="crane-img"
                  style={{
                    height: "300px",
                    width: "300px",
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div> */}
      {/* <div className="why-a3_mob">
        <div className="menu-a4_mob">
          <span
            className="achetez-vos-produits-a5_mob"
            style={{ textAlign: "center" }}
          >
            Nos actus
          </span>
          <span className="soyez-livres-a6_mob" style={{ textAlign: "center" }}>
            Soyez livrés par nos Franks ou nos partenaires
          </span>
        </div>
        <div className="frame-a7_mob">
          <Grid container spacing={2}>
            <Grid xs={12} md={4} item>
              <div className="actu">
                <div className="img-wrapper-blue">
                  <div className="pic-26-1"></div>
                </div>
                <div className="text-f9">
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "70%",
                        color: "#080626",
                        fontFamily: "Raleway-Variable",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Du nouveau chez Frank
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "400",
                          margin: 0,
                        }}
                      >
                        il y a 10 heures
                      </p>{" "}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      <East
                        style={{
                          background: "#fff",
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 2,
                          fontSize: "16px",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <span
                    className="lorem-ipsum-dolor-fc"
                    style={{ marginBottom: "10px" }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </span>
                </div>
              </div>
            </Grid>

            <Grid xs={12} md={4} item>
              <div className="actu">
                <div className="img-wrapper-blue">
                  <div className="pic-26-2"></div>
                </div>
                <div className="text-f9">
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "70%",
                        color: "#080626",
                        fontFamily: "Raleway-Variable",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Du nouveau chez Frank
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "400",
                          margin: 0,
                        }}
                      >
                        il y a 10 heures
                      </p>{" "}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      <East
                        style={{
                          background: "#fff",
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 2,
                          fontSize: "16px",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <span
                    className="lorem-ipsum-dolor-fc"
                    style={{ marginBottom: "10px" }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </span>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={4} item>
              <div className="actu">
                <div className="img-wrapper-blue">
                  <div className="pic-26-3"></div>
                </div>
                <div className="text-f9">
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      style={{
                        width: "70%",
                        color: "#080626",
                        fontFamily: "Raleway-Variable",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Du nouveau chez Frank
                      <p
                        style={{
                          fontFamily: "Raleway-Italic",
                          fontSize: "14px",
                          color: "#000",
                          fontWeight: "400",
                          margin: 0,
                        }}
                      >
                        il y a 10 heures
                      </p>{" "}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      <East
                        style={{
                          background: "#fff",
                          borderRadius: 100,
                          color: defaultLabelColors.main_blue,
                          padding: 2,
                          fontSize: "16px",
                        }}
                      />{" "}
                    </div>
                  </div>
                  <span
                    className="lorem-ipsum-dolor-fc"
                    style={{ marginBottom: "10px" }}
                  >
                    Lorem ipsum dolor sit amet consectetur. Sapien pharetra
                    adipiscing odio et bibendum duis. Auctor scelerisque tellus
                    tortor euismod...
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "Raleway-Italic",
            fontSize: "22px",
            gap: 5,
          }}
        >
          VOIR TOUS LES ARTICLES <East />
        </div>
        <div className="div-line-117"></div>
      </div> */}
      <div style={{ padding: "0px 10px 0px 10px",background:'#fff' }}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop:'40px'
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h2
              style={{
                // textAlign: "left",
                fontSize: "32px",
                fontFamily: "Raleway-Semi-Bold",
                padding: "0px 0px 20px 20px",
                margin: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              Nos actualités
            </h2>
            <ul className="cards">
              {props?.allBlogs?.slice(0, 3)?.map((item: any) => {
                return (
                  <>
                    <li
                      className="cards__item"
                      onClick={() => {
                        navigate(
                          `/blog-details/${item?.blog_cat_id}/${item?.id}`
                        );
                      }}
                    >
                      <div className="card">
                        <div
                          className="card__image"
                          style={{
                            backgroundImage: `url(${item?.blog_image?.[0]})`,
                          }}
                        ></div>
                        <div className="card__content">
                          <div style={{ display: "flex" }}>
                            <div
                              className="card__title"
                              style={{ width: "80%" }}
                            >
                              {handleLocalize(item?.title)}
                              <p
                                style={{
                                  fontFamily: "Raleway-Italic",
                                  fontSize: "14px",
                                  color: "#000",
                                  fontWeight: "400",
                                  marginTop: 5,
                                }}
                              >
                                Posté le{" "}
                                {moment(
                                  `${item?.created_at}`.endsWith("Z")
                                    ? item?.created_at
                                    : item?.created_at + "Z"
                                ).fromNow()}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "20%",
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <East
                                style={{
                                  background: "#fff",
                                  borderRadius: 100,
                                  color: defaultLabelColors.main_blue,
                                  padding: 5,
                                  fontSize: "16px",
                                }}
                              />{" "}
                            </div>
                          </div>
                          <p className="card__text">
                            {handleLocalize(item?.sub_title)}
                          </p>
                        </div>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </Grid>

        <Grid container className="display-flex">
          <Grid xs={12} item>
            <div
              style={{
                textAlign: "center",
                fontSize: "26px",
                fontFamily: "Raleway-Extra-Bold-Italic",
                color: "#000",
              }}
            >
              <h3
                style={{
                  cursor: "pointer",
                  fontSize: "18px",
                  fontFamily: "Raleway-Semi-Bold-Italic",
                  padding:20
                }}
                className="display-flex"
                onClick={() => {
                  navigate("/blogs");
                }}
              >
                {" "}
                VOIR TOUS LES ARTICLES &nbsp; <East />
              </h3>
            </div>

            {/* <div
              style={{
                marginTop: "30px",
                height: 1,
                background: "grey",
                textAlign: "center",
              }}
            ></div> */}
          </Grid>
        </Grid>
      </div>

      <div className="no-inherit"  >
        <Grid container   >
          <Grid
            xs={12}
            item
            className="display-flex"
            style={{
              flexDirection: "column",
              background: "#F5C9B3",
              width: "100%",
              padding:'30px'
            }}
          >
            <div
              style={{
                fontSize: "26px",
                fontWeight: "900",
                fontFamily: "Raleway-Italic",
                padding:'30px 0px 30px 40px',
                textAlign:"left",
                width:'100%',
              }}
            >
              Des questions ?
            </div>

            <Accordion
              elevation={0}
              sx={{
                "&::before": {
                  content: "none", // Remove the pseudo-element
                },
              }}
              style={{
                border: "0px",
                background: "#F5C9B3",
              }}
              className="no-inherit"
            >
              <AccordionSummary
                style={{ border: "none",margin:"15px 0px" }}
                expandIcon={<ExpandMore style={{ float: "right" }} />}
                className="span-what-is-frank_mobile"
              >
                <h3 style={{ fontWeight: "500" }}>
                  Comment fonctionne la livraison collaborative ?
                </h3>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  width: "100%",
                  fontFamily: "Raleway-Variable",
                }}
              >
                Grâce à notre plateforme, un particulier peut envoyer un colis
                qui sera livré par un autre particulier lors d’un de ses trajets
                personnels. Au cœur de notre activité, la livraison
                collaborative nous permet de créer une communauté d’entraide en
                garantissant un service d’envoi peu coûteux et écologique.
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              style={{
                border: "0px",
                background: "#F5C9B3",
                width: "100%",

              }}
            >
              <AccordionSummary
                style={{ border: "none",margin:"15px 0px" }}
                expandIcon={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontSize: "28px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {" "}
                    <ExpandMore style={{ textAlign: "right" }} />{" "}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="span-what-is-frank_mobile"
              >
                <h3 style={{ fontWeight: "500" }}>
                  Comment fonctionne la livraison professionnelle avec Frank ?
                </h3>
              </AccordionSummary>
              <AccordionDetails style={{ width: "90%" }}>
                <p>
                  {" "}
                  Nous couvrons l’envoi du premier au dernier kilomètre et
                  stockons vos colis dans notre entrepôt en Île-de-France. En
                  fonction de vos besoins, nous proposons plusieurs modes de
                  livraison professionnelle :
                </p>

                <p>
                  {" "}
                  • Pick & Pack : récupération des colis à votre adresse,
                  préparation des colis, packaging d’envoi ;{" "}
                </p>
                <p>
                  • Livraison totale : nous nous occupons de tout, de la
                  récupération des colis jusqu’à leur dépôt chez le client final
                  ;
                </p>
                <p>
                  • Stockage : nous stockons vos colis dans notre entrepôt en
                  Île-de-France, avant leur retrait.
                </p>

                <p>
                  {" "}
                  Pour plus d’informations, vous pouvez nous contacter en
                  envoyant un mail à l’adresse :{" "}
                  <a href="mailto:hello@frankme.com">hello@frankme.com</a>.
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              style={{
                border: "0px",
                background: "#F5C9B3",
                width: "100%",
              }}
            >
              <AccordionSummary
                style={{ border: "none", width: "100%" }}
                expandIcon={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontSize: "28px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {" "}
                    <ExpandMore style={{ textAlign: "right" }} />{" "}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="span-what-is-frank_mobile"
              >
                <h3 style={{ fontWeight: "500" }}>
                  Puis-je gagner de l’argent avec Frank ?
                </h3>
              </AccordionSummary>
              <AccordionDetails style={{ width: "90%" }}>
                <p>
                  {" "}
                  Vous êtes transporteur ? En voiture, en train, en trottinette,
                  en péniche ou à pied, quel que soit le mode de transport, vous
                  gagnez de l’argent !{" "}
                </p>

                <p>
                  Vous envoyez un colis avec Frank ? Vous ne gagnez pas
                  d’argent, mais vous en économisez à coup sûr !{" "}
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              style={{
                border: "0px",
                background: "#F5C9B3",
                width: "100%",
              }}
            >
              <AccordionSummary
                style={{ border: "none", width: "100%" }}
                expandIcon={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontSize: "28px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {" "}
                    <ExpandMore style={{ textAlign: "right" }} />{" "}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="span-what-is-frank_mobile"
              >
                <h3 style={{ fontWeight: "500" }}>
                  Comment fonctionne l’assurance des colis ?
                </h3>
              </AccordionSummary>
              <AccordionDetails style={{ width: "90%" }}>
                Tout envoi de colis via Frank est assuré à une hauteur de 100 €.
                Pour vos colis les plus précieux, vous pouvez souscrire à une
                assurance supplémentaire (HISCOX).
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              style={{
                border: "0px",
                background: "#F5C9B3",
                width: "100%",
              }}
            >
              <AccordionSummary
                style={{ border: "none", width: "100%" }}
                expandIcon={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontSize: "28px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {" "}
                    <ExpandMore style={{ textAlign: "right" }} />{" "}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="span-what-is-frank_mobile"
              >
                <h3 style={{ fontWeight: "500" }}>
                  Comment devenir transporteur ?
                </h3>
              </AccordionSummary>
              <AccordionDetails style={{ width: "90%" }}>
                <p>
                  {" "}
                  Pour devenir transporteur à nos côtés, rien de plus simple :{" "}
                </p>

                <p>
                  {" "}
                  • Vous créez un compte sur le site ou l’application de Frank ;{" "}
                </p>
                <p>
                  {" "}
                  • Vous checkez les annonces à côté de vous, ou sur vos trajets
                  ;{" "}
                </p>
                <p> • Vous acceptez une offre ;</p>
                <p>
                  {" "}
                  • Vous remettez le colis au destinataire, qui vous remet un
                  code pour valider la livraison ;{" "}
                </p>
                <p>
                  {" "}
                  • Vous recevez le montant de la livraison sur votre
                  porte-monnaie une fois que vous avez renseigné le code.{" "}
                </p>
              </AccordionDetails>
            </Accordion>

            <Accordion
              elevation={0}
              style={{
                border: "0px",
                background: "#F5C9B3",
                width: "100%",
                padding: "10px 0px",
              }}
            >
              <AccordionSummary
                style={{ border: "none",margin:"15px 0px" }}
                expandIcon={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontSize: "28px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {" "}
                    <ExpandMore style={{ textAlign: "right" }} />{" "}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="span-what-is-frank_mobile css-yw020d-MuiAccordionSummary-expandIconWrapper_mobile"
              >
                <h3 style={{ fontWeight: "500", padding: "5px 0px" }}>
                  En tant que transporteur, comment transférer l’argent de mon
                  porte-monnaie sur mon compte bancaire ?
                </h3>
              </AccordionSummary>
              <AccordionDetails style={{ width: "90%" }}>
                <p>
                  Sur l’application Frank, il y a un espace porte-monnaie, où
                  vous trouverez le montant total de vos gains. Il suffit de
                  cliquer sur la carte bancaire, puis sur “transférer sur un
                  compte bancaire” et de renseigner les informations demandées.
                  L’argent arrive sur votre compte au bout de quelques jours !
                </p>
              </AccordionDetails>
            </Accordion>

         

            <Accordion
              elevation={0}
              style={{
                border: "0px",
                background: "#F5C9B3",
                width: "100%",
              }}
            >
              <AccordionSummary
                style={{ border: "none",margin:"15px 0px" }}
                expandIcon={
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      fontSize: "28px",
                      fontFamily: "Raleway-Variable",
                    }}
                  >
                    {" "}
                    <ExpandMore style={{ textAlign: "right" }} />{" "}
                  </div>
                }
                aria-controls="panel1-content"
                id="panel1-header"
                className="span-what-is-frank_mobile"
              >
                <h3 style={{ fontWeight: "500" }}>
                  Y a-t-il une limite de poids pour la livraison des colis ?
                </h3>
              </AccordionSummary>
              <AccordionDetails style={{ width: "100%" }}>
                <p>
                  Il n’y a pas de limite de poids chez Frank. Pour un
                  déménagement, vous pouvez prévoir le nombre de personnes dont
                  vous avez besoin en renfort en fonction de la taille des
                  meubles.
                </p>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
      <div style={{background:defaultLabelColors.main_blue}}>
      <CustomMobileFooter />
      </div>
      {/* footer */}
      {/* <div style={{ padding: "10px 20px 10px 20px" }}>
        <div
          className="triangle"
          style={{ height: "90vh", position: "relative" }}
        >
          <div
            style={{
              paddingTop: 20,
              rowGap: 20,
              display: "flex",
              flexDirection: "column",
              zIndex: 111,
            }}
          >
            <FrankIcon fill={"#fff"} height={70} />

            <p
              className="heading-two"
              style={{
                fontSize: "28px",
                textAlign: "center",
                color: "#fff",
                padding: "0px 10px 0px 10px",
              }}
            >
              <span style={{ fontSize: "24px", fontWeight: "500" }}>
                Télécharger
              </span>
              <br />
              notre application mobile
            </p>

            <div
              className="display-flex"
              style={{
                flexDirection: "column",
                gap: "10px",
                marginTop: "-10px",
              }}
            >
              <a
                href="https://apps.apple.com/app/frank/id6502341979"
                target="_blank"
              >
                <AppStoreFR height={60} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.frankc2c"
                target="_blank"
                style={{ textAlign: "center", width: "190px" }}
              >
                <img
                  src={playSore}
                  height={56}
                  alt="google-play"
                  width={"65px"}
                />
              </a>
            </div>
          </div>

          <img
            src={downloadappStoreBanner}
            style={{ position: "absolute", bottom: 0 }}
            alt="download-banner"
          />
        </div>
      </div>
      <div
        className="footer-new"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="contain" style={{ padding: "40px 0px 0px 0px" }}>
          <div className="col">
            <p>
              <FrankIcon fill={"#fff"} height={80} />
            </p>
          </div>
          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Particulier
            </span>
            <ul>
              <li>Envoyer un colis</li>
              <li>Transporteur</li>
              <li>Devenir transporteur</li>

              <li style={{ marginTop: "10px", fontWeight: "700" }}>
                Mon compte
              </li>
              <li style={{ fontWeight: "700" }}>Messagerie</li>
            </ul>
          </div>
          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Transporteur
            </span>
            <ul>
              <li>Devenir transporteur</li>
              <li>Voir les annonces pour</li>
              <li> une livraison</li>
            </ul>
          </div>
          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Déménagement{" "}
            </span>
            <ul>
              <li>Déménageur particulier</li>
              <li>Déménageur professionnel</li>
            </ul>
          </div>
          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Marketplace
            </span>
            <ul></ul>
          </div>
          <div className="col">
            <span
              style={{
                padding: 0,
                fontSize: "14px",
                fontWeight: "700",
                color: "#fff",
                fontFamily: "Raleway-Italic",
              }}
            >
              Stockage colis
            </span>
            <ul>
              <li>Trouver un point relais particulier </li>
              <li> Proposer un espace de point relais</li>
              <li>Consigner bagage</li>

              <li
                style={{
                  marginTop: "10px",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                Blog
              </li>
              <li style={{ fontWeight: "700", fontSize: "18px" }}>FAQ</li>
            </ul>
          </div>
          <div className="col" style={{ width: "190px", height: 200 }}>
            <div
              style={{
                border: "1px solid #fff",
                borderRadius: "12px",
                marginTop: "20px",
                padding: "20px",
                width: "180px",
              }}
            >
              <span
                style={{
                  padding: 0,
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#fff",
                  fontFamily: "Raleway-Italic",
                }}
              >
                Nous Contacter:
              </span>
              <ul>
                <li>hello@frankme.com</li>
                <li> 04 XX XX XX XX</li>
                <li>91 avenue de la République 75011 Paris</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "70px",
                gap: "10px",
                marginTop: "10px",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <img src={facebookIcon} alt="facebook-icon" />
              </div>
              <div>
                <img src={instaIcon} alt="instgram-icon" />
              </div>
              <div>
                <img src={linkedInIcon} alt="linkedin-icon" />
              </div>
            </div>
          </div>
          <div
            className="col"
            style={{
              width: "100%",
              // marginLeft: "170px",
              fontFamily: "Raleway-Italic",
              fontSize: "14px",
              color: "#fff",
              // marginTop: "-30px",
              textAlign: "center",
            }}
          >
            <a
              href="https://frankme.com/privacy-policy"
              target="_blank"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Politique de confidentialité
            </a>
            |
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="https://frankme.com/terms-and-condition"
              target="_blank"
            >
              Termes et conditions
            </a>
          </div>

          <div className="clearfix"></div>
        </div>
      </div> */}
    </div>
  );
}

export default MobileView;
