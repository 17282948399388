import moment from "moment";
import React, { useEffect } from "react";
import { color_theme, defaultLabelColors } from "../../utils/constants";
import { handleLocalize } from "../../utils/helper";
import apiServices from "../../services/RequestHandler";
import { getAllNotifications } from "../../Store/MainAppReducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import { useNavigate } from "react-router-dom";

export default function Notifications(props: any) {
  // const { navigation } = props;
  const navigate = useNavigate();
  const selector = useSelector((state: RootState) => state.mainApp);
  const dispatch = useDispatch<any>();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      notifications: [],
    }
  );
  useEffect(() => {}, [selector.lang]);

  const handlePressNotifiation = async (item: any) => {
    try {
      await apiServices.getFromApi(`/notifications/read/${item.id}`, "");
      dispatch(
        getAllNotifications({
          end_point: "/notifications/by-user",
        })
      );
      if (item.navigation_id === "messaging") {
        let obj: any = {
          id: Number(item?.nav_data?.ship_id),
          customer_id: Number(selector?.user?.id),
        };
        const resp = await apiServices.postFromApi(
          `c2c-shipments/details`,
          obj,
          ""
        );
        const details = resp?.data ?? {};
        if (resp?.data?.id) {
          // eslint-disable-next-line
          let receiver_id;
           let customer_id;
          if (`${item?.nav_data?.receiver?.id}` !== `${details?.customer_id}`) {
            customer_id = Number(item?.nav_data?.receiver?.id);
            receiver_id = Number(item?.nav_data?.sender?.id);
          } else {
            // eslint-disable-next-line
            customer_id = Number(item?.nav_data?.sender?.id);
            receiver_id = Number(item?.nav_data?.sender?.id);
          }
          navigate(`/chat/${details?.id}/${receiver_id}`);
        }
      } else {
        if (item.nav_data?.ship_id || item.nav_data?.id) {
          if (item.navigation_id === "transaction_details") {
            navigate(`/anounce/${item.nav_data?.ship_id || item.nav_data?.id}`);
          } else if (item.navigation_id === "job_details") {
            navigate(`/job/${item.nav_data?.ship_id || item.nav_data?.id}`);
          } else if (item.navigation_id === "send_parcel") {
            navigate(`/send-package`);
          } else if (item.navigation_id === "announcement_messaging") {
            navigate(`/chat/${item.nav_data?.ship_id || item.nav_data?.id}`);
          } else {
            navigate(
              `/${item.navigation_id}/${
                item.nav_data?.ship_id || item.nav_data?.id
              }`
            );
          }
        } else {
          if (item.navigation_id === "transaction_details") {
            navigate(`/anounce/${item.nav_data?.ship_id || item.nav_data?.id}`);
          } else if (item.navigation_id === "job_details") {
            navigate(`/job/${item.nav_data?.ship_id || item.nav_data?.id}`);
          } else if (item.navigation_id === "announcement_messaging") {
            navigate(`/chat/${item.nav_data?.ship_id || item.nav_data?.id}`);
          } else if (item.navigation_id === "send_parcel") {
            navigate(`/send-package`);
          } else {
            navigate(
              `/${item.navigation_id}/${
                item.nav_data?.ship_id || item.nav_data?.id
              }`
            );
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setState({
      notifications: selector.notifications,
      refreshing: false,
    });
  }, [selector.notifications]);

  return (
    <>
      <div
        style={{
          background: "#fff",
          paddingLeft: "40px",
          paddingRight: "40px",
          paddingTop: "30px",
          minHeight: "100vh",
          borderRadius: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: 14,
            marginTop: 26,
          }}
        >
          {state.notifications?.map((item: any, index: number) => (
            <NotificationCard
              key={index}
              item={item}
              index={index}
              onPressCard={() => handlePressNotifiation(item)}
            />
          ))}
        </div>
      </div>
    </>
  );
}

const NotificationCard = ({ item, index, onPressCard }: any) => {
  let date_string = "";
  if (
    moment().format("DD/MM/YYYY") ===
    moment(
      item?.created_at
        ? `${item?.created_at}`.endsWith("Z")
          ? item?.created_at
          : item?.created_at + "Z"
        : new Date()
    ).format("DD/MM/YYYY")
  ) {
    date_string = moment(
      item?.created_at
        ? `${item?.created_at}`.endsWith("Z")
          ? item?.created_at
          : item?.created_at + "Z"
        : new Date()
    ).format("HH:mm");
  } else {
    date_string = moment(
      item?.created_at
        ? `${item?.created_at}`.endsWith("Z")
          ? item?.created_at
          : item?.created_at + "Z"
        : new Date()
    ).format("DD/MM/YYYY");
  }
  return (
    <div
      key={"NOTIFICATION_" + index + 1}
      onClick={onPressCard}
      style={{
        position: "relative",
        paddingBottom: "10px",
      }}
    >
      <div
        style={{
          borderColor: defaultLabelColors.main_background_two,
          padding: 16,
          borderWidth: 1,
          background: defaultLabelColors.main_background_two,
          backgroundColor: defaultLabelColors.main_background_two,
          borderRadius: 12,
          flexDirection: "row",
          display: "flex",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <span
            style={{
              fontSize: 16,
              color: color_theme.black,
              fontFamily: "Raleway-Variable",
              opacity: 0.7,
            }}
          >
            {item.notif_body
              ? handleLocalize(item.notif_body)
              : item.description ?? ""}
          </span>
        </div>
        <div
          style={
            {
              // justifyContent: 'flex-end',
              // alignItems: 'flex-end',
            }
          }
        >
          <span
            style={{
              fontFamily: "Raleway-Semi-Bold",
              fontSize: 14,
            }}
          >
            {date_string}
          </span>
        </div>
      </div>
      {!item.is_read && (
        <div
          style={{
            position: "absolute",
            top: -5,
            right: -5,
            height: 16,
            width: 16,
            backgroundColor: color_theme.main_orange,
            borderRadius: 100,
            borderWidth: 2,
            borderColor: color_theme.card_border,
          }}
        />
      )}
    </div>
  );
};
