import LocalizedStrings from "react-localization";

export let strings = new LocalizedStrings({
//   en: {
//     date_of_birth_placeholder:"DD/MM/YYYY",
//     small_desc: ' Between an envelope and a shoebox (less than 5 kg)',
//     medium_desc: 'Between a bag and a case of wine (5 kg - 15 kg)',
//     large_desc: 'Between a television and a dresser (15 kg - 25 kg)',
//     e_large_desc: ' Between a wardrobe and a dining table (more than 25 kg)',
//     search: "Search..",
//     use_email: "or use your email account.",
//     login_with: "Login with",
//     signup_with: "Sign up with",
//     accept_terms_conditions:
//       "I accept the general conditions of use of the Frank",
//     accept_newsletter: "I agree to receive latest updates of Frank",
//     personal_info: "Personal Information",
//     set_credentials: "Set Login Creadentials",
//     set_mobile_number: "Enter Your Mobile Number",
//     profile: "Profile",
//     chat: "Chat",
//     support: "Support",
//     seller_details:"Seller Details",
//     address: "Address",
//     confirmation: "Confirmation",
//     logout_confirmation: "Are you sure want to logout from this device?",
//     logout: "Logout",
//     settings: "Settings",
//     mini_shop: "Mini Shop",
//     shop_details: "Shop Details",
//     order_history: "Order History",

//     my_deliveries: "Deliveries",
//     my_parcels: "My Announcements",

//     signin: "Sign in",
//     or_connect: "Or Connect With",
//     sign_up: "Create Account",
//     set_new_password: "Set New Password",
//     wallet: "Wallet",
//     blogs: "Frank Blogs",
//     blog_details: "Blog Details",
//     alread_have_acc: "Already have an account? ",
//     login_here: "Login Here",
//     signup_here: "Signup Here",
//     dont_hace_acc: "Don't have an account? ",
//     forgot_password: "Forgot Password",

//     continue: "Continue",
//     login: "Login",

//     first_name: "First Name",
//     surname: "Surname",
//     date_birth: "Date of Birth",
//     user_name: "Username",
//     password: "Password",
//     old_password: "Old Password",
//     phone_number: "Phone Number",
//     confirm_password: "Confirm Password",
//     confirm_password_error:'Confirm Password cannot be empty',
//     next: "Next",
//     send_number_text:
//       "We will send you a validation code by SMS to verify your number.",
//     didnt_receive_code: `Didn't receive code? `,
//     resend_code: `Resend Code `,
//     send_otp: "Send OTP",
//     validate_otp: "Validate OTP",
//     send_otp_text: "Enter the One-Time-Passcode (OTP) that has been sent to: ",
//     verify_otp: "Verify One Time Passcode (OTP)",
//     your_mood: "What are you up to today?",
//     sender: "Sender",
//     transporter: "Transporter",
//     transporter_2: "Transporter",
//     relocation: "Relocation",
//     send_green_head: "Send Green, Sending Your Way",
//     send_green_text: "Each delivery via Frank saves an average of 25 kg of CO₂",

//     home: "Home",
//     announces: "Announces",
//     send: "Send",
//     inbox: "Inbox",
//     account: "Account",

//     reduce_carbon_head: "Reduce",
//     reduce_carbon: "your\ncarbon footprint",
//     reduce_carbon_title: `Eco-responsible`,
//     reduce_carbon_desc: `A parcel sent with Frank it's 25kg/CO2 less! By optimizing your parcel's journey you are doing a favor for the planet.`,

//     eco_delivery_head: "Economical\n",
//     eco_delivery: "delivery",
//     eco_delivery_title: "Economical",
//     eco_delivery_desc:
//       "A parcel sent with Frank is 50% cheaper than with a traditional carrier. No more ruinous parcel shipments!",

//     assured_delivery_head: "Assured\n",
//     assured_delivery: "delivery",
//     assured_delivery_title: "Assured",
//     assured_delivery_desc:
//       "A parcel sent with Frank is insured up to 100€. The carrier receives payment once the parcel has delivered and the recipient validated the delivery.",

//     recent_transaction: "Recent Transactions",
//     tax_on_commission:'Tax on commission',
//     create_shipment: "Create Shipment",
//     upto_six_pics: "Up to 6 photos, JPG, PNG and GIF format up to 7MB.",
//     add_photos: "Add Photos",
//     prod_name: "Ex: Sofa, Table",
//     i_know_dimensions: "I know the exact dimensions",
//     add_descriptive: "Add Descriptive Information Here ..",
//     prod_size: "Ex: Regular, Medium, etc.",
//     fragile: " Fragile ",
//     add_item: "Add Item",
//     origin_area: "Origin Area",
//     pickup_address: "Pickup Address",
//     pickup_place_placholder: "Pick up place",
//     delivery_place_placholder: "Delivery place",
//     pickup_place_1: "In front of my house",
//     pickup_place_2: "Inside my house",

//     delivery_type: "Urgent",
//     delivery_type_text: "Between 1 to 2 days",

//     delivery_type2: "I can wait",
//     delivery_type2_text: "Between 3 to 7 days",

//     delivery_type3: "I have time",
//     delivery_type3_text: "Between 8 to 30 days",

//     back: "Back",
//     desitnation_area: "Destination Area",
//     dropoff_address: "Dropoff Address",
//     continue_: "Continue",

//     announcements: "Announcements",
//     all: "All",
//     around_me: "Around Me",
//     online_announce: " online announcements",
//     accept: "Accept",
//     negotiate: "Negotiate",

//     update_info: "Update Information",
//     email_address: "Email Address",
//     my_profile: "My Profile",

//     transfer: "Transfer to a bank account",
//     your_wallet: "Your Wallet",
//     user_or_email: "Username or Email",
//     logging_in_text: "By logging in, you are agree to our",
//     terms_condition: "Terms & Conditions",
//     privacy_poligy: "Privacy & Policy",

//     welcome: "Welcome",
//     length: "Length",
//     width: "Width",
//     height: "Height",
//     choose_size: "Package Size",
//     when_do_you_want_your_parcel_delivered: "Estimated delivery day",
//     make_offer: "Make an offer",
//     confirm: "Confirm",
//     enter_offer_price: "Enter offer price",
//     offer_request_sent: "Your offer has been sent to the Sender! 🎉",
//     thanks_frank: "Thanks Frank",
//     documents: "Documents",
//     notification: "Notifications",
//     change_password: "Change Password",
//     all_offers: "All Offers",
//     announcement_details: "Announcement Details",
//     frank_storage: "Storage",
//     explore: "Explore",
//     shop: "Shop",

//     camera: "Camera",
//     gallery: "Gallery",
//     cancel: "Cancel",
//     quantity: "Quantity",
//     pickup_place_area: "Pick up place area",
//     recipient_name: `Reipient's name`,

//     delivery_cost: "Delivery Cost",
//     item_sizes: "Item & Sizes",
//     type_of_delivery: "Delivery Type",
//     sender_2: "Sender",
//     sender_details: "Sender Details",
//     language: "Language",
//     general_cond: "General Conditions",
//     payments: "Payments",
//     how_many_persons: "De combien de personnes en plus avez-vous besoin ?",
//     pickup_date: "Pickup Date",
//     delivery_date: "Delivery Date",
//     floor: "Floor",
//     elevator: "Elevator",
//     other_item: `Other Item?`,
//     quotation: "Quotation",
//     person: "Person",
//     elevator_available: "Is there an elevator available?",
//     yes: "Yes",
//     no: "No",
//     dont_need_person: `Don't need person`,
//     frank_shop: "Frank Shop",
//     particular: "Particular",
//     professional: "Professional",
//     company_name: "Company Name",
//     shop_desc: "Description of your company",
//     comp_address: "Address of your company",
//     category: "Category",
//     categories: "Categories",
//     add_logo: `Add Company Logo`,
//     add_banner: `Add Company Banner`,
//     frank_shop_2: "Frank Shop",
//     forgot_password_desc:
//       "Forgot your password? No problem! Simply enter your email address to reset it in a few easy steps.",
//     prod_title: "Title",
//     prod_desc: "Product Description",
//     prod_color: "Color",
//     prod_price: "Price",
//     prod_contition: "Condition",
//     optional: "Optional",
//     close: "Close",
//     not_verified_shop_message:
//       "Your request for the Frank store has not yet been approved. We will get back to you in less than 24 hours.",
//     cart: "Cart",
//     track: "Track",
//     total: "Total",
//     service_charges: "Platform Fee",
//     particular_relocation_desc: "Anyone can deliver your package",
//     pro_relocation_desc: "Coming Soon",
//     summary: "Summary",
//     deliver_order: `Deliver Order`,
//     pick_order: `Pick Order`,
//     would_like_to: "You would like to",
//     proposed_price_placeholder: "Enter proposed price",
//     frank_recommended_price: "Frank's recommended price is between ",
//     recommended_price_error: `You cannot enter an amount less than Frank's suggested price`,
//     minimum_error: "You cannot enter an amount minimum of 1€",
//     maximum_price_error: `You cannot enter an amount maximum of 9,999€`,
//     reviews: "Reviews",
//     messages: "Messages",
//     brand: "Brand",
//     used_condition: "Condition",
//     inactive_shop:
//       "Your shop is inactivated by Frank, please contact support for assistance",
//     add_logo_particular_shop: "Add Photo to your Shop",
//     search_product: "Search Products",
//     // new translation
//     success: "Success",
//     error: "Error",
//     try_again: "Try Again",
//     email_registered: "Email Registered Successfully",
//     title_error: "Title Should not be Empty",
//     first_name_error: "Firstname Should not be Empty",
//     surname_error: "Surname Should not Be Empty",
//     email_error: "Email Should not be left blank or empty",
//     success_login: "Successfully Logged In!!",
//     internal_error: `Sorry, we're experiencing technical difficulties. Please try again later. If the problem persists, please contact support for assistance`,

//     network_failed: "Network Failed",
//     network_failed_error:
//       "Unable to proceed. Please check your internet connection and try again.",
//     apple_auth_error:
//       "Unable to authenticate with Apple, For more information please contact us support@frankme.com",
//     facebook_auth_error: `Unable to authenticate with Facebook, please try again later. If problem occurs then try contact us at support@frankme.com`,
//     user_cancelled_proccess: "User cancelled the authentication process",
//     signin_with: "Sign in with ",
//     valid_email: `Email should be a valide email`,
//     password_error: "Password should not be empty",
//     user_not_exists: `User not Exists`,
//     mr: "Mr.",
//     mrs: "Mrs.",
//     ms: "Ms",
//     personal_info_msg: `Let's get to know you! Please enter your first name, Surname, and Birthdate`,
//     profile_added: "Profile Details Added Successfully",
//     user_name_error: "Username Should not Be Empty",
//     passowrd_not_match: `Password and confirm Password not matched.`,
//     retype_password: "Please Re-type your password",
//     set_credentials_success: "Login credentials Have Been Set Successfully",
//     password_info: `The Password must be a minimum of 8 characters and include the following requirements`,

//     length_desc: `Passwords should be at least 8 characters long`,
//     uppercase_letter: `Uppercase Letters: `,
//     uppercase_letter_desc: `Include at least one uppercase letter(A-Z).`,
//     lower_letter: `Lowercase Letters: `,
//     lower_letter_desc: `Include at least one lowercase letter (a-z).`,
//     digits_letter: `Digits: `,
//     digits_letter_desc: `Include at least one number (0-9)`,
//     special_letter: `Special Characters: `,
//     special_letter_desc: `Iincorporate at least one Special Character, Such as @,#,!,%,^,&,*,(,).`,
//     too_weak: "Too Weak",
//     weak: "Weak",
//     moderate: "Moderate",
//     strong: "Strong",
//     very_strong: "Very Strong",
//     phone_number_error: `Phone Number Should not be Empty`,
//     otp_sent_msg: `A One Time Passcode (OTP) Has Been sent to your Provided Number. Please Enter the otp here`,
//     phone_shared: `Your Phone number will be shared with the delivery company or sender so they can arrange the pickup and delivery of your package.`,
//     otp_empty_err: `One Tiem Passcode (OTP) Cannot Be left Blank or Emptied`,
//     otp_validated: `One Time Passcode (OTP) Has Been Successfully Validated. Now You can Login to your Account.`,
//     four_digit_code: `We've Sent a 4-digit code to `,
//     enter_otp_email: `Enter the One-Time-Passcode (OTP) that has been sent to `,
//     valid_otp: `Please enter a valid OTP`,
//     wrong_or_expored_otp: `The OTP has either expired or does not match. Please request a new otp or try Again`,
//     valid_otp_mesg:
//       "OTP Validated successfully, Please Enter your new password",
//     password_reset_mesg: `Your Password Has been Successfully reset! You can now log in with your new password`,
//     incorrect_credentials: "Incorrect User name or password",
//     add_address: `Add Address`,
//     address_saved_msg: `Address Saved to profile Successfully`,
//     id_verification: "ID Verification",
//     your_verification_status: `Your verification status is `,

//     idenfy_head_1_desc:
//       "To Ensure Rapid Validations of documents send photos or scans.",
//     idenfy_head_1: "In Color: ",
//     idenfy_head_2: "Complete: ",
//     idenfy_head_3: "eadable and of good Quality: ",
//     idenfy_head_2_desc: "Black and white documents will not be accepted.",
//     idenfy_head_3_desc:
//       "All Pages are present no information is hidden or truncated",
//     idenfy_head_4_desc:
//       "Neither Blurry.not dark, not damaged and without reflection.",
//     start_verification: "Start Verification",
//     enable_notif: "Enable Notification",
//     enable_notif_desc: `Stay Updated with the latest news, and updates by enabling notifications! Click on the button below to turn on notifications and never miss out on important information and updates`,
//     change: "Change",
//     view: "View",
//     create_shop: `Create your Frank Shop`,
//     manage_shop: "Manage Shop",

//     add_product: "Add Products",
//     manage_product: `Manage Product`,
//     view_shop_orders: "View Shop Orders",

//     ind_shop_text: `Limited Categories and Yearly sale upto 2500€`,
//     pro_shop_text: `Categories and yearly sale Unlimited `,
//     products: "Products",
//     excellent: "Excellent",
//     good: "Good",
//     average: "Average",
//     below_average: "Below Average",
//     poor: "Poor",
//     based_on: `based on`,
//     overall_rating: "Overall Rating",
//     add_to_cart: "Add to Cart",
//     item_added: "Item added to cart",
//     cart_details: "Cart Details",
//     checkout: "Checkout",
//     empty_cart: "Empty Cart",

//     checkout_confirm: "Are you Sure you want to Checkout?",
//     remove_item_confirm: "Are you Sure want to remove this item from the Cart?",
//     clear_cart_confirm: "Are you Sure you want to clear your cart?",
//     no_items_cart: "There is not items in Cart",
//     start_shipping: "Start Shopping",
//     free: "Free",
//     shipping: "Shipping",
//     expected_delivery: "Expected Delivery",
//     select_deliery_method: "Please Select Delivery Method",
//     warning: "Warning",
//     order_summary: "Order Summary",
//     get_ur_order: "You will get your order in Between",

//     continue_payment: "Continue to Payment",
//     agree_by_order: `By Clicking Placing an order you agree to terms of use and Privacy Policy`,
//     confirm_submit_order: "Please Confirm and submit your order",
//     order_placed: "Your order has been placed successfully",
//     leave_checkout_msg: `No Worries! You can review your cart items before completing order.Press "Back" to return.`,
//     leave_checkout: "Leaving Checkout ?",
//     upload_6_image: `You Can Upload Upto only 6 Photos`,
//     remove_item: `Remove item`,
//     select_size: "Please Select the parcel size",
//     upload_image: "Please Upload at least 1 image",
//     transporter_name: "Transporter Name",

//     delivery_otp_code:
//       "Give this code to your rider once you received the order.",
//     delivery_otp_code_desc:
//       "Please ensure this code is only disclosed to your delivery rider",
//     small: "Small",
//     medium: "Medium",
//     large: "Large",
//     xl: "Extra Large",
//     your_announcements: "Your Announcement",

//     declined: "Declined",
//     decline: "Decline",
//     send_message: "Send a message",
//     handling_charges: "Handling Charges",
//     sub_total: "Subtotal",
//     earned: "Earned",
//     view_details: "View Details",
//     no_item_found: "No item found",
//     select_product: "Select Product",
//     search_item: "Search item or product",
//     choose_payment_method: "Choose payment method",

//     call: "Call",

//     male: "Male",
//     female: "Female",
//     other: "No Gender",
//     un_paid: "Unpaid",
//     address_error: `Address should not be empty`,
//     shop_category_error: `Please select shop category`,
//     siret_error: `SIRET should not be empty`,
//     company_name_error: "Company name should not be empty",

//     product_name_error: "Product Title should not be empty",
//     product_price_error: "Product Price should not be 0€",
//     product_category_error: "Product Category should not be left blank",
//     product_image_error: "Please uplaod atlease 1 product image",
//     item_name_error: "Please enter the name or title for Product / Item",
//     quantity_error: "Please enter the quantity",
//     marked_read: "Marked as Read",

//     order_accept_msg: `Did you already pick up the order?`,
//     order_deliver_msg: `Are you sure you want to Deliver this Order?\nOnce order is delivered it cannot be reverted!`,

//     pickup_otp_message: `Please ask the customer for the OTP to confirm the pickup.\nEnter the OTP below to complete the process`,
//     delivery_otp_message: `Please ask the customer for the OTP to confirm the delivery.\nEnter the OTP below to complete the process`,
//     otp_confirmation: "OTP Confirmation Required",
// pickup_otp_for_transporter:`The OTP you entered is not valid, please ask customer to provide you a valid OTP!`,
//     enter_o_password: "Please enter your old Password",
//     password_changed_seccess: `Password changed successfully, Please log in again with your new password`,
//     enter_n_password: "Please enter your new password",
//     leave_feedback: `Leave Feedback`,
//     cust_feedback_err: `Customer Rating is required in case of send feedback`,

//     out_of_range: "Delivery area is out of range",
//     expected_deliver_dates: `Please select expected delivery dates`,
//     order_amt: "Order Amount",
//     messages_: "Messaging",
//     pay_with: `Pay with `,
//     relocation_announce: "Relocation",
//     track_order: "Track Order",
//     tracking_number: `Tracking Number`,
//     update_shop: "Update Shop",
//     open_setting: "Open Settings",
//     done: "Done",
//     payment_cancel: "You have cancelled the payment authorization process.",
//     sub_category: "Sub category",
//     I_ACCEPT_FRANK_TERMS_CONDITION:
//       "J'accepte les conditions générales d’utilisation de Frank et avoir au moins 18 ans.",
//     I_AGREE_LATEST_UPDATED_FRANK: "J’accepte  de recevoir l’actualité de Frank.",
//     All_announes: "All",
//     Express_announces: "Express",
//     ArountMe_announces: "Around Me",
//     Forgot_password_title:'Forgot Password'
//   },
  fr: {
    //terms and condition and privacy policy

     
      privact_policy: "Politique de Confidentialité",
      intoduction: "Introduction",
      intro_para_1:
        "Cette Politique de Confidentialité décrit comment PEOPLES POST (FRANK, nous, notre ou nos) collecte, utilise, divulgue et protège vos informations personnelles lorsque vous utilisez notre plateforme de services de transport multimodal, accessible via notre site web et notre application mobile (collectivement, la Plateforme).",
      intro_para_2:
        "En accédant ou en utilisant la Plateforme, vous acceptez les termes de cette Politique de Confidentialité. Si vous n'acceptez pas ces termes, veuillez ne pas utiliser la Plateforme.",
      defination: "1. Définitions",
      defination_desc:
        "Les termes suivants ont la signification indiquée dans cette Politique de Confidentialité lorsqu'ils sont écrits avec une majuscule :",
      def_point_1:
        "●	Annonce : La proposition de transport publiée sur la Plateforme par un Utilisateur, qu'il s'agisse d'un Annonceur ou d'un Partenaire, pour effectuer un Transport, un Service, ou une Garde.",
      def_point_2:
        "●	Annonceur : Le commerçant , le professionnel ou le particulier utilisant la Plateforme pour publier des Annonces afin d'expédier, garder ou recevoir des biens.",
      def_point_3:
        "●	Application: L'application mobile développée et mise à disposition par FRANK, disponible sur les plateformes ",
      def_point_4:
        "●	Compte : L'espace personnel et sécurisé qu'un Utilisateur crée et utilisé pour accéder aux services de la Plateforme.",
      def_point_5:
        "●	Donnée personnelle : Toute information relative à une personne physique identifiée ou identifiable, collectée par la Plateforme.",
      def_point_6:
        "●	Partenaire : Toute entité ou individu ayant signé un contrat avec FRANK pour utiliser ses API et publier des Annonces sous son propre nom.",
  
      def_point_7:
        "●	Plateforme : La solution numérique opérée par FRANK, accessible via le Site web ou l'Application mobile, permettant la mise en relation pour des services de transport.",
      def_point_8:
        "●	Service : L'ensemble des fonctionnalités et services de mise en relation offerts par la Plateforme aux Utilisateurs.",
      def_point_9:
        "●	Site : Le site Internet officiel www.frankme.com, y compris tous ses sous-domaines associés.",
      def_point_10:
        "●	Transporter : Toute personne ou entreprise professionnelle utilisant la Plateforme pour fournir des services de transport de biens moyennant une rémunération.",
      def_point_11:
        "●	Utilisateur : Toute personne accédant et utilisant la Plateforme, qu'il s'agisse de commerçants, particuliers, transporteurs, partenaires ou autres.",
      def_point_12:
        "●	Keeper : Toute personne, professionnelle ou non, utilisant la Plateforme pour offrir des services de garde et/ou d'entreposage de biens contre rémunération.",
      def_point_13:
        "●	Sender : Toute personne, professionnelle ou non, utilisant la Plateforme pour envoyer des biens ou colis, sans rémunération, sauf partage des frais de transport.",
  
      information_1: "2. Informations Que Nous Collectons",
      info_intro_1:
        "Nous collectons les informations personnelles que vous nous fournissez directement lorsque vous vous inscrivez pour un compte, publiez des annonces, communiquez avec d'autres utilisateurs ou utilisez nos services. Ces informations peuvent inclure :",
  
      info_value_1: "●	Nom",
      info_value_2: "●	Adresse e-mail",
      info_value_3: "●	Numéro de téléphone",
      info_value_4: "●	Adresse postale",
      info_value_5: "●	Informations de paiement",
      info_value_6:
        "●	Informations de profil (par exemple, photo de profil, biographie)",
      info_value_7: "●	Contenu des communications avec d'autres utilisateurs",
  
      information_2: "2.2 Informations que Nous Collectons Automatiquement",
      info_intro_2:
        "Lorsque vous utilisez la Plateforme, nous pouvons automatiquement collecter certaines informations sur votre appareil et votre utilisation de la Plateforme, y compris :",
  
      info_2_value_1: "●	Adresse IP",
      info_2_value_2: "●	Type et version du navigateur",
      info_2_value_3: "●	Système d'exploitation",
      info_2_value_4: "●	Informations sur l'appareil",
      info_2_value_5:
        "●	Données de journal (par exemple, heures d'accès, pages vues, clics)",
  
      information_3: "2.3 Informations provenant de Tiers",
      info_intro_3:
        "Nous pouvons recevoir des informations vous concernant de la part de tiers, tels que des services de vérification d'identité et des bases de données publiques, pour nous aider à vérifier votre identité et à respecter les exigences légales.",
  
      Use_of_information: "3.Utilisation des Informations",
      use_of_info_intro:
        "Nous utilisons vos informations personnelles pour diverses finalités, notamment :",
  
      use_of_info_desc_1: "●	Fournir, maintenir et améliorer nos services",
      use_of_info_desc_2:
        "●	Traiter les transactions et envoyer les informations relatives, telles que les confirmations et les factures",
      use_of_info_desc_3:
        "●	Communiquer avec vous au sujet des produits, services, offres et événements",
      use_of_info_desc_4:
        "●	Surveiller et analyser les tendances, l'utilisation et les activités en lien avec nos services",
      use_of_info_desc_5:
        "●	Détecter, enquêter et prévenir les transactions frauduleuses et autres activités illégales",
      use_of_info_desc_6:
        "●	Respecter les obligations légales et protéger nos droits",
  
      sharing_of_information_title: "4. Partage des Informations",
      sharing_of_information_intro:
        "Nous pouvons partager vos informations personnelles avec :",
      sharing_of_information_desc_1:
        "●	Fournisseurs de Services : Des prestataires tiers qui exécutent des services en notre nom, tels que le traitement des paiements, l'analyse des données, la livraison des e-mails, les services d'hébergement et le service client.",
      sharing_of_information_desc_2:
        "●	Autres Utilisateurs : Lorsque vous interagissez avec d'autres utilisateurs sur la Plateforme, certaines informations peuvent être partagées avec eux, telles que votre nom, vos coordonnées et votre historique de transactions.",
      sharing_of_information_desc_3:
        "●	Conformité avec les Lois : Pour respecter les obligations légales, répondre aux demandes des autorités publiques et gouvernementales, et protéger nos droits et intérêts.",
      sharing_of_information_desc_4:
        "●	Transferts d'Entreprise : Dans le cadre d'une fusion, d'une vente, d'une acquisition ou d'une autre transaction commerciale, nous pouvons transférer vos informations personnelles aux parties impliquées.",
  
      security_title: "5. Sécurité",
      security_intro:
        "Nous prenons des mesures raisonnables pour protéger vos informations personnelles contre l'accès, l'utilisation, la divulgation, la modification et la destruction non autorisés. Ces mesures incluent le chiffrement, les contrôles d'accès et le stockage sécurisé des données.",
  
      your_rights: "6. Vos Droits",
      your_rights_intro:
        "Vous avez certains droits concernant vos informations personnelles, y compris :",
  
      your_rights_desc_1:
        "●	Le droit d'accéder, de corriger, de mettre à jour ou de supprimer vos informations personnelles.",
      your_rights_desc_2:
        "●	Le droit de restreindre ou de vous opposer à notre traitement de vos informations personnelles.",
      your_rights_desc_3:
        "●	Le droit de recevoir une copie de vos informations personnelles dans un format structuré, couramment utilisé et lisible par machine.",
      your_rights_desc_4:
        "●	Le droit de retirer votre consentement à tout moment, lorsque nous nous basons sur votre consentement pour traiter vos informations personnelles.",
  
      your_rights_desc_5:
        "Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : info@frankme.com.",
  
      data_retentions_title: "7. Conservation des Données",
      data_retentions_intro:
        "Nous conservons vos informations personnelles aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels nous les avons collectées, y compris pour respecter les obligations légales, comptables ou de rapport. Si vous supprimez votre compte, nous pouvons conserver certaines informations comme requis par la loi ou pour des fins commerciales légitimes.",
  
      international_data_transfer_title:
        "8. Transferts Internationaux de Données",
      international_data_transfer_intro:
        "Vos informations personnelles peuvent être transférées et traitées dans des pays autres que le vôtre. Nous veillons à ce que ces transferts soient effectués en conformité avec les lois de protection des données applicables et que des garanties appropriées soient en place pour protéger vos informations personnelles.",
  
      changes_to_privacy_policy_title:
        "9. Modifications de cette Politique de Confidentialité",
      changes_to_privacy_policy_intro:
        "Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre pour refléter les changements dans nos pratiques ou les exigences légales. Nous vous informerons de tout changement significatif en publiant la Politique de Confidentialité mise à jour sur la Plateforme et en indiquant la date de la dernière révision. Votre utilisation continue de la Plateforme après la date d'effet de la Politique de Confidentialité révisée constitue votre acceptation des modifications.",
  
      contact_us_title: "10. Contactez-nous",
      contact_us_intro_1:
        "Si vous avez des questions ou des préoccupations concernant cette Politique de Confidentialité ou nos pratiques en matière de confidentialité, veuillez nous contacter à l'adresse suivante :",
      contact_us_desc_1:
        "FRANK - Peoples Post 22 rue Emeriau, 75008 Paris Email : [insérer adresse e-mail de contact de l'entreprise]",
      contact_us_desc_2:
        "Cette Politique de Confidentialité est intégrée et fait partie des Conditions Générales d'Utilisation du Service FRANK. En utilisant la Plateforme, vous acceptez les termes et conditions énoncés dans cette Politique de Confidentialité et les CGU.",
  
      idditional_provisions_title: "11. Dispositions Supplémentaires",
      additional_provisions_title_1: "11.1 Mesures de Sécurité des Informations",
      additional_provisions_title_intro_1:
        "Pour assurer la sécurité de vos informations personnelles, nous mettons en œuvre les mesures suivantes :",
      additional_provisions_desc_1:
        "●	Mises à jour régulières des logiciels et du matériel",
      additional_provisions_desc_2:
        "●	Pare-feu et systèmes de détection des intrusions",
      additional_provisions_desc_3:
        "●	Transmission de données chiffrées (SSL/TLS)",
      additional_provisions_desc_4:
        "●	Contrôles d'accès sécurisés et procédures d'authentification",
  
      additional_provisions_title_2: "11.2 Protection des Enfants",
      additional_provisions_title_intro_2:
        "La Plateforme n'est pas destinée aux enfants de moins de 18 ans. Nous ne collectons pas sciemment d'informations personnelles auprès d'enfants de moins de 18 ans. Si nous prenons connaissance qu'un enfant de moins de 18 ans nous a fourni des informations personnelles, nous prendrons des mesures pour supprimer ces informations.",
  
      additional_provisions_title_3: "11.3 Responsabilités de l'Utilisateur",
      additional_provisions_title_intro_3:
        "Les utilisateurs sont responsables de la confidentialité de leurs informations de compte, y compris leur nom d'utilisateur et leur mot de passe. Les utilisateurs doivent nous notifier immédiatement s'ils soupçonnent une utilisation non autorisée de leur compte ou toute autre violation de sécurité.",
  
      additional_provisions_title_4:
        "11.4 Notification en Cas de Violation de Données",
      additional_provisions_title_intro_4:
        "En cas de violation de données compromettant vos informations personnelles, nous vous en informerons ainsi que les autorités de surveillance compétentes conformément aux lois applicables en matière de protection des données.",
  
      additional_provisions_title_5: "11.5 Liens vers des Sites Tiers",
      additional_provisions_title_intro_5:
        "La Plateforme peut contenir des liens vers des sites web ou des ressources tiers. Nous ne sommes pas responsables des pratiques de confidentialité ou du contenu de ces sites tiers. Nous vous encourageons à consulter les politiques de confidentialité de tout site tiers que vous visitez.",
  
      additional_provisions_title_6: "11.6 Résolution des Litiges",
      additional_provisions_title_intro_6:
        "Si vous avez des plaintes concernant notre conformité à cette Politique de Confidentialité, veuillez nous contacter en premier lieu. Nous enquêterons et tenterons de résoudre les plaintes et les litiges concernant l'utilisation et la divulgation des informations personnelles conformément à cette Politique de Confidentialité. Si vous n'êtes pas satisfait de notre réponse, vous pouvez avoir le droit de déposer une plainte auprès de l'autorité de protection des données de votre juridiction.",
  
      additional_provisions_title_7:
        "11.7 Base Légale pour le Traitement des Données Personnelles",
      additional_provisions_title_intro_7:
        "Nous traitons vos données personnelles sur la base des motifs légaux suivants :",
  
      additional_provisions_title_desc_7_1: "●	Votre consentement",
      additional_provisions_title_desc_7_2:
        "●	La nécessité d'exécuter un contrat avec vous",
      additional_provisions_title_desc_7_3: "●	Le respect d'une obligation légale",
      additional_provisions_title_desc_7_4:
        "●	Nos intérêts légitimes, à condition qu'ils ne prévalent pas sur vos droits et libertés",
  
      additional_provisions_title_8: "11.8 Délégué à la Protection des Données",
      additional_provisions_title_intro_8:
        "Nous avons nommé un Délégué à la Protection des Données (DPD) pour superviser la conformité à cette Politique de Confidentialité. Si vous avez des questions ou des préoccupations concernant nos pratiques en matière de protection des données, vous pouvez contacter le DPD à l'adresse suivante :",
  
      additional_provisions_title_8_desc:
        "Délégué à la Protection des Données Frank Email : info@frankme.com",
  
      additional_provisions_title_9: "11.9 Mises à Jour et Amendements",
      additional_provisions_title_intro_9:
        "Nous pouvons mettre à jour cette Politique de Confidentialité pour refléter les changements dans nos pratiques, les exigences légales ou les besoins opérationnels. Nous vous informerons de tout changement significatif en publiant la Politique de Confidentialité mise à jour sur la Plateforme et en indiquant la date de la dernière révision. Votre utilisation continue de la Plateforme après la date d'effet de la Politique de Confidentialité révisée constitue votre acceptation des modifications.",
  
      cookies_title: "12. Cookies",
      cookies_intro_1:
        "Certains cookies utilisés par la Plateforme nécessitent votre consentement préalable. Par exemple, nous utilisons Google Analytics pour mesurer et analyser les données d'audience : nombre de visites sur le Site, nombre de pages vues, activité, ville de connexion de l'Utilisateur, etc.",
      cookies_intro_2:
        "Lors de votre première visite sur le Site, une bannière vous informe de la présence de ces cookies et vous invite à indiquer si vous acceptez ou refusez leur utilisation. Votre choix est conservé pendant six mois, sauf si vous le modifiez ou videz le cache de votre navigateur entre-temps.",
      cookies_intro_3:
        "Pour plus d'informations, veuillez consulter notre Politique relative aux Cookies accessible depuis la bannière.",
  
      mooderation_of_messages_title: "13. Modération des Messages",
      mooderation_of_messages_intro:
        "Nous pouvons examiner vos conversations avec d'autres Utilisateurs pour les finalités suivantes :",
  
      mooderation_of_messages_desc_1: "●	Assistance, avec votre consentement",
      mooderation_of_messages_desc_2:
        "●	Prévention des fraudes : Nous avons des systèmes automatisés pour analyser les messages échangés afin de nous assurer qu'ils ne contiennent pas de coordonnées, empêchant ainsi les Utilisateurs de contourner notre système de réservation en ligne.",
  
      services_provides_by_third_party_title: "14.Services Fournis par des Tiers",
      services_provides_by_third_party_intro_1:
        "Pour faciliter votre inscription ou connexion à notre Site, vous pouvez vous authentifier via un compte d'un réseau partenaire, tel que 'Connexion par Facebook' si vous avez un compte Facebook. Cela permet de pré-remplir automatiquement votre formulaire d'inscription FRANK avec les informations que vous avez déjà fournies à Facebook.",
      services_provides_by_third_party_intro_2:
        "Nous pouvons collecter des informations lorsque vous naviguez sur nos pages de Réseaux Sociaux ou utilisez leurs fonctionnalités d'authentification. Nous proposons Facebook Connect, Apple Connect et Google Connect.",
  
      retention_period_title: "15. Période de Conservation",
      retention_period_intro_1: "15.1 Gestion de la Relation Commerciale",
      retention_period_intro_1_intro:
        "Vos données personnelles ne seront pas conservées au-delà de la durée nécessaire à la gestion de notre relation commerciale avec vous. Toutefois, les données nécessaires pour établir la preuve d'un droit ou d'un contrat seront conservées comme requis par la loi.",
  
      retention_period_intro_2: "15.2 Documents d'Identité",
      retention_period_intro_2_intro:
        "Les documents d'identité sont transmis directement à notre prestataire bancaire Stripe, de manière cryptée et sécurisée, pour vérification d'identité. Ils ne sont pas stockés sur les serveurs de FRANK.",
  
      retention_period_intro_3: "15.3 Données de Carte Bancaire",
      retention_period_intro_3_intro:
        "Les transactions financières sont gérées par un prestataire de services de paiement qui assure leur bonne exécution et sécurité. Ce prestataire peut avoir besoin de vos données personnelles relatives à vos numéros de cartes bancaires, qu'il collecte et conserve en notre nom et pour notre compte. Nous n'avons pas accès à ces données.",
  
      retention_period_intro_4: "15.4 Gestion des Listes d'Opposition",
      retention_period_intro_4_intro:
        "Les informations pour prendre en compte votre droit d'opposition sont conservées pendant au moins trois ans à partir de l'exercice du droit d'opposition.",
  
      retention_period_intro_5: "15.5 Statistiques de Mesure d'Audience",
      retention_period_intro_5_intro:
        "Les informations stockées dans les dispositifs des utilisateurs ou tout autre élément utilisé pour identifier les utilisateurs et permettre leur traçabilité ou leurs visites ne seront pas conservées au-delà de six mois.",
  
      user_rights_title: "16. Droits des Utilisateurs",
      user_rights_intro:
        "Vous disposez des droits suivants concernant vos informations personnelles, conformément à la législation sur la protection des données :",
      user_rights_desc_1:
        "●	Droit d'Accès : Obtenir une copie de vos informations personnelles que nous détenons.",
      user_rights_desc_2:
        "●	Droit de Rectification : Demander la correction des informations personnelles inexactes.",
      user_rights_desc_3:
        "●	Droit à l'Effacement : Demander la suppression de vos informations personnelles, sous réserve qu'elles ne soient plus nécessaires aux fins décrites à l'article 5.",
      user_rights_desc_4:
        "●	Droit d'Opposition : Vous opposer au traitement de vos informations personnelles pour des raisons liées à votre situation particulière, si le traitement est basé sur nos intérêts légitimes. Vous opposer au traitement de vos informations personnelles à des fins de prospection à tout moment.",
      user_rights_desc_5:
        "●	Droit à la Limitation : Demander la suspension du traitement de vos informations personnelles dans certains cas.",
      user_rights_desc_6:
        "●	Droit à la Portabilité des Données : Recevoir vos informations personnelles dans un format structuré, couramment utilisé lorsque le traitement est basé sur l'exécution d'un contrat ou votre consentement.",
  
      user_rights_intro_2:
        "Pour exercer ces droits, écrivez-nous à info@frankme.com avec l'adresse e-mail liée à votre compte FRANK.",
  
      changes_to_this_policy_title: "17. Modifications de cette Politique",
      changes_to_this_policy_intro:
        "Nous nous réservons le droit, à notre seule discrétion, de modifier cette Politique de Confidentialité à tout moment, en tout ou en partie. Ces modifications entreront en vigueur dès la publication de la nouvelle politique. Votre utilisation continue du site après la date d'effet de ces modifications vaut reconnaissance et acceptation de la nouvelle politique. Si cette nouvelle politique ne vous convient pas, vous ne devez plus accéder au site.",
  
      contact_information_title: "18. Coordonnées de Contact",
      contact_information_intro:
        "Pour toute question relative à cette Politique de Confidentialité, veuillez nous contacter à notre adresse dédiée : info@frankme.com.",
      // **********************************
  
      terms_and_conditions: "CONDITIONS GENERALES D UTILISATION DU SERVICE FRANK",
  
      terms_and_conditions_para_1:
        "La société PEOPLES POST (ci-après, « FRANK » ou la « Société ») édite une plateforme de services de transport multimodal, accessible via son site web et sous forme d’application mobile. Cette plateforme est destinée à (i) mettre en relation des personnes souhaitant transporter des colis, ou tout autre type d'objets autorisés, entre différents lieux, que ce soit à l’intérieur ou à l’extérieur du territoire français, (ii) offrir une variété de services d’expédition et de messagerie couvrant tous les modes de transport (routier, ferroviaire, aérien, fluvial, maritime) et modèles économiques (B2B, B2C, C2B, C2C, D2C). ",
  
      terms_and_conditions_para_2:
        "Les présentes Conditions Générales d’Utilisation du service en ce compris les annexes (ci-après, les « CGU ») ont pour objet de définir les modalités et conditions d’utilisation de la Plateforme FRANK par toutes les personnes ayant accès à celle-ci (ci-après désigné comme « Utilisateur »), ou souhaitant être mis en relation avec un autre « Utilisateur » pour lui confier le transport de tout objet et/ou pour stocker temporairement cet objet (ci-après « Services »).  ",
  
      terms_and_conditions_para_3:
        "Les présentes CGU sont conclues entre l’Utilisateur et la Société, individuellement désignée la « Partie », collectivement les « Parties ». ",
  
      terms_and_conditions_para_4:
        "Les CGU sont accessibles à tout moment par un lien direct sur le site et l’application. Elles peuvent être téléchargées en cliquant sur le lien suivant: [Télécharger les CGU en PDF].  ",
  
      terms_and_conditions_para_5:
        "Pour toute demande liée à l’utilisation de la Plateforme, l’Utilisateur est invité à adresser un e-mail à l’adresse suivante :  ",
  
      Article_1: "Article 1. Définition  ",
      Article_definition_para_1:
        "Les termes suivants ont la signification qui suit dans les CGU lorsqu’ils sont rédigés avec une majuscule. Utilisés au singulier comme au pluriel, ils ont la même signification.  ",
  
      Account: "Annonce",
      Account_des:
        "Désigne le message publié sur la Plateforme par un Utilisateur dans le but soit de solliciter des services de transport de Colis, de proposer des services de Cotransportage, soit d’offrir un espace de stockage. L'annonce comprend des détails tels que la nature et les dimensions du Colis, les exigences spécifiques de transport, la destination souhaitée, ainsi que le budget ou le prix proposé par le Client pour le transport. Cette publication vise à initier un processus de mise en relation avec les Transporteurs ou autres Utilisateurs de la plateforme, et peut évoluer en une commande formelle suite à l'acceptation par un Transporteur ou un autre Utilisateur intéressé par l'offre présentée. ",
      Additional_Customer_Benefits: "Avantages supplémentaires pour les Clients",
      Additional_Customer_Benefits_des:
        "Désigne les divers services disponibles pour les clients via FRANK, incluant, mais sans s'y limiter, la possibilité d'acheter des produits dans les mini-boutiques, l'option de louer des bannières publicitaires et l'accès potentiel futur aux services FRANK Care ainsi qu'à d'autres offres améliorées. Ces services apportent une valeur ajoutée et une commodité supplémentaire aux clients, leur permettant de bénéficier d'un éventail plus large d'options et de fonctionnalités.",
      Advertisement: "CGV",
      Advertisement_des:
        "Désigne les Conditions Générales de Vente du Transporteur professionnel.  ",
      Carrier: "Client",
      Carrier_des:
        "Désigne toute personne physique ou morale (un particulier, un professionnel du transport, un auto-entrepreneur, un prestataire de services) enregistrée ou non sur la Plateforme et utilisant les Services pour transporter un colis vers une destination.",
  
      Collection_point: "Colis  ",
      Collection_point_des:
        "Désigne les colis et/ou objets, et/ou articles, de quelque nature que ce soit, licites, qui sont transportés par un Transporteur vers une destination convenue d’un commun accord avec le Client, tels que les points de dépôt ou point d’enlèvement. ",
      Collection_point_des_2:
        "La liste non exhaustive des objets ne pouvant faire l’objet d’un transport figure à l’article 8 ; En tout état de cause le Transporteur est tenu d’une obligation de vérification du colis qu’il prend en charge et demeure seul responsable du Colis dont il a la garde",
  
      Company: "Compte",
      Company_desc:
        "Désigne l’interface personnelle que l’Utilisateur doit créer pour accéder aux Services sur la Plateforme.",
      Contribution: "Contribution   ",
      Contribution_des:
        "Désigne le montant de la contribution aux frais de transport et/ou aux coûts de stockage payés par le Client au Transporteur.  ",
      Cotransporter: "Cotransporteur",
  
      Cotransporter_des:
        "Désigne un particulier qui propose des services de transport de Colis dans le cadre d’un déplacement pour son propre compte et à titre non onéreux, excepté pour la seule contribution aux frais de transport et de stockage temporaire du Colis.  ",
      Customer: "Destinataire",
      Customer_des:
        "Désigne toute personne recevant le Colis transporté par le Transporteur à la demande du Client et confirmant la réalisation du Service. Le Destinataire n’est pas nécessairement un Utilisateur de la Plateforme.",
  
      Drop_off_point: "Fournisseur",
      Drop_off_point_des: `
            Désigne toute boutique en ligne qui propose des services à ses clients (via une marketplace ou une vente directe D2C) ou toute personne inscrite qui peut fournir son emplacement en transit (magasin e-commerçant ; magasin en vente directe D2C).
  
  Désigne également un espace Colis ou point relais Colis ou un magasin de gestion de Colis, c'est-à-dire une localisation provisoire entre l'origine et la destination du Colis où le Client et le Transporteur peuvent convenir d'un lieu de stockage provisoire pour les Colis.
  
  Tout Client ou Transporteur enregistré sur la plateforme peut proposer son emplacement en tant qu’espace de Colis.
  
            `,
  
      Packages: "Fournisseur de Stockage ",
      Packages_des_1:
        "Désigne l'entité professionnelle qui possède et gère les installations de stockage proposées par FRANK. Le Fournisseur de Stockage est responsable de l'entretien des unités de stockage ou des espaces de stockage, en garantissant leur disponibilité et leur sécurité pour les Utilisateurs qui louent ces espaces pour une courte ou longue durée. Cela inclut la mise à disposition de boxes de stockage pour un usage personnel et professionnel, ainsi que l'administration des contrats de location pour des durées déterminées ou indéterminées.",
      
      Pick_up_point: "Point de dépôt",
      Pick_up_point_des:
        "Désigne la destination convenue entre le Transporteur et le Client où le Transporteur doit livrer la marchandise au Consignataire. Les points de dépôt seront également utilisés par les Clients, les Transporteurs, les Destinataires et FRANK afin de déposer des colis à partir de ces points.",
      Platform: "Plateforme",
      Platform_des:
        "Désigne les applications FRANK développées par la Société. Ces applications comprennent l'application transporteur, l'application client et le site web. L'application et le site web incluront le transport, la logistique, le stockage, le fret et la consultance en informatique.",
  
      Professional_carrier: "Point de collecte",
      Professional_carrier_des:
        "Désigne le lieu convenu entre le Transporteur et le Client pour le dépôt et le ramassage du Colis. Les Points de Collecte sont utilisés par les Clients, les Transporteurs, les Destinataires et FRANK pour la collecte des Colis. De plus, les Points de Collecte peuvent servir de lieux désignés pour le dépôt et le ramassage gérés par des fournisseurs d'espace, où le regroupement des Colis se fait conformément aux meilleures pratiques de FRANK. Ce service de regroupement améliore l'efficacité du processus logistique en consolidant les Colis avant leur livraison ou distribution finale.",
      Profile: "Profil",
      Profile_des:
        "Désigne l’interface personnelle que l’Utilisateur doit créer pour accéder aux Services sur la Plateforme. ",
      Recipient_des_1:
        "Désigne toute personne recevant le Colis transporté par le Transporteur à la demande du Client et confirmant la réalisation du Service. Le Destinataire n’est pas nécessairement un Utilisateur de la Plateforme.",
      Recipient_des_2:
        "Refers to a company that professionally transports Parcels and/or items of any kind and whose services are offered on the Platform. ",
  
      Service: "Service  ",
      Service_des_1:
        "Désigne le service de transport de Colis et/ou d’articles ou objets de toute nature entre particuliers et/ou professionnels (B2B, B2C, C2B, C2C, D2C) à destination de particuliers et/ou de professionnels entre différents lieux, mis à disposition par la Société.  ",
      Service_des_2:
        "Dans le cadre du service, les Colis et/ou articles de divers Utilisateurs peuvent être regroupées pour être expédiées à destination, puis séparées pour la livraison.",
      Service_des_3:
        "Le service peut être utilisé par des Utilisateurs enregistrés avec un Compte de Profil ou par des Utilisateurs non enregistrés qui n’ont pas de Compte de Profil.  ",
      Storage: "Stockage",
      Storage_des:
        "Désigne le service offert par FRANK qui fournit aux Utilisateurs l'accès à des unités de stockage ou des espaces de stockage à louer, soit pour une courte durée, soit pour une longue durée. Ce service comprend la mise à disposition de boxes de stockage pour un usage personnel ou professionnel, permettant aux clients de stocker leurs biens mobiliers pour une durée déterminée ou indéterminée.",
  
      Storage_provider: "Fournisseur de stockage",
      Storage_provider_des:
        "Désigne le service offert par FRANK qui fournit aux Utilisateurs l'accès à des unités de stockage ou des espaces de stockage à louer, soit pour une courte durée, soit pour une longue durée. Ce service comprend la mise à disposition de boxes de stockage pour un usage personnel ou professionnel, permettant aux clients de stocker leurs biens mobiliers pour une durée déterminée ou indéterminée.",
  
      Supplier: "Supplier",
      Supplier_des_1:
        "Refers to any online shop that offers services to its customers (via a marketplace or D2C direct sales) or any registrant that can provide its location in transit (e-merchant shop; D2C direct sales shop).",
      Supplier_des_2:
        "Also refers to a Parcel space or Parcel relay point or a Parcel management shop, i.e. a temporary location between the origin and destination of the Parcel where the Customer and the Carrier can agree on a temporary storage location for the Parcel.",
      Supplier_des_3:
        "Any Customer or Carrier registered on the platform may offer their location as a Parcel space.",
  
      Terms_and_Conditions_of_Sale: "Transporteur professionnel ",
      Terms_and_Conditions_of_Sale_des:
        "D signe une entreprise transportant professionnellement des Colis et/ou articles de toute nature et dont les services sont propos s sur la Plateforme. ",
      User: "Utilisateur",
      User_des:
        "Désigne toute personne utilisant les Services, qu’il s’agisse d’un Client, d’un Transporteur, un Destinataire ou un Fournisseur.",
  
      Artice_2: "Article 2. Accès, acceptation et modification des CGU",
      Artice_2_1_head: "2.1 Conditions d’accès à la Plateforme et disponibilité   ",
      Artice_2_1_para_1:
        "La Plateforme et les Services qui y sont prévus sont destinés aux personnes majeures de +18 ans disposant de la pleine capacité juridique pour commander sur la Plateforme. Les utilisateurs mineurs doivent être autorisés par leur représentant légal pour pouvoir passer commande sur la Plateforme. La Société se réserve le droit de demander à tout Utilisateur un justificatif de son identité concernant son âge et l’autorisation du représentant légal. ",
      Artice_2_1_para_2:
        "Tout transporteur doit disposer d'un véhicule conforme aux normes de sécurité et adapté au transport du Colis, ainsi que de tous les documents légaux requis pour la conduite dudit véhicule, y compris un permis de conduire valide et une assurance responsabilité civile en cours de validité.",
  
      Artice_2_1_para_3:
        "La Société peut mettre en place une vérification de certaines informations afin de faciliter la fourniture du Service, de l'améliorer, et de prévenir ou détecter les fraudes.",
  
      Artice_2_1_para_4:
        "Tous les utilisateurs de la Plateforme acceptent de se conformer strictement aux présentes Conditions Générales d'Utilisation ainsi qu'à l'ensemble des lois applicables à leur activité sur la Plateforme. Cela inclut, sans s'y limiter, les réglementations nationales et internationales en vigueur concernant le transport, la sous-traitance, le cotransportage, ainsi que les normes relatives à la sécurité et à la prohibition de certains biens. La Société se réserve le droit de vérifier à tout moment le respect par les Utilisateurs des conditions d'utilisation et des lois applicables.  ",
      Artice_2_1_para_5:
        "En cas de non-respect avéré, après une vérification appropriée, l'Utilisateur pourra être interdit d'accès à la Plateforme. Préalablement à toute action d'interdiction temporaire ou permanente, l'Utilisateur concerné sera notifié des motifs de cette décision et pourra répondre ou fournir des explications. L'Utilisateur disposera d'un droit de recours contre toute décision d'interdiction. Les modalités de ce recours seront communiquées à l'Utilisateur en même temps que la notification de l'interdiction et devront être exercées dans un délai défini.",
      Artice_2_1_para_6:
        "En tout état de cause, la Société met en œuvre tous les moyens raisonnables à sa disposition pour maintenir la Plateforme accessible sept (7) jours sur sept et vingt-quatre (24) heures sur vingt-quatre (24). ",
      Artice_2_1_para_7:
        "Toutefois, l’accès à la Plateforme est fourni par la Société « en l’état » et « dans les limites de disponibilité ». L'accès à la Plateforme peut être interrompu ou limité en raison de maintenance nécessaire pour améliorer et/ou préserver la Plateforme, y compris les mises à jour logicielles et matérielles, sans que l’interruption ne donne lieu à une quelconque obligation ou indemnité. De même, en raison de circonstances imprévues telles que, mais sans s'y limiter, des défaillances techniques, des problèmes liés au réseau Internet, ou des cas de force majeure tels que des catastrophes naturelles, des grèves, des émeutes, des actes terroristes, ou toute autre situation échappant au contrôle raisonnable de la Société. La Société ne saurait être tenue responsable des interruptions ou des limitations d'accès à la Plateforme causées par de tels événements. Ainsi, bien que nous nous efforcions de minimiser de telles perturbations et de rétablir le service dans les plus brefs délais, la Société ne garantit pas une disponibilité sans interruption ou sans erreur de la Plateforme.",
      Artice_2_1_para_8:
        "Toutefois, l’accès à la Plateforme est fourni par la Société « en l’état » et « dans les limites de disponibilité ». L'accès à la Plateforme peut être interrompu ou limité en raison de maintenance nécessaire pour améliorer et/ou préserver la Plateforme, y compris les mises à jour logicielles et matérielles, sans que l’interruption ne donne lieu à une quelconque obligation ou indemnité. De même, en raison de circonstances imprévues telles que, mais sans s'y limiter, des défaillances techniques, des problèmes liés au réseau Internet, ou des cas de force majeure tels que des catastrophes naturelles, des grèves, des émeutes, des actes terroristes, ou toute autre situation échappant au contrôle raisonnable de la Société. La Société ne saurait être tenue responsable des interruptions ou des limitations d'accès à la Plateforme causées par de tels événements. Ainsi, bien que nous nous efforcions de minimiser de telles perturbations et de rétablir le service dans les plus brefs délais, la Société ne garantit pas une disponibilité sans interruption ou sans erreur de la Plateforme.",
        Artice_2_1_para_9:
        "Les Utilisateurs de la Plateforme sont invités à prendre les mesures nécessaires pour se prémunir contre les interruptions possibles lors de l'utilisation de la Plateforme, notamment en sauvegardant les informations importantes et en prévoyant des alternatives en cas d'indisponibilité du service.",
  
      Artice_2_2_head: "2.2 Inscription   ",
      Artice_2_2_para_1:
        "Toute inscription ou utilisation de la Plateforme ou commande d’un Service implique une acceptation obligatoire sans réserve ni restriction des présentes CGU. Lors de l’inscription, chaque utilisateur peut accepter expressément les présentes CGU en cochant la case prévue à cet effet. Cette action ne requiert pas de signature manuscrite. ",
  
      Artice_2_3_head: "2.3 Désinscription   ",
  
      Artice_2_3_para_1:
        "L’Utilisateur s’inscrit sur la Plateforme pour utiliser les Services pour une durée indéterminée. L’Utilisateur peut décider de clôturer définitivement son Profil à tout moment, de plein droit et sans formalité légale, en se désinscrivant via la page de configuration Profil soit en demandant la désinscription par e-mail à l’adresse suivante DPO@frankme.com. La désinscription prend effet immédiatement. Elle supprime automatiquement les données de l’Utilisateur et son Profil, néanmoins, l’Utilisateur doit achever les collectes, transports, cotransportage ou stockage en cours ou honorer tout engagement pris avant ladite résiliation.  ",
  
      Artice_2_4_head: "2.4 Profil d’Utilisateur",
  
      Artice_2_4_para_1:
        "L’accès à la Plateforme est gratuit et ouvert, grâce à un processus d’inscription qui permet l’ouverture d’un Profil Utilisateur y compris sans inscription. Le Profil peut être créé à l’aide du formulaire sur la Plateforme. L’Utilisateur sera tenu de fournir des informations personnelles, traitées conformément à la politique de confidentialité de la Société disponible sur la Plateforme au lien suivant : [insérer le lien à la politique de confidentialité].  ",
  
      Artice_2_4_para_2:
        "La Société peut effectuer d’autres types de vérification et de clarification nécessaires à l’utilisation de la Plateforme. L’Utilisateur garantit que toutes les informations qu’il a fournies dans le formulaire d’inscription sont exactes, à jour, sincères et non trompeuses. Il appartient donc à l’Utilisateur de communiquer toute modification des informations personnelles telle que l’adresse, le numéro de téléphone ou les informations de paiement. Toute inscription contenant des informations inexactes ne sera pas traitée Les informations telles que les mots de passe ou les noms d’utilisateur sont de nature confidentiels. Il est de la responsabilité de l’Utilisateur de prendre les mesures nécessaires pour assurer leur confidentialité. L’Utilisateur s’engage à ne pas divulguer son mot de passe et à informer la Société de tout accès non autorisée à son Profil. L’Utilisateur s’engage à utiliser son Profil à titre personnel et à ne permettre à aucun tiers de l’utiliser à sa place ou pour son compte, à moins qu’il n’en assume l’entière responsabilité. À défaut, la responsabilité de la Société ne saurait être engagée, et la Société se réserve le droit, si cela est justifié, de suspendre ou supprimer le Profil de l’Utilisateur.",
      Artice_2_5_head: "2.5 Acceptation et modification des CGU",
      Artice_2_5_para_1:
        "Avant toute inscription ou utilisation de la Plateforme vous reconnaissez avoir lu attentivement les présentes CGU. En cas de non-acceptation des CGU, l’utilisateur se doit de renoncer à l’accès à la Plateforme.  ",
      Artice_2_4_para_3:
        "Pour contribuer à l'amélioration continue de son fonctionnement et adapter son Service aux évolutions de son secteur, la Société se réserve le droit de modifier les caractéristiques et les fonctionnalités de la Plateforme. Cela inclut l'ajout, la suppression ou le remplacement de certaines caractéristiques. En raison de cette nécessité d'évolution, les CGU peuvent également être mises à jour régulièrement. Toute modification substantielle des CGU est applicable immédiatement après notification des Utilisateurs par e-mail. Les Utilisateurs peuvent résilier les CGU en cas de non-acceptation de leur évolution. Néanmoins, elles doivent achever les transports en cours ou honorer tout engagement pris avant la résiliation. ",
      Artice_3: "Article 3.Description du service",
  
      Artice_3_1_head: "3.1 Objet du service",
  
      Artice_3_1_para_1:
        "FRANK permet aux Clients de se faire livrer un Colis (expédition en magasin, expédition routière de Client à Client, expédition par train de Client à Client, expédition aérienne de Client à Client et/ ou Stockage ou toute autre option présente ou à venir) par un Transporteur soit en un lieu convenu d’un commun accord avec le Transporteur au moyen des échanges privés sur la Plateforme, soit en choisissant un lieu proposé par un Fournisseur. Selon les cas, entre le point de départ et le point de destination du Colis, les Services prévoient un ou plusieurs lieux de stockage temporaire du Colis. Ces emplacements sont sélectionnés en fonction d'une optimisation du transport, garantissant efficacité, sécurité et temps de transit minimal, tel que déterminé par FRANK.",
  
      Artice_3_2_head: "3.2 Annonces",
  
      Artice_3_2_para_1:
        "Les Clients et les Fournisseurs sont autorisés à publier des annonces sur la Plateforme, qui seront notamment accessibles aux Transporteurs. Il est essentiel que chaque annonce soit rédigée avec clarté et précision pour garantir une communication efficace. Les Clients doivent spécifiquement mentionner dans leurs annonces toute condition ou difficulté particulière liée au colis concerné, telle que des exigences de manipulation spéciale, des précautions supplémentaires requises, ou tout autre aspect nécessitant une vigilance accrue de la part du Transporteur. Cette divulgation vise à permettre aux Transporteurs de préparer de manière adéquate l'expédition et d'assurer un transport en toute sécurité. Les Fournisseurs proposant des espaces de stockage doivent indiquer clairement dans leurs annonces les informations importantes telles que l'adresse exacte du point de stockage, les horaires d'ouverture, ainsi que toute autre information pertinente qui pourrait affecter l'acheminement et la livraison des colis. Cette divulgation vise à permettre aux Transporteurs de préparer de manière adéquate l'expédition et d'assurer un transport en toute sécurité.",
      Artice_3_2_para_2:
        "Une fois l’Annonce publiée, l’Utilisateur peut recevoir des propositions de la part de Transporteurs. Chaque annonce d'un Utilisateur devient une commande dès acceptation par un Transporteur, formant ainsi un contrat. Cette commande s’accompagne d’un niveau d’engagement financier, représenté par un prix ou un budget préalablement déterminé sous la seule responsabilité du Client et qui correspond au montant maximum qu’il entend payer pour le transport du Colis. ",
      Artice_3_2_para_3:
        "Avant de répondre à une Annonce, il est obligatoire que le Transporteur ait complété toutes les informations requises dans son Profil Utilisateur, conformément aux dispositions des présentes Conditions Générales d'Utilisation (CGU). En répondant à une Annonce, le Transporteur s'engage à fournir les détails de sa proposition, y compris le jour et l'heure prévus pour la collecte et la livraison du Colis.",
      Artice_3_2_para_4:
        "En répondant à une Annonce, le Transporteur confirme sa compréhension et son acceptation des conditions spécifiques mentionnées par le Client. Il s'engage à respecter toutes les précautions indiquées pour le traitement et le transport du colis.",
      Artice_3_2_para_5:
        "Sans préjudice de la responsabilité des Transporteurs dans la vérification de tout Colis collecté, la Plateforme peut mettre en place une vérification pour s'assurer que les informations fournies dans les annonces et les profils des utilisateurs sont exactes et conformes aux standards de qualité requis. Tout manquement à ces obligations peut entraîner des mesures correctives, y compris, mais sans s'y limiter, la modification ou la suppression de l'annonce, ou des restrictions d'accès à la Plateforme pour les parties concernées.",
  
      Artice_3_3_head: "3.3 Mise en relation",
      Artice_3_3_para_1:
        "FRANK se limite à mettre en relation le Client et le Transporteur, le cas échéant le Client et le Transporteur avec un Fournisseur. FRANK ne fournit pas de service de transport, ni de stockage, dans le cadre de l’acheminement et de la livraison du Colis.  ",
      Artice_3_3_para_2:
        "Les prestations du Transporteur sont notamment régies par la loi, par toutes conditions particulières entre le Client et le Transporteur, ainsi que le cas échéant par les Conditions Générales de Vente de ce dernier, lesquelles doivent être acceptées par le Client avant de finaliser la Commande. Le Client reconnaît ainsi que, en dehors des conditions convenues d’un commun accord avec le Transporteur au moment de la commande, les conditions de Transport du Colis sont celles prévues aux CGV du Transporteur. En cas de transports entre deux particuliers, il est impératif que les deux parties respectent les obligations légales en vigueur, notamment celles relatives à la responsabilité civile et au transport de marchandises entre non-professionnels. Ces dispositions comprennent, sans s'y limiter, le respect des normes de sécurité, de confidentialité des informations échangées, et de l’intégrité des biens transportés.  ",
  
      Artice_3_3_para_3:
        "Suite à une Annonce, une ou plusieurs offre(s) de transport peuvent être générées automatiquement par la Société comme étant la ou les plus appropriée(s) puis soumise(s) à l’appréciation libre du Client. En conséquence, la Société ne pourra être tenue responsable du refus du Client d'accepter l'offre du Transporteur. Le Client demeurant également libre d'accepter ou refuser l’offre du Transporteur et retenir l'offre d'un autre Transporteur ou de choisir un autre lieu de livraison.",
  
      Artice_3_3_para_4:
        "Une fois que le Client accepte l’offre de Transport et que le Transporteur convient de prendre en charge le Colis du Client, les Utilisateurs déterminent ensemble les conditions de la collecte et de la livraison au moyen de messages privés sur la Plateforme.",
  
      Artice_3_3_para_5:
        "Vous reconnaissez et acceptez que dans le cas où plusieurs intermédiaires interviennent dans la livraison du Colis du Client, ou en cas de modification de l’itinéraire ou du mode de livraison, FRANK puisse intervenir en dernier recours pour faciliter le respect des engagements des Utilisateurs. ",
  
      Artice_3_3_para_6:
        "FRANK n’est partie ni à l’accord ou au contrat formé entre les Utilisateurs de la Plateforme, ni à tout accord établi entre les Utilisateurs de la Plateforme et un tiers. Le rôle de FRANK se limite à faciliter l’accès à son service ainsi qu’aux échanges entre les Utilisateurs. FRANK ne prend aucun engagement ni aucune responsabilité pour les relations contractuelles formées entre les Utilisateurs ou avec des tiers. ",
  
      Artice_4: "Article 4. Prix",
  
      Artice_4_para_1:
        "L’inscription, l’accès, la recherche, la publication et la consultation d’offres de Services sur la Plateforme sont gratuits à tout Utilisateur disposant d’un accès internet. Les coûts liés à l’accès la Plateforme relèvent de l’unique responsabilité de l’Utilisateur.  ",
      Artice_4_para_2:
        "L’utilisation des Services est payante dans les conditions mentionnées ci-après : ",
      Artice_4_1_head: "4.1. Prix",
      Artice_4_1_para_1:
        "Les prix affichés sur la Plateforme sont exprimés en Euros (€) et sont applicables en France métropolitaine, y compris la Corse. Ces prix comprennent toutes remises applicables, ainsi que les frais de douane, frais gouvernementaux, et autres charges de toute nature, en plus de la TVA en vigueur au moment de la commande.  ",
      Artice_4_1_para_2:
        "Les prix mentionnés sur la Plateforme, que ce soit dans une annonce ou lors des négociations entre Utilisateurs, sont présentés toutes taxes comprises (TTC). Ces prix incluent, le cas échéant, les frais de mise en relation, droits de douane, frais gouvernementaux, qui sont détaillés au moment du paiement par le Client. Les Transporteurs seront quant à eux clairement informés si le prix final à leur reverser est net de commission, permettant ainsi une transparence totale sur le montant effectivement versé après déduction des frais payables à FRANK. ",
  
      Artice_4_1_para_3:
        "En cas de modification du taux de TVA applicable, le prix des services sera ajusté en conséquence.",
  
      Artice_4_1_para_4:
        "Les offres promotionnelles sont valables pour la durée précisée et ne peuvent pas être combinées avec d'autres offres de réduction.",
  
      Artice_4_1_para_5:
        "Pour les utilisateurs situés hors de l'Union européenne, où l'Euro (€) n'est pas la monnaie locale, les prix seront affichés dans la monnaie locale en vigueur. Ces prix seront calculés sur la base du taux de change actuel au moment de la finalisation de la commande, et incluront toutes taxes et frais applicables dans le pays concerné. La monnaie locale affichée sera utilisée pour toutes les transactions financières liées à la commande.",
  
      Artice_4_2_head: "4.2 Montant de la commande",
      Artice_4_2_para_1:
        "Dans le cadre de l'utilisation de la Plateforme, le Client est tenu de définir un plafond financier pour le service de transport qu'il souhaite obtenir. Ce montant représente le prix maximum TTC que ce dernier est prêt à payer pour le transport du Colis, hors frais de service additionnels appliqués par FRANK. Ce plafond financier est clairement affiché dans l'annonce du Client.  ",
      Artice_4_2_para_2:
        "Les Transporteurs peuvent répondre à cette annonce en acceptant explicitement le montant proposé, en le refusant, ou en soumettant une contre-offre pour un montant supérieur. Le montant finalement convenu entre le Client et le Transporteur, ajouté aux frais de service de la Plateforme, constitue le coût total TTC que le Client s'engage à payer pour le transport du Colis. Il s’agit du prix de la commande, sauf disposition contraire de la loi spécifiquement en matière de transport des marchandises telle que prévue en Annexe 2. Pour prévenir les abus et garantir la qualité du service, ou le respect de réglementation applicable, FRANK se réserve un droit de modération.  ",
  
      Artice_4_3_head: "4.3. Frais de services",
      Artice_4_3_para_1:
        "Pour chaque transaction sur la Plateforme, la Société perçoit des frais de service correspondant à la mise en relation entre le Client et le Transporteur. Lorsque le Client réserve un transport auprès du Transporteur, le Client paie le montant convenu avec le Transporteur, majoré des frais de service, dans les conditions prévues ci-après. De plus, d'autres frais de service fournis par FRANK ou demandés par le Client, appelés Avantages Supplémentaires pour les Clients, peuvent également être facturés.",
  
      Artice_4_3_para_2:
        "Les frais de service sont indiqués avant tout paiement des Services par l’Utilisateur, hors TVA du montant de la commande majoré d’un taux de TVA de vingt (20) %. ",
      Artice_4_3_para_3:
        "La Société se réserve le droit de modifier à tout moment le montant des frais de Service. Ces modifications n’auront pas d’effet sur les frais de Service acceptés par les utilisateurs avant la date de prise d’effet de ces modifications.  ",
  
      Artice_4_4_head: "4.4. Prix du transport",
  
      Artice_4_4_para_1:
        "Le prix du transport désigne le montant final convenu entre le Client et le Transporteur pour le service de transport du Colis, calculé sur la base de différents paramètres considérés par la Plateforme tels que, notamment, la distance parcourue, mètres cubes, le type de transport, l’urgence de la livraison, le poids du Colis, le type de colis, etc..  ",
      Artice_4_4_para_2:
        "Ce montant est sujet à une déduction qui comprend une commission de transport due à FRANK. Une fois que le Transporteur sélectionné par le Client a accepté la demande formulée sur la Plateforme et ce jusqu’à la réception du Colis par le Destinataire, le paiement du transport est provisionné sur un compte séquestre, à la discrétion des entreprises en cas de modification de l’itinéraire ou du mode de livraison.  ",
      Artice_4_4_para_3:
        "À partir du moment où la confirmation est réputée avoir été donnée, le prix du transport peut être consulté sur le Profil Utilisateur du Transporteur. Une fois que le Colis est livré à sa destination finale, le prix du transport est payé au Transporteur, après déduction des frais éventuels d’intervention dus à la Société et/ou aux différents intervenants tels que, notamment, les prestataires de services de la Société. Dans le cas où la livraison du Colis nécessite que plus d’un Transporteur atteigne son Point de dépôt, le paiement sera effectué, sous réserve du respect de la politique de paiement, une fois que le Colis aura atteint le dernier Point de dépôt ou le Destinataire final. Le Transporteur peut initier le transfert du montant net depuis son Profil Utilisateur ou l’interface dédiée.",
      Artice_4_4_para_4:
        "Le paiement s’effectue par virement bancaire en utilisant les informations fournies par le Transporteur sur son Profil Utilisateur ou l’interface. La Société n’est en aucun cas responsable vis-à-vis du Transporteur d’un incident de paiement, pour quelque raison que ce soit, notamment en cas d’opposition sur la carte ou d’utilisation frauduleuse. Le Transporteur s’engage à restituer à la Société, à première demande, toute somme perçue qui aurait été mise en cause en raison d’un tel incident de paiement.",
      Artice_4_5_head: "4.5. Contribution au frais de cotransportage",
  
      Artice_4_5_para_1:
        "En tant que Transporteur utilisant notre Plateforme, vous êtes responsable de fixer la contribution financière relative au service de cotransportage. Il est essentiel de comprendre que notre Plateforme est destinée à faciliter le partage des coûts sans générer de profits. Vous devez donc veiller à ce que la contribution demandée aux Utilisateurs corresponde uniquement aux dépenses directement liées à la réalisation du service de cotransportage.",
      Artice_4_5_para_2:
        "À l'occasion de la mise en ligne d'une Annonce pour un service de cotransportage, notre société propose une estimation initiale de la contribution au frais de cotransportage. Cette estimation se base sur des facteurs tels que la nature spécifique du service et la distance totale du transport. Bien que cette estimation soit indicative, vous avez la liberté de l'ajuster pour qu'elle reflète fidèlement vos coûts effectifs. Cependant, pour maintenir l'équité et éviter tout abus, notre société peut intervenir pour modérer les ajustements excessifs de la contribution financière.",
      Artice_4_5_para_3:
        "Si vous ne respectez pas ces directives et fixez une contribution excédant vos coûts réels, vous vous exposez au risque de voir l'opération requalifiée juridiquement. Dans un tel cas, vous serez tenu pour seul responsable des conséquences légales ou réglementaires résultant de cette requalification.",
  
      Artice_5: "Article 5. Commande, annulation de la réservation ",
  
      Artice_5_1_head: "5.1. Commande",
      Artice_5_1_para_1:
        "Aux fins des présentes, la commande s’entend de l’acte juridique par lequel l’Utilisateur sollicite une prestation de transport de Colis sur la Plateforme. Les conditions particulières applicables à la Commande, tiennent compte des modalités spécifiques à sa formation en cas de transport public routier ou de cotransportage qui sont notamment prévues en annexes.  ",
  
      Artice_5_2_head: "5.2. Annulation de la réservation  ",
      Artice_5_2_para_1:
        "Dans l’hypothèse où le Transporteur ou le Fournisseur agirait en qualité de professionnel, le Client dispose du droit de Droit de rétractation pendant les quatorze (14) jours suivant la date de souscription. Néanmoins, conformément à l’article L. 221-28 13° du Code de la consommation, le Client ne peut pas exercer ce droit si le Service est démarré dans ce délai. En dehors du cas mentionné ci-dessus, si un Utilisateur annule la réservation après confirmation, les frais seront remboursés partiellement ou totalement dans les cas détaillés suivants : ",
  
      Artice_5_2_point_1:
        "-	En cas d'annulation imputable au Transporteur, le Client sera remboursé de la totalité des sommes versées, sans aucune indemnité supplémentaire ; Si l'annulation intervient dans les quarante-huit (48) heures à compter de la confirmation de la réservation, le transporteur sera facturé d'un montant égal à cinquante pour cent (50 %) de la somme versée par le Client, afin de couvrir les frais d'une nouvelle réservation pour effectuer le Service, à l'exclusion de tout autre frais.  ",
      Artice_5_2_point_2:
        "-	En cas de modification des conditions générales du voyage par le Transporteur sans l’accord du Client, ce dernier pourra demander l’annulation de sa réservation. Le Client est remboursé de l’intégralité des sommes versées. ",
      Artice_5_2_point_3:
        "-	En cas d’annulation imputable au Client au plus tard dans les vingt-quatre (24) heures suivant la confirmation de la réservation, ce dernier sera remboursé du montant qu’il a payé, déduction faite du montant des frais de mobilisation dans la constitution de la chaîne de livraison et d’un pourcentage de XXX de la somme versée au Transporteur.",
  
      Artice_6: "Article 6. Responsabilités, assurance",
  
      Artice_6_1_head: "6.1 Responsabilités  ",
      Artice_6_1_para_1:
        "L’utilisation de la Plateforme et la réception de paiements en tant que Transporteur sont soumises à la fourniture de tous les documents requis par la réglementation en vigueur pour le transport de marchandises et la lutte contre le blanchiment d’argent. Cela comprend la licence de transport, l’attestation d’assurance de transport de marchandises, ainsi que, pour les sociétés commerciales, la pièce d’identité du dirigeant, un extrait Kbis de moins de 3 mois, les statuts de la société du Transporteur et la déclaration des bénéficiaires effectifs. En utilisant la Plateforme, chaque utilisateur garantit qu’il remplit toutes les conditions mentionnées ci-dessus. Il est responsable de la véracité et de la légalité des informations qu'il fournit lors de l'inscription et lors de l'utilisation des services de la Plateforme.",
      Artice_6_1_para_2:
        "La Société n’est qu’un intermédiaire permettant aux Clients et au Transporteur d’entrer en contact l’un avec l’autre et ne peut jamais être considérée comme une partie à la relation d’affaires entre le Client et le Transporteur. Elle n’intervient en aucune manière dans les transactions qu’ils effectuent sur la Plateforme, de leur propre initiative et dans le cadre des conditions qu’ils fixent seuls. La Société ne saurait être tenue pour responsable des dommages indirects qui pourraient survenir du fait de l’achat des Services par le Client. La Société ne saurait être tenue pour responsable de l’inexécution de la réservation en raison d’un cas de force majeure tel que défini par les présentes ainsi que dans la loi et la jurisprudence. De même, la responsabilité de la Société ne saurait être engagée en cas de dommage résultant de l’utilisation du réseau Internet, tels que virus, intrusion, perte de données, interruption Service Internet, etc. L'Utilisateur reconnaît que la Société ne peut être tenue responsable des dommages directs, indirects, accessoires, spéciaux, fortuits, consécutifs ou punitifs, ainsi que des pertes de profits ou de revenus qui pourraient être subis directement ou indirectement, notamment en relation avec l'accès ou l'utilisation de la Plateforme, l'incapacité d'accès ou d'utilisation de la Plateforme, ou toute utilisation ou altération non autorisée des transmissions de l'Utilisateur.",
      Artice_6_1_para_3:
        "En outre, du fait de son rôle de simple intermédiaire, la Société ne saurait être tenue pour responsable des litiges entre les Utilisateurs. Les Utilisateurs sont pleinement informés que l’opération dans laquelle ils sont impliqués, quelles qu’en soient la qualité et la nature, relève directement et exclusivement de la responsabilité de chaque partie à la transaction, à l’exclusion de la Société. La Société ne peut être tenue responsable du contenu illicite des Colis. Sauf disposition contraire de la loi, chaque Utilisateur dégage la Société de toute responsabilité dans le cadre de réclamation, et/ou de litige entre les Utilisateurs, et/ou de tout dommage présent ou futur, présumée ou non, avérée ou non, résultant directement ou indirectement d’une telle réclamation et/ou dispute.",
      Artice_6_1_para_4:
        "Le Fournisseur est responsable de son lieu de stockage. À cet égard, il doit être titulaire d’une police d’assurance adaptée. Le Fournisseur doit informer son assureur de son intention de mettre sa localisation à la disposition d’un tiers, afin d’obtenir son accord.  ",
      Artice_6_1_para_5:
        "Si le Fournisseur est un professionnel, il doit également être titulaire d’une assurance des risques matériels et environnementaux. Le Fournisseur est responsable de la remise au Transporteur et/ou de la restitution au Client du Colis stocké dans l’état dans lequel il se trouvait lors de sa remise par le Client.",
      Artice_6_1_para_6:
        "Le Fournisseur est tenu d’informer le Client dans les plus brefs délais de toute perte, détérioration ou vol de la Colis stocké. Le Fournisseur est donc le dépositaire des marchandises déposées, au sens où des articles 1915 et suivants du Code civil concernant le contrat de dépôt. La Société propose la police d’assurance prévue à l’article ci-après.  ",
  
      Artice_6_2_head: "6.2 Assurance ",
  
      Artice_6_2_para_1:
        "La société a souscrit une police d’assurance auprès de [insérer le nom de l’entreprise]. ",
      Artice_6_2_para_2:
        "L’assurance comprend les dommages matériels et le vol de biens. Cette police d’assurance protège le propriétaire du Colis confié lors du transport de la marchandise par le Transporteur. Le Client s’engage à informer la Société de tout dommage à ses biens qui pourrait être incluse dans la police d’assurance dans les cinq (5) jours ouvrables suivant la détection des dégâts. La Société se chargera alors de la gestion du dossier auprès du Client et, le cas échéant, avec l’assurance. Les modalités de traitement sont précisées dans l’assurance Note d’information disponible ici [insérer le lien].  ",
      Artice_6_2_para_3:
        "En tout état de cause, la Société ne pourra en aucun cas être tenue responsable des dommages et préjudices qui peuvent se produire sur les Colis.",
  
      Artice_7: "Article 7. Données personnelles, propriété intellectuelle  ",
      Artice_7_1_head: "7.1 Données personnelles ",
      Artice_7_1_para_1:
        "Conformément à la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique et aux libertés l’informatique, les fichiers et les libertés, dite « Loi informatique et Libertés », et le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 sur la protection des personnes physiques à l’égard du traitement des données à caractère personnel et sur la libre circulation de ces données (ci-après dénommé le « RGPD »), les Utilisateurs sont informés que la Société procède à un traitement automatisé de leurs données à caractère personnel qui a pour finalité l’utilisation des Services, le traitement et la gestion des relations commerciales et des commandes.",
      Artice_7_1_para_2:
        "Ces données peuvent être transmises à des filiales et/ou sociétés contrôlées par la Société et aux sous-traitants qui contribuent à ces relations pour leur gestion, leur exécution, traitement et paiement. Chaque Utilisateur dispose d’un droit d’accès, de modification, de rectification, de limitation, de portabilité et de suppression des données le concernant : ainsi que le droit de s’opposer au traitement de ses données personnelles. À cette fin, ils doivent contacter le service d’assistance par courrier électronique à l’adresse suivante Adresse : DPO@frankme.com",
      Artice_7_1_para_3:
        "L’Utilisateur comprend qu’en ce qui concerne les rectifications et les mises à jour des données relatives à ses données de Profils, il peut effectuer lui-même les modifications et suppressions nécessaires. Pour plus d’informations, les Utilisateurs peuvent se référer à la Politique de Confidentialité accessible sur la Plateforme dans la rubrique « Politique de confidentialité ». Ladite politique fait partie intégrante des Conditions Générales. L’acceptation des CGU implique l’acceptation de la politique de confidentialité correspondante.",
  
      Artice_7_2_head: "7.2 Propriété intellectuelle ",
      Artice_7_2_para_1:
        "La Société est le propriétaire exclusif des droits de propriété intellectuelle ou détient les droits d’utilisation sur tous les éléments accessibles sur la Plateforme, y compris les éléments visuels et textuels 27 composants de la Plateforme, la marque et les logos « FRANK », le graphisme de la Plateforme ainsi que dessins, modèles, photographies, textes, illustrations, logos, animés ou non séquences animées, sonores ou non, graphiques etc. que l’on trouve sur cette Plateforme ainsi que leurs compilations sont la propriété exclusive de la Société, de la structure et des logiciels utilisés pour faire fonctionner la Plateforme., ainsi que sur l’ensemble des données collectées. Tous ces éléments sont protégés par tous les droits de propriété intellectuelle ou par les producteurs de bases de données de droits en vigueur.  ",
      Artice_7_2_para_2:
        "Toute utilisation ou reproduction non autorisée, même partielle, de la Plateforme constitue une infraction.  ",
      Artice_7_2_para_3:
        "En dehors des éléments publiés sous licence libre et dans le but exclusif d’informations pour un usage personnel et privé, toutes reproductions, ou représentations, même partielles, par quelque moyen que ce soit, toutes les demandes automatisées ou non automatisées de récupération de données publiée sur cette Plateforme, et toute utilisation de copies faites à d’autres fins étant expressément interdit. Ni tout ou partie de la Plateforme, ni son contenu, ni les marques ne peuvent être utilisés, reproduits, dupliqués, copiés, vendus, revendus, rendus accessibles, modifiés ou exploités d’une autre manière, en tout ou en partie, à quelque fin que ce soit, fait sans l’autorisation de l’éditeur sont illicites et constituent une contrefaçon au sens des dispositions du Code de la propriété intellectuelle. Toute autre utilisation est constitutive de contrefaçon.",
  
      Artice_8:
        "Article 8. Sécurité, biens exclus du transport à partir de la Plateforme",
  
      Artice_8_1_head: "8.1 Sécurité  ",
      Artice_8_1_para_1:
        "La Société s’engage à rectifier dans les plus brefs délais tout élément inexact ou obsolète dans les informations qui peuvent être présentes sur la Plateforme. La Plateforme peut contenir plusieurs liens vers des sites ou des ressources de tiers. L’Utilisateur reconnaît et accepte que la Société ne puisse en aucun cas être tenue responsable des dommages ainsi que de la disponibilité de sites web exploités par des tiers, y compris ses éventuels partenaires, auxquels les Utilisateurs peuvent y accéder par le biais de la Plateforme. La Société n’est pas non plus responsable du contenu, des produits ou des services disponibles sur ces sites tiers régis par leurs propres conditions d’utilisation. L’utilisation de ces sites ou ressources est sous la seule responsabilité de l’Utilisateur.  ",
      Artice_8_1_para_2:
        "La Plateforme peut contenir des avis d’Utilisateurs sur des fonctionnalités interactives qui permettent aux Utilisateurs de soumettre, publier, afficher ou transmettre du contenu à d’autres Utilisateurs ou à d’autres personnes sur ou via la Plateforme. ",
      Artice_8_1_para_3:
        `Toute contribution des Utilisateurs doit être conforme aux contenus énoncés dans la Charte de bonne conduite en Annexe 1.   `,
      Artice_8_1_para_4:
        "L’Utilisateur comprend qu’il est responsable des contributions soumises par lui ou pour son compte. La Société n’assume aucune responsabilité à cet égard quant au contenu, y compris sa légalité, sa fiabilité, son exactitude et sa pertinence. La Société ne s’engage pas en tout état de cause à contrôler une contribution avant sa publication. Il se peut que cela se produise a posteriori l’événement. La Société ne peut être tenue responsable envers un tiers du contenu ou de l’exactitude des contributions de l’Utilisateur. La Société se réserve le droit de supprimer ou de modifier tout contenu mis en ligne par un Utilisateur qui ne respecte pas les présentes CGU, ou qui communiquent des informations fausses ou inexactes ou qui portent atteinte à un Utilisateur de la plateforme.  ",
      Artice_8_2_head: "8.2 Liste des objets exclus ",
  
      Artice_8_2_para_1:
        "Cette liste n’est pas exhaustive. Il peut exister des spécificités liées à la réglementation de certains pays, auquel cas, il faut se renseigner auprès des services douaniers des pays concernés. En tout état de cause, avant d’expédier ou de collecter un Colis, assurez-vous toujours qu’il ne présente aucun danger.",
  
      Artice_8_2_point_1: "•	Les stupéfiants et les matières psychotropes,",
      Artice_8_2_point_2: "•	Les produits de contrefaçon,",
      Artice_8_2_point_3: "•	Les animaux vivants, ",
      Artice_8_2_point_4: "•	Les objets de valeur tels que les bijoux, métaux précieux, billets de banque, valeurs au porteur, or ou argent, et autres objets de valeur,",
      Artice_8_2_point_5:
        "•	Les biens culturels ayant un intérêt majeur pour le patrimoine national,",
      Artice_8_2_point_6:
        "•	Les objets dont le caractère sensible est soumis à des formalités particulières : armes, médicaments, tabacs, amiante, marchandises stratégiques utilisables à des fins civiles ou militaires ou produits issus d'espèces animales ou végétales sauvages protégées par la convention de Washington,",
      Artice_8_2_point_7:
        "Les objets dont le caract re sensible est soumis   des formalit s particuli res : armes, m dicaments, tabacs, amiante, marchandises strat giques utilisables   des fins civiles ou militaires ou produits issus d'esp ces animales ou v g tales sauvages prot g es par la convention de Washington,",
      Artice_8_2_point_8:
        "•	Les objets dont la nature ou l’emballage peuvent présenter un danger pour autrui,",
      Artice_8_2_point_9:
        "•	Les matières inflammables (alcools, parfums), explosives (y compris les munitions inertes ou factices), radioactives ou corrosives, les déchets dangereux et les matières dangereuses telles que les gaz comprimés ou tout objet contenant du gaz ou de l’air comprimé ou liquide (produits aérosols, canots de sauvetage, extincteurs, etc.),",
      Artice_8_2_point_10: "Les piles et batteries au lithium,",
      Artice_8_2_point_11:
        "•	Les matières biologiques périssables, infectieuses ou non infectieuses,",
      Artice_8_2_point_12: "Les animaux morts,",
      Artice_8_2_point_13: "•	Les organes et dépouilles humaines,",
      Artice_8_2_point_14: "Les objets obsc nes ou immoraux   ",
  
      Artice_9: "Article 9.Force majeure ",
      Artice_9_para_1:
        "Tout événement découlant d'un cas de force majeure, au sens de l'article 1218 du Code civil, tel que ceux fixés ci-dessous ne pourront en aucun cas être une cause de responsabilité pour cause de retard ou de non-exécution de l'obligation concernée, ni induire le versement de dommages et intérêts ou pénalités de retard :  ",
      Artice_9_point_1: "-	les actes de guerre ou de terrorisme ; ",
      Artice_9_point_2: "-	les actes criminels ; ",
  
      Artice_9_point_3: "-	les émeutes ;",
      Artice_9_point_4: "-	catastrophes naturelles ou industrielles ; ",
      Artice_9_point_5: "-	explosions;  ",
      Artice_9_point_6:
        "-	exigences légales et d’autres dispositions législatives ou réglementaires restreignant l’activité de l’entreprise ; et ",
      Artice_9_point_7:
        "-	perturbations des réseaux de communications électroniques échappant au contrôle de la Société  ",
      Artice_9_para_3:
        "En cas de force majeure, la Société pourra être amenée à suspendre les Services. Les effets de tout contrat sont alors suspendus et reprendront après la cessation de la force majeure. ",
  
      Artice_10: "Article 10. Droit applicable et juridiction compétente ",
      Artice_10_para_1:
        "Les présentes CGU ainsi que leurs annexes sont accessibles à tout moment par un lien direct sur la Plateforme. Elles peuvent être téléchargées en cliquant sur le [Télécharger les CGU en PDF].",
      Artice_10_point_1:
        "-	Les présentes CGU et les opérations qui en découlent sont soumises au droit français ;  ",
      Artice_10_point_2:
        "-	La langue d’interprétation des présentes CGU est la langue française.  ",
  
      Artice_10_para_2:
        "L'Utilisateur et la Société s'engagent à trouver un accord à l'amiable pour tous les litiges concernant la validité, l'interprétation ou l'exécution des termes des CGU. En cas d’absence d’accord amiable, le litige sera soumis à la médiation. Si l'Utilisateur n'est pas un professionnel, il peut recourir à un médiateur pour régler de manière amiable tout différend l'opposant à un professionnel.  ",
      Artice_10_para_3:
        `Les informations sur la procédure de médiation de la consommation et la liste des médiateurs sont disponibles à l'adresse suivante :  https://www.economie.gouv.fr/mediation-conso  `,
      Artice_10_para_4:
        `En vertu de la législation européenne sur la protection des consommateurs, une procédure de règlement des litiges est également proposée par la Commission européenne sur un portail dédié :  http://ec.europa.eu/consumers/odr `,
  
      Artice_10_para_5:
        "La saisine de la juridiction compétente interviendra uniquement en cas d’échec de la médiation.",
  
      Artice_10_para_6:
        "Toutes les actions et litiges auxquels les CGU pourraient donner lieu seront soumis à la juridiction exclusive du Tribunal de Commerce de Paris, ceci même en cas de pluralité de défendeurs, d’appel en garantie ou de procédure de référé.  ",
  
      Artice_10_para_7:
        "Les litiges entre les Utilisateurs doivent être réglés directement entre eux, la Société n’étant pas destinée à les régler.",
      Artice_11: "Article 11. Informations légales",
      Artice_11_para_1:
        `Le site Internet www.frankme.com désigne la Plateforme FRANK, dirigée par la société PEOPLES POSTE une société par actions simplifiée de droit français au capital social de cent euros (100 €), dont le siège social est situé 22 rue Emeriau, 75008 Paris et immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 879 963 197. Elle est représentée par Monsieur Pouya Ahad, President, Directeur General.
        Adresse mail de contact : info@frankme.com  
        `,
  
      Appendex_1: "Annexe 1. Charte de bonne conduite",
      Appendex_1_para_1:
        "La présente Charte de Bonne Conduite (ci-après « la Charte ») établit les principes et règles que chaque Utilisateur doit observer lors de l'utilisation de la plateforme. L'adhésion à ces principes assure une expérience sécurisée, légale et respectueuse pour tous.",
      Appendex_1_head_1: "1. Respect de la loi  ",
  
      Appendex_1_head_1_para_1:
        "Les utilisateurs doivent s'assurer que leurs contributions sur la plateforme sont conformes à la législation en vigueur, notamment en ne promouvant aucun contenu illégal, diffamatoire, obscène ou incitant à la haine.",
  
      Appendex_1_head_2: "2. Sécurité et conformité des transports",
      Appendex_1_head_2_para_1:
        "Accepter uniquement les missions adaptées à vos capacités et équipements. Ne pas entreprendre de transport qui nécessite des autorisations, des équipements ou des compétences que vous ne possédez pas.",
  
      Appendex_1_head_3: "3. Ponctualité et fiabilité ",
      Appendex_1_head_3_para_1:
        "Respecter scrupuleusement les dates et horaires convenus pour la collecte et la livraison. Tout changement doit être communiqué et convenu à l'avance avec les parties concernées.",
  
      Appendex_1_head_4: "4. Communication efficace",
      Appendex_1_head_4_para_1:
        "Informer les expéditeurs et les destinataires de votre arrivée en avance pour éviter toute confusion ou attente inutile.",
  
      Appendex_1_head_5: "5. Intégrité des livraisons",
  
      Appendex_1_head_5_para_1:
        "Assurer un transport soigneux des colis, en particulier ceux fragiles ou de valeur. En cas de dommage, la responsabilité de l'assurance devra être engagée pour le remboursement.",
  
      Appendex_1_head_6: "6. Gestion des conflits",
      Appendex_1_head_6_para_1:
        "Gérer les désaccords ou les problèmes avec professionnalisme et courtoisie, en évitant toute confrontation et en cherchant une résolution pacifique.",
  
      Appendex_1_head_7: "7. Utilisation inappropriée de la Plateforme",
      Appendex_1_head_7_para_1:
        "Ne pas utiliser la Plateforme à des fins illégales, abusives ou pour perturber son bon fonctionnement ou celui des autres utilisateurs.",
  
      Appendex_1_head_8:
        "8. Respect de la vie privée et des données personnelles",
      Appendex_1_head_8_para_1:
        "Ne pas divulguer d'informations personnelles sans consentement explicite de la personne concernée.",
  
      Appendex_1_head_9: "9. Interdiction de pratiques nuisibles",
      Appendex_1_head_9_para_1:
        "S'abstenir d'introduire des programmes malveillants, d'utiliser des outils automatisés non autorisés, ou de mener des actions susceptibles de nuire à la plateforme ou à ses utilisateurs.",
  
      Appendex_1_head_10: "10. Comportement éthique en ligne",
      Appendex_1_head_10_para_1:
        "Éviter tout comportement en ligne qui pourrait être perçu comme harcelant, intimidant ou usurpateur d'identité.",
  
      Appendex_1_para_2:
        "En cas de violation de la Charte, des mesures telles que la suspension ou la limitation d'accès à la Plateforme, la suppression de contenu, ou des actions en justice peuvent être prises pour protéger les intérêts de la communauté et l'intégrité de la Plateforme.",
  
      Appendex_2:
        "Annexe 2. Conditions particulières relatives au transport public routier de colis",
  
      Appendex_2_article1_head: "Article 1. Conformité réglementaire  ",
  
      Appendex_2_article1_para_1:
        "Le Transporteur professionnel, en acceptant toute annonce sur la plateforme FRANK, s'engage à s'organiser de manière à respecter ses propres obligations légales et réglementaires. Ceci inclut l'assurance que le transport soit réalisé en conformité avec les conditions d'établissement, d'honorabilité professionnelle, de capacité financière et de capacité professionnelle, ainsi qu'avec l'immatriculation au registre des transports conformément à l’article L. 3211-1 du Code des transports, et au registre du commerce et des sociétés. Toute suspension ou retrait de ces inscriptions doit être immédiatement signalée à la plateforme.",
      Appendex_2_article1_para_2:
        "Le Transporteur professionnel doit également garantir que toutes les opérations de transport réalisées via la plateforme respectent les engagements contractuels pris avec le Client. Cela inclut le respect strict des règles relatives aux périodes de travail et de repos prescrites par la réglementation en vigueur.",
      Appendex_2_article1_para_3:
        "Il est impératif que le Transporteur utilise tous les moyens de contrôle et les documents réglementairement exigés pour garantir non seulement la sécurité mais aussi la conformité totale des opérations de transport. Cette exigence sert à prévenir tout risque lié à la non-conformité réglementaire et à renforcer la confiance des clients dans les services fournis.",
      Appendex_2_article2_head:
        "Article 2. Obligations relatives au transport public de marchandises",
      Appendex_2_article2_1_head: "2.1 Travail non dissimulé ",
      Appendex_2_article2_1_para_1:
        "En conformité avec l'article L.3261-3 du Code des transports, la plateforme FRANK impose aux transporteurs professionnels de marchandises l'obligation de justifier, tous les six mois, qu'ils remplissent les conditions suivantes :",
      Appendex_2_article2_1_point_1:
        ".	Régularité de l'emploi : Le transporteur doit démontrer qu'il ne détache pas de salariés ni n'utilise de salariés détachés dans des conditions irrégulières. De plus, pour les entreprises établies en France, il doit également prouver qu'il ne pratique pas de travail dissimulé et n'emploie pas de salariés non autorisés à exercer une activité professionnelle sur le territoire français.",
      Appendex_2_article2_1_point_2:
        ".	Assurance professionnelle : Le transporteur doit fournir une preuve actualisée de l'existence d'une assurance couvrant les conséquences financières de sa responsabilité professionnelle pour les activités exercées.",
      Appendex_2_article2_2_head: "2.2 Obligations d’informations   ",
  
      Appendex_2_article2_2_para_1:
        "Conformément à l'article L3222-4 du Code des transports, le Client est tenu à un devoir d'information. Avant la présentation du véhicule au point de chargement, le Client doit fournir toutes les informations nécessaires à l'exécution du contrat de transport. Ceci inclut une description détaillée du colis, la liste des prestations annexes convenues, ainsi que son acceptation explicite des durées et conditions de réalisation prévues pour le transport.",
      Appendex_2_article2_2_para_2:
        "En outre, conformément à l'article L3222-5 du Code des transports, le Transporteur établira un document de transport qui sera rempli progressivement au cours de l'opération de transport. Ce document, qui sera signé par le remettant ou son représentant à chaque étape significative au chargement et au déchargement, détaillera les dates et heures d'arrivée et de départ, l'heure d'arrivée au lieu de déchargement demandé, et les prestations annexes effectuées. Ce document fait foi des modalités d'exécution du contrat jusqu'à preuve du contraire et engage la responsabilité des parties en cas de refus de signature non motivé.",
      Appendex_2_article2_2_para_3:
        "Le Transporteur informera explicitement le Client de toute estimation des temps nécessaires à l'exécution des tâches ainsi que des ajustements de rémunération pouvant être appliqués en cas de dépassement des temps alloués qui lui seraient non imputables. En tout état de cause, la mise en œuvre des dispositions de l’article L. 3222-8 du Code des transports doit être portée à la connaissance du cocontractant.",
  
      Appendex_2_article2_3_head:
        "2.3 Documentation et suivi de l'acheminement ",
      Appendex_2_article2_3_para_1:
        "Le Transporteur établira un document de suivi dès le début du transport. Ce document sera mis à jour à chaque étape importante du processus de chargement et de déchargement. Conformément à l'article L3222-5 du Code des transports, ce document devra être signé par le remettant ou son représentant au début du transport, et par le destinataire à l'arrivée, afin d'attester des prestations effectuées et des horaires respectés. De plus, le Transporteur peut être tenu de fournir des preuves photographiques ou d'autres preuves du colis au début et à la fin du transport, ainsi qu'à des étapes significatives du processus. Cela inclut des photos du colis avant le chargement et après le déchargement, ainsi que toutes autres preuves jugées nécessaires pour vérifier l'état et la réception du colis.",
  
      Appendex_2_article2_4_head: "2.4 Cabotage  ",
      Appendex_2_article2_4_para_1:
        "Les Utilisateurs doivent opérer conformément à la réglementation nationale et européenne en vigueur, notamment détenir les licences requises pour le type de transport effectué et s'assurer que toutes les opérations de transport, y compris le cabotage par des entreprises non établies en France respectent les conditions établies par les articles L. 3421-3 et L. 3421-4 du Code des transports.",
      Appendex_2_article2_5_head: "2.5 Responsabilité et sous-traitance  ",
  
      Appendex_2_article2_5_para_1:
        "Le Transporteur peut sous-traiter le transport à une autre entreprise de transport routier sous sa responsabilité entière et totale, à condition de respecter les conditions définies à l’article L. 3224-1 du Code des transports ainsi que les dispositions applicables aux commissionnaires de transport notamment en matière de transfert des risques.",
      Appendex_2_article2_5_para_2:
        "Les opérations de chargement, de calage et d’arrimage incluent le sanglage. Les sangles sont fournies par le Transporteur. Il est rappelé que la responsabilité des dommages matériels survenus au cours des opérations de chargement et déchargement pèse sur celui qui les exécute.  ",
      Appendex_2_article2_6_head: "2.6 Rémunération et délai de paiement ",
      Appendex_2_article2_5_para_3:
        "Les paiements des services sur la Plateforme sont effectués conformément aux CGU. Ces paiements sont réalisés dans des délais plus avantageux que ceux prévus par la loi, reflétant ainsi notre engagement à offrir un service efficace et répondant aux attentes de notre communauté.",
      Appendex_2_article2_5_para_4:
        "Cependant, nous reconnaissons que des événements imprévus peuvent survenir. En conséquence, malgré notre engagement à agir rapidement, et sauf circonstances légales le justifiant, les délais de paiement ne sauraient dépasser les délais légaux maximums prescrits. Cette mesure garantit le respect de la législation tout en protégeant les intérêts de toutes les parties impliquées.",
      Appendex_2_article2_5_para_5:
        "En outre, concernant la rémunération des transporteurs publics de marchandises, il est impératif que les transporteurs évaluent si le prix déterminé par le client via notre plateforme couvre adéquatement les critères énoncés par l'article L3221-2 du Code des transports. Ces critères incluent les prestations effectivement accomplies, les durées de mise à disposition du véhicule pour le chargement et le déchargement, la durée nécessaire pour la réalisation sécuritaire du transport, et les coûts des produits énergétiques nécessaires. Si le prix fixé par le client ne répond pas à ces critères, le transporteur a l'obligation de refuser l’offre ou de faire une contre-offre.",
      Appendex_2_article2_5_para_6:
        "Dans ce cadre, la Plateforme mettre en œuvre tous les moyens pour s’assurer que les prix proposés sont cohérents avec les dispositions légales et suffisants pour couvrir les coûts mentionnés, garantissant ainsi une rémunération juste pour les transporteurs.",
      Appendex_1_head:
        "Article 3. Traitement des indemnités et pénalités ",
  
      Appendex_1_article_3:"Les Utilisateur reconnaissent que FRANK n'est pas responsable du paiement de toute indemnité ou pénalité découlant de problèmes de transport. En outre, l’établissement contradictoire de leur existence ou réalité ou de quelconque dysfonctionnement donnant lieu à pénalité ou indemnité incombe exclusivement aux Utilisateurs concernés, qui doivent trouver un accord mutuel pour régler ces questions.",
  
      Annexe_3_head:`
      Annexe 3. Conditions particulières relatives au cotransportage de colis
      `,
      Appendex_3_article1_head: "Article 1. Caractéristiques du cotransportage  ",
      Appendex_3_article1_para_1:
        "Les Utilisateurs de la Plateforme peuvent proposer d'effectuer du cotransportage de colis pour d’autres Utilisateurs. Conformément à l’article L. 3232-1 du Code des transports, le cotransportage de colis se définit comme l'utilisation en commun, à titre privé, d'un véhicule terrestre à moteur effectuée à titre non onéreux, excepté le partage des frais, pour transporter des colis dans le cadre d'un déplacement qu'un conducteur effectue pour son propre compte.",
      Appendex_3_article1_para_2:
        "A cette fin, le co-transporteur doit être en possession d'un permis de conduire en vigueur. Il doit offrir des services de transport pour des véhicules dont il est propriétaire ou pour lesquels il a reçu une autorisation expresse du propriétaire, et il doit détenir une attestation d'assurance valide pour chaque type de risque couvert, notamment la Responsabilité Civile Contractuelle et une assurance Dommage aux Biens pour les véhicules qu'il transporte. De plus, il doit être en mesure de fournir à la Plateforme une copie de ces documents sur simple demande. Il lui est également interdit de sous-traiter le transport par le biais de services tels que DHL, CHRONOPOST, UPS ou tout autre service similaire. ",
      Appendex_3_article1_para_3:
        "Les Cotransporteurs doivent déclarer toute activité de cotransportage et s'assurer que le montant des contributions financières reçues ne dépasse pas le plafond annuel établi par le ministère chargé des transports.",
      Appendex_3_article2_head: "Article 2. Vérification des colis",
      Appendex_3_article2_para_1:
        "Avant tout cotransportage, le cotransporteur doit vérifier l'état et la conformité des Colis avec les restrictions de la Plateforme telles que prévues à l’article 8 des CGU. Les Colis ne doivent pas contenir de produits illicites, dangereux, ou réglementés spécifiquement comme l’alcool, le tabac, ou les produits pharmaceutiques, articles de contrebande….",
      Appendex_3_article2_para_2:
        "La Plateforme se réserve le droit de refuser ou de retirer une Annonce si le contenu du Colis est jugé non conforme aux normes établies ou à l'ordre public.",
      Appendex_3_article3_head: "Article 3. Acheminement",
      Appendex_3_article3_para_1:
        "Les descriptions des trajets proposés par les Cotransporteurs doivent être précises, incluant les points de départ et d’arrivée, ainsi que les horaires prévus. Chaque trajet doit être validé par FRANK avant sa publication.",
      Appendex_3_article3_para_2:
        "La commande d’un trajet par un utilisateur est définitive une fois la confirmation du Cotransporteur reçue. La plateforme facilite cet échange et assure le suivi de la réalisation du service.",
  
      Appendex_3_article4_head: "Article 4. Stockage",
      Appendex_3_article4_para_1:
        "Le cotransporteur ne doit pas stocker les Colis pour une durée excédant celle strictement nécessaire à l’organisation du trajet. Les conditions spécifiques de stockage, si nécessaire, doivent être clairement communiquées aux Utilisateurs. Ces emplacements sont sélectionnés en fonction d'une optimisation du transport, garantissant efficacité, sécurité et temps de transit minimal, tels que déterminés par FRANK.",
      Appendex_3_article5_head:
        "Article 5. Contribution aux frais de cotransportage",
      Appendex_3_article5_para_1:
        "Les contributions aux frais de cotransportage sont partagées équitablement et ne doivent pas excéder les coûts réels encourus pour le trajet. Les Cotransporteurs s'engagent à ne réaliser aucun bénéfice via la Plateforme. Les prix pour le service de cotransportage doivent être clairement définis et communiqués à l'avance, incluant les frais de service de FRANK. Ces tarifs sont payables via des méthodes sécurisées proposées par la plateforme. ",
      Appendex_3_article5_para_2:
        "Tout litige découlant du transport de colis entre particuliers doit être résolu directement entre les parties concernées, FRANK agissant uniquement en tant qu'intermédiaire technique et non comme partie au contrat de transport.",
      Appendex_3_article6_head:
        "Article 6. Transfert des risques et responsabilité ",
      Appendex_3_article6_para_1:
        "Le transfert des risques s'effectue dès la remise du colis au Cotransporteur de sorte que le Cotransporteur est responsable du Colis jusqu'à sa livraison effective, conformément aux modalités convenues avec le Client.",
      Appendex_3_article6_para_2:
        "En cas de dommages ou de perte, le Cotransporteur est tenu de dédommager le Client, sauf en cas de force majeure. ",
    


    seller_details:"Détail du vendeur",
    Vendre_un_article:"Vendre un article",
    small_desc: 'Entre une enveloppe et une boite à chaussure (<5 Kg)',
    medium_desc: 'Entre un sac à dos et une caisse à vin (5 KG- 15 Kg )',
    large_desc: 'Entre une télévision et une commode (15 kg - 25 kg)',
    e_large_desc: 'Entre une armoire et une table à manger (+25 Kg)',
    search: "Rechercher",
    use_email: "or use your email account.",
    login_with: "Login with",
    signup_with: "Sign up with",
    accept_terms_conditions:
      "Tu confirmes que tu acceptes les Termes & Conditions de Frank et avoir au moins 18 ans",
    accept_newsletter: "J’accepte de recevoir par e-mail les offres de Frank ",
    personal_info: "Information Personnelle",
    set_credentials: "Définir les informations de connexion",
    set_mobile_number:
      "Vérifiez votre numero de telephone pour sécuriser votre compte",
    verify_otp: `Vérifier le code d'accès à usage unique (OTP)`,
    profile: "Profil",
    chat: "Discuter",
    call: "Appeler",
    support: "Support",
    confirmation: "Confirmation",
    logout_confirmation:
      "Êtes-vous sûr de vouloir vous déconnecter de cet appareil ?",
    logout: "Logout",
    settings: "Paramètres",
    mini_shop: "Mini Shop",
    shop_details: "Shop Details",
    order_history: "Transactions",

    my_deliveries: "Deliveries",
    my_parcels: "Mes Annonces",

    signin: "Se connecter",
    or_connect: "Ou conectez-vous avec",
    alread_have_acc: "Vous avez déjà un compte? ",
    login_here: "se connecter",

    sign_up: "Créer un compte",
    forgot_password: "Mot de passe oublié",
    set_new_password: "Définir un nouveau mot de passe",
    wallet: "Porte monnaie",
    blogs: "Frank Blogs",
    blog_details: "Blog Details",
    continue: "S’inscrire",
    login: "Se connecter",

    first_name: "Nom",
    surname: "Prénom",
    date_birth: "Date de Naissance",
    user_name: `Nom d'utilisateur`,
    old_password: "Ancien mot de passe",
    password: "Mot de passe",
    confirm_password: "Confirmez le mot de passe",
    next: "S’inscrire",
    phone_number: "Numéro de téléphone",
    send_number_text:
      "Vous recevrez un code par SMS afin d’activer votre compte",

    didnt_receive_code: `Tu n’as pas reçu de code? `,
    resend_code: `Renvoyer le code`,
    send_otp: "Obtenir mon code de vérification",
    validate_otp: "Vérifier",
    send_otp_text: "le code de vérification que vous avez recu sur ce numéro:",

    your_mood: "Quel est le programme du jour?",
    sender: `Envoyer\nun colis`,
    transporter: "Transporter\nun colis",
    transporter_2: "Transporter un colis",
    relocation: "Déménager",
    send_green_head: "Votre Envoi, Moins de CO₂!",
    send_green_text:
      "Chaque livaraison avec Frank réduit en moyenne les émmisions de CO₂ de 25 kg.",

    home: "Accueil",
    announces: "Annonces",
    send: "Envoyer",
    inbox: "Messages",
    account: "Compte",

     
    reduce_carbon_head: "Livraison\nécologique",
    reduce_carbon: "votre empreinte carbone",
    reduce_carbon_title: "Éco-responsable",
    reduce_carbon_desc:
      "Un colis envoyé avec Frank, c’est 25kg/CO2 en moins ! Vous faites du bien à la planète en optimisant le trajet de votre colis, mais aussi celui d’un transporteur qui, sans vous, aurait peut-être roulé à vide !",

    eco_delivery_head: "Livraison\néconomique",
    eco_delivery: "sur vos envois",
    eco_delivery_title: "Économique",
    eco_delivery_desc:
      "Un colis envoyé avec Frank, c’est 50 % moins cher qu’avec un transporteur traditionnel. Fini les envois de colis ruineux !",

    assured_delivery_head: "Livraison\nassurée",
    assured_delivery: "vos envois",
    assured_delivery_title: "Assuré",
    assured_delivery_desc:
      "Un colis envoyé avec Frank, c’est une assurance à hauteur de 100€. Le transporteur reçoit le montant de sa course une fois que le colis est bien arrivé à destination et que le destinataire en a accusé réception.",

    recent_transaction: "Transactions récentes",

    create_shipment: "Envoyer un colis",
    upto_six_pics: `Jusqu’à 6 photos, formats JPG, PNG et GIF jusqu’à 5 Mb.`,
    add_photos: "Ajouter des photos",
    prod_name: "Ex: Table, Stylo, Four",
    i_know_dimensions: "Je connais les dimensions",
    add_descriptive: "Information complémentaires. ",
    prod_size: "La taille de mon colis",
    fragile: " Fragile ",
    add_item: "Ajouter un colis",
    origin_area: "Ma position actuelle",
    pickup_address: "Adresse de ramassage",
    pickup_place_placholder: "Lieu de prise en charge",
    pickup_place_1: "Devant chez moi",
    pickup_place_2: "Dans la pièce de mon choix",
    delivery_place_placholder: "Lieu de livraison",

    delivery_type: "Urgent",
    delivery_type_text: "Entre 1 à 2 jours",

    delivery_type2: "Je peux attendre",
    delivery_type2_text: "Entre 3 à 7 jours",

    delivery_type3: "Quand mon annonce trouvera un transporteur",
    delivery_type3_text: "Entre 8 à 30 jours",

    back: "Retour",
    desitnation_area: "Adresse de Livraison",
    dropoff_address: "Adresse de livraison",
    continue_: "Suivant",

    announcements: "Annonces",
    all: "Tout",
    around_me: "Autour de moi",
    online_announce: " annonces en ligne",
    accept: "Accepter",
    negotiate: "Négocier",
    address: "Adresse",

    update_info: "Enregistrer",
    email_address: "Adresse e-mail",
    my_profile: "Mon Profil",

    transfer: "Transférer vers un compte bancaire",
    your_wallet: "Mon porte monnaie",
    user_or_email: "E-mail ou nom d'utilisateur",

    logging_in_text: "En vous connectant, vous acceptez notre ",
    terms_condition: "Termes & Conditions ",
    privacy_poligy: "Politique de confidentialité ",
    signup_here: "Inscrivez-vous ici",
    dont_hace_acc: "Vous n'avez pas de compte? ",

    welcome: "Bienvenue",
    length: "Longueur",
    width: "Largeur",
    height: "Hauteur",
    choose_size: "Dimension du colis",

    when_do_you_want_your_parcel_delivered:
      "Vous souhaitez que votre colis soit livré …",

    make_offer: "Faire une offre",
    confirm: "Confirmer",
    enter_offer_price: `Entrez le prix de l'offre`,
    offer_request_sent: "Votre offre a été envoyée à l’expéditeur 🎉",
    thanks_frank: "Merci Frank",
    documents: "Documents",
    notification: "Notifications",
    change_password: "Changer le mot de passe",
    all_offers: "Toutes les offres",
    announcement_details: `Détails de l'annonce`,
    frank_storage: "Consigne",
    explore: "Explorer",
    shop: "Boutique",
    camera: "Prendre une photo",
    gallery: "Choisir de ma galerie",
    cancel: "Annuler",
    quantity: "Quantité",
    pickup_place_area: "Lieu de prise en charge",
    recipient_name: "Nom du destinataire",
    delivery_cost: "Frais de livraison",
    item_sizes: "Produit & Dimension",
    type_of_delivery: "Type de livraison",
    sender_2: "Expéditeur",
    sender_details: `À propos de l'expéditeur`,
    language: "Langue",
    general_cond: "Condition générales",
    payments: "Méthode de paiement",
    how_many_persons: "De combien de personnes avez-vous besoin?",
    pickup_date: "Date d'enlèvement",
    delivery_date: "Date de livraison",
    floor: "Étage",
    elevator: "Ascenseur",
    other_item: `D’autres articles?`,
    quotation: "Demandez un devis",
    person: "personne",
    elevator_available: "Y a-t-il un ascenseur?",
    yes: "Oui",
    no: "Non",
    dont_need_person: `Je n'ai pas besoin de personne`,
    frank_shop: "Le Marché by Frank",

    particular: "Particulier",
    professional: "Professionnel",
    company_name: "Dénomination de l’entreprise ",
    shop_desc: "Description de votre entreprise",
    comp_address: "Adresse de votre entreprise",
    category: "Catégorie",
    categories: "Catégories",

    add_logo: `Ajouter le logo de l'entreprise`,
    add_banner: `Ajouter une bannière d'entreprise`,
    frank_shop_2: "Marché de Frank",
    forgot_password_desc: `Mot de passe oublié? Aucun problème! Entrez simplement votre adresse e-mail pour la réinitialiser en quelques étapes simples`,

    prod_title: "Titre",
    prod_desc: "Description du produit",
    prod_color: "Couleur",
    prod_price: "Prix",
    prod_contition: "Conditionner",
    optional: "Facultatif",
    close: "Fermer",
    not_verified_shop_message:
      "Votre demande pour la boutique Frank n'a pas encore été approuvée. On revient vers vous dans moins de 24h.",
    cart: "Panier",
    track: "Suivi",
    total: "Total à payer",
    service_charges: "Frais de plateforme",
    particular_relocation_desc: "Un déménagement entre particulier",
    pro_relocation_desc: "À venir",
    summary: "Résumé",

    deliver_order: `Livrer la commande`,
    pick_order: `J'ai récupéré le colis`,
    would_like_to: "Vous souhaitez",
    proposed_price_placeholder: "Proposition tarifaire",
    frank_recommended_price: `Le prix recommandé par Frank est compris entre `,
    recommended_price_error: `vous ne pouvez pas entrer un montant moins que le prix suggéré par Frank`,
    minimum_error: "Vous ne pouvez pas saisir un montant minimum de 1€",
    maximum_price_error: `Vous pouvez saisir un montant maximum de 9,999€`,
    reviews: "Évaluations",
    messages: "Messaging",
    brand: "Marque",
    used_condition: "Etat",
    inactive_shop: `Votre boutique est inactif, veuillez contacter le service support support@frankme.com`,
    add_logo_particular_shop: "Ajouter une photo pour votre profil",
    search_product: "Rechercher un article ou une marque",

    // new translatiions
    success: "Succès",
    error: "Erreur",
    try_again: "Essayer à nouveau",
    email_registered: `E-mail enregistré avec succès`,
tax_on_commission:"taxe sur la commission",
    title_error: "Le titre ne doit pas être vide",
    first_name_error: "Le prénom ne doit pas être vide",
    surname_error: "Le nom de famille ne doit pas être vide",
    email_error: `L'e-mail ne doit pas être vide`,
    success_login: "Connection avec succès !!",
    internal_error: `Nous rencontrons des problèmes techniques. Veuillez réessayer, si le problème persiste contactez le service client.`,
    network_failed: "Échec du réseau",
    network_failed_error: `Impossible de continuer. S'il vous plaît, vérifiez votre connexion à internet et réessayez.`,
    apple_auth_error: `Impossible de s'authentifier auprès d'Apple. Pour plus d'informations, veuillez nous contacter support@frankme.com`,
    facebook_auth_error: `Impossible de vous authentifier auprès de Facebook, veuillez réessayer plus tard. Si un problème survient, essayez de nous contacter à support@frankme.com`,
    user_cancelled_proccess: `L'utilisateur a annulé le processus d'authentification`,
    signin_with: "Se connecter avec ",
    valid_email: `L'e-mail doit être un e-mail valide`,
    password_error: "Le mot de passe ne doit pas être vide",
    user_not_exists: `Ce compte n'existe pas !`,
    // Mr,Mrs,Ms	Monsieur, Madame, Mademoiselle
    mr: "Monsieur",
    mrs: "Madame",
    ms: "Mademoiselle",

    personal_info_msg: `Faisons connaissance ! Veuillez saisir votre prénom, votre nom et votre date de naissance`,
    profile_added: `Détails du profil ajoutés avec succès`,
    user_name_error: `Le nom d'utilisateur ne doit pas être vide`,
    passowrd_not_match: `mot de passe et confirmer le mot de passe qui ne correspond pas.`,
    retype_password: "Veuillez retaper le mot de passe",
    set_credentials_success: `Les informations de connexion ont été définies avec succès`,
    password_info: `Le mot de passe doit comporter au moins 8 caractères et inclure les exigences suivantes`,
    length_desc: `les mots de passe doivent comporter au moins 8 caractères.`,
    uppercase_letter: `Lettres majuscules: `,
    uppercase_letter_desc: `Incluez au moins une lettre majuscule (A-Z).`,
    lower_letter: `Lettres minuscules: `,
    lower_letter_desc: `Incluez au moins une lettre minuscule (a-z).`,
    digits_letter: `Chiffres: `,
    digits_letter_desc: `Inclure au moins un chiffre (0-9)`,
    special_letter: `Caractères spéciaux: `,
    special_letter_desc: `Incorporez au moins un caractère spécial, tel que @,#,!,%,^,&,*,(,).`,

    too_weak: "Trop faible",
    weak: "Faible",
    moderate: "Modéré",
    strong: "Fort",
    very_strong: "Très Fort",
    phone_number_error: `Le numéro de téléphone ne doit pas être vide`,
    otp_sent_msg: `Un code d'accès unique (OTP) a été envoyé à votre numéro fourni. Veuillez saisir le otp`,
    phone_shared: `Votre numéro de téléphone sera partagé avec la société de livraison ou l'expéditeur afin qu'ils puissent organiser le ramassage et la livraison de votre colis.`,
    otp_empty_err: `Un mot de passe Tiem (OTP) ne peut pas être vide`,
    otp_validated: `Le code d’accès unique (OTP) a été validé avec succès. Vous pouvez maintenant vous connecter à votre compte.`,
    four_digit_code: `Nous avons envoyé un code à 4 chiffres à `,
    enter_otp_email: `Saisissez le code d'accès à usage unique (OTP) qui a été envoyé à `,
    valid_otp: `Veuillez saisir un OTP valide`,
    wrong_or_expored_otp: `L'OTP a expiré ou ne correspond pas. Veuillez demander un nouvel OTP ou réessayer.`,
    valid_otp_mesg: `OTP validé avec succès, veuillez entrer votre nouveau mot de passe`,
    password_reset_mesg: `Votre mot de passe a été réinitialisé avec succès ! Vous pouvez maintenant vous connecter avec votre nouveau mot de passe`,
    incorrect_credentials: "Identifiant ou mot de passe incorrect",
    add_address: `Ajoutez l'adresse`,
    address_saved_msg: `Adresse enregistrée dans le profil avec succès`,
    id_verification: `Vérification d'identité`,
    your_verification_status: `Votre statut de vérification est `,

    idenfy_head_1_desc: `Pour garantir des validations rapides des documents, envoyez des photos ou des scans`,
    idenfy_head_1: "En couleur: ",
    idenfy_head_2: "Complet: ",
    idenfy_head_3: "Lisible et de bonne qualité: ",
    idenfy_head_2_desc:
      "Les documents en noir et blanc ne seront pas acceptés.",
    idenfy_head_3_desc: `toutes les pages sont présentes, aucune information n'est masquée ou tronquée`,
    idenfy_head_4_desc: "ni flou, ni sombre, ni abîmé et sans reflet",

    start_verification: "Commencer la vérification",
    enable_notif: "Activer les notifications",
    enable_notif_desc: `Restez informé des dernières nouvelles et mises à jour en activant les notifications ! Cliquez sur le bouton ci-dessous pour activer les notifications et ne jamais manquer les informations et mises à jour importantes`,
    change: "Changement",
    view: "Voir",
    create_shop: `Créez votre boutique Frank`,
    manage_shop: "Gérer la boutique",
    add_product: "Ajouter des produits",
    manage_product: `Gérer le produit`,
    view_shop_orders: "Afficher les commandes",

    ind_shop_text: `Catégories limitées et vente annuelles jusqu'à 2500€`,
    pro_shop_text: `Catégories et vente annuelles Illimité`,
    products: "Produits",

    excellent: "Excellent",
    good: "Bien",
    average: "Moyenne",
    below_average: "Moyen",
    poor: "Mauvais",
    based_on: `basé sur`,
    overall_rating: `Note globale`,
    add_to_cart: "Ajouter au panier",
    item_added: `Article ajouté au panier`,
    cart_details: "Panier",

    checkout: "Vérifier",
    empty_cart: `Vider le panier`,

    checkout_confirm: `Etes-vous sûr de vouloir passer à l'étape paiement?`,
    remove_item_confirm: `Êtes-vous sûr de vouloir supprimer cet article du panier?`,
    clear_cart_confirm: `Êtes-vous sûr de vouloir vider votre panier ?`,
    no_items_cart: `Il n'y a aucun article dans le panier`,
    start_shipping: "Commencer vos achats",
    free: "Gratuit",
    shipping: "Expédition",
    expected_delivery: "Livraison prévue",
    select_deliery_method: "Veuillez sélectionner le mode de livraison",
    warning: "Avertissement",
    date_of_birth_placeholder:'JJ/MM/AAAA',
    order_summary: "Récapitulatif de la commande",
    get_ur_order: "Vous receverez votre commande dans les 3 heures",
    confirm_submit_order: "Veuillez confirmer et soumettre votre commande",
    agree_by_order: `En cliquant sur continuer le paiement, vous acceptez les conditions d'utilisation et la politique de confidentialité.`,
    continue_payment: "Continuer le paiement",
    leave_checkout: `Vous quittez la commande ?`,
    leave_checkout_msg: `Pas de soucis! Vous pouvez consulter les articles de votre panier avant de finaliser la commande. Appuyez sur « Retour » pour revenir.`,
    order_placed: "Votre commande a été passée avec succès",
    upload_image: "Veuillez télécharger au moins 1 image",
    select_size: "Veuillez sélectionner la taille du colis",
    remove_item: `Retirer l'objet`,
    upload_6_image: `Vous pouvez télécharger jusqu'à 6 photos seulement`,
    transporter_name: "Nom du transporteur",

    delivery_otp_code:
      "Donnez ce code à votre cavalier une fois la commande reçue.",
    delivery_otp_code_desc: `Veuillez vous assurer que ce code n'est partagé qu'à votre livreur.`,

    small: "Petit",
    medium: "Moyen",
    large: "Large",
    xl: "Extra Large",

    your_announcements: "Votre Annonce",

    declined: "Refusée",
    decline: "Refuser",

    send_message: "Envoyer un message",
    handling_charges: "Frais de service",
    sub_total: "Total",
    earned: "Gagné",
    view_details: "Voir les détails",
    no_item_found: "Aucun article trouvé",
    select_product: `Sélectionner un produit`,
    search_item: "Rechercher un article",

    choose_payment_method: "Choisissez le mode de paiement",

    male: "Monsieur",
    female: "Madame",
    other: "Non binaire",
    un_paid: "Non Payé",

    company_name_error: `Le nom de l'entreprise ne doit pas être vide`,
    siret_error: `SIRET ne doit pas être vide`,
    shop_category_error: `Veuillez sélectionner la catégorie de boutique`,
    address_error: `L'adresse ne doit pas être vide`,

    product_name_error: "Le nom du produit ne peut pas rester vide",
    product_price_error: "Le prix ne peut pas rester vide ou 0€",
    product_category_error: "La catégorie ne peut pas rester vide",
    product_image_error: "Mettez au moins une photo",

    item_name_error: "Veuillez saisir le nom ou le titre du produit/article",
    quantity_error: "Veuillez entrer la quantité",

    marked_read: `Marqué comme lu`,

    order_accept_msg: `Avez-vous récupéré la commande?`,
    // order_accept_msg: `Êtes-vous sûr de vouloir mettre à jour le statut de cette commande de « Acceptée » à « En cours » ?\nUne fois la commande préparée, elle ne peut pas être annulée.!`,
    order_deliver_msg: `Êtes-vous sûr de vouloir livrer cette commande?\nUne fois la commande livrée, elle ne peut pas être annulé`,

    otp_confirmation: "Confirmation OTP requise",
    delivery_otp_message: `Veuillez demander au client l'OTP pour confirmer la livraison.\nEntrez l'OTP ci-dessous pour terminer le processus`,
    pickup_otp_message: `Veuillez demander au client le code de retrait pour confirmer le ramassage`,

    enter_o_password: "Veuillez entrer votre ancien mot de passe",
    password_changed_seccess: `Le mot de passe a été modifié avec succès. Veuillez vous reconnecter avec votre nouveau mot de passe.`,
    enter_n_password: "Veuillez entrer votre nouveau mot de passe",

    leave_feedback: `Laisser un commentaire`,
    cust_feedback_err: `La notation du client est requise en cas de soumission de feedback`,

    out_of_range: `Vous êtes hors de la zone de livraison`,
    expected_deliver_dates: `Por favor seleccione las fechas de entrega previstas`,
    order_amt: "Total de la commande",

    messages_: "Messages",
    pay_with: `Payer avec `,
    relocation_announce: "Déménagement",

    track_order: "Suivre le colis",
    tracking_number: `Numéro de suivi`,
    update_shop: "Mettre à jour la boutique",

    open_setting: "Ouvrir les paramètres",
    done: "Fait",
    payment_cancel: "Vous avez annulé le processus d'autorisation de paiement.",
    sub_category: `Sous-catégorie.`,
 
    All_announes: "Tout",
    Express_announces: "Express",
    ArountMe_announces: "Autour de moi",
    Forgot_password_title:"Mot de passe oublié",
    your_product:"Vous Produit"

  },
  en: {

//Terms and condition and privacy policy
 
  //terms
  terms_and_conditions: "TERMS AND CONDITIONS OF USE OF THE FRANK SERVICE",

  terms_and_conditions_para_1:
    'PEOPLES POST (hereinafter "FRANK" or the "Company") publishes a multimodal transport services platform, accessible via its website and in the form of a mobile application. This platform is intended to (i) connect people wishing to transport parcels, or any other type of authorized object, between different locations, whether inside or outside France, (ii) offer a variety of shipping and courier services covering all modes of transport (road, rail, air, river, sea) and business models (B2B, B2C, C2B, C2C, D2C).',

  terms_and_conditions_para_2:
    "The purpose of these Terms and Conditions of Use of the service, including the appendices (hereinafter the 'TCU') is to define the terms and conditions of use of the FRANK Platform by all persons having access to it (hereinafter referred to as a 'User'), or wishing to be put in contact with another 'User' to entrust him with the transport of any object and/or to temporarily store this object (hereinafter referred to as the 'Services'). ",

  terms_and_conditions_para_3:
    "These TCU are entered into between the User and the Company, individually referred to as the 'Party', collectively the' 'Parties'.",

  terms_and_conditions_para_4:
    "The TCU can be accessed at any time via a direct link on the site and the application. They can be downloaded by clicking on the following link: [Download TCU in PDF].",

  terms_and_conditions_para_5:
    "For any request relating to the use of the Platform, the User is invited to send an e-mail to the following address: ",

  Article_1: "Article 1.Definition ",
  Article_definition_para_1:
    "The following terms have the following meaning in the TCU when capitalized. When used in the singular or plural, they have the same meaning.",

  Account: "Account",
  Account_des:
    "Refers to the personal interface that the User must create to access the Services on the Platform.",
  Additional_Customer_Benefits: "Additional Customer Benefits",
  Additional_Customer_Benefits_des:
    "Refers to the various services available to Customers through FRANK, including but not limited to the opportunity to purchase products from mini shops, the option to rent advertising banners, and potential future access to FRANK care services and other enhanced offerings. These services provide additional value and convenience to Customers, allowing them to benefit from a broader range of options and features.",
  Advertisement: "Advertisement",
  Advertisement_des:
    "Refers to the message published on the Platform by a User with the aim of either requesting Parcel transport services, offering Co-transportation services or offering storage space. The advertisement includes details such as the nature and dimensions of the Parcel, the specific transport requirements, the desired destination and the budget or price proposed by the Customer for the transport. This publication is intended to initiate a process of establishing contact with Carriers or other Users of the platform and may evolve into a formal order following acceptance by a Carrier or another User interested in the offer presented. ",
  Carrier: "Carrier",
  Carrier_des:
    "Refers to any person, professional or otherwise, using the Platform to transport a Customer's parcel to a specific destination (Carrier, Secondary Carrier, Air Carrier, Fleet Manager), it being specified that several carriers may be involved in the same delivery. ",

  Collection_point: "Collection point ",
  Collection_point_des:
    "Refers to the location agreed upon by the Carrier and the Customer for the deposit and pick-up of the Parcel. Collection Points are utilized by Customers, Carriers, Consignees, and FRANK for the collection of Parcels. Additionally, Collection Points may serve as designated drop-off and pick-up locations managed by space providers, where the regrouping of Parcels occurs in accordance with FRANK's best practices. This regrouping service enhances the efficiency of the logistics process by consolidating Parcels before their final delivery or distribution.",

  Company: "Company",
  Company_desc: "FRANK Company as defined in the preamble hereto.",
  Contribution: "Contribution ",
  Contribution_des:
    "Refers to the amount of the contribution to transport costs and/or storage costs paid by the Customer to the Carrier.",
  Cotransporter: "Cotransporter",

  Cotransporter_des:
    "Refers to a private individual who offers Parcel transport services as part of a trip on their own behalf and without charge, except for the sole contribution to the cost of transport and temporary storage of the Parcel.",
  Customer: "Customer",
  Customer_des:
    "Refers to any natural or legal person (an individual, a transport professional, a self-employed entrepreneur, a service provider) registered or not on the Platform and using the Services to transport a parcel to a destination.",

  Drop_off_point: "Drop-off point",
  Drop_off_point_des:
    "Refers to the destination agreed between the Carrier and the Customer where the Carrier is to deliver the goods to the Consignee. These points will also be used by Customers, Carriers, Consignees, and FRANK to drop off packages.",

  Packages: "Packages",
  Packages_des_1:
    "Refers to parcels and/or objects, and/or items, of any nature whatsoever, which are lawfully transported by a Carrier to a destination agreed with the Customer, such as a drop-off point or collection point.",
  Packages_des_2:
    "The non-exhaustive list of items that may not be transported is shown in article 8; In any event, the Carrier is bound by an obligation to check the parcel that it takes in charge and remains solely responsible for the Parcel in its custody. ",
  Pick_up_point: "Pick-up point",
  Pick_up_point_des:
    "Refers to the destination agreed between the Carrier and the Customer where the Customer must deposit the Parcel for the Carrier to pick up. These points will also be used by Customers, Carriers, Consignees, and FRANK to collect packages.",
  Platform: "Platform",
  Platform_des:
    "FRANK applications developed by the Company. These applications include the carrier application, the customer application and the website. App and web service will include Transport, Logistique, Storage, Freight and IT consulting.",

  Professional_carrier: "Professional carrier ",
  Professional_carrier_des:
    "Refers to a licensed and registered entity that provides transportation services for parcels and goods on behalf of customers. A Professional Carrier is responsible for the secure and timely delivery of items, adhering to all relevant legal and regulatory requirements. This entity is distinguished by its professional qualifications, capacity, and adherence to industry standards, ensuring a high level of service and reliability for both individual and business clients.",
  Profile: "Profile",
  Profile_des:
    "Refers to the personal interface that the User must create to access the Services on the Platform.",
  Recipient: "Recipient",
  Recipient_des_1:
    "Refers to any person receiving the Parcel transported by the Carrier at the Customer's request and confirming the performance of the Service. The Recipient is not necessarily a Platform User.",
  Recipient_des_2:
    "Refers to a company that professionally transports Parcels and/or items of any kind and whose services are offered on the Platform.",

  Service: "Service ",
  Service_des_1:
    "Refers to the service for transporting Parcels and/or items or objects of any kind between private individuals and/or professionals (B2B, B2C, C2B, C2C, D2C) to private individuals and/or professionals between different locations, provided by the Company.",
  Service_des_2:
    "As part of the service, Packages and/or items from different Users may be grouped together for dispatch to their destination and then separated for delivery.",
  Service_des_3:
    "The service may be used by registered Users with a Profile Account or by unregistered Users who do not have a Profile Account.",
  Storage: "Storage",
  Storage_des:
    "Refers to the service offered by FRANK that provides users with access to storage units or storage spaces for rent, either on a short-term or long-term basis. This service includes the provision of storage boxes for personal or professional use, allowing customers to store their movable belongings for specified or unspecified durations.",
  Storage_provider:
    "Refers to the professional entity that owns and manages the storage facilities offered through FRANK. The Storage Provider is responsible for maintaining the storage units or storage spaces, ensuring their availability and security for users who rent these spaces on a short-term or long-term basis. This includes the provision of storage boxes for both personal and professional use, as well as the administration of rental agreements for specified or unspecified durations.",

  Supplier: "Supplier",
  Supplier_des_1:
    "Refers to any online shop that offers services to its customers (via a marketplace or D2C direct sales) or any registrant that can provide its location in transit (e-merchant shop; D2C direct sales shop).",
  Supplier_des_2:
    "Also refers to a Parcel space or Parcel relay point or a Parcel management shop, i.e. a temporary location between the origin and destination of the Parcel where the Customer and the Carrier can agree on a temporary storage location for the Parcel.",
  Supplier_des_3:
    "Any Customer or Carrier registered on the platform may offer their location as a Parcel space.",

  Terms_and_Conditions_of_Sale: "Terms and Conditions of Sale",
  Terms_and_Conditions_of_Sale_des:
    "Refers to the Professional Carrier's General Terms and Conditions of Sale.",
  User: "User",
  User_des:
    "Refers to any person using the Services, whether a Customer, a Carrier, a Consignee or a Supplier.",

  Artice_2: "Article 2. Access, acceptance and modification of the TCU",
  Artice_2_1_head: "2.1 Platform access conditions and availability",
  Artice_2_1_para_1:
    "The Platform and the Services provided therein are intended for adults over the age of 18 who have full legal capacity to order on the Platform. Users who are minors must be authorized by their legal representative in order to place an order on the Platform. The Company reserves the right to ask any User for proof of identity concerning their age and the authorization of their legal representative.",

  Artice_2_1_para_2:
    "All carriers must have a vehicle that complies with safety standards and is suitable for transporting the Parcel, as well as all the legal documents required to drive the said vehicle, including a valid driving license and valid third-party insurance.",

  Artice_2_1_para_3:
    "The Company may verify certain information in order to facilitate the provision of the Service, to improve it and to prevent or detect fraud.",

  Artice_2_1_para_4:
    "All users of the Platform agree to comply strictly with these Terms and Conditions of Use and with all laws applicable to their activity on the Platform. This includes, but is not limited to, national and international regulations in force concerning transport, subcontracting and co-transportation, as well as standards relating to the security and prohibition of certain goods. The Company reserves the right to check Users' compliance with the terms of use and applicable laws at any time.",
  Artice_2_1_para_5:
    "In the event of proven non-compliance, after appropriate verification, the User may be banned from accessing the Platform. Prior to any temporary or permanent ban, the User concerned will be notified of the reasons for this decision and will be able to respond or provide explanations. The User will have the right to appeal against any banning decision. The details of this appeal will be communicated to the User at the same time as notification of the ban and must be exercised within a specified period.",
  Artice_2_1_para_6:
    "In any event, the Company uses all reasonable means at its disposal to keep the Platform accessible seven (7) days a week and twenty-four (24) hours a day.",
  Artice_2_1_para_7: `However, access to the Platform is provided by the Company "as is" and "as available". Access to the Platform may be interrupted or limited due to maintenance required to improve and/or preserve the Platform, including software and hardware updates, without the interruption giving rise to any obligation or compensation. Similarly, due to unforeseen circumstances such as, but not limited to, technical failures, Internet network problems, or cases of force majeure such as natural disasters, strikes, riots, acts of terrorism, or any other situation beyond the Company's reasonable control. The Company cannot be held responsible for interruptions or limitations to access to the Platform caused by such events. Therefore, although we endeavor to minimize such disruptions and to restore the service as quickly as possible, the Company does not guarantee uninterrupted or error-free availability of the Platform.`,
  Artice_2_1_para_8:
    "Platform Users are invited to take the necessary steps to protect themselves against possible interruptions when using the Platform, in particular by saving important information and providing alternatives in the event of service unavailability.",

  Artice_2_2_head: "2.2 Registration",
  Artice_2_2_para_1:
    "Any registration or use of the Platform or ordering of a Service implies unreserved and unrestricted acceptance of these TCU. When registering, each user may expressly accept these TCU by ticking the box provided for this purpose. This action does not require a handwritten signature.",

  Artice_2_3_head: "2.3 Deregistration ",

  Artice_2_3_para_1:
    "The User registers on the Platform to use the Services for an indefinite period. The User may decide to permanently close his/her Profile at any time, ipso jure and without legal formalities, by unsubscribing via the Profile configuration page or by requesting unsubscription by e-mail to the following address DPO@frankme.com. Unsubscription takes effect immediately. It automatically deletes the User's data and Profile, however, the User must complete any collections, transports, co-transports or storage in progress or honor any commitments made prior to the said termination.",

  Artice_2_4_head: "2.4 User profile",

  Artice_2_4_para_1:
    "Access to the Platform is free and open, thanks to a registration process which allows the opening of a User Profile even without registration. The Profile can be created using the form on the Platform. The User will be required to provide personal information, which will be processed in accordance with the Company's privacy policy available on the Platform at the following link: [insert link to privacy policy].",

  Artice_2_4_para_2:
    "The Company may carry out other types of verification and clarification necessary for the use of the Platform. The User guarantees that all the information provided in the registration form is accurate, up-to-date, truthful, and not misleading. It is therefore the User's responsibility to communicate any changes to personal information such as address, telephone number or payment details. Any registration containing inaccurate information will not be processed. Information such as passwords or usernames is confidential in nature. It is the User's responsibility to take the necessary measures to ensure their confidentiality. The User undertakes not to divulge his/her password and to inform the Company of any unauthorized access to his/her Profile. The User undertakes to use his Profile on a personal basis and not to allow any third party to use it in his place or on his behalf unless he assumes full responsibility for it. Failing this, the Company may not be held liable, and the Company reserves the right, if justified, to suspend or delete the User's Profile.",
  Artice_2_5_head: "2.5 Acceptance and modification of the TCU",
  Artice_2_5_para_1:
    "Before registering or using the Platform, you acknowledge that you have carefully read these Terms and Conditions of Use. If you do not accept the TCU, you must give up access to the Platform.",
  Artice_2_5_para_2:
    "In order to contribute to the continuous improvement of its operation and to adapt its Service to developments in its sector, the Company reserves the right to modify the characteristics and functionalities of the Platform. This includes adding, deleting or replacing certain features. Because of this need to evolve, the TCU may also be updated regularly. Any substantial change to the TCU is applicable immediately after Users have been notified by e-mail. Users may terminate the TCU if they do not accept the changes. However, they must complete any transport in progress or honor any commitment made prior to termination.",
  Artice_3: "Article 3.Description of the service",

  Artice_3_1_head: " 3.1 Purpose of the service",

  Artice_3_1_para_1:
    "FRANK allows Customers to have a Parcel delivered (in-store shipment, Customer-to-Customer road shipment, Customer-to-Customer train shipment, Customer-to-Customer air shipment and/or Storage or any other present or future option) by a Carrier either to a location mutually agreed with the Carrier by means of private exchanges on the Platform, or by choosing a location proposed by a Supplier. Depending on the case, between the point of departure and the point of destination of the Parcel, the Services provide one or more places for the temporary storage of the Parcel. These locations are selected on an optimization of the transport , ensuring efficiency, security and minimal transit time as determined by FRANK.",

  Artice_3_2_head: "3.2 Advertisements",

  Artice_3_2_para_1:
    "Customers and Suppliers are authorized to publish advertisements on the Platform, which will be accessible to Carriers in particular. It is essential that each announcement is written clearly and accurately to ensure effective communication. Customers must specifically mention in their advertisements any particular condition or difficulty relating to the package concerned, such as special handling requirements, additional precautions required, or any other aspect requiring increased vigilance on the part of the Carrier. The purpose of this disclosure is to enable Carriers to adequately prepare the shipment and ensure safe transport. Suppliers offering storage space must clearly indicate in their advertisements important information such as the exact address of the storage point, opening hours and any other relevant information that may affect the routing and delivery of packages. The purpose of this disclosure is to enable Carriers to adequately prepare the shipment and ensure safe transport.",
  Artice_3_2_para_2:
    "Once the Advertisement has been published, the User may receive offers from Carriers. Each Ad posted by a User becomes an order as soon as it is accepted by a Carrier, thus forming a contract. This order is accompanied by a level of financial commitment, represented by a price or budget previously determined under the sole responsibility of the Customer and which corresponds to the maximum amount that he/she intends to pay for the transport of the Parcel.",
  Artice_3_2_para_3:
    "Before responding to an Advertisement, the Carrier must have completed all the information required in their User Profile, in accordance with the provisions of these Terms and Conditions of Use (TCU). By replying to an Advertisement, the Carrier undertakes to provide details of its proposal, including the day and time scheduled for collection and delivery of the Parcel.",
  Artice_3_2_para_4:
    "By responding to an Advertisement, the Carrier confirms its understanding and acceptance of the specific conditions mentioned by the Customer. The Carrier undertakes to respect all the precautions indicated for the handling and transport of the parcel.",
  Artice_3_2_para_5:
    "Without prejudice to the Carriers' responsibility to verify any Parcels collected, the Platform may implement a verification process to ensure that the information provided in the advertisements and user profiles is accurate and complies with the required quality standards. Any failure to comply with these obligations may result in corrective action, including, but not limited to, the modification or deletion of the listing, or restrictions on access to the Platform for the parties concerned.",

  Artice_3_3_head: "3.3 Connecting people",
  Artice_3_3_para_1:
    "FRANK is limited to connecting the Customer with the Carrier and, if applicable, the Customer and the Carrier with a Supplier. FRANK does not provide any transportation or storage services in connection with the delivery of the Parcel. ",
  Artice_3_3_para_2:
    "The Carrier's services are governed in particular by the law, by any special conditions between the Customer and the Carrier, as well as, where applicable, by the latter's Terms and Conditions of Sale, which must be accepted by the Customer before finalizing the Order. The Customer thus acknowledges that, apart from the conditions mutually agreed with the Carrier at the time of the Order, the conditions of Carriage of the Parcel are those set out in the Carrier's GCS. In the event of carriage between two private individuals, it is imperative that both parties comply with the legal obligations in force, in particular those relating to civil liability and the carriage of goods between non-professionals. These provisions include, but are not limited to, compliance with security standards, the confidentiality of information exchanged, and the integrity of the goods transported. ",

  Artice_3_3_para_3:
    "Following an Advertisement, one or more transport offers may be automatically generated by the Company as being the most appropriate and then submitted to the Customer's free assessment. Consequently, the Company may not be held liable if the Customer refuses to accept the Carrier's offer. The Client also remains free to accept or refuse the Carrier's offer and to accept the offer of another Carrier or to choose another place of delivery.",

  Artice_3_3_para_4:
    "Once the Customer accepts the Transport offer and the Carrier agrees to take charge of the Customer's Parcel, the Users determine together the conditions of collection and delivery by means of private messages on the Platform.",

  Artice_3_3_para_5:
    "You acknowledge and agree that if several intermediaries are involved in the delivery of the Customer's Parcel, or in the event of a change in the delivery route or method, FRANK may intervene as a last resort to facilitate compliance with the Users' commitments.",

  Artice_3_3_para_6:
    "FRANK is not a party to the agreement or contract formed between the Users of the Platform, nor to any agreement established between the Users of the Platform and a third party. FRANK's role is limited to facilitating access to its service and exchanges between Users. FRANK assumes no responsibility or liability for contractual relationships formed between Users or with third parties. ",

  Artice_4: "Article 4. Prices",

  Artice_4_para_1:
    "Registration, access, searching, publication and consultation of Service offers on the Platform are free of charge for any User with Internet access. Costs associated with accessing the Platform are the sole responsibility of the User. ",
  Artice_4_para_2:
    "Use of the Services is subject to payment under the conditions set out below: ",
  Artice_4_1_head: "4.1. Prices",

  Artice_4_1_para_1:
    "The prices displayed on the Platform are expressed in Euros (€) and are applicable in mainland France, including Corsica. These prices include all applicable discounts, as well as customs duties, government fees and other charges of any kind, in addition to the VAT in force at the time of the order.",
  Artice_4_1_para_2:
    "The prices quoted on the Platform, whether in an advertisement or during negotiations between Users, are inclusive of all taxes. Where applicable, these prices include the costs of making the connection, customs, government fees € which are detailed at the time of payment by the Customer. The Carriers will be clearly informed if the final price to be paid to them is net of commission, thus allowing total transparency on the amount actually paid after deduction of the fees payable to FRANK.",

  Artice_4_1_para_3:
    "In the event of a change in the applicable VAT rate, the price of the services will be adjusted accordingly.",

  Artice_4_1_para_4:
    "Promotional offers are valid for the period specified and cannot be combined with other discount offers.",

  Artice_4_1_para_5:
    "For users located outside the European Union, where the Euro is not the local currency, prices will be displayed in the applicable local currency. These prices will be calculated on the basis of the current exchange rate at the time the order is finalized and will include any taxes and charges applicable in the country concerned. The local currency displayed will be used for all financial transactions related to the order.",

  Artice_4_2_head: "4.2 Order value",
  Artice_4_2_para_1:
    "When using the Platform, the Customer is obliged to define a financial limit for the transport service he wishes to obtain. This amount represents the maximum price including VAT that the Customer is prepared to pay for the transport of the Parcel, excluding additional service charges applied by FRANK. This financial limit is clearly displayed in the Customer's advertisement.",
  Artice_4_2_para_2:
    "The Carriers may respond to this announcement by explicitly accepting the proposed amount, by refusing it, or by submitting a counter-offer for a higher amount. The amount finally agreed between the Customer and the Carrier, added to the Platform's service charges, constitutes the total cost including VAT that the Customer undertakes to pay for the transport of the Parcel. This is the price of the order, unless otherwise provided by the law specifically relating to the transport of goods as set out in Appendix 2. In order to prevent abuse and to guarantee the quality of the service, or to comply with applicable regulations, FRANK reserves the right of moderation.",

  Artice_4_3_head: "4.3. Service charges",
  Artice_4_3_para_1:
    "For each transaction on the Platform, the Company charges a service fee corresponding to the connection between the Customer and the Carrier. When the Customer books transport with the Carrier, the Customer pays the amount agreed with the Carrier, plus the service fee, under the conditions set out below. Additionally other service fees rendered by FRANK or requested by the Customer, referred to as Additional Customer Benefits, may also be charged.",

  Artice_4_3_para_2:
    "The service fees are indicated before any payment for the Services by the User, excluding VAT on the amount of the order plus a VAT rate of twenty (20) %.",
  Artice_4_3_para_3:
    "The Company reserves the right to change the amount of the Service fee at any time. These changes will have no effect on the Service charges accepted by users prior to the date on which the changes take effect.",

  Artice_4_4_head: "4.4. Transport prices",

  Artice_4_4_para_1:
    "The transport price means the final amount agreed between the Customer and the Carrier for the service of transporting the Parcel, calculated on the basis of various parameters considered by the Platform such as, in particular, the distance traveled, cubic meters, the type of transport, the urgency of the delivery, the weight of the Parcel, the type of package, etc.",
  Artice_4_4_para_2:
    "This amount is subject to a deduction which includes a transport commission due to FRANK. Once the Carrier selected by the Customer has accepted the request made on the Platform and until receipt of the Parcel by the Consignee, the payment for the transport is held in an escrow account, at the discretion of the companies in the event of a change to the itinerary or delivery method.",
  Artice_4_4_para_3:
    "From the moment when confirmation is deemed to have been given, the price of the transport may be consulted on the Carrier's User Profile. Once the Parcel has been delivered to its final destination, the transport price is paid to the Carrier, after deduction of any intervention costs due to the Company and/or the various parties involved, such as, in particular, the Company's service providers. In the event that delivery of the Parcel requires more than one Carrier to reach its Drop-Off Point, payment will be made, subject to compliance with the payment policy, once the Parcel has reached the last Drop-Off Point or the Final Recipient. The Carrier may initiate the transfer of the net amount from his User Profile or the dedicated interface.",
  Artice_4_4_para_4:
    "Payment is made by bank transfer using the information provided by the Carrier on their User Profile or the interface. The Company shall under no circumstances be liable to the Carrier for any payment incident, for any reason whatsoever, in particular in the event of the card being blocked or used fraudulently. The Carrier undertakes to return to the Company, on first request, any sum received that may have been implicated due to such a payment incident.",
  Artice_4_5_head: "4.5. Contribution to co-transportation costs",

  Artice_4_5_para_1:
    "As a Carrier using our Platform, you are responsible for setting the financial contribution relating to the co-transport service. It is essential to understand that our Platform is intended to facilitate the sharing of costs without generating profits. You must therefore ensure that the contribution requested from Users corresponds solely to expenses directly linked to the provision of the cotransport service.",
  Artice_4_5_para_2:
    "When an Advertisement for a co-transport service is placed online, our company offers an initial estimate of the contribution to the co-transportation costs. This estimate is based on factors such as the specific nature of the service and the total distance of the transport. Although this estimate is indicative, you are free to adjust it so that it accurately reflects your actual costs. However, to maintain fairness and avoid abuse, our company may intervene to moderate excessive adjustments to the financial contribution.",
  Artice_4_5_para_3:
    "If you do not comply with these guidelines and set a contribution that exceeds your actual costs, you run the risk of having the operation legally requalified. In such a case, you will be held solely responsible for any legal or regulatory consequences resulting from this reclassification.",

  Artice_5: "Article 5. Order, cancellation of reservation",

  Artice_5_1_head: "5.1. Control",
  Artice_5_1_para_1:
    "For the purposes of these Terms and Conditions, the Order is understood to be the legal act by which the User requests a Parcel transport service on the Platform. The special terms and conditions applicable to the Order take into account the specific terms and conditions for its formation in the case of public road transport or co-transport, which are set out in the Appendices.",

  Artice_5_2_head: "5.2. Cancellation of reservation ",
  Artice_5_2_para_1:
    "In the event that the Carrier or the Supplier acts as a professional, the Customer has the right of withdrawal during the fourteen (14) days following the date of subscription. However, in accordance with article L. 221-28 13 of the French Consumer Code, the Customer may not exercise this right if the Service is started within this period. Apart from the case mentioned above, if a User cancels the booking after confirmation, the fees will be refunded in part or in full in the cases detailed below: ",

  Artice_5_2_point_1:
    "In the event of cancellation attributable to the Carrier, the Customer will be refunded all sums paid, without any additional compensation; If the cancellation occurs within forty-eight (48) hours of confirmation of the reservation, the Carrier will be invoiced an amount equal to fifty percent (50%) of the sum paid by the Customer, to cover the cost of a new reservation to carry out the Service, to the exclusion of any other costs. ",
  Artice_5_2_point_2:
    "If the Carrier modifies the general conditions of the journey without the Customer's agreement, the Customer may request the cancellation of his/her reservation. The Customer will be refunded all sums paid. ",
  Artice_5_2_point_3:
    "In the event of cancellation attributable to the Customer no later than twenty-four (24) hours following confirmation of the reservation, the Customer will be reimbursed the amount paid, less the amount of the mobilization costs involved in setting up the delivery chain and a percentage of XXX of the sum paid to the Carrier.",

  Artice_6: "Article 6. Liability and insurance",

  Artice_6_1_head: "6.1 Responsibilities ",
  Artice_6_1_para_1:
    "The use of the Platform and the receipt of payments as a Carrier are subject to the provision of all the documents required by the regulations in force for the transport of goods and the fight against money laundering. This includes the transport license, the certificate of insurance for the transport of goods, as well as, for commercial companies, the identity document of the director, a Kbis extract less than 3 months old, the articles of association of the Carrier's company and the declaration of beneficial owners. By using the Platform, each user guarantees that they meet all the above conditions. They are responsible for the truthfulness and legality of the information they provide when registering and using the Platform's services.",
  Artice_6_1_para_2:
    "The Company is only an intermediary enabling Customers and Carriers to contact each other and can never be considered as a party to the business relationship between the Customer and the Carrier. It does not intervene in any way in the transactions that they carry out on the Platform, on their own initiative and within the framework of the conditions that they set themselves. The Company may not be held liable for any indirect damage that may arise as a result of the Customer purchasing the Services. The Company may not be held liable for non-performance of the reservation due to a case of force majeure as defined herein and in the law and case law. Similarly, the Company may not be held liable in the event of damage resulting from the use of the Internet network, such as a virus, intrusion, loss of data, interruption of the Internet service, etc. The User acknowledges that the Company may not be held liable for any direct, indirect, incidental, special, fortuitous, consequential or punitive damages, or for any loss of profits or revenue that may be suffered directly or indirectly, in particular in connection with access to or use of the Platform, the inability to access or use the Platform, or any unauthorized use or alteration of the User's transmissions.",
  Artice_6_1_para_3:
    "Furthermore, as a mere intermediary, the Company cannot be held liable for any disputes between Users. Users are fully informed that the transaction in which they are involved, whatever its quality and nature, is the direct and exclusive responsibility of each party to the transaction, to the exclusion of the Company. The Company cannot be held responsible for the illicit content of the Parcels. Unless otherwise provided by law, each User releases the Company from any liability in the event of a claim and/or dispute between Users, and/or for any present or future damage, presumed or not, proven or not, resulting directly or indirectly from such a claim and/or dispute.",
  Artice_6_1_para_4:
    "The Supplier is responsible for its storage location. In this respect, it must hold an appropriate insurance policy. The Supplier must inform its insurer of its intention to make its location available to a third party, in order to obtain its agreement.",
  Artice_6_1_para_5:
    "If the Supplier is a professional, it must also hold insurance covering material and environmental risks. The Supplier is responsible for handing over to the Carrier and/or returning to the Customer the stored Package in the condition in which it was when it was handed over by the Customer.",
  Artice_6_1_para_6:
    "The Supplier is obliged to inform the Customer as soon as possible of any loss, deterioration or theft of the stored Parcel. The Supplier is therefore the depositary of the goods deposited, within the meaning of Articles 1915 et seq. of the Civil Code concerning the deposit contract. The Company offers the insurance policy provided for in the article below.",

  Artice_6_2_head: "6.2 Insurance",

  Artice_6_2_para_1:
    "The company has taken out an insurance policy with [insert company name].",
  Artice_6_2_para_2:
    "The insurance covers material damage and theft of goods. This insurance policy protects the owner of the entrusted Parcel when the goods are being transported by the Carrier. The Customer undertakes to inform the Company of any damage to its property that may be included in the insurance policy within five (5) working days of detecting the damage. The Company will then manage the file with the Client and, where applicable, with the insurance company. The processing procedures are specified in the insurance Information Note available here [insert link].",
  Artice_6_2_para_3:
    "In any event, the Company may not be held liable for any damage or loss that may occur to the Parcels.",

  Artice_7: "Article 7.Personal data, intellectual property",
  Artice_7_1_head: "7.1 Personal data",
  Artice_7_1_para_1: `In accordance with Act no. 78-17 of 6 January 1978, as amended, relating to information technology, files and freedoms, known as the "Information Technology and Freedoms Act", and Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data (hereinafter referred to as the "RGPD"), Users are informed that the Company automatically processes their personal data for the purpose of using the Services, processing and managing commercial relations and orders.`,
  Artice_7_1_para_2:
    "This data may be transmitted to subsidiaries and/or companies controlled by the Company and to subcontractors who contribute to these relations for their management, execution, processing and payment. Each User has the right to access, modify, rectify, limit, port and delete data concerning them: as well as the right to object to the processing of their personal data. To do so, they must contact the helpdesk by e-mail at the following address: Address: DPO@frankme.com",
  Artice_7_1_para_3: `Users understand that they can make the necessary changes and deletions themselves as regards rectifications and updates to their Profile data. For further information, Users may refer to the Privacy Policy accessible on the Platform under the heading "Privacy Policy". This policy forms an integral part of the Terms and Conditions. Acceptance of the TCU implies acceptance of the corresponding Privacy Policy.`,

  Artice_7_2_head: "7.2 Intellectual property rights",
  Artice_7_2_para_1: `The Company is the exclusive owner of the intellectual property rights or holds the rights of use on all the elements accessible on the Platform, including the visual and textual elements 27 components of the Platform, the "FRANK" trademark and logos, the graphics of the Platform as well as drawings, models, photographs, texts, illustrations, logos, animated or non-animated sequences, with or without sound, graphics etc. found on this Platform as well as their compilations are the exclusive property of the Company, the structure and software used to operate the Platform, as well as all the data collected. All these elements are protected by all intellectual property rights or by the producers of databases of rights in force.`,
  Artice_7_2_para_2:
    "Any unauthorized use or reproduction, even partial, of the Platform constitutes an infringement.",
  Artice_7_2_para_3:
    "Apart from the elements published under a free license and for the exclusive purpose of information for personal and private use, all reproductions or representations, even partial, by any means whatsoever, all automated or non-automated requests to retrieve data published on this Platform, and all use of copies made for other purposes are expressly prohibited. Neither all or part of the Platform, nor its content, nor the trademarks may be used, reproduced, duplicated, copied, sold, resold, made accessible, modified or exploited in any other way, in whole or in part, for any purpose whatsoever, made without the publisher's authorization are unlawful and constitute an infringement within the meaning of the provisions of the French Intellectual Property Code. Any other use constitutes an infringement.",

  Artice_8:
    "Article 8.Security, goods excluded from transport from the Platform",

  Artice_8_1_head: "8.1 Safety",
  Artice_8_1_para_1:
    "The Company undertakes to rectify as soon as possible any inaccurate or obsolete information that may be present on the Platform. The Platform may contain several links to third-party sites or resources. The User acknowledges and accepts that the Company may not under any circumstances be held liable for any damage to or the availability of websites operated by third parties, including its possible partners, which Users may access via the Platform. Nor is the Company responsible for the content, products or services available on these third-party sites, which are governed by their own terms of use. Use of these sites or resources is the sole responsibility of the User.",
  Artice_8_1_para_2:
    "The Platform may contain User notices of interactive features that allow Users to submit, publish, display or transmit content to other Users or other persons on or via the Platform.",
  Artice_8_1_para_3:
    "All User contributions must comply with the content set out in the Charter of Good Conduct in Appendix 1.",
  Artice_8_1_para_4:
    "The User understands that he/she is responsible for the contributions submitted by him/her or on his/her behalf. The Company assumes no responsibility in this respect for the content, including its legality, reliability, accuracy and relevance. The Company does not undertake in any event to check a contribution before it is published. This may happen after the event. The Company cannot be held liable to any third party for the content or accuracy of the User's contributions. The Company reserves the right to delete or modify any content posted online by a User who does not comply with these TCU, or who communicates false or inaccurate information or who harms a User of the platform.",
  Artice_8_2_head: "8.2 List of excluded objects",

  Artice_8_2_para_1:
    "This list is not exhaustive. There may be specific regulations in certain countries, in which case you should check with the customs authorities of the countries concerned. In any case, before sending or collecting a Parcel, always make sure that it presents no danger.",

  Artice_8_2_point_1: "Narcotics and psychotropic substances,",
  Artice_8_2_point_2: "Counterfeit products,",
  Artice_8_2_point_3: "Paedophile products,",
  Artice_8_2_point_4: "Live animals",
  Artice_8_2_point_5:
    "Valuables such as jewellery, precious metals, banknotes, bearer bonds, gold or silver, and other valuables,",
  Artice_8_2_point_6:
    "Cultural property of major interest to the national heritage,",
  Artice_8_2_point_7:
    "Items of a sensitive nature are subject to special formalities: weapons, medicines, tobacco, asbestos, strategic goods for civil or military use or products from wild animal or plant species protected by the Washington Convention,",
  Artice_8_2_point_8:
    "Items whose nature or packaging may present a danger to others,",
  Artice_8_2_point_9:
    "Flammable materials (alcohol, perfumes), explosive materials (including inert or dummy ammunition), radioactive or corrosive materials, hazardous waste and dangerous materials such as compressed gases or any object containing gas or compressed or liquid air (aerosol products, lifeboats, fire extinguishers, etc.),",
  Artice_8_2_point_10: "Lithium batteries,",
  Artice_8_2_point_11:
    "Perishable biological materials, whether infectious or non-infectious,",
  Artice_8_2_point_12: "Dead animals,",
  Artice_8_2_point_13: "Human organs and remains,",
  Artice_8_2_point_14: "Obscene or immoral objects...",

  Artice_9: "Article 9. Force majeure",
  Artice_9_para_1:
    "Any event arising from a case of force majeure, within the meaning of article 1218 of the French Civil Code, such as those set out below, shall under no circumstances be a cause of liability for delay or non-performance of the obligation concerned, nor lead to the payment of damages or late penalties:",
  Artice_9_point_1: "acts of war or terrorism",
  Artice_9_point_2: "criminal acts",

  Artice_9_point_3: "riots",
  Artice_9_point_4: "natural or industrial disasters",
  Artice_9_point_5:
    "explosions legal requirements and other legislative or regulatory provisions restricting the company's activity; and",
  Artice_9_point_6:
    "legal requirements and other legislative or regulatory provisions restricting the company's activity; and",
  Artice_9_point_7:
    "disruption of electronic communications networks beyond the Company's control.",
  Artice_9_para_3:
    " In the event of force majeure, the Company may suspend the Services. The effects of any contract will then be suspended and will resume once the force majeure has ceased. ",

  Artice_10: " Applicable law and competent jurisdiction ",
  Artice_10_para_1:
    "These TCU and their appendices can be accessed at any time via a direct link on the Platform. They can be downloaded by clicking on the [Download TCU in PDF].",
  Artice_10_point_1:
    "These TCU and the transactions arising from them are governed by French law; ",
  Artice_10_point_2:
    "The language of interpretation of these TCU is French. ",

  Artice_10_para_2:
    "The User and the Company undertake to reach an amicable agreement for all disputes concerning the validity, interpretation or performance of the terms of the TCU. If no amicable agreement can be reached, the dispute will be submitted to mediation. If the User is not a professional, he/she may have recourse to a mediator to settle amicably any dispute opposing him/her to a professional. ",
  Artice_10_para_3:
    "Information on the consumer mediation procedure and the list of mediators is available at the following address: https://www.economie.gouv.fr/mediation-conso",
  Artice_10_para_4:
    "Under European consumer protection legislation, the European Commission also offers a dispute resolution procedure on a dedicated portal: http://ec.europa.eu/consumers/odr.",

  Artice_10_para_5:
    "The dispute will only be referred to the competent court if mediation fails.",

  Artice_10_para_6:
    "All actions and disputes to which the TCU may give rise shall be subject to the exclusive jurisdiction of the Commercial Court of Paris, even in the event of multiple defendants, third-party proceedings or summary proceedings. ",

  Artice_10_para_7:
    "Disputes between Users must be settled directly between them, as the Company is not intended to settle them.",
  Artice_11: "Article 11. legal information",
  Artice_11_para_1:
    "The website www.frankme.com refers to the FRANK Platform, managed by the company PEOPLES POSTE, a simplified joint stock company under French law with a share capital of one hundred euros (€100), whose registered office is located at 22 rue Emeriau, 75008 Paris and registered with the Paris Trade and Companies Register under number 879 963 197. It is represented by Pouya Ahad, President, Directeur General. ",
  Artice_11_para_2: "Contact email address: info@frankme.com",

  Appendex_1: "Appendix 1.Charter of good conduct",
  Appendex_1_para_1: `This Charter of Good Conduct (hereinafter "the Charter") sets out the principles and rules that each User must observe when using the platform. Adherence to these principles ensures a secure, legal and respectful experience for all.",
Appendex_1_head_1:"1. Compliance with the law `,

  Appendex_1_head_1_para_1:
    "Users must ensure that their contributions to the platform comply with current legislation, in particular by not promoting any content that is illegal, defamatory, obscene or incites hatred.",

  Appendex_1_head_2: "2. Transport safety and compliance",
  Appendex_1_head_2_para_1:
    "Only accept assignments that are suited to your skills and equipment. Do not undertake transport that requires authorizations, equipment or skills that you do not possess.",

  Appendex_1_head_3: "3. Punctuality and reliability",
  Appendex_1_head_3_para_1:
    "Strictly adhere to the agreed dates and times for collection and delivery. Any changes must be communicated and agreed in advance with the parties concerned.",

  Appendex_1_head_4: "4. Effective communication",
  Appendex_1_head_4_para_1:
    "Inform senders and recipients of your arrival in advance to avoid any confusion or unnecessary delays.",

  Appendex_1_head_5: "5. Integrity of deliveries",
  Appendex_1_head_5_para_1:
    "Ensure that parcels are transported with care, particularly fragile or valuable items. In the event of damage, the insurance company will be responsible for reimbursement.",

  Appendex_1_head_6: "6. Conflict management",
  Appendex_1_head_6_para_1:
    "Handling disagreements or problems with professionalism and courtesy, avoiding confrontation and seeking a peaceful resolution.",

  Appendex_1_head_7: "7. Inappropriate use of the Platform",
  Appendex_1_head_7_para_1:
    "Not to use the Platform for illegal or abusive purposes or to disrupt its smooth operation or that of other users.",

  Appendex_1_head_8: "8. Respect for privacy and personal data",
  Appendex_1_head_8_para_1:
    "Not to disclose personal information without the explicit consent of the person concerned.",

  Appendex_1_head_9: "9. Prohibition of harmful practices",
  Appendex_1_head_9_para_1:
    "Refrain from introducing malicious programs, using unauthorized automated tools, or carrying out actions likely to harm the platform or its users.",

  Appendex_1_head_10: "10. Ethical behavior online",
  Appendex_1_head_10_para_1:
    "Avoid any online behavior that could be perceived as harassing, intimidating or impersonating others.",

  Appendex_1_para_2:
    "In the event of a breach of the Charter, measures such as suspending or limiting access to the Platform, deleting content or taking legal action may be taken to protect the interests of the community and the integrity of the Platform.",

  Appendex_2:
    "Appendix 2. Special conditions relating to the public road transport of parcels",

  Appendex_2_article1_head: "Article 1. Regulatory compliance",

  Appendex_2_article1_para_1:
    "By accepting any advertisement on the FRANK platform, the Professional Carrier undertakes to organize itself in such a way as to comply with its own legal and regulatory obligations. This includes ensuring that the transport is carried out in compliance with the conditions of establishment, professional good repute, financial standing and professional capacity, as well as registration in the transport register in accordance with article L. 3211-1 of the Transport Code, and in the trade and companies register. The platform must be notified immediately of any suspension or withdrawal of these registrations.",
  Appendex_2_article1_para_2:
    "The Professional Carrier must also guarantee that all transport operations carried out via the platform comply with the contractual commitments made with the Customer. This includes strict compliance with the rules relating to work and rest periods prescribed by the regulations in force.",
  Appendex_2_article1_para_3:
    "It is imperative that the Carrier uses all the means of control and documents required by law to guarantee not only safety but also full compliance of transport operations. This requirement serves to prevent any risk associated with regulatory non-compliance and to strengthen customer confidence in the services provided.",
  Appendex_2_article2_head:
    "Article 2.Obligations relating to the public transport of goods",
  Appendex_2_article2_1_head: "2.1 Undeclared work",
  Appendex_2_article2_1_para_1:
    "In accordance with article L.3261-3 of the French Transport Code, the FRANK platform requires professional goods carriers to provide proof every six months that they meet the following conditions:",
  Appendex_2_article2_1_point_1:
    "Regularity of employment: The carrier must demonstrate that he is not posting employees or using employees posted under irregular conditions. In addition, for companies established in France, they must also prove that they are not engaged in undeclared work and are not employing workers who are not authorized to carry out a professional activity on French territory.",
  Appendex_2_article2_1_point_2:
    "Professional insurance: Carriers must provide up-to-date proof of the existence of insurance covering the financial consequences of their professional liability for the activities carried out.",
  Appendex_2_article2_2_head: "2.2 Information obligations ",

  Appendex_2_article2_2_para_1:
    "In accordance with Article L3222-4 of the French Transport Code, the Customer has a duty to provide information. Before the vehicle is presented at the loading point, the Customer must provide all the information required to perform the transport contract. This includes a detailed description of the package, a list of the ancillary services agreed, and explicit acceptance of the times and conditions of carriage provided for.",
  Appendex_2_article2_2_para_2:
    "In addition, in accordance with Article L3222-5 of the Transport Code, the Carrier will draw up a transport document which will be completed progressively during the transport operation. This document, which will be signed by the remitter or its representative at each significant loading and unloading stage, will detail the dates and times of arrival and departure, the time of arrival at the requested unloading location, and the ancillary services performed. This document is proof of the terms and conditions of performance of the contract until proven otherwise, and shall incur the liability of the parties in the event of an unjustified refusal to sign.",
  Appendex_2_article2_2_para_3:
    "The Transmission Operator shall explicitly inform the Customer of any estimate of the time required to perform the tasks and of any adjustments to remuneration that may be applied in the event that the allocated time is exceeded for reasons for which it is not responsible. In any event, the implementation of the provisions of article L. 3222-8 of the Transport Code must be brought to the attention of the co-contractor.",

  Appendex_2_article2_3_head: "2.3 Route documentation and monitoring",
  Appendex_2_article2_3_para_1:
    "The Carrier will draw up a tracking document from the start of the carriage. This document will be updated at each major stage of the loading and unloading process. In accordance with article L3222-5 of the Transport Code, this document must be signed by the remitter or his representative at the start of the transport, and by the recipient on arrival, in order to certify the services performed and the times respected. Additionally, the Carrier may be required to provide photographic evidence or other proof of the package at the start and end of the transport, and at significant stages during the process. This includes pictures of the package before loading and after unloading, as well as any other proofs deemed necessary to verify the condition and receipt of the package.",

  Appendex_2_article2_4_head: "2.4 Cabotage ",
  Appendex_2_article2_4_para_1:
    "Users must operate in accordance with the national and European regulations in force, in particular hold the licenses required for the type of transport carried out and ensure that all transport operations, including cabotage by companies not established in France, comply with the conditions set out in Articles L. 3421-3 and L. 3421-4 of the French Transport Code.",

  Appendex_2_article2_5_head: "2.5 Liability and subcontracting",

  Appendex_2_article2_5_para_1:
    "The Carrier may sub-contract the carriage to another road haulage company under its full and total responsibility, provided that it complies with the conditions defined in article L. 3224-1 of the Transport Code as well as the provisions applicable to freight forwarders, particularly with regard to the transfer of risks.",

  Appendex_2_article2_5_para_2:
    "Loading, securing and lashing operations include strapping. The straps are supplied by the Carrier. You are reminded that responsibility for material damage occurring during loading and unloading operations lies with the person carrying them out.",

  Appendex_2_article2_6_head: "2.6 Remuneration and payment terms ",
  Appendex_2_article2_6_para_1:
    "Payments for services on the Platform are made in accordance with the TCU. These payments are made within more advantageous timescales than those provided for by law, reflecting our commitment to offering an efficient service that meets the expectations of our community.",

  Appendex_2_article2_6_para_2:
    "However, we recognize that unforeseen events may occur. As a result, despite our commitment to act promptly, and unless justified by legal circumstances, payment terms will not exceed the statutory maximums. This ensures compliance with the law while protecting the interests of all parties involved.",
  Appendex_2_article2_6_para_3:
    "In addition, with regard to the remuneration of public goods carriers, it is imperative that carriers assess whether the price determined by the customer via our platform adequately covers the criteria set out in Article L3221-2 of the Transport Code. These criteria include the services actually provided, the time required to make the vehicle available for loading and unloading, the time required to carry out the transport safely, and the cost of the energy products required. If the price set by the customer does not meet these criteria, the carrier is obliged to refuse the offer or make a counter-offer.",
  Appendex_2_article2_6_para_4:
    "In this context, the Platform will make every effort to ensure that the prices offered are consistent with the legal provisions and sufficient to cover the costs mentioned, thus guaranteeing fair remuneration for the carriers.",

  Appendex_1_head:
    "Appendix 3.Special conditions relating to the co-transport of parcels",

  Appendex_3_article1_head: "Article 1. Characteristics of co-transport",

  Appendex_3_article1_para_1:
    "Users of the Platform may offer to co-transport parcels for other Users. In accordance with article L. 3232-1 of the French Transport Code, parcel co-transport is defined as the joint use, on a private basis, of a motorized land vehicle carried out without consideration, except for the sharing of costs, to transport parcels as part of a journey made by a driver on his own behalf.",
  Appendex_3_article1_para_2:
    "To this end, the co-transporter must hold a valid driving license. He must offer transport services for vehicles of which he is the owner or for which he has received express authorization from the owner, and he must hold a valid insurance certificate for each type of risk covered, in particular Contractual Civil Liability and Property Damage insurance for the vehicles he transports. In addition, they must be able to provide the Platform with a copy of these documents on request. It is also forbidden to subcontract the transport via services such as DHL, CHRONOPOST, UPS or any other similar service. ",
  Appendex_3_article1_para_3:
    "Cotransporters must declare any cotransport activity and ensure that the amount of financial contributions received does not exceed the annual ceiling set by the Ministry of Transport.",

  Appendex_3_article2_head: "Article 2. Checking packages",
  Appendex_3_article2_para_1:
    "Before any co-transport, the co-transporter must check the condition and compliance of the Packages with the Platform's restrictions as set out in article 8 of the TCU. The Parcels must not contain illegal, dangerous or specifically regulated products such as alcohol, tobacco or pharmaceutical products, contraband items....",
  Appendex_3_article2_para_2:
    "The Platform reserves the right to refuse or withdraw an Ad if the content of the Parcel is deemed not to comply with established standards or public order.",

  Appendex_3_article3_head: "Article 3. Routing",
  Appendex_3_article3_para_1:
    "The descriptions of the routes proposed by the Cotransporters must be precise, including the departure and arrival points, as well as the scheduled times. Each journey must be validated by FRANK before publication.",

  Appendex_3_article3_para_2:
    "A user's order for a journey is final once the Cotransporter has received confirmation. The platform facilitates this exchange and monitors the provision of the service.",

  Appendex_3_article4_head: "Article 4. Storage",
  Appendex_3_article4_para_1:
    "The co-transporter must not store the Parcels for any longer than is strictly necessary for the organization of the journey. Any specific storage conditions must be clearly communicated to the Users. These locations are selected on an optimization of the transport, ensuring efficiency, security and minimal transit time as determined by FRANK. ",

  Appendex_3_article5_head:
    "Article 5. Contribution to co-transportation costs",
  Appendex_3_article5_para_1:
    "Contributions to cotransportation costs are shared equitably and must not exceed the actual costs incurred for the journey. The Cotransporters undertake not to make any profit via the Platform. Prices for the cotransport service must be clearly defined and communicated in advance, including FRANK's service fees. These rates are payable via secure methods offered by the Platform. ",
  Appendex_3_article5_para_2:
    "Any dispute arising from the carriage of parcels between private parties must be resolved directly between the parties concerned, FRANK acting solely as a technical intermediary and not as a party to the contract of carriage.",
  Appendex_3_article6_head: "Article 6. Transfer of risk and liability",
  Appendex_3_article6_para_1:
    "The transfer of risks takes place as soon as the parcel is handed over to the Cotransporter, so that the Cotransporter is responsible for the Parcel until it is actually delivered, in accordance with the terms agreed with the Customer.",
  Appendex_3_article6_para_2:
    "In the event of damage or loss, the Cotransporter is obliged to compensate the Customer, except in cases of force majeure. ",
  //Policy    privact_policy: "Privacy and Policy",
  intoduction: "Introduction",
  intro_para_1:
    'This Privacy Policy describes how PEOPLES POST ("FRANK," "we," "us," or "our") collects, uses, discloses, and protects your personal information when you use our multimodal transport services platform, accessible via our website and mobile application (collectively, the "Platform").',
  intro_para_2:
    "By accessing or using the Platform, you agree to the terms of this Privacy Policy. If you do not agree with these terms, please do not use the Platform.",
  defination: "1. Definitions",
  defination_desc:
    "The following terms have the following meaning in this Privacy Policy when capitalized:",
  def_point_1:
    "●	'Advertisement': The transport proposal published on the Platform by a User, whether it is an Advertiser or a Partner, for Transport, Service, or Storage purposes.",
  def_point_2:
    "●	'Application': The mobile application developed and provided by FRANK, available on iOS and Android platforms.",
  def_point_3:
    "●	'Application': The mobile application developed and provided by FRANK, available on iOS and Android platforms. ",
  def_point_4:
    "●	'Account': The personal and secure digital space that a User creates and uses to access the services of the Platform.",
  def_point_5:
    "●	'Personal Data': Any information relating to an identified or identifiable natural person, collected by the Platform.",
  def_point_6:
    "●	'Partner': Any entity or individual who has signed a contract with FRANK to use its APIs and publish Advertisements under their own name.",

  def_point_7:
    "●	'Platform': The digital solution operated by FRANK, accessible via the website or mobile application, allowing for connection services for transport.",
  def_point_8:
    "●	'Service': The set of functionalities and services offered by the Platform to Users.",
  def_point_9:
    "●	'Site': The official website www.frankme.com, including all associated sub-domains.",
  def_point_10:
    "●	'Transporter': Any person or professional company using the Platform to provide transport services for goods for a fee.",
  def_point_11:
    "●	'User': Any person accessing and using the Platform, whether they are merchants, individuals, transporters, partners, or others.",
  def_point_12:
    "●	'Keeper': Any person, professional or non-professional, using the Platform to offer storage and/or warehousing services for goods for a fee.",
  def_point_13:
    "●	'Sender': Any person, professional or non-professional, using the Platform to send goods or parcels, without a fee, except for sharing transportation costs.",

  information_1: "2. Information We Collect",
  info_intro_1:
    "We collect personal information that you provide directly to us when you register for an account, post advertisements, communicate with other users, or use our services. This information may include:",

  info_value_1: "●	Name",
  info_value_2: "●	Email address",
  info_value_3: "●	Phone number",
  info_value_4: "●	Postal address",
  info_value_5: "●	Payment information",
  info_value_6: "●	Profile information (e.g., profile picture, bio)",
  info_value_7: "●	Content of communications with other users",

  information_2: "2.2 Information We Collect Automatically",
  info_intro_2:
    "When you use the Platform, we may automatically collect certain information about your device and usage of the Platform, including:",

  info_2_value_1: "●	IP address",
  info_2_value_2: "●	Browser type and version",
  info_2_value_3: "●	Operating system",
  info_2_value_4: "●	Device information",
  info_2_value_5: "●	Device information",

  information_3: "2.3 Information from Third Parties",
  info_intro_3:
    "We may receive information about you from third parties, such as identity verification services and public databases, to help us verify your identity and comply with legal requirements.",

  Use_of_information: "3. Use of Information",
  use_of_info_intro:
    "We use your personal information for various purposes, including:",

  use_of_info_desc_1: "●	To provide, maintain, and improve our services",
  use_of_info_desc_2:
    "●	To process transactions and send related information, such as confirmations and invoices",
  use_of_info_desc_3:
    "●	To communicate with you about products, services, offers, and events",
  use_of_info_desc_4:
    "●	To monitor and analyze trends, usage, and activities in connection with our services",
  use_of_info_desc_5:
    "●	To detect, investigate, and prevent fraudulent transactions and other illegal activities",
  use_of_info_desc_6:
    "●	To comply with legal obligations and protect our rights",

  sharing_of_information_title: "4. Sharing of Information",
  sharing_of_information_intro:
    "We may share your personal information with:",
  sharing_of_information_desc_1:
    "●	Service Providers: Third-party vendors who perform services on our behalf, such as payment processing, data analysis, email delivery, hosting services, and customer service.",
  sharing_of_information_desc_2:
    "●	Other Users: When you interact with other users on the Platform, certain information may be shared with them, such as your name, contact details, and transaction history.",
  sharing_of_information_desc_3:
    "●	Compliance with Laws: To comply with legal obligations, respond to requests from public and government authorities, and protect our rights and interests.",
  sharing_of_information_desc_4:
    "●	Business Transfers: In connection with a merger, sale, acquisition, or other business transaction, we may transfer your personal information to the involved parties.",

  security_title: "5. Security",
  security_intro:
    "We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, alteration, and destruction. These measures include encryption, access controls, and secure data storage.",

  your_rights: "6. Your Rights",
  your_rights_intro:
    "You have certain rights regarding your personal information, including:",

  your_rights_desc_1:
    "●	The right to access, correct, update, or delete your personal information.",
  your_rights_desc_2:
    "●	The right to restrict or object to our processing of your personal information.",
  your_rights_desc_3:
    "●	The right to receive a copy of your personal information in a structured, commonly used, and machine-readable format.",
  your_rights_desc_4:
    "●	The right to withdraw your consent at any time, where we rely on your consent to process your personal information.",

  your_rights_desc_5:
    "To exercise these rights, please contact us at: info@frankme.com.",

  data_retentions_title: "7. Data Retention",
  data_retentions_intro:
    "We retain your personal information for as long as necessary to fulfill the purposes for which we collected it, including to comply with legal, accounting, or reporting obligations. If you delete your account, we may retain certain information as required by law or for legitimate business purposes.",

  international_data_transfer_title: "8. International Data Transfers",
  international_data_transfer_intro:
    "Your personal information may be transferred to and processed in countries other than your own. We ensure that such transfers are made in compliance with applicable data protection laws and that appropriate safeguards are in place to protect your personal information.",

  changes_to_privacy_policy_title: "9. Changes to this Privacy Policy",
  changes_to_privacy_policy_intro:
    "We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated Privacy Policy on the Platform and indicating the date of the latest revision. Your continued use of the Platform after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.",

  contact_us_title: "10. Contact Us",
  contact_us_intro_1:
    "If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:",
  contact_us_desc_1:
    "FRANK - Peoples Post 22 rue Emeriau, 75008 Paris Email: info@frankme.com",
  contact_us_desc_2:
    "This Privacy Policy is incorporated into and forms part of the Terms and Conditions of Use of the FRANK Service. By using the Platform, you agree to the terms and conditions outlined in this Privacy Policy and the TCU.",

  idditional_provisions_title: "11. Additional Provisions",
  additional_provisions_title_1: "11.1 Information Security Measures",
  additional_provisions_title_intro_1:
    "To ensure the security of your personal information, we implement the following measures:",
  additional_provisions_desc_1: "●	Regular software and hardware updates",
  additional_provisions_desc_2: "●	Firewalls and intrusion detection systems",
  additional_provisions_desc_3: "●	Encrypted data transmission (SSL/TLS)",
  additional_provisions_desc_4:
    "●	Secure access controls and authentication procedures",

  additional_provisions_title_2: "11.2 Children's Privacy",
  additional_provisions_title_intro_2:
    "The Platform is not intended for children under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.",

  additional_provisions_title_3: "11.3 User Responsibilities",
  additional_provisions_title_intro_3:
    "Users are responsible for maintaining the confidentiality of their account information, including their username and password. Users should notify us immediately if they suspect any unauthorized use of their account or any other breach of security.",

  additional_provisions_title_4: "11.4 Data Breach Notification",
  additional_provisions_title_intro_4:
    "In the event of a data breach that compromises your personal information, we will notify you and the relevant supervisory authorities in accordance with applicable data protection laws.",

  additional_provisions_title_5: "11.5 Third-Party Links",
  additional_provisions_title_intro_5:
    "The Platform may contain links to third-party websites or resources. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party sites you visit.",

  additional_provisions_title_6: "11.6 Dispute Resolution",
  additional_provisions_title_intro_6:
    "If you have any complaints regarding our compliance with this Privacy Policy, please contact us first. We will investigate and attempt to resolve complaints and disputes regarding the use and disclosure of personal information in accordance with this Privacy Policy. If you are not satisfied with our response, you may have the right to lodge a complaint with the data protection authority in your jurisdiction.",

  additional_provisions_title_7:
    "11.7 Legal Basis for Processing Personal Data",
  additional_provisions_title_intro_7:
    "We process your personal data based on the following legal grounds:",

  additional_provisions_title_desc_7_1: "●	Your consent",
  additional_provisions_title_desc_7_2:
    "●	The necessity to perform a contract with you",
  additional_provisions_title_desc_7_3: "●	Compliance with a legal obligation",
  additional_provisions_title_desc_7_4:
    "●	Our legitimate interests, provided they do not override your rights and freedoms",

  additional_provisions_title_8: "11.8 Data Protection Officer",
  additional_provisions_title_intro_8:
    "We have appointed a Data Protection Officer (DPO) to oversee compliance with this Privacy Policy. If you have any questions or concerns about our data protection practices, you can contact the DPO at:",

  additional_provisions_title_8_desc:
    "Data Protection Officer FRANK Email: info@frankme.com",

  additional_provisions_title_9: "11.9 Updates and Amendments",
  additional_provisions_title_intro_9:
    "We may update this Privacy Policy to reflect changes in our practices, legal requirements, or operational needs. We will notify you of any significant changes by posting the updated Privacy Policy on the Platform and indicating the date of the latest revision. Your continued use of the Platform after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.",

  cookies_title: "12. Cookies",
  cookies_intro_1:
    "Some cookies used by the Platform require your prior consent. For example, we use Google Analytics to measure and analyze audience data: number of visits to the Site, number of pages viewed, activity, city from which the User connects, etc.",
  cookies_intro_2:
    "When you first visit the Site, a banner informs you of the presence of these cookies and invites you to indicate whether you accept or refuse their use. Your choice is stored for six months, unless you modify it or clear your browser cache in the meantime.",
  cookies_intro_3:
    "For more information, please refer to our Cookie Policy accessible from the banner.",

  mooderation_of_messages_title: "13. Moderation of Messages",
  mooderation_of_messages_intro:
    "We may review your conversations with other Users for the following purposes:",

  mooderation_of_messages_desc_1: "●	Assistance, with your consent",
  mooderation_of_messages_desc_2:
    "●	Fraud prevention: We have automated systems to analyze exchanged messages to ensure they do not contain any contact details, preventing Users from bypassing our online booking system.",

  services_provides_by_third_party_title:
    "14. Services Provided by Third Parties",
  services_provides_by_third_party_intro_1:
    "To facilitate your registration or login to our Site, you may authenticate via an account from a partner network, such as 'Login with Facebook' if you have a Facebook account. This allows pre-filling your FRANK registration form with information you have already provided to Facebook.",
  services_provides_by_third_party_intro_2:
    "We may collect information when you browse our Social Network pages or use their authentication features. We offer Facebook Connect, Apple Connect, and Google Connect.",

  retention_period_title: "15. Retention Period",
  retention_period_intro_1: "15.1 Commercial Relationship Management",
  retention_period_intro_1_intro:
    "Your personal data will not be retained beyond the duration necessary to manage our commercial relationship with you. However, data needed to establish proof of a right or contract will be retained as required by law.",

  retention_period_intro_2: "15.2 Identity Documents",
  retention_period_intro_2_intro:
    "Identity documents are transmitted directly to our banking service provider Stripe, encrypted and secure, for identity verification. They are not stored on FRANK servers.",

  retention_period_intro_3: "15.3 Bank Card Data",
  retention_period_intro_3_intro:
    "Financial transactions are handled by a payment service provider ensuring their proper execution and security. This provider may need your personal data relating to your bank card numbers, which they collect and store on our behalf. We do not access this data.",

  retention_period_intro_4: "15.4 Opposition List Management",
  retention_period_intro_4_intro:
    "Information to account for your opposition rights is retained for at least three years from the exercise of the right of opposition.",

  retention_period_intro_5: "15.5 Audience Measurement Statistics",
  retention_period_intro_5_intro:
    "Information stored in user devices or any other element used to identify users and traceability or visits will not be kept beyond six months.",

  user_rights_title: "16. User Rights",
  user_rights_intro:
    "You have the following rights regarding your personal data, in accordance with data protection legislation:",
  user_rights_desc_1:
    "●	Right of Access: Obtain a copy of your personal data we hold.",
  user_rights_desc_2:
    "●	Right of Rectification: Request the correction of inaccurate personal data.",
  user_rights_desc_3:
    "●	Right to Erasure: Request deletion of your personal data, subject to it no longer being necessary for the purposes described in section 5.",
  user_rights_desc_4:
    "●	Right to Object: Object to the processing of your personal data for reasons related to your particular situation, if processing is based on our legitimate interests. Object to the processing of your personal data for marketing purposes at any time.",
  user_rights_desc_5:
    "●	Right to Restriction: Request the suspension of processing of your personal data in certain cases.",
  user_rights_desc_6:
    "●	Right to Data Portability: Receive your personal data in a structured, commonly used format when processing is based on contract execution or your consent.",

  user_rights_intro_2:
    "To exercise these rights, write to us at info@frankme.com with the email address linked to your FRANK account.",

  changes_to_this_policy_title: "17. Changes to this Policy",
  changes_to_this_policy_intro:
    "We reserve the right, at our sole discretion, to modify this Privacy Policy at any time, in whole or in part. These changes will take effect upon publication of the new policy. Your continued use of the site following the effective date of these changes constitutes recognition and acceptance of the new policy. If this new policy does not suit you, you should no longer access the site.",

  contact_information_title: "18. Contact Information",
  contact_information_intro:
    "For any questions related to this Privacy Policy, please contact us at our dedicated address: info@frankme.com.",
 


    your_product:"Vous Produit",
    seller_details:"Détail du vendeur",
    Vendre_un_article:"Vendre un article",
    small_desc: 'Entre une enveloppe et une boite à chaussure (<5 Kg)',
    medium_desc: 'Entre un sac à dos et une caisse à vin (5 KG- 15 Kg )',
    large_desc: 'Entre une télévision et une commode (15 kg - 25 kg)',
    e_large_desc: 'Entre une armoire et une table à manger (+25 Kg)',
    search: "Rechercher",
    use_email: "or use your email account.",
    login_with: "Login with",
    signup_with: "Sign up with",
    accept_terms_conditions:
      "Tu confirmes que tu acceptes les Termes & Conditions de Frank et avoir au moins 18 ans",
    accept_newsletter: "J’accepte de recevoir par e-mail les offres de Frank ",
    personal_info: "Information Personnelle",
    set_credentials: "Définir les informations de connexion",
    set_mobile_number:
      "Vérifiez votre numero de telephone pour sécuriser votre compte",
    verify_otp: `Vérifier le code d'accès à usage unique (OTP)`,
    profile: "Profil",
    chat: "Discuter",
    call: "Appeler",
    support: "Support",
    confirmation: "Confirmation",
    logout_confirmation:
      "Êtes-vous sûr de vouloir vous déconnecter de cet appareil ?",
    logout: "Logout",
    settings: "Paramètres",
    pickup_otp_for_transporter:`Le code OTP que vous avez saisi n'est pas valide`,

        I_ACCEPT_FRANK_TERMS_CONDITION:
      "J'accepte les conditions générales d’utilisation de Frank et avoir au moins 18 ans.",
    I_AGREE_LATEST_UPDATED_FRANK: "J’accepte  de recevoir l’actualité de Frank.",
    mini_shop: "Mini Shop",
    shop_details: "Shop Details",
    order_history: "Transactions",

    my_deliveries: "Deliveries",
    my_parcels: "Mes Annonces",

    signin: "Se connecter",
    or_connect: "Ou conectez-vous avec",
    alread_have_acc: "Vous avez déjà un compte? ",
    login_here: "se connecter",

    sign_up: "Créer un compte",
    forgot_password: "Mot de passe oublié",
    set_new_password: "Définir un nouveau mot de passe",
    wallet: "Porte monnaie",
    blogs: "Frank Blogs",
    blog_details: "Blog Details",
    continue: "S’inscrire",
    login: "Se connecter",

    first_name: "Nom",
    surname: "Prénom",
    date_birth: "Date de Naissance",
    user_name: `Nom d'utilisateur`,
    old_password: "Ancien mot de passe",
    password: "Mot de passe",
    confirm_password: "Confirmez le mot de passe",
    next: "S’inscrire",
    phone_number: "Numéro de téléphone",
    send_number_text:
      "Vous recevrez un code par SMS afin d’activer votre compte",

    didnt_receive_code: `Tu n’as pas reçu de code? `,
    resend_code: `Renvoyer le code`,
    send_otp: "Obtenir mon code de vérification",
    validate_otp: "Vérifier",
    send_otp_text: "le code de vérification que vous avez recu sur ce numéro:",

    your_mood: "Quel est le programme du jour?",
    sender: `Envoyer\nun colis`,
    transporter: "Transporter\nun colis",
    transporter_2: "Transporter un colis",
    relocation: "Déménager",
    send_green_head: "Votre Envoi, Moins de CO₂!",
    send_green_text:
      "Chaque livaraison avec Frank réduit en moyenne les émmisions de CO₂ de 25 kg.",

    home: "Accueil",
    announces: "Annonces",
    send: "Envoyer",
    inbox: "Messages",
    account: "Compte",

     
    reduce_carbon_head: "Livraison\nécologique",
    reduce_carbon: "votre empreinte carbone",
    reduce_carbon_title: "Éco-responsable",
    reduce_carbon_desc:
      "Un colis envoyé avec Frank, c’est 25kg/CO2 en moins ! Vous faites du bien à la planète en optimisant le trajet de votre colis, mais aussi celui d’un transporteur qui, sans vous, aurait peut-être roulé à vide !",

    eco_delivery_head: "Livraison\néconomique",
    eco_delivery: "sur vos envois",
    eco_delivery_title: "Économique",
    eco_delivery_desc:
      "Un colis envoyé avec Frank, c’est 50 % moins cher qu’avec un transporteur traditionnel. Fini les envois de colis ruineux !",

    assured_delivery_head: "Livraison\nassurée",
    assured_delivery: "vos envois",
    assured_delivery_title: "Assuré",
    assured_delivery_desc:
      "Un colis envoyé avec Frank, c’est une assurance à hauteur de 100€. Le transporteur reçoit le montant de sa course une fois que le colis est bien arrivé à destination et que le destinataire en a accusé réception.",

    recent_transaction: "Transactions récentes",

    create_shipment: "Envoyer un colis",
    upto_six_pics: `Jusqu’à 6 photos, formats JPG, PNG et GIF jusqu’à 5 Mb.`,
    add_photos: "Ajouter des photos",
    prod_name: "Ex: Table, Stylo, Four",
    i_know_dimensions: "Je connais les dimensions",
    add_descriptive: "Information complémentaires. ",
    prod_size: "La taille de mon colis",
    fragile: " Fragile ",
    add_item: "Ajouter un colis",
    origin_area: "Ma position actuelle",
    pickup_address: "Adresse de ramassage",
    pickup_place_placholder: "Lieu de prise en charge",
    pickup_place_1: "Devant chez moi",
    pickup_place_2: "Dans la pièce de mon choix",
    delivery_place_placholder: "Lieu de livraison",

    delivery_type: "Urgent",
    delivery_type_text: "Entre 1 à 2 jours",

    delivery_type2: "Je peux attendre",
    delivery_type2_text: "Entre 3 à 7 jours",

    delivery_type3: "Quand mon annonce trouvera un transporteur",
    delivery_type3_text: "Entre 8 à 30 jours",

    back: "Retour",
    desitnation_area: "Adresse de Livraison",
    dropoff_address: "Adresse de livraison",
    continue_: "Suivant",

    announcements: "Annonces",
    all: "Tout",
    around_me: "Autour de moi",
    online_announce: " annonces en ligne",
    accept: "Accepter",
    negotiate: "Négocier",
    address: "Adresse",

    update_info: "Enregistrer",
    email_address: "Adresse e-mail",
    my_profile: "Mon Profil",

    transfer: "Transférer vers un compte bancaire",
    your_wallet: "Mon porte monnaie",
    user_or_email: "E-Mail ou nom d'utilisateur",

    logging_in_text: "En vous connectant, vous acceptez notre ",
    terms_condition: "Termes & Conditions ",
    privacy_poligy: "Politique de confidentialité ",
    signup_here: "Inscrivez-vous ici",
    dont_hace_acc: "Vous n'avez pas de compte? ",

    welcome: "Bienvenue",
    length: "Longueur",
    width: "Largeur",
    height: "Hauteur",
    choose_size: "Dimension du colis",

    when_do_you_want_your_parcel_delivered:
      "Vous souhaitez que votre colis soit livré …",

    make_offer: "Faire une offre",
    confirm: "Confirmer",
    enter_offer_price: `Entrez le prix de l'offre`,
    offer_request_sent: "Votre offre a été envoyée à l’expéditeur 🎉",
    thanks_frank: "Merci Frank",
    documents: "Documents",
    notification: "Notifications",
    change_password: "Changer le mot de passe",
    all_offers: "Toutes les offres",
    announcement_details: `Détails de l'annonce`,
    frank_storage: "Consigne",
    explore: "Explorer",
    shop: "Boutique",
    camera: "Prendre une photo",
    gallery: "Choisir de ma galerie",
    cancel: "Annuler",
    quantity: "Quantité",
    pickup_place_area: "Lieu de prise en charge",
    recipient_name: "Nom du destinataire",
    delivery_cost: "Frais de livraison",
    item_sizes: "Produit & Dimension",
    type_of_delivery: "Type de livraison",
    sender_2: "Expéditeur",
    sender_details: `À propos de l'expéditeur`,
    language: "Langue",
    general_cond: "Condition générales",
    payments: "Méthode de paiement",
    how_many_persons: "De combien de personnes avez-vous besoin?",
    pickup_date: "Date d'enlèvement",
    delivery_date: "Date de livraison",
    floor: "Étage",
    elevator: "Ascenseur",
    other_item: `D’autres articles?`,
    quotation: "Demandez un devis",
    person: "personne",
    elevator_available: "Y a-t-il un ascenseur?",
    yes: "Oui",
    no: "Non",
    dont_need_person: `Je n'ai pas besoin de personne`,
    frank_shop: "Le Marché by Frank",

    particular: "Particulier",
    professional: "Professionnel",
    company_name: "Dénomination de l’entreprise ",
    shop_desc: "Description de votre entreprise",
    comp_address: "Adresse de votre entreprise",
    category: "Catégorie",
    categories: "Catégories",

    add_logo: `Ajouter le logo de l'entreprise`,
    add_banner: `Ajouter une bannière d'entreprise`,
    frank_shop_2: "Marché de Frank",
    forgot_password_desc: `Mot de passe oublié? Aucun problème! Entrez simplement votre adresse e-mail pour la réinitialiser en quelques étapes simples`,

    prod_title: "Titre",
    prod_desc: "Description du produit",
    prod_color: "Couleur",
    prod_price: "Prix",
    prod_contition: "Conditionner",
    optional: "Facultatif",
    close: "Fermer",
    not_verified_shop_message:
      "Votre demande pour la boutique Frank n'a pas encore été approuvée. On revient vers vous dans moins de 24h.",
    cart: "Panier",
    track: "Suivi",
    total: "Total à payer",
    service_charges: "Frais de plateforme",
    particular_relocation_desc: "Un déménagement entre particulier",
    pro_relocation_desc: "À venir",
    summary: "Résumé",

    deliver_order: `Livrer la commande`,
    pick_order: `J'ai récupéré le colis`,
    would_like_to: "Vous souhaitez",
    proposed_price_placeholder: "Proposition tarifaire",
    frank_recommended_price: `Le prix recommandé par Frank est compris entre `,
    recommended_price_error: `vous ne pouvez pas entrer un montant moins que le prix suggéré par Frank`,
    minimum_error: "Vous ne pouvez pas saisir un montant minimum de 1€",
    maximum_price_error: `Vous pouvez saisir un montant maximum de 9,999€`,
    reviews: "Évaluations",
    messages: "Messaging",
    brand: "Marque",
    used_condition: "Etat",
    inactive_shop: `Votre boutique est inactif, veuillez contacter le service support support@frankme.com`,
    add_logo_particular_shop: "Ajouter une photo pour votre profil",
    search_product: "Rechercher un article ou une marque",

    // new translatiions
    success: "Succès",
    error: "Erreur",
    try_again: "Essayer à nouveau",
    email_registered: `E-mail enregistré avec succès`,
tax_on_commission:"taxe sur la commission",
    title_error: "Le titre ne doit pas être vide",
    first_name_error: "Le prénom ne doit pas être vide",
    surname_error: "Le nom de famille ne doit pas être vide",
    email_error: `L'e-mail ne doit pas être vide`,
    success_login: "Connection avec succès !!",
    internal_error: `Nous rencontrons des problèmes techniques. Veuillez réessayer, si le problème persiste contactez le service client.`,
    network_failed: "Échec du réseau",
    network_failed_error: `Impossible de continuer. S'il vous plaît, vérifiez votre connexion à internet et réessayez.`,
    apple_auth_error: `Impossible de s'authentifier auprès d'Apple. Pour plus d'informations, veuillez nous contacter support@frankme.com`,
    facebook_auth_error: `Impossible de vous authentifier auprès de Facebook, veuillez réessayer plus tard. Si un problème survient, essayez de nous contacter à support@frankme.com`,
    user_cancelled_proccess: `L'utilisateur a annulé le processus d'authentification`,
    signin_with: "Se connecter avec ",
    valid_email: `L'e-mail doit être un e-mail valide`,
    password_error: "Le mot de passe ne doit pas être vide",
    user_not_exists: `Ce compte n'existe pas !`,
    // Mr,Mrs,Ms	Monsieur, Madame, Mademoiselle
    mr: "Monsieur",
    mrs: "Madame",
    ms: "Mademoiselle",

    personal_info_msg: `Faisons connaissance ! Veuillez saisir votre prénom, votre nom et votre date de naissance`,
    profile_added: `Détails du profil ajoutés avec succès`,
    user_name_error: `Le nom d'utilisateur ne doit pas être vide`,
    passowrd_not_match: `mot de passe et confirmer le mot de passe qui ne correspond pas.`,
    retype_password: "Veuillez retaper le mot de passe",
    set_credentials_success: `Les informations de connexion ont été définies avec succès`,
    password_info: `Le mot de passe doit comporter au moins 8 caractères et inclure les exigences suivantes`,
    length_desc: `les mots de passe doivent comporter au moins 8 caractères.`,
    uppercase_letter: `Lettres majuscules: `,
    uppercase_letter_desc: `Incluez au moins une lettre majuscule (A-Z).`,
    lower_letter: `Lettres minuscules: `,
    lower_letter_desc: `Incluez au moins une lettre minuscule (a-z).`,
    digits_letter: `Chiffres: `,
    digits_letter_desc: `Inclure au moins un chiffre (0-9)`,
    special_letter: `Caractères spéciaux: `,
    special_letter_desc: `Incorporez au moins un caractère spécial, tel que @,#,!,%,^,&,*,(,).`,

    too_weak: "Trop faible",
    weak: "Faible",
    moderate: "Modéré",
    strong: "Fort",
    very_strong: "Très Fort",
    phone_number_error: `Le numéro de téléphone ne doit pas être vide`,
    otp_sent_msg: `Un code d'accès unique (OTP) a été envoyé à votre numéro fourni. Veuillez saisir le otp`,
    phone_shared: `Votre numéro de téléphone sera partagé avec la société de livraison ou l'expéditeur afin qu'ils puissent organiser le ramassage et la livraison de votre colis.`,
    otp_empty_err: `Un mot de passe Tiem (OTP) ne peut pas être vide`,
    otp_validated: `Le code d’accès unique (OTP) a été validé avec succès. Vous pouvez maintenant vous connecter à votre compte.`,
    four_digit_code: `Nous avons envoyé un code à 4 chiffres à `,
    enter_otp_email: `Saisissez le code d'accès à usage unique (OTP) qui a été envoyé à `,
    valid_otp: `Veuillez saisir un OTP valide`,
    wrong_or_expored_otp: `L'OTP a expiré ou ne correspond pas. Veuillez demander un nouvel OTP ou réessayer.`,
    valid_otp_mesg: `OTP validé avec succès, veuillez entrer votre nouveau mot de passe`,
    password_reset_mesg: `Votre mot de passe a été réinitialisé avec succès ! Vous pouvez maintenant vous connecter avec votre nouveau mot de passe`,
    incorrect_credentials: "Identifiant ou mot de passe incorrect",
    add_address: `Ajoutez l'adresse`,
    address_saved_msg: `Adresse enregistrée dans le profil avec succès`,
    id_verification: `Vérification d'identité`,
    your_verification_status: `Votre statut de vérification est `,

    idenfy_head_1_desc: `Pour garantir des validations rapides des documents, envoyez des photos ou des scans`,
    idenfy_head_1: "En couleur: ",
    idenfy_head_2: "Complet: ",
    idenfy_head_3: "Lisible et de bonne qualité: ",
    idenfy_head_2_desc:
      "Les documents en noir et blanc ne seront pas acceptés.",
    idenfy_head_3_desc: `toutes les pages sont présentes, aucune information n'est masquée ou tronquée`,
    idenfy_head_4_desc: "ni flou, ni sombre, ni abîmé et sans reflet",

    start_verification: "Commencer la vérification",
    enable_notif: "Activer les notifications",
    enable_notif_desc: `Restez informé des dernières nouvelles et mises à jour en activant les notifications ! Cliquez sur le bouton ci-dessous pour activer les notifications et ne jamais manquer les informations et mises à jour importantes`,
    change: "Changement",
    view: "Voir",
    create_shop: `Créez votre boutique Frank`,
    manage_shop: "Gérer la boutique",
    add_product: "Ajouter des produits",
    manage_product: `Gérer le produit`,
    view_shop_orders: "Afficher les commandes",

    ind_shop_text: `Catégories limitées et vente annuelles jusqu'à 2500€`,
    pro_shop_text: `Catégories et vente annuelles Illimité`,
    products: "Produits",

    excellent: "Excellent",
    good: "Bien",
    average: "Moyenne",
    below_average: "Moyen",
    poor: "Mauvais",
    based_on: `basé sur`,
    overall_rating: `Note globale`,
    add_to_cart: "Ajouter au panier",
    item_added: `Article ajouté au panier`,
    cart_details: "Panier",

    checkout: "Vérifier",
    empty_cart: `Vider le panier`,

    checkout_confirm: `Etes-vous sûr de vouloir passer à l'étape paiement?`,
    remove_item_confirm: `Êtes-vous sûr de vouloir supprimer cet article du panier?`,
    clear_cart_confirm: `Êtes-vous sûr de vouloir vider votre panier ?`,
    no_items_cart: `Il n'y a aucun article dans le panier`,
    start_shipping: "Commencer vos achats",
    free: "Gratuit",
    shipping: "Expédition",
    expected_delivery: "Livraison prévue",
    select_deliery_method: "Veuillez sélectionner le mode de livraison",
    warning: "Avertissement",
    date_of_birth_placeholder:'JJ/MM/AAAA',
    order_summary: "Récapitulatif de la commande",
    get_ur_order: "Vous receverez votre commande dans les 3 heures",
    confirm_submit_order: "Veuillez confirmer et soumettre votre commande",
    agree_by_order: `En cliquant sur continuer le paiement, vous acceptez les conditions d'utilisation et la politique de confidentialité.`,
    continue_payment: "Continuer le paiement",
    leave_checkout: `Vous quittez la commande ?`,
    leave_checkout_msg: `Pas de soucis! Vous pouvez consulter les articles de votre panier avant de finaliser la commande. Appuyez sur « Retour » pour revenir.`,
    order_placed: "Votre commande a été passée avec succès",
    upload_image: "Veuillez télécharger au moins 1 image",
    select_size: "Veuillez sélectionner la taille du colis",
    remove_item: `Retirer l'objet`,
    upload_6_image: `Vous pouvez télécharger jusqu'à 6 photos seulement`,
    transporter_name: "Nom du transporteur",

    delivery_otp_code:
      "Donnez ce code à votre cavalier une fois la commande reçue.",
    delivery_otp_code_desc: `Veuillez vous assurer que ce code n'est partagé qu'à votre livreur.`,

    small: "Petit",
    medium: "Moyen",
    large: "Large",
    xl: "Extra Large",

    your_announcements: "Votre Annonce",

    declined: "Refusée",
    decline: "Refuser",

    send_message: "Envoyer un message",
    handling_charges: "Frais de service",
    sub_total: "Total",
    earned: "Gagné",
    view_details: "Voir les détails",
    no_item_found: "Aucun article trouvé",
    select_product: `Sélectionner un produit`,
    search_item: "Rechercher un article",

    choose_payment_method: "Choisissez le mode de paiement",

    male: "Monsieur",
    female: "Madame",
    other: "Non binaire",
    un_paid: "Non Payé",

    company_name_error: `Le nom de l'entreprise ne doit pas être vide`,
    siret_error: `SIRET ne doit pas être vide`,
    shop_category_error: `Veuillez sélectionner la catégorie de boutique`,
    address_error: `L'adresse ne doit pas être vide`,

    product_name_error: "Le nom du produit ne peut pas rester vide",
    product_price_error: "Le prix ne peut pas rester vide ou 0€",
    product_category_error: "La catégorie ne peut pas rester vide",
    product_image_error: "Mettez au moins une photo",

    item_name_error: "Veuillez saisir le nom ou le titre du produit/article",
    quantity_error: "Veuillez entrer la quantité",

    marked_read: `Marqué comme lu`,

    order_accept_msg: `Avez-vous récupéré la commande?`,
    // order_accept_msg: `Êtes-vous sûr de vouloir mettre à jour le statut de cette commande de « Acceptée » à « En cours » ?\nUne fois la commande préparée, elle ne peut pas être annulée.!`,
    order_deliver_msg: `Êtes-vous sûr de vouloir livrer cette commande?\nUne fois la commande livrée, elle ne peut pas être annulé`,

    otp_confirmation: "Confirmation OTP requise",
    delivery_otp_message: `Veuillez demander au client l'OTP pour confirmer la livraison.\nEntrez l'OTP ci-dessous pour terminer le processus`,
    pickup_otp_message: `Veuillez demander au client le code de retrait pour confirmer le ramassage`,

    enter_o_password: "Veuillez entrer votre ancien mot de passe",
    password_changed_seccess: `Le mot de passe a été modifié avec succès. Veuillez vous reconnecter avec votre nouveau mot de passe.`,
    enter_n_password: "Veuillez entrer votre nouveau mot de passe",

    leave_feedback: `Laisser un commentaire`,
    cust_feedback_err: `La notation du client est requise en cas de soumission de feedback`,

    out_of_range: `Vous êtes hors de la zone de livraison`,
    expected_deliver_dates: `Por favor seleccione las fechas de entrega previstas`,
    order_amt: "Total de la commande",

    messages_: "Messages",
    pay_with: `Payer avec `,
    relocation_announce: "Déménagement",

    track_order: "Suivre le colis",
    tracking_number: `Numéro de suivi`,
    update_shop: "Mettre à jour la boutique",

    open_setting: "Ouvrir les paramètres",
    done: "Fait",
    payment_cancel: "Vous avez annulé le processus d'autorisation de paiement.",
    sub_category: `Sous-catégorie.`,
    confirm_password_error:'Confirm Password cannot be empty',
 
    All_announes: "Tout",
    Express_announces: "Express",
    ArountMe_announces: "Autour de moi",
    Forgot_password_title:"Mot de passe oublié"

  }
});
