import * as React from "react";
import {
  Grid,
  TextField,
} from "@mui/material";

import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import Toast from "../../UiComponent/Toast/Toast";
import apiServices from "../../services/RequestHandler";
import { spacing } from "../../utils/constants";
import CustomButton from "../../UiComponent/CustomButton";
import { FrankIcon } from "../../assets/images/FrankIcons";
import { RootState } from "../../Store";
import { useSelector } from "react-redux";
import { clearAllDataFromLocalStorage, getDataFromLocalStorage } from "../../utils/LocalStore.util";
import { hideEmail } from "../../utils/helper";

function AccountDelete(props: any) {
  const [otp, setOtp] = React.useState("");
  const selector = useSelector((state: RootState) => state?.mainApp);

  const navigate = useNavigate();
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      isEmailDisable: false,
      showOTP: false,
      showSubmitButton: true,
      user: getDataFromLocalStorage("user"),
    }
  );
  console.log("user", state?.user);
// eslint-disable-next-line
  let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const onInputChange = (e: any) => {
    try {
      const { value, name } = e.target;
      if (value[0] === " ") {
        return;
      }

      setState({
        [name]: value,
      });
    } catch (error) {}
  };
  // const onCheckboxChange = (e: any) => {
  //   try {
  //     const { name, checked } = e.target;

  //     setState({
  //       [name]: checked,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const submitEmail = async () => {
    try {
      setState({ disableBtn: true });
      if (!state?.user && !state?.email) {
        Toast.Error("Veuillez entrer une adresse e-mail valide");
        setState({ disableBtn: false });
        return;
      }
      if (!state?.user && !regEmail.test(state?.email)) {
        Toast.Error("Veuillez entrer une adresse e-mail valide");
        setState({ disableBtn: false });
        return;
      }
      let obj = {
        email: state?.user ? state?.user?.email : state?.email,
      };
      let resp = await apiServices?.postFromApi("c2c/validate-delete", obj, "");
      setState({
        resp_otp: resp?.result,
      });
      if (resp?.status === 200 || resp?.status === 201) {
        setState({
          isEmailDisable: true,
          showSubmitButton: false,
          showOTP: true,
          disableBtn: false,
        });
      } else {
        Toast.Error(
          JSON.stringify(
            resp?.error ??
              resp?.error?.error ??
              resp?.isEmail ??
              resp?.isNotEmpty ??
              resp?.message ??
              "Something went wrong. Please wait a short while and try again. If problem persists then contact to support team."
          )
        );
        setState({ disableBtn: false });
      }
    } catch (error) {
      setState({ disableBtn: false });
    }
  };
  const submitOtp = async () => {
    try {
      if (!otp) {
        Toast.Error("OTP ne doit pas être vide"); // Ensure correct usage of Toast library
        return;
      }
      if (otp.length < 4) {
        Toast.Error("La longueur de l'OTP ne peut pas être inférieure à 4"); // Ensure correct usage of Toast library
        return;
      }

      let obj = {
        email: state?.user ? state?.user?.email : state?.email ,
        deleted_otp: Number(otp),
      };

      let resp = await apiServices?.postFromApi(
        "c2c/verify-otp-and-delete",
        obj,
        ""
      );

      if (resp?.status === 201 || resp?.status === 200) {
        Toast.showSuccess("Compte supprimé avec succès");
        clearAllDataFromLocalStorage()
        console.log("resp",resp);

        navigate("/");
      } else {
        console.log("resp",resp);
        
        Toast.Error(
          JSON.stringify(
            resp?.error ??
              resp?.error?.error ??
              resp?.isEmail ??
              resp?.isNotEmpty ??
              resp?.isNumber ?? 
              resp?.message ??
              "Something went wrong. Please wait a short while and try again. If the problem persists, contact the support team."
          )
        );
      }
    } catch (error) {
      Toast.Error("An unexpected error occurred. Please try again.");
      console.error(error);
    }
  };
  const handleChange = (otp: string) => {
    if (otp.length > 4) {
      otp = otp.slice(0, 4);
    }
    setOtp(otp);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          borderBottom: "1px solid #ee6931",
        }}
      >
        <FrankIcon fill={"#ee6931"} height={80} />
      </Grid>

      <Grid
        container
        spacing={spacing?.spacing2}
        style={{
          padding: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          xs={12}
          md={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Variable",
              fontSize: "42px",
              fontWeight: "700",
              color: "#ee6931",
            }}
          >
            {selector?.lang === "fr"
              ? `Etes-vous sûr de vouloir supprimer votre compte ?`
              : `Are you sure you want to delete your acccount?`}
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: 20,
          }}
        >
          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontSize: "19px",
              margin: 0,
              textAlign: "center",
            }}
          >
            {selector?.lang === "fr"
              ? `Une fois que vous avez supprimé votre compte, vous ne pouvez pas l'annuler. Toutes vos données seront définitivement effacées de cette application, y compris vos informations de profil, vos préférences, le contenu enregistré et tout historique d'activité`
              : `Once you delete your account, it cannot be undone. All your data will be permanently erased from this app indudes your profile information, preferences, saved content, and any activity history`}
          </p>

          <p
            style={{
              fontFamily: "Raleway-Italic",
              fontSize: "19px",
              margin: 0,
              textAlign: "center",
            }}
          >
            {selector.lang === "fr"
              ? `Nous sommes tristes de vous voir partir 🥹 mais nous comprenons que cela soit parfois nécessaire. Veuillez prendre un moment pour réfléchir aux conséquences avant de continuer. `
              : `We're sad to see you go 🥹 but we understand that sometimes it's necessary. Please take a moment to consider the consequences before proceeding.`}
          </p>
        </Grid>

        <Grid
          xs={12}
          item
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <p style={{ textAlign: "right",display:'inline-block' }}>edit</p> */}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {state?.isEmailDisable && !state?.user ? (
              <div>
                <p
                  style={{
                    textAlign: "right",
                    fontFamily: "Raleway-Variable",
                    color: "#ee6931",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setState({
                      isEmailDisable: false,
                      showOTP: false,
                      showSubmitButton: true,
                      is_accepted_one: false,
                      is_accepted_two: false,
                      is_accepted_three: false,
                    });
                    setOtp("");
                  }}
                >
                  Edit
                </p>
              </div>
            ) : null}

            {state?.user ? null : (
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  style={{ width: "380px" }}
                  onChange={onInputChange}
                  value={state?.email}
                  name="email"
                  disabled={state?.isEmailDisable}
                />
              </div>
            )}
          </div>
        </Grid>

        {state?.showOTP ? (
          <>
            <Grid
              xs={12}
              item
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Raleway-Italic",
                  fontSize: "19px",
                  textAlign: "center",
                }}
              >
                {selector.lang === "fr"
                  ? `Nous vous avons envoyé un code de vérification à votre adresse e-mail ${hideEmail(
                      state.user?.email ? state.user?.email : state?.email
                    )}. Veuillez saisir le code ci-dessous pour procéder à la suppression du profil`
                  : `We've sent a verification code to your email ${hideEmail(
                      state.user?.email ? state.user?.email : state?.email
                    )}. Please enter the code below to proceed with profile deletion.`}
              </p>
              <div
                style={{
                  border: "1px solid lightgrey",
                  padding: "2px",
                  borderRadius: "6px",
                }}
              >
                <OTPInput
                  value={otp}
                  onChange={handleChange}
                  numInputs={4}
                  renderSeparator={
                    <span style={{ color: "lightgrey", fontWeight: "700" }}>
                      -
                    </span>
                  }
                  renderInput={(props) => (
                    <input
                      {...props}
                      style={{
                        border: "none",
                        width: "55px",
                        height: "42px",
                        borderRadius: "8px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        background: "#f4f4f4",
                        color: "#ee6931",
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
            {/* <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  style={{ color: "grey", fontSize: "12px" }}
                  control={
                    <Checkbox
                      size="small"
                      onChange={(event) => onCheckboxChange(event)}
                      name="is_accepted_one"
                      checked={state?.is_accepted_one ? true : false}
                    />
                  }
                  label="You won't be able to reactivate your account"
                />

                <FormControlLabel
                  style={{ color: "grey", fontSize: "12px" }}
                  control={
                    <Checkbox
                      onChange={(event) => onCheckboxChange(event)}
                      name="is_accepted_two"
                      checked={state?.is_accepted_two ? true : false}
                      style={{ fontSize: "12px" }}
                      size="small"
                    />
                  }
                  label="You'll no longer be able to use Frank"
                />
                <FormControlLabel
                  style={{ color: "grey", fontSize: "12px" }}
                  control={
                    <Checkbox
                      name="is_accepted_three"
                      onChange={(event) => onCheckboxChange(event)}
                      checked={state?.is_accepted_three ? true : false}
                      style={{ fontSize: "12px" }}
                      size="small"
                    />
                  }
                  label="Your account will be inactive for the next 30 days. then it will delete permanantly"
                />
              </FormGroup>
            </Grid> */}
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                value={"Continuer"}
                style={{
                  background: otp?.length < 4 ? "lightgrey" : "#ee6931",
                  fontFamily: "Raleway-Variable",
                  FontSize: "22px",
                  color: "#fff",
                  border: "1px solid #fff",
                  padding: "10px",
                  width: "200px",
                }}
                disable={otp?.length < 4}
                onClick={submitOtp}
              />
            </Grid>
          </>
        ) : null}

        {state?.showSubmitButton ? (
          <>
            <Grid
              xs={12}
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CustomButton
                value={
                  selector.lang === "fr"
                    ? "Supprimer mon compte"
                    : "Delete my Account"
                }
                style={{
                  background: "#ee6931",
                  fontFamily: "Raleway-Variable",
                  FontSize: "22px",
                  color: "#fff",
                  border: "1px solid #fff",
                  padding: "10px",
                  width: "200px",
                }}
                onClick={submitEmail}
              />
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
}

export default AccountDelete;
