import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FrankIcon } from "../../assets/images/FrankIcons";
import CustomButton from "../../UiComponent/CustomButton";
import { defaultLabelColors } from "../../utils/constants";

function Unscubscribe(props: any) {
    const navigate = useNavigate()
  return (
    <>
      <div
        style={{
          background: defaultLabelColors.main_background_two,
          minHeight: "100vh"
        }}
      >
        <Grid container>
          <Grid xs={12} item>
            <div
              className="display-flex"
              style={{ flexDirection: "column", gap: "25px" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <FrankIcon
                  fill={defaultLabelColors.main_orange}
                  height={200}
                  width={200}
                />
              </div>
              <div
                style={{
                  fontFamily: "Raleway-Bold",
                  color: "#281208",
                  fontSize: "26px",
                }}
              >
                Vos préférences ont été mises à jour
              </div>
              <div
                style={{
                  fontFamily: "Raleway-Semi-Bold",
                  color: "#78778A",
                  fontSize: "22px",
                }}
              >
                Vos changements seront effectifs dans les prochaines 48 heures.
              </div>
              <div>
                <CustomButton
                  style={{
                     fontSize: "18px",
                    fontWeight: "500",
                    color: "#fff",
                    fontFamily: "Raleway-Bold",
                    background: "#ee6931",
                    border: "none",
                    borderRadius: "12px",
                    padding: "15px 40px 15px 40px",
                  
                    width: "100%",
                    marginTop: "10px",
                  }}
                  value={"Découvrez-en plus"}
                  onClick={() => {navigate("/")}}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Unscubscribe;
