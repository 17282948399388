// MapForLocations.tsx
import React, { useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import apiServices from "../../services/RequestHandler";
import MarkerPinIcon from "../../assets/images/FrankIcons/PinMarkerIcon.png";
import locImage from "../../assets/images/FrankIcons/myLoc.png";

interface MapForLocationsProps {
  location?: { lat: number; lng: number };
  handleGetLocation?: (address: any) => void;
}

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
};

const MapForLocations: React.FC<MapForLocationsProps> = ({
  location,
  handleGetLocation,
}) => {
  const [currentLocation, setCurrentLocation] = useState(location);
  const [userLocation, setUserLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
// eslint-disable-next-line
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  useEffect(() => {
    if (location) {
      setCurrentLocation(location);
    }
  }, [location]);
  const onMapPress = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();

      // Set the marker to the clicked position
      setMarkerPosition({ lat, lng });

      try {
        // Reverse geocode to get the address from the lat/lng
        const resp = await apiServices.getFromApi(
          `helper/get-address?lat=${lat}&lng=${lng}`,
          ""
        );
        // Call the callback function with the returned address
        if (handleGetLocation) {
          handleGetLocation(resp);
        }
      } catch (error) {
        console.error("Error fetching the address:", error);
      }
    }
  };
  const handleGetUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords || {};
          if (latitude && longitude) {
            const newLocation = { lat: latitude, lng: longitude };
            setUserLocation(newLocation);
            setCurrentLocation(newLocation);

           } else {
            console.error("Location coordinates are not available.");
          }
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
              break;
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{
        width: "100%",
        height: "100%",
        borderRadius: "16px",
      }}
      center={ currentLocation ? currentLocation : userLocation ? userLocation :  { lat: 48.85747021102465, lng: 2.3532122881782325}}
      zoom={12}
      options={options}
      onClick={onMapPress}
    >
      {currentLocation ? (
        <Marker
          icon={{
            url: MarkerPinIcon,
            scaledSize: new window.google.maps.Size(50, 50),
          }}
          position={currentLocation}
        />
      ) : null}

      <button
        onClick={handleGetUserLocation}
        style={{
          position: "absolute",
          bottom: "120px",
          right: "10px",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
        }}
      >
        <img
          src={locImage}
          alt="My Location"
          style={{ width: "20px", height: "20px" }}
        />
      </button>
    </GoogleMap>
  );
};

export default MapForLocations;
