import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import apiServices from "../services/RequestHandler";
import Toast from "../UiComponent/Toast/Toast";
import { firebaseConfig } from "./constants";
import { getDataFromLocalStorage } from "./LocalStore.util";
import {   initializeFirestore } from "firebase/firestore";

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

// export const firestoreDB = getFirestore(firebaseApp);
export const firestoreDB = initializeFirestore(
  firebaseApp,
  {},
  "frank" // Replace with your actual database name
);

export const requestPermission = async (): Promise<boolean> => {
  try {
    // Request permission from the user
    const isNotifAvailable = "Notification" in window;
    if (isNotifAvailable) {
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        return true; // Return true when granted
      } else {
        return false; // Return false when not granted
      }
    } else {
      return false;
    }
  } catch (error) {
    return false; // Return false in case of an error
  }
};

export const checkForPermission = async () => {
  try {
    const isNotifAvailable = "Notification" in window;
    if (isNotifAvailable) {
      const permissionGranted = await requestPermission();

      if (permissionGranted) {
        const token = await getDeviceToken();
        const user = getDataFromLocalStorage("user");
        if (token && user?.id) {
          await apiServices.postFromApi(
            "c2c/u-token",
            {
              device_token: token,
            },
            ""
          );
        }
      } else {
        Toast.showInfo(
          "Activer les notifications à partir des paramètres du navigateur"
        );
      }
    }
  } catch (error) {}
};

export const getDeviceToken = () => {
  console.log("Notification" in window);
  return getToken(messaging, {
    vapidKey:
      "BA0NbUKT40AdGUe30tGiZ4joEiTP59nuSi1xSEss1ylHtSyLkDC2SWA3M_gyJXwhEt8fUzowZgMDaeTNWOsCb8g",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        return null;
      }
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
};

// interface Payload {
//   notification: {
//     title: string;
//     image?: string; // Optional field
//   };
// }

// const payload: Payload = {
//   notification: {
//     title: "Notification Title",
//     image: "path_to_image", // Optional
//   },
// };

onMessage(messaging, (payload: any) => {
  navigator.serviceWorker
    .getRegistration("/firebase-cloud-messaging-push-scope")
    .then((registration: ServiceWorkerRegistration | undefined) => {
      apiServices
        .getFromApi("/notifications/by-user", "")
        .then((resp: any) => console.log("CONS"))
        .catch((err) => console.log(err));
    });
});
