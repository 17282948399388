import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import { color_theme } from "../../../utils/constants";

export function CheckoutForm(props: any) {
  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const { stripe, elements } = props as any;

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "https://www.v2internal.frankme.com/",
      },
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      if (props.handleConfirmPayment) {
        props.handleConfirmPayment();
      }
      if (props.onPressBuy) {
        props.onPressBuy();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        style={{
          padding: 15,
          backgroundColor: props.stripe ? color_theme.main_blue : "#E7E7E7",
          background: color_theme.main_blue,
          borderRadius: 6,
          placeContent: "center",
          display: "flex",
          placeItems: "center",
          marginTop: 15,
          border: "none",
          outline: "none",
          fontSize: 14,
          fontFamily: "Raleway-Semi-Bold",
          lineHeight: "18px",
          color: color_theme.white,
          width: "100%",
          cursor: "pointer",
        }}
        disabled={!props.stripe}
      >
        Ajouter la carte
      </button>
    </form>
  );
}

export default function InjectedCheckoutForm(props: any) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          handleConfirmPayment={props.handleConfirmPayment}
          onPressBuy={props.onPressBuy}
        />
      )}
    </ElementsConsumer>
  );
}
